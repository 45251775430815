import {Component, OnInit, ViewChild} from '@angular/core';
import {HotelService} from '../../services/hotel.service';
import {Router} from '@angular/router';
import {Store} from '../../store';
import {OverlayService} from '../../services/overlay.service';
import {RoomService} from '../../services/room.service';

@Component({
  selector: 'app-room-type-list',
  templateUrl: './room-type-list.component.html',
  styleUrls: ['./room-type-list.component.css']
})
export class RoomTypeListComponent implements OnInit {

    roomTypes$ = this.store.select<any[]>('roomTypes');
    params$ = this.store.select<any>('hotelParams');

    constructor(
        private router: Router,
        private store: Store,
        private overlayService: OverlayService,
        private roomService: RoomService,
    ) {}

    ngOnInit(
    ) {

    }
    add() {
        this.overlayService.open('roomTypeAdd');
        this.store.set('asideOpen', true);
    }
    edit(r) {
        this.store.set('roomType', r);
        this.overlayService.open('roomTypeEdit');
        this.store.set('asideOpen', true);
    }
    delete(roomType) {
        this.store.set('roomType', roomType);
        this.overlayService.open('roomTypeDelete');
    }
}
