<ng-container *ngIf="rooms.length">
    <div class="table-wrapper">

    <table class="mat-elevation-z2 table-container example-full-width data-listing" style="margin-bottom: 30px; margin-top: 30px">
        <!-- <thead>
        <tr>
            <th style="width: 20%">Item name</th>
            <th style="width: 10%"></th>
            <th *ngFor="let r of rooms">{{r.roomName}}</th>
        </tr>
        </thead> -->
        <tbody>
            <ng-container *ngFor="let rg of rooms[0].groups; let g = index">
                <tr>
                    <td colspan="6"><h2 class="mat-title mt-4">{{rg.name}}</h2></td>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th *ngFor="let r of rooms">{{r.roomName}}</th>
                </tr>
                <ng-container *ngFor="let rf of rg.products; let i = index">
                <tr>
                    <td>
                        {{rf.name}}
                    </td>
                    <td>
                        <a class="hover" (click)="toggleAll(rf, g, i)">[toggle all]</a>
                    </td>
                    <td *ngFor="let r of rooms">
                        <a class="hover" *ngIf="r.groups[g].products[i].selected" (click)="toggleFeature(r.roomID, r.groups[g].products[i])">
                            <mat-icon style="color: #28a745">check_circle</mat-icon>
                        </a>
                        <a class="hover" *ngIf="!r.groups[g].products[i].selected" (click)="toggleFeature(r.roomID, r.groups[g].products[i])">
                            <mat-icon style="color: #dddddd">check_circle_outline</mat-icon>
                        </a>

                        <!-- <a  (click)="toggleFeature(r.roomID, r.groups[g].products[i])">
                            {{r.groups[g].products[i].selected}}

                        </a> -->
                        <!-- <a *ngIf="!r.groups[g].products[rf].selected" (click)="toggleFeature(r.roomID, rf)">
                            <mat-icon style="color: #dddddd">check_circle_outline</mat-icon>
                        </a> -->

                    </td>
                </tr>


            </ng-container>
        </ng-container>
        </tbody>
    </table>

</div>

</ng-container>



<ng-container *ngIf="productGroups.length">
    xx
    <div class="table-wrapper">

    <table
        class="mat-elevation-z2 example-full-width data-listing"
        style="margin-bottom: 30px"
        *ngFor="let pg of productGroups">
        <thead>
        <tr>
            <th style="width: 33%">Product Group</th>
            <th style="width: 33%">Product Name</th>
            <th>Selected</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let p of pg.products">
            <td>{{pg.name}}</td>
            <td>{{p.name}}</td>
            <td>
                <a *ngIf="p.selected" (click)="toggleFeature(p)">
                    <mat-icon style="color: #28a745">check_circle</mat-icon>
                </a>
                <a *ngIf="!p.selected" (click)="toggleFeature(p)">
                    <mat-icon style="color: #dddddd">check_circle_outline</mat-icon>
                </a>
            </td>
        </tr>

        </tbody>
    </table>

</div>

</ng-container>

