import { Component, OnInit } from '@angular/core';
import {ProductService} from '../../services/product.service';
import {Store} from '../../store';
import {OverlayService} from '../../services/overlay.service';

@Component({
  selector: 'app-product-group-delete',
  templateUrl: './product-group-delete.component.html',
  styleUrls: ['./product-group-delete.component.css']
})
export class ProductGroupDeleteComponent implements OnInit {

    product_group = {
        ID: '',
        name: '',
        logo: undefined
    };

    constructor(
        private productService: ProductService,
        private store: Store,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
        const product_group = this.store.selectForLocal('productGroup');
        if (product_group) {
            this.product_group = product_group;
        }
    }
    close() {
        this.overlayService.closeAll();
    }
    delete() {
        this.productService.groupDelete(this.product_group).subscribe(data => {
            this.close();
        });
    }
}
