import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import { GrowlerService } from './growler.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HotelService {
    env = environment;

    defaultParams = {
        searchTerm: '',
        name: '',
        county: '',
        limit: 10,
        limits: [10, 20, 30, 40],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'hotel.name ASC',
        sorts: [
            { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
            { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
            { ID: 'county.name DESC', name: 'County A-Z' },
            { ID: 'county.name ASC', name: 'County Z-A' },
        ],
    };


    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) { }

    allLite() {
        return this.http.get(this.env.apiV2Path + 'hotel/allLite').pipe(map((data: any) => {
            return data.data;
        }));
    }
    getHotelBySlug(slug) {
        return this.http.get(environment.apiV2Path + 'hotel/' + slug).pipe(
            map((data: any) => {
                return data.data;
            })
        );
    }

    updateParams(params) {
        this.store.set('hotelParams', params);
        this.search();
    }
    resetParams() {
        this.store.set('hotelParams', JSON.parse(JSON.stringify(this.defaultParams)));
        console.log(JSON.parse(JSON.stringify(this.defaultParams)))
        this.search();
    }
    search() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('hotelParams')
        };
        return this.http.post(this.apiService.path() + 'hotel/search', dataToSend).subscribe((data: any) => {
            this.store.set('hotels', data.data);
            const p = this.store.selectForLocal('hotelParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (let i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            this.store.set('hotelParams', p);
            return data.data;
        });
    }

    all() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('hotelParams')
        };
        return this.http.post(this.apiService.path() + 'hotel/search', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }


    toggleObject(hotel, field) {
        const data = this.store.selectForLocal('hotels');
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === hotel.id) {
                if (!data[i][field]) {
                    data[i][field] = true;
                } else {
                    data[i][field] = false;
                }
            }
        }
        this.store.set('hotels', data);
        this.saveRemote(hotel);
    }

    setHotel(hotel) {
        const data = JSON.parse(JSON.stringify(this.store.selectForLocal('hotels')));
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === hotel.id) {
                data[i] = hotel;
            }
        }
        this.store.set('hotel', hotel);
        this.store.set('hotels', data);
    }
    save(hotel) {
        const data = this.store.selectForLocal('hotels');
        let foundRecord = false;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === hotel.id) {
                data[i] = hotel;
                foundRecord = true;
            }
        }
        this.store.set('hotels', data);

        this.saveRemote(hotel);
        this.search();
    }

    createRemote(hotel) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            hotel: hotel
        };
        return this.http.post(this.apiService.path() + 'hotel', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }



    saveRemote(hotel) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            hotel: hotel
        };
        return this.http.post(this.apiService.path() + 'hotel', dataToSend).subscribe((data: any) => {
            if (data.errors.length) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['Hotel details updated']);
                this.setHotel(data.data);
            }
        });
    }

    saveFeatureGroup(f) {
        const dataToSend = {
            featureGroup: f
        };
        return this.http.post(this.apiService.path() + 'hotel/features/group/save', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }

    hotelFeatures(hotelID) {
        const dataToSend = {
            hotelID
        };
        return this.http.post(this.apiService.path() + 'hotel/features', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    sortHotelFeatures(hotelFeatures) {
        const dataToSend = {
            hotelFeatures: hotelFeatures
        };
        return this.http.post(this.apiService.path() + 'hotel/features/sort', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }

    toggleFeature(f, hotelID) {
        const dataToSend = {
            hotelID,
            feature: f
        };
        return this.http.post(this.apiService.path() + 'hotel/features/index', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    addFeature(f) {
        const dataToSend = {
            feature: f
        };
        return this.http.post(this.apiService.path() + 'hotel/features/add', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    deleteFeature(featureToDelete) {
        console.log(featureToDelete)
        const dataToSend = {
            id: featureToDelete.id
        };
        return this.http.post(this.apiService.path() + 'hotel/features/delete', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }

}
