import { Component, OnInit } from '@angular/core';
import { Store } from '../store';
import { ReportingService } from '../services/reporting.service';

@Component({
    selector: 'app-rr-api',
    templateUrl: './rr-api.component.html',
    styleUrls: ['./rr-api.component.css']
})
export class RrApiComponent implements OnInit {

    data = [];

    loading = false;

    params = {
        searchText: '',
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "rr_api.createdAt DESC",
        sorts: [
            { ID: "rr_api.createdAt DESC", name: "Recent First" },
            { ID: "rr_api.createdAt ASC", name: "Recent Last" },
        ],
    };

    asideOpen$ = this.store.select<boolean>('asideOpen');
    overlays$ = this.store.select<any>('overlays');

    constructor(
        private store: Store,
        private reportingService: ReportingService,
    ) {
    }

    ngOnInit() {
        this.filterSearch();
    }

    setSort(v) {
        this.params.sort = v;
        this.search();
    }

    setParam(name, value) {
        this.params[name] = '';
        this.search();
    }

    filterSearch() {
        this.params.page = 0;
        this.search();
    }

    search() {
        this.loading = true;

        this.reportingService.searchRRSync(this.params).subscribe(data => {
            console.log(data.data)
            this.data = data.data;

            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
            window.scroll(0, 0);
            this.loading = false;
        });
    }

    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }

}
