import { ToolsService } from './../../services/tools.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '../../store';
import { Router } from '@angular/router';
import { RoomService } from '../../services/room.service';
import { OverlayService } from '../../services/overlay.service';

@Component({
    selector: 'app-rooms-list',
    templateUrl: './rooms-list.component.html',
    styleUrls: ['./rooms-list.component.css']
})
export class RoomsListComponent implements OnInit {

    rooms$ = this.store.select<any[]>('rooms');
    params$ = this.store.select<any>('roomParams');

    constructor(
        private store: Store,
        private router: Router,
        private roomService: RoomService,
        private overlayService: OverlayService,
        private toolsService: ToolsService,
    ) { }

    ngOnInit() {
    }
    edit(room) {
        if (room) {
            this.roomService.setRoom(room);
        } else {
            room = {
                isNew: true,
                id: this.toolsService.secureId(),
                BB: true,
                SC: false,
                beachside: false,
                childrensZBeds: true,
                checkIn: '3pm to 9pm',
                checkOut: 'Until 11am',
                cost: 0,
                deposit: 50,
                deposit_text: '',
                description: '',
                disabledFriendly: false,
                dogFriendly: false,
                familyRoom: false,
                features: [],
                floorPlan: {},
                groundFloor: false,
                hotel: { name: '', id: '' },
                images: [],
                maxAdults: 2,
                maxChildren: 2,
                maxGuests: 4,
                minAdults: 1,
                minChildren: 0,
                minNights: 1,
                minNightsSC: 0,
                name: '',
                numberOfRooms: 1,
                rateIncludedAdults: 0,
                roomNumber: '',
                roomType: { name: '', id: '' },
                shortDescription: '',
                sofabedThreshold: 0,
                topRated: true,
                twinnable: false,
                urgency_message: false,
                urgency_message_text: '',
                welcome_room_type: '',
            }
        }
        console.log(room)

        this.roomService.setRoom(room);

        this.router.navigate(['/rooms/edit']);
    }
    editFeatures(room) {
        this.roomService.setRoom(room);
        this.store.set('asideOpen', true);
        this.overlayService.open('roomFeatures');
    }

    delete(room) { }
}
