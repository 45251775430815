import { Component, OnInit, Input } from '@angular/core';
import { Store } from '../../store';

@Component({
    selector: 'app-hotel-address-form',
    templateUrl: './hotel-address-form.component.html',
    styleUrls: ['./hotel-address-form.component.css']
})
export class HotelAddressFormComponent implements OnInit {
    @Input() hotel;

    counties$ = this.store.select<any[]>('counties');

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    constructor(
        private store: Store
    ) { }

    ngOnInit() {
    }

}
