<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/vouchers']">Vouchers</a>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary" (click)="create()">Create Voucher</button>
        </div>
    </div>
</mat-card>

  <div class="mat-card mt-2 p-2">
      <div class="row">
          <div class="col-md-4">
              <mat-form-field class="padding-10 example-full-width">
                  <mat-label>Voucher name</mat-label>
                  <input matInput type="text" autocomplete="off" name="searchText" [(ngModel)]="params.searchText" (keyup)="filterSearch()">
              </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.hotel"
                    (selectionChange)="filterSearch()"
                    placeholder="Brands">
                    <mat-option [value]="undefined">All Brands</mat-option>
                    <mat-option
                        [value]="sy.id"
                        *ngFor="let sy of (hotels$ | async)">
                        {{sy.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

          <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.active"
                    (selectionChange)="filterSearch()"
                    placeholder="Active">
                    <mat-option [value]="1">Only active</mat-option>
                    <mat-option [value]="0">All</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="filterSearch()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

          <div class="col-md-2">
              <mat-form-field class="padding-10">
                  <mat-select [(ngModel)]="params.limit" (ngModelChange)="filterSearch()" placeholder="Per page">
                      <mat-option [value]="l" *ngFor="let l of params.limits">{{l}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>

      </div>
  </div>


<div class="mat-card mt-4 p-2">
  <div class="card mb-3 no-records" *ngIf="!loading && !data.length">
      No results for the search criteria entered
  </div>
  <div class="card mb-3 no-records" *ngIf="loading">
      Loading data <i class="fa fa-spinner fa-spin"></i>
  </div>


  <div *ngIf="data.length">


      <table class="example-full-width data-listing">
          <thead>
          <tr>
              <th>
                  <a *ngIf="params.sort === 'voucher.name DESC'" (click)="setSort('voucher.name')">Name <i class="fa fa-angle-up"></i></a>
                  <a *ngIf="params.sort !== 'voucher.name DESC'" (click)="setSort('voucher.name DESC')">Name <i class="fa fa-angle-down"></i></a>
              </th>
              <th>
                <a *ngIf="params.sort === 'voucher.type DESC'" (click)="setSort('voucher.type')">Type <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'voucher.type DESC'" (click)="setSort('voucher.type DESC')">Type <i class="fa fa-angle-down"></i></a>
            </th>
            <th>
                <a *ngIf="params.sort === 'voucher.hotel DESC'" (click)="setSort('voucher.hotel')">Hotel <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'voucher.hotel DESC'" (click)="setSort('voucher.hotel DESC')">Hotel <i class="fa fa-angle-down"></i></a>
            </th>
            <th>
                <a *ngIf="params.sort === 'packages.name DESC'" (click)="setSort('packages.name')">Promotion <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'packages.name DESC'" (click)="setSort('packages.name DESC')">Promotion <i class="fa fa-angle-down"></i></a>
            </th>
           <th class="d-none d-lg-table-cell">
                 <a *ngIf="params.sort === 'voucher.active DESC'" (click)="setSort('voucher.active')">Active <i class="fa fa-angle-up"></i></a>
                 <a *ngIf="params.sort !== 'voucher.active DESC'" (click)="setSort('voucher.active DESC')">Active <i class="fa fa-angle-down"></i></a>
             </th>
             <th>Assigned to</th>
             <th>Used</th>

              <th style="min-width: 90px" class="d-none d-xl-table-cell">
                  <a *ngIf="params.sort === 'users.createdAt DESC'" (click)="setSort('users.createdAt')">createdAt <i class="fa fa-angle-up"></i></a>
                  <a *ngIf="params.sort !== 'users.createdAt DESC'" (click)="setSort('users.createdAt DESC')">createdAt <i class="fa fa-angle-down"></i></a>
              </th>
              <th></th>
          </tr>
          </thead>
          <tbody>
          <tr class="hover" *ngFor="let data of data; let i = index" [ngClass]="{'tr-odd': isOdd(i)}">
              <td (click)="update(data)">{{data.name}}</td>
              <td (click)="update(data)" style="width: 175px">
                <span *ngIf="data.type === 'promo'">Promotion</span>
                <span *ngIf="data.type === 'discount'">
                    <span *ngIf="data.discountType === 'PERC'">{{data.discountValue}}% off</span>
                    <span *ngIf="data.discountType === 'POUND'">{{data.discountValue | currency: 'GBP'}} off</span>
                </span>
                </td>
              <td (click)="update(data)" style="width: 175px">{{data.hotels}}</td>
              <td (click)="update(data)">{{data.promotionName}}</td>
              <td (click)="update(data)" style="width: 175px">
                  <mat-icon *ngIf="data.active" class="text-success">check</mat-icon>
                  <mat-icon *ngIf="!data.active" class="text-danger">remove</mat-icon>
              </td>

              <td (click)="update(data)">{{data.totalUsers}}</td>
              <td (click)="update(data)">{{data.totalUsed}}</td>

              <td class="d-none d-xl-table-cell">{{data.createdAt | date:'dd MMM yyyy'}}</td>
                <td class="text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="viewUsers(data)">
                            <mat-icon>group</mat-icon>
                            <span>View Users</span>
                        </button>
                        <button mat-menu-item (click)="update(data)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="delete(data)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </td>
          </tr>
          </tbody>
      </table>

      <app-pagination [params]="params" (update)="search()"></app-pagination>

  </div>

</div>


