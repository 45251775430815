import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FaqService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
    ) {
    }

    getQuestions() {
        return this.http.get(this.env.apiV2Path + 'faq')
            .pipe(
                map((data: any) => {
                    this.store.set('faqQuestions', data.data);
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    findAllTopics() {
        const faq = this.store.selectForLocal('faq');
        const dataToSend = {
            params: faq.params,
        };
        return this.http.post(this.env.apiV2Path + 'faq/search', dataToSend)
            .pipe(
                map((data: any) => {
                    faq.data = data.data.data;

                    faq.params.pages = Math.ceil(data.data.totalRecords / faq.params.limit);
                    faq.params.totalRecords = data.data.totalRecords;
                    faq.params.pageArray = [];
                    for (let i = 0; i < faq.params.pages; i++) {
                        faq.params.pageArray.push(i);
                    }
                    this.store.set('faq', faq);

                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    findOne(id) {
        return this.http.get(this.env.apiV2Path + 'faq/' + id)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    create(topic) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            topic,
            user,
        };
        return this.http.post(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    update(topic) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            topic,
            user,
        };
        return this.http.put(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    createQuestion(question) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            question,
            user,
        };
        return this.http.post(this.env.apiV2Path + 'faq/question', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    updateQuestion(question) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            question,
            user,
        };
        return this.http.put(this.env.apiV2Path + 'faq/question', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    sortTopics(topics) {
        const dataToSend = {
            topics
        };
        return this.http.post(this.env.apiV2Path + 'faq/sort', dataToSend)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    sortQuestions(questions) {
        const dataToSend = {
            questions
        };
        return this.http.post(this.env.apiV2Path + 'faq/questions/sort', dataToSend)
            .pipe(map((data: any) => {
                return data;
            }));
    }


}
