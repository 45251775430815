<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            Add ons
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card class="margin-top-10">
    <div class="row">
        <div class="col-md-4">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.searchText" (keyup)="getCards()" placeholder="Freetext search">
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.hotelId"
                    (selectionChange)="getCards()"
                    placeholder="Brands">
                    <mat-option [value]="undefined">All Brands</mat-option>
                    <mat-option
                        [value]="sy.id"
                        *ngFor="let sy of (hotels$ | async)">
                        {{sy.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="getCards()" [(ngModel)]="startDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Purchased from">
                <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="getCards()" [(ngModel)]="endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Purchased to">
                <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="getCards()" [(ngModel)]="stayStartDate" (click)="stayStartpicker.open()" [matDatepicker]="stayStartpicker" placeholder="Staying from">
                <mat-datepicker #stayStartpicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="getCards()" [(ngModel)]="stayEndDate" (click)="stayEndpicker.open()" [matDatepicker]="stayEndpicker" placeholder="Staying to">
                <mat-datepicker #stayEndpicker></mat-datepicker>
            </mat-form-field>
        </div>


        <div class="col-md-2">
        <mat-form-field>
            <mat-select
                [(ngModel)]="params.sort"
                (selectionChange)="getCards()"
                placeholder="Sort By">
                <mat-option
                    [value]="sort.id"
                    *ngFor="let sort of params.sorts">
                    {{sort.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    </div>
</mat-card>

<div class="row margin-top-10">
    <div class="col-md-2" *ngFor="let total of totals">
        <mat-card class=" text-center">
            <p class="mb-1">{{total.brand}}</p>
            <p class="mb-1">{{total.totalSpends}} Items</p>
            <p class="mb-1">{{total.totalSpend | currency: 'GBP'}}</p>
        </mat-card>

    </div>
</div>


    <div class="row">


        <div class="col-md-12">

            <mat-card class="margin-top-10">
                <div *ngIf="cards.length">
                    <table class="table example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>Total Value</th>
                            <th>Purchased</th>
                            <th>Brand</th>
                            <th>Item Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let card of cards; let i = index">
                            <td style="text-align: right;">{{card.totalSales | currency: 'GBP'}}</td>
                            <td style="text-align: right;">{{card.totalSpends}}</td>
                            <td>{{card.hotel}}</td>
                            <td>{{card.name}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>

<!-- <mat-card class="margin-top-10">
    <app-pagination [params]="params" [term]="'Cards'" (update)="paginate($event)"></app-pagination>
</mat-card> -->



