<div class="mb-3 mt-3">


  <div class="d-flex align-items-center">
      <h6 class="mb-0">Search</h6>
  </div>


  <div class="mat-card mt-2 p-2">
      <div class="row">
          <div class="col-3 col-lg-3">
              <mat-form-field class="padding-10" appearance="outline">
                  <mat-label>Customer name</mat-label>
                  <input matInput type="text" autocomplete="off" name="searchText" [(ngModel)]="params.searchText" (keyup)="filterSearch()">
              </mat-form-field>
          </div>

          <div class="col-3 col-lg-3">
              <mat-form-field class="padding-10" appearance="outline">
                  <mat-label> </mat-label>
                  <mat-select [(ngModel)]="params.limit" (ngModelChange)="filterSearch()">
                      <mat-option [value]="l" *ngFor="let l of params.limits">{{l}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>

          <div class="col-md-3 text-right">
              <a mat-raised-button color="danger" (click)="clearList()">Clear List</a>
          </div>
      </div>
  </div>


</div>

<div class="mat-card mt-2 p-2">
  <div class="card mb-3 no-records" *ngIf="!loading && !data.length">
      No results for the search criteria entered
  </div>
  <div class="card mb-3 no-records" *ngIf="loading">
      Loading data <i class="fa fa-spinner fa-spin"></i>
  </div>


  <div *ngIf="data.length">


      <table class="example-full-width data-listing">
          <thead>
          <tr>
              <th>
                  <a *ngIf="params.sort === 'users.firstname DESC'" (click)="setSort('users.firstname')">First name <i class="fa fa-angle-up"></i></a>
                  <a *ngIf="params.sort !== 'users.firstname DESC'" (click)="setSort('users.firstname DESC')">First name <i class="fa fa-angle-down"></i></a>
              </th>
              <th>
                <a *ngIf="params.sort === 'users.surname DESC'" (click)="setSort('users.surname')">Surname <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'users.surname DESC'" (click)="setSort('users.surname DESC')">Surname <i class="fa fa-angle-down"></i></a>
            </th>
            <th>
              <a *ngIf="params.sort === 'users.username DESC'" (click)="setSort('users.username')">Email address <i class="fa fa-angle-up"></i></a>
              <a *ngIf="params.sort !== 'users.username DESC'" (click)="setSort('users.username DESC')">Email address <i class="fa fa-angle-down"></i></a>
          </th>
          <th style="min-width: 90px" class="d-none d-xl-table-cell">
                <a *ngIf="params.sort === 'voucher_user.createdAt DESC'" (click)="setSort('voucher_user.createdAt')">Added <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'voucher_user.createdAt DESC'" (click)="setSort('voucher_user.createdAt DESC')">Added <i class="fa fa-angle-down"></i></a>
            </th>
            <th style="min-width: 90px" class="d-none d-xl-table-cell">
              <a *ngIf="params.sort === 'voucher_user.usedAt DESC'" (click)="setSort('voucher_user.usedAt')">Voucher used <i class="fa fa-angle-up"></i></a>
              <a *ngIf="params.sort !== 'voucher_user.usedAt DESC'" (click)="setSort('voucher_user.usedAt DESC')">Voucher used <i class="fa fa-angle-down"></i></a>
          </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of data; let i = index" [ngClass]="{'tr-odd': isOdd(i)}">
              <td>{{data.firstname}}</td>
              <td style="width: 175px">{{data.surname}}</td>
              <td style="width: 175px">{{data.username}}</td>
              <td class="d-none d-xl-table-cell">{{data.createdAt | date:'dd MMM yyyy'}}</td>
              <td class="d-none d-xl-table-cell">{{data.usedAt | date:'dd MMM yyyy @ HH:mm'}}</td>
                <td class="text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="delete(data)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </td>
          </tr>
          </tbody>
      </table>

      <app-pagination [params]="params" (update)="search()"></app-pagination>

  </div>

</div>

<!-- <app-voucher-edit *ngIf="voucherToEdit" [voucher]="voucherToEdit" (complete)="search()"></app-voucher-edit> -->


