
<div class="row">
    <div class="col-md-6">
        <h2 class="mat-title">Hotel Address</h2>

        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="hotel.address.add1" placeholder="Address Line 1">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="hotel.address.add2" placeholder="Address Line 2">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="hotel.address.add3" placeholder="Address Line 3">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="hotel.address.town" placeholder="Town/City">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-select
                [(ngModel)]="hotel.address.county.id"
                placeholder="Select County">
                <mat-option
                    [value]="c.id"
                    *ngFor="let c of (counties$ | async)">
                    {{c.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="hotel.address.postcode" placeholder="Postcode">
        </mat-form-field>

        <h2 class="mat-title margin-top-20">Hotel location text</h2>
        <quill-editor [modules]="config" [(ngModel)]="hotel.address.addressDescription" [style]="{'height':'200px'}"></quill-editor>

    </div>
    <div class="col-md-6">
        <!-- <agm-map [latitude]="hotel.address.lat" [longitude]="hotel.address.lng">
            <agm-marker [latitude]="hotel.address.lat" [longitude]="hotel.address.lng"></agm-marker>
        </agm-map> -->
    </div>
</div>
