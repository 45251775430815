import {Component, OnInit, Input} from '@angular/core';
import {Store} from '../../store';
import {OverlayService} from '../../services/overlay.service';

@Component({
  selector: 'app-room-overview',
  templateUrl: './room-overview.component.html',
  styleUrls: ['./room-overview.component.css']
})
export class RoomOverviewComponent implements OnInit {
    @Input() room;

    overlays$ = this.store.select<any>('overlays');
    hotels$ = this.store.select<any[]>('hotels');

    startDate = new Date(1990, 0, 1);
    totals = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    yesNo = [
        {id: true, name: 'Yes'},
        {id: false, name: 'No'},
    ];
    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],  // custom dropdown
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    constructor(
        private store: Store,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
        // console.log(this.room.floorPlan.imgBase)
    }

    cropImage() {
        this.store.set('cropImage', this.room.floorPlan);
        this.overlayService.open('imageCropper');

    }

    setFloorPlan(e) {
        console.log(e);
        this.room.floorPlan = e;
    }




}
