<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{confirmationData.title}}</h5>
                <a class="close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">
                <p class="text-left">{{confirmationData.detail}}</p>

                <p class="text-left">
                    Export data to: {{(user$ | async).email}}
                </p>

                <div class="text-center">
                    <button class="btn btn-success btn-width-50 mt-3 mb-3" (click)="confirm()">
                        Export Now
                    </button>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>


