import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {OverlayService} from '../services/overlay.service';
import {ImagesService} from '../services/images.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit {
    @Input() image;
    @Output() result: EventEmitter<any> =  new EventEmitter<any>();

    imageChangedEvent: any = '';
    croppedImage: any = '';
    imageToCrop: any = '';
    cropperReady = false;

    constructor(
        private overlayService: OverlayService,
        private imageService: ImagesService,
    ) { }

  ngOnInit() {
      this.imageToCrop = this.image.imgBase;
      this.cropperReady = true;
  }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event: ImageCroppedEvent) {
        console.log('image cropped');
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log('image loaded');
        this.cropperReady = true;
        // show cropper
    }
    loadImageFailed() {
        console.log('image failed');
        // show message
    }
    save() {
        console.log(this.image);
        this.image.imgBase = this.croppedImage;
        this.imageService.crop(this.image).subscribe(data => {
            console.log(data);
            this.result.emit(this.image);
            this.close();
        });
    }
    close() {
        this.overlayService.close('imageCropper');
    }
}
