<mat-card class="breadcrumb">
    <div class="row">
      <div class="col-md-10">
        <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
        <!--<mat-icon>arrow_forward_ios</mat-icon>-->
        <a [routerLink]="['/feedback/templates']">Templates</a>
      </div>
      <div class="col-md-2 text-right">
        <!--<button id="pullright" mat-raised-button color="primary" routerLink="/hotels/create">Add Hotel</button>-->
      </div>
    </div>
  </mat-card>

<div class="data-container">
    <div class="text-right">
        <button class="btn btn-primary-reverse btn-round btn-slim" (click)="addTemplate()">Add new Template</button>
    </div>

    <div class="no-results" *ngIf="!templates.length">
        <p>No templates found</p>
    </div>

    <div class="mt-4" *ngIf="templates.length">

        <div class="dd">
            <ol class="dd-list mt-2"
                dragula="priCategory"
                [(dragulaModel)]="templates"
                (dragulaModelChange)="drop($event)">
                <li *ngFor="let q of templates"
                    class="dd-item"
                    [ngClass]="{'dd-collapsed' : !q.open}">
                    <div class="dd-handle">
                        <!-- <mat-list-item class="mat-list-item mat-list-item-avatar mat-list-item-with-avatar ng-star-inserted" style="height: 35px">
                            <div class="mat-list-item-content">
                                <div class="mat-list-item-ripple mat-ripple" mat-ripple=""></div>
                                <mat-icon (click)="deleteConfirm(q)" class="mat-icon mat-list-icon material-icons" mat-list-icon="" role="img" aria-hidden="true">
                                    delete
                                </mat-icon>
                                <mat-icon (click)="editTemplate(q)" class="mat-icon mat-list-icon material-icons" mat-list-icon="" role="img" aria-hidden="true">
                                    edit
                                </mat-icon>
                                <div class="mat-list-text">
                                    <h4 class="mat-line" mat-line="">
                                        {{q.subject}} - {{q.template}}
                                    </h4>
                                </div>
                            </div>
                        </mat-list-item> -->

                        <span class="label label-info mr-3">
                            <i class="fa fa-bars"></i>
                        </span>

                        <span class="float-right">
                            <a (click)="deleteConfirm(q)" style="margin-left: 10px">
                                <i class="fa fa-trash"></i>
                            </a>
                        </span>

                        <span class="float-right">
                            <a class="ml-3" (click)="editTemplate(q)">
                                <i class="fa fa-edit"></i>
                            </a>
                        </span>

                        <span class="float-right">
                            <a class="ml-3" (click)="toggleStatus(q)">
                            <i
                                class="toggle fa"
                                [ngClass]="{
                                'fa-toggle-on text-success': q.is_active,
                                'fa-toggle-off' : !q.is_active}"></i>
                            </a>
                        </span>
                        <span class="float-right ml-3">{{q.thumbs_percent}}</span>
                        <span class="float-right">
                            <a [routerLink]="['/feedback/templates', q.id, 'questions']">View questions</a>
                        </span>

                        {{q.name}} - {{q.invite_email_subject}}

                    </div>
                </li>

            </ol>
        </div>





    </div>
<!--        <app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>-->

</div>


<app-confirmation *ngIf="this.confirmationData.open" (complete)="confirmAction($event)" [data]="confirmationData"></app-confirmation>


