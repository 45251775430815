import { Injectable } from '@angular/core';
import {Store} from '../store';
import * as Cookies from 'cookies-js';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    private static readonly LOGIN_COOKIE = 'login-cookie';
    private cookieExpiration = 60 * 5000;

    constructor(
        private store: Store,
        private http: HttpClient,
        private apiService: ApiService,
    ) { }

    login(user) {
        return this.http.post(this.apiService.path() + 'authenticate', user).pipe(
            map((data: any) => {
                if (data.data.authenticated) {
                    this.store.set('user', data.data);
                    this.store.set('showMenu', true);
                    this.setCookie(data.data);
                }
                return data;
            }));
    }

    logout() {
        this.store.set('user', undefined);
        this.store.set('showMenu', false);
        this.removeCookie();
    }



    setCookie(userData) {
        Cookies.set(
            AuthenticationService.LOGIN_COOKIE, JSON.stringify(userData),
            { expires: this.cookieExpiration }
        );
    }

    removeCookie() {
        Cookies.expire(AuthenticationService.LOGIN_COOKIE);
        this.store.set('user', undefined);
    }


    cookieCheck() {
        const lg = Cookies.get(AuthenticationService.LOGIN_COOKIE);
        if (Cookies.get(AuthenticationService.LOGIN_COOKIE)) {
            const user = JSON.parse(Cookies.get(AuthenticationService.LOGIN_COOKIE));
            this.store.set('user', user);
            return true;
        } else {
            this.store.set('user', undefined);
            return false;
        }
    }

    isAuthenticated() {
        const promise = new Promise(
            (resolve, reject) => {
                resolve(this.cookieCheck());
            }
        );
        return promise;
    }


}
