import { Store } from './../../store';
import { EventsService } from './../../services/events.service';
import { Router } from '@angular/router';
import { GrowlerService } from './../../services/growler.service';
import { OverlayService } from './../../services/overlay.service';
import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

    data = [];
    dataToDelete;
    deleteConfirmation = {
        title: 'Confirm delete',
        detail: 'Are you sure you want to delete this event?'
    }

    loading = false;

    yesNo = [{ id: 0, name: 'Please Select' }, { id: 1, name: 'Yes' }, { id: 2, name: 'No' }]
    params = {
        searchText: '',
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "event.name",
        sorts: [
            { ID: "event.firstname ASC", name: "Name A-Z" },
            { ID: "event.firstname DESC", name: "Name Z-A" },
            { ID: "event.createdAt DESC", name: "Recent First" },
            { ID: "event.createdAt ASC", name: "Recent Last" },
        ],
    };

    constructor(
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
        private router: Router,
        private eventsService: EventsService
    ) {
        this.filterSearch = debounce(this.filterSearch, 450);
    }

    ngOnInit() {
        this.filterSearch();
    }

    setSort(v) {
        this.params.sort = v;
        this.search();
    }

    setParam(name, value) {
        this.params[name] = '';
        this.search();
    }

    filterSearch() {
        this.params.page = 0;
        this.search();
    }

    search() {
        this.loading = true;

        this.eventsService.search(this.params).subscribe(data => {
            console.log(data.data)
            this.data = data.data;

            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
            window.scroll(0, 0);
            this.loading = false;
        });
    }


    setStatus(data) {
    }

    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }

    delete(data) {
        this.dataToDelete = data;
    }
    deleteAction(e) {

        if (e.action) {
            this.eventsService.delete(this.dataToDelete).subscribe(data => {
                this.dataToDelete = undefined;
                this.search();
            })
        } else {
            this.dataToDelete = undefined;
        }
    }
    create() {
        this.router.navigate(['/events', 'create'])
    }
    update(data) {
        this.router.navigate(['/events', data.id])
    }

    visitEvent(data) {
        window.open('https://rooms.norfolkpassport.com/events/' + data.eventType.slug + '/' + data.slug, '_blank').focus();
    }
}
