import {Component, Input, OnInit} from '@angular/core';
import {Store} from '../../store';
import {PrebookService} from '../../services/prebook.service';
import {GrowlerService} from '../../services/growler.service';

@Component({
  selector: 'app-prebook',
  templateUrl: './prebook.component.html',
  styleUrls: ['./prebook.component.css']
})
export class PrebookComponent implements OnInit {
    @Input() hotelID;

    pb: any;

  constructor(
      private store: Store,
      private pbService: PrebookService,
      private growlerService: GrowlerService,
  ) { }

  ngOnInit() {
      this.groupGetByHotel(this.hotelID);
  }

    groupGetByHotel(id) {
      this.pbService.groupGetByHotel(id).subscribe(data => {
          this.pb = data;
          console.log(data);
      });
    }
    setFeatureImage(e) {
        console.log(e);
        this.pb.image = e;
    }

    addItem(idx) {
      this.pb.groups[idx].items.push({Id: 0, name: '', cost: 0, description: '', hotel: this.hotelID, item_group: this.pb.groups[idx].id});
    }
    saveItem(item) {
      this.pbService.itemUpdate(item).subscribe(data => {
         this.growlerService.notification(['Item saved']);
      });
    }
    deleteItem(item, idx) {
        this.pbService.itemDelete(item).subscribe(data => {
            for (let i = 0; i < this.pb.groups[idx].items.length; i++) {
                if (this.pb.groups[idx].items[i].id === item.id) {
                    this.pb.groups[idx].items.splice(i, 1);
                }
            }

            this.growlerService.notification(['Item deleted']);
        });
    }
    updateOverview() {
      this.pbService.updateOverview(this.pb).subscribe(data => {
          this.growlerService.notification(['Details saved']);
      });
    }

    drop(e, group) {
        console.log(e, group)
        // moveItemInArray(this.images, event.previousIndex, event.currentIndex);
        this.updateSortOrder(group);
    }

    updateSortOrder(group) {
        this.pbService.updateSortOrder(group).subscribe();
    }


}
