import { HotelService } from './../../services/hotel.service';
import { ToolsService } from './../../services/tools.service';
import { EventsService } from './../../services/events.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import { GrowlerService } from 'src/app/services/growler.service';

@Component({
    selector: 'app-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

    isNew = false;
    dataId = '';
    dataToEdit;
    errorString = '';
    eventTypes = [];
    hotels = [];

    data = {
        id: '',
        tmpId: '',
        name: '',
        description: '',
        location: '',
        mapUrl: '',
        terms: '',
        active: 1,
        hideFromCheckout: 0,
        allowPassportDiscount: 0,
        type: '',
        skus: [],
        images: [],
        bannerImage: '',
        stDate: undefined,
        endDate: undefined,
        stTime: '09:00',
        endTime: '15:00',
        capacity: 1,
        hotel: '',
        hotels: [],
        contactEmail: '',
    };

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],  // custom dropdown
            ['bold', 'italic', 'underline', 'link'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    constructor(
        private toolsService: ToolsService,
        private router: Router,
        private route: ActivatedRoute,
        private eventsService: EventsService,
        private imagesService: ImagesService,
        private hotelService: HotelService,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
        this.getHotels();
        this.getTypes()
        this.dataId = this.route.snapshot.params.id;
        if (this.dataId === 'create') {
            this.isNew = true;
            this.data.id = this.toolsService.secureId();
        } else {
            this.get()
        }
    }

    getHotels() {
        this.hotelService.allLite().subscribe(data => {

            this.hotels = data;
        })
    }

    get() {
        this.eventsService.get(this.dataId).subscribe(data => {
            console.log(data);

            this.data = data;
        })

    }

    getTypes() {
        this.eventsService.getTypes().subscribe(data => {

            this.eventTypes = data;
        })

    }

    save() {
        let validated = true;

        console.log(this.data);
        if (!this.data.name) { validated = false; }
        if (!this.data.location) { validated = false; }
        if (!this.data.hotel) { validated = false; }
        // if (!this.data.hotels.length) { validated = false; }
        if (!this.data.bannerImage) { validated = false; }
        if (!this.data.type) { validated = false; }
        if (!this.data.terms) { validated = false; }
        if (!this.data.description) { validated = false; }
        if (!this.data.stDate) { validated = false; }
        if (!this.data.endDate) { validated = false; }
        if (!this.data.stTime) { validated = false; }
        if (!this.data.endTime) { validated = false; }


        if (!validated) {
            this.growlerService.error(['Please fill out all required fields.']);
            this.errorString = 'Please fill out all required fields.';
            return;
        }
        this.data.stDate = new Date(this.data.stDate);
        this.data.endDate = new Date(this.data.endDate);
        this.data.stDate = new Date(this.data.stDate.setHours(this.data.stDate.getHours() + 4))
        this.data.endDate = new Date(this.data.endDate.setHours(this.data.endDate.getHours() + 4))

        if (this.isNew) {
            this.eventsService.create(this.data).subscribe(data => {
                this.growlerService.notification(['Event created']);
                this.router.navigate(['/events']);
            })
        } else {
            this.eventsService.update(this.data).subscribe(data => {
                this.growlerService.notification(['Event updated']);
                this.router.navigate(['/events']);
            })
        }
    }

    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }

    setEndData() {
        if (this.dataId === 'create') {
            this.data.endDate = this.data.stDate;
        }
    }

    dataEditComplete(e) {
        this.dataToEdit = undefined;
        console.log(e.data)
        if (e.action) {
            if (!e.data.id) {
                this.data.skus.push(e.data);
            } else {
                for (let i = 0; i < this.data.skus.length; i++) {
                    if (this.data.skus[i].id === e.data.id) {
                        this.data.skus[i] = e.data;
                    }
                }
            }
        }
    }

    addSku() {
        this.dataToEdit = {
            name: '',
            price: 0,
            capacity: 0,
            deleted: 0,
            active: 1,
            stDate: undefined,
            endDate: undefined,
            notes: '',
            maxTickets: 10,
        }
    }

    editSku(sku) {
        this.dataToEdit = sku;
    }

    sortSkus(skus) {

    }
    toggleDeleteSku(idx) {
        if (!this.data.skus[idx].deleted) {
            this.data.skus[idx].deleted = 1
        } else {
            this.data.skus[idx].deleted = 0
        }
    }
    toggleStatusSku(idx) {
        if (!this.data.skus[idx].active) {
            this.data.skus[idx].active = 1
        } else {
            this.data.skus[idx].active = 0
        }
    }

    setEventImage(e) {
        console.log(e);
        this.data.images.push(e);

    }
    setEventBannerImage(e) {
        console.log(e.URL);
        this.data.bannerImage = e.URL;

    }

    deleteImage(idx) {
        this.imagesService.delete(this.data.images[idx]).subscribe(data => { });
        this.data.images.splice(idx, 1);
    }

    isHotelSelected(id) {
        return this.data.hotels.indexOf(id);
    }

    toggleHotel(id) {
        if (this.isHotelSelected(id) < 0) {
            this.data.hotels.push(id);
        } else {
            this.data.hotels.splice(this.isHotelSelected(id), 1)
        }
    }

    visitEvent(data) {
        window.open('https://rooms.norfolkpassport.com/events/' + data.eventType.slug + '/' + data.slug, '_blank').focus();
    }

    copyToClipboard(data) {

        const url = 'https://rooms.norfolkpassport.com/events/' + data.eventType.slug + '/' + data.slug;

        // Create a temporary textarea element
        const textarea = document.createElement('textarea');
        textarea.value = url;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();

        try {
            // Execute the copy command
            const successful = document.execCommand('copy');
            const message = successful ? 'Copied to clipboard' : 'Unable to copy to clipboard';
            console.log(message);
        } catch (err) {
            console.error('Failed to copy:', err);
        }

        // Clean up by removing the temporary textarea element
        document.body.removeChild(textarea);
    }

    drop(e) {
        // this.toSave = e;
        console.log(e)
        this.eventsService.sortSkus(e).subscribe(data => {
        });
    }

}
