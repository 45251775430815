<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <ng-container *ngIf="exportConfirmationData.type === 'export'">
                    <h5 class="modal-title">{{exportConfirmationData.title}}</h5>
                </ng-container>
                <ng-container *ngIf="exportConfirmationData.type === 'sync'">
                    <h5 class="modal-title">{{exportConfirmationData.title}}</h5>
                </ng-container>
                <ng-container *ngIf="exportConfirmationData.type === 'segment'">
                    <h5 class="modal-title">{{exportConfirmationData.title}}</h5>
                </ng-container>
                <a class="close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">

                <ng-container *ngIf="exportConfirmationData.type === 'export'">
                    <p class="text-left">{{exportConfirmationData.detail}}</p>
                    <ng-container *ngIf="!exportCompleted">
                        <div class="mb-2">
                            <mat-form-field class="padding-10">
                                <mat-label>Give your export a name</mat-label>
                                <input matInput type="text" autocomplete="off" [(ngModel)]="filename" >
                            </mat-form-field>
                            <br />
                            <br />

                        </div>
                        <button *ngIf="filename" class="btn btn-primary btn-width-50 mt-3 mb-3" (click)="confirm()">
                            Export Now
                        </button>

                    </ng-container>

                    <p class="mt-4 alert alert-info" *ngIf="exportCompleted">
                        Your export is now ready for download.
                        <br />
                        <a [href]="url" target="_blank">Click here to download</a>
                    </p>


                </ng-container>
                <ng-container *ngIf="exportConfirmationData.type === 'sync'">
                    <button *ngIf="!exportCompleted" class="btn btn-success btn-width-50 mt-3 mb-3" (click)="confirm()">
                        Sync Now
                    </button>

                    <p class="mt-4 alert-info" *ngIf="exportCompleted">
                        Your sync has been submitted. It may take some time to send all data over
                    </p>


                </ng-container>
                <ng-container *ngIf="exportConfirmationData.type === 'segment'">

                    <p *ngIf="!segmentSelected">This will create a reporting segment on the Campaign Monitor list with the same search parameters you selected</p>
                    <p *ngIf="segmentSelected">This will update your reporting segment on the Campaign Monitor list with the same search parameters you selected</p>
                    <ng-container *ngIf="!exportCompleted">
                        <div class="mb-2">
                            <mat-form-field class="padding-10 example-full-width">
                                <mat-label *ngIf="!segmentSelected">Give your segment a name</mat-label>
                                <mat-label *ngIf="segmentSelected">Segment name</mat-label>
                                <input matInput type="text" autocomplete="off" [(ngModel)]="filename" >
                            </mat-form-field>
                            <br />
                            <br />
                        </div>


                        <p class="text-left">
                            Select a voucher from the list if you would like to assign a voucher to the data selection, otherwise leave blank.
                        </p>

                        <div class="mb-3">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Voucher Selection</mat-label>
                                <mat-select [(ngModel)]="voucherSelected">
                                    <mat-option [value]="undefined">No voucher required</mat-option>
                                    <mat-option [value]="v" *ngFor="let v of vouchers">{{v.name}} ({{v.type}})</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <div *ngIf="filename" class="text-center">
                            <button class="btn btn-primary btn-width-50 mt-3 mb-3" (click)="confirm()">
                                Sync Now
                            </button>
                        </div>

                    </ng-container>

                    <p class="mt-4 alert-info p-4" *ngIf="exportCompleted">
                        Your segment has been saved to Campaign Monitor.
                    </p>



                </ng-container>


            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>


