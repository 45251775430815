<div class="dropzone dropzone-container"
     [dropzone]="uploadConfig"
     (error)="onUploadError($event)"
     (uploadprogress)="uploadprogress($event)"
     (success)="onUploadSuccess($event)">

    <div class="dz-details">
        <div class="dz-filename"><span data-dz-name></span></div>
        <div class="dz-size" data-dz-size></div>
        <img data-dz-thumbnail />
    </div>
    <!--<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>-->
    <!--<div class="dz-success-mark"><span>✔</span></div>-->
    <!--<div class="dz-error-mark"><span>✘</span></div>-->
    <!--<div class="dz-error-message"><span data-dz-errormessage></span></div>-->
</div>

