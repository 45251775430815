import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CmsService {

    env = environment;

    snippetTypes = [
        { id: 'title', name: 'Section title' },
        { id: 'text', name: 'Text Block' },
        { id: 'image', name: 'Image Block' },
        { id: 'wysiwyg', name: 'Editable Content' },
    ];
    fontStyles = ['normal', 'italic'];
    fontWeights = ['lighter', 'normal', 'bold'];
    fontColours = ['black', 'white', 'red', 'blue', 'green', 'orange'];

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) {
    }

    getSnippetTypes() {
        return this.snippetTypes;
    }
    getStyles() {
        return this.fontStyles;
    }
    getWeights() {
        return this.fontWeights;
    }
    getColours() {
        return this.fontColours;
    }
    getAll() {
        return this.http.get(this.apiService.path() + 'cms/all').pipe(map((data: any) => {
            return data;
        }));
    }

    getByID(id) {
        return this.http.get(this.apiService.path() + 'cms/' + id).pipe(map((data: any) => {
            return data;
        }));
    }

    create(cmsData) {
        const dataToSend = {
            data: cmsData,
        };
        return this.http.post(this.apiService.path() + 'cms/create', dataToSend).pipe(map((data: any) => {
            this.growlerService.notification(['CMS Snippet Created']);
            return data;
        }));
    }
    update(cmsData) {
        const dataToSend = {
            data: cmsData,
        };
        return this.http.post(this.apiService.path() + 'cms/update', dataToSend).pipe(map((data: any) => {
            this.growlerService.notification(['CMS Snippet Updated']);
            return data;
        }));
    }

    getContactTile() {
        return this.http.get(this.env.apiV2Path + 'cms/contact').pipe(map((data: any) => {
            return data;
        }));
    }


    updateContactTile(contact) {
        const dataToSend = {
            contact,
        };
        return this.http.post(this.env.apiV2Path + 'cms/contact', dataToSend).pipe(map((data: any) => {
            this.growlerService.notification(['CMS Updated']);
            return data;
        }));
    }

}
