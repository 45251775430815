import { Injectable } from '@angular/core';
import { Store } from '../store';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private store: Store
    ) { }

    openMenu() {
        this.store.set('menuOpen', true);
    }
    closeMenu() {
        this.store.set('menuOpen', false);
    }
    toggleMenu() {
        let m = this.store.selectForLocal('menuOpen');
        if (m) {
            m = false;
        } else {
            m = true;
        }
        this.store.set('menuOpen', m);
    }
    toggleHeaderMenu() {
        let m = this.store.selectForLocal('menuHeaderOpen');
        if (m) {
            m = false;
        } else {
            m = true;
        }
        this.store.set('menuHeaderOpen', m);
    }



}
