
<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>
    
    <h2 class="mat-title">Room Features</h2>
    
    <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let f of room.features">
            <mat-slide-toggle
                class="example-margin"
                [(ngModel)]="f.selected"
                color="primary">
                {{f.name}}
            </mat-slide-toggle>
        </mat-list-item>
    </mat-list>
</div>
