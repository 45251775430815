import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-booking-navbar',
    templateUrl: './booking-navbar.component.html',
    styleUrls: ['./booking-navbar.component.css']
})
export class BookingNavbarComponent implements OnInit {
    @Input() step = 1;
    @Input() layout = 'booking';

    constructor(
        private router: Router,
    ) { }

    ngOnInit() {
    }

    navTo(v) {
        if (v === 1) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/user'])
            }
        }
        if (v === 2) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/availability'])
            }
        }
        if (v === 3) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/room'])
            }
        }
        if (v === 4) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/payment'])
            }
        }

    }
}
