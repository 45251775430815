import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { debounce } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { GrowlerService } from 'src/app/services/growler.service';

@Component({
    selector: 'app-event-attendees',
    templateUrl: './event-attendees.component.html',
    styleUrls: ['./event-attendees.component.css']
})
export class EventAttendeesComponent implements OnInit {

    data = [];
    exportType = '';

    params = {
        searchText: '',
        eventId: '',
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "event_booking.createdAt DESC",
        sorts: [
            { ID: "event_booking.createdAt DESC", name: "Created first" },
            { ID: "event_booking.createdAt ASC", name: "Created last" },
        ],

    }


    exportConfirmationData = {
        open: false,
        title: '',
        detail: '',
        data: undefined,
    };

    ticketToDelete;

    deleteConfirmationData = {
        open: false,
        title: '',
        detail: '',
        data: undefined,
    };

    constructor(
        private eventsService: EventsService,
        private route: ActivatedRoute,
        private router: Router,
        private growlerService: GrowlerService,
    ) {
        this.filterSearch = debounce(this.filterSearch, 350);
    }

    ngOnInit() {
        this.params.eventId = this.route.snapshot.params['id'];
        this.filterSearch();
    }

    exportConfirm(exportType) {
        this.exportType = exportType;

        if (exportType === 'purchasers') {
            this.exportConfirmationData.title = 'Export Event Buyers';
        } else {
            this.exportConfirmationData.title = 'Export Event Attendees';
        }

        this.exportConfirmationData.data = '';
        this.exportConfirmationData.detail = 'Clicking confirm will create a CSV file and deliver by email';
        this.exportConfirmationData.open = true;
    }
    exportConfirmationComplete(e) {
        this.exportConfirmationData.open = false;
        if (e.action) {
            if (this.exportType === 'purchasers') {
                this.eventsService.exportPurchasers(this.route.snapshot.params['id']).subscribe(data => {
                    if (data.success) {
                        this.growlerService.notification(['Your export has completed and will be emailed to you']);
                    } else {
                        this.growlerService.error(['Sorry - there was a problem exporting your data']);
                    }
                    console.log(data);
                });

            } else {
                this.eventsService.exportAttendees(this.route.snapshot.params['id']).subscribe(data => {
                    if (data.success) {
                        this.growlerService.notification(['Your export has completed and will be emailed to you']);
                    } else {
                        this.growlerService.error(['Sorry - there was a problem exporting your data']);
                    }
                    console.log(data);
                });

            }
        } else {
            console.log('cancelled');
        }

    }


    filterSearch() {
        this.params.page = 0;

        this.search();
    }

    search() {
        this.eventsService.searchAttendees(this.params).subscribe(data => {
            console.log(data)
            this.data = data.data;
            this.params.pages = Math.ceil(
                data.totalRecords / this.params.limit
            );
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }

        })
    }

    resetParams() {
        this.params.searchText = '';
        this.filterSearch();
    }

    revealTickets(booking) {
        this.data.forEach((b) => {
            if (b.id !== booking.id) { b.showTickets = false; }
        });
        if (!booking.showTickets) {
            booking.showTickets = true;
        } else {
            booking.showTickets = false;
        }
    }

    deleteConfirm(ticket) {
        this.ticketToDelete = ticket
        this.deleteConfirmationData.title = 'Confirm ticket cancellation';

        this.deleteConfirmationData.data = '';
        this.deleteConfirmationData.detail = 'Clicking confirm will cancel the ticket selected';
        this.deleteConfirmationData.open = true;

    }

    deleteTicket(e) {
        console.log(e)
        if (e.action) {
            this.ticketToDelete.deleted = 1;
            this.eventsService.deleteTicket(this.ticketToDelete.ticketId).subscribe(data => {

            })
        } else {

        }
        this.ticketToDelete = undefined;
    }
}
