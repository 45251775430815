
<div class="text-right margin-bottom-20">
    <app-image-upload class="pull-right" [type]="type" [ownerID]="ownerID" (imageUploaded)="appendImage($event)"></app-image-upload>
    <br />
</div>


<ul dragula="roomImages" [(dragulaModel)]="images" (dragulaModelChange)="drop($event)">
    <li class="tile gallery-tile" [ngClass]="{'small' : smallThumb }" *ngFor="let image of images">
        <button mat-mini-fab color="warn" (click)="cropImage(image)" style="position: absolute; top: 5px; left: 5px">
            <mat-icon>crop</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="delete(image)" style="position: absolute; top: 5px; right: 5px">
            <mat-icon>delete</mat-icon>
        </button>

        <img *ngIf="!image.imgBase" [src]="image.URL" />
        <img *ngIf="image.imgBase" [src]="image.imgBase" />
    </li>
</ul>
<br class="break: left;" />
<app-image-delete *ngIf="(overlays$ | async).imageDelete" [modalData]="modalData" [modals]="modals" (modalResponse)=" deleteResponse($event)"></app-image-delete>
