import { Component, OnInit } from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlerService} from '../../services/growler.service';

@Component({
  selector: 'app-cms-edit',
  templateUrl: './cms-edit.component.html',
  styleUrls: ['./cms-edit.component.css']
})
export class CmsEditComponent implements OnInit {

    snippet: any;
    snippetTypes = [];
    colours = [];
    styles = [];
    weights = [];



    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],  // custom dropdown
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['clean']
        ]
    };

  constructor(
      private cmsService: CmsService,
      private router: Router,
      private route: ActivatedRoute,
  ) { }

  ngOnInit() {
      this.getByID();
      this.snippetTypes = this.cmsService.getSnippetTypes();
      this.colours = this.cmsService.getColours();
      this.weights = this.cmsService.getWeights();
      this.styles = this.cmsService.getStyles();
  }

  getByID() {
      this.cmsService.getByID(this.route.snapshot.params['id']).subscribe(data => {
          this.snippet = data.data;
      });
  }
    setImage(e) {
        console.log(e);
        this.snippet.background_image = e.id;
        this.snippet.images.push(e);
    }


    save() {
        this.cmsService.update(this.snippet).subscribe(data => {

        });
    }

    back() {
        this.router.navigate(['/cms']);
    }

}
