import { Component, OnInit } from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cms-list',
  templateUrl: './cms-list.component.html',
  styleUrls: ['./cms-list.component.css']
})
export class CmsListComponent implements OnInit {

    snippets = [];
  constructor(
      private cmsService: CmsService,
      private router: Router,
  ) { }

  ngOnInit() {
      this.getAll();
  }

  getAll() {
      this.cmsService.getAll().subscribe(data => {
          console.log(data.data)
          this.snippets = data.data;
      });
  }

    edit(snippet) {
        this.router.navigate(['/cms/', snippet.id]);
    }

}
