import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ImagesService} from '../../services/images.service';
import {OverlayService} from '../../services/overlay.service';

@Component({
  selector: 'app-image-delete',
  templateUrl: './image-delete.component.html',
  styleUrls: ['./image-delete.component.css']
})
export class ImageDeleteComponent implements OnInit {
    @Input() modalData;
    @Input() modals;
    @Output() modalResponse: EventEmitter<any> =  new EventEmitter<any>();

    dataToEmit = {
        modal: 'delete',
        active: false,
        data: {},
        success: false
    };

    constructor(
        private imagesService: ImagesService,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
    }
    confirm() {
        this.imagesService.delete(this.modalData)
            .subscribe(data => {
                console.log(data);
                this.dataToEmit.success = true;
                this.dataToEmit.data = data;
                this.modalResponse.emit(this.dataToEmit);
                this.close();
            });
    }
    close() {
        this.overlayService.closeAll();
        this.modalResponse.emit(this.dataToEmit);
    }

}
