<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <h2 class="mat-title mb-0">Staff</h2>
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card class="text-right">
    <div class="row">
        <div class="col-md-5">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.searchText" (keyup)="filterSearch()" placeholder="Passport number, firstname, surname, email, postcode">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.staffPrinted"
                    (selectionChange)="filterSearch()"
                    placeholder="Card printed">
                    <mat-option [value]="''">All records</mat-option>
                    <mat-option [value]="'YES'">Printed</mat-option>
                    <mat-option [value]="'NO'">Not printed</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="filterSearch()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-1 text-right">
            <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>
        </div>
    </div>

</mat-card>

<mat-card class="no-records" *ngIf="!passports.length">
    No passports listed
</mat-card>

<ng-container *ngIf="passports.length">

    <div class="margin-top-20">


        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Number</th>
                <th>Name</th>
                <th>Email</th>
                <th class="text-center">Registered</th>
                <th class="text-center">Printed?</th>
                <th class="text-center">Account?</th>
                <th class="text-right">Edit</th>
                <th class="text-right">Delete</th>
                <th class="text-center">
                    Selected ({{selected.length}})<br />
                    <a class="hover" (click)="selectAll()">All</a> | <a class="hover" (click)="clearSelected()">None</a>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let r of passports">
                <td (click)="edit(r)">{{r.cardNumber}}</td>
                <td (click)="edit(r)"> {{r.firstname}} {{r.surname}}</td>
                <td (click)="edit(r)">{{r.username}}</td>
                <td (click)="edit(r)">{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td (click)="edit(r)">
                    <span *ngIf="r.cardPrinted">Yes</span>
                    <span *ngIf="!r.cardPrinted">No</span>
                </td>
                <td class="text-center" (click)="edit(r)">
                    Yes
                </td>
                <td class="text-right">
                    <button type="button" class="btn btn-warning btn-sm" (click)="edit(r)">
                        <i class="fa fa-edit"></i> Edit
                    </button>
                </td>
                <td class="text-right">
                    <button type="button" class="btn btn-danger btn-sm" (click)="deleteConfirmation(r)">
                        <i class="fa fa-trash"></i> Delete
                    </button>
                </td>
                <td class="text-center">
                    <a (click)="setSelected(r.id)">
                        <i class="fas fa-check-square text-dark ui-checkbox" *ngIf="isSelected(r.id)"></i>
                        <i class="far fa-square ui-checkbox" *ngIf="!isSelected(r.id)"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
        <app-pagination [params]="params" (update)="search()"></app-pagination>

    </div>


    <mat-card class="margin-top-30" *ngIf="excelTable.length">
        <button type="button" class="btn btn-success btn-sm mr-3" (click)="exportAsPDF()">
            <i class="fa fa-envelope"></i> Print labels
        </button>
        <button type="button" class="btn btn-warning btn-sm mr-3" (click)="exportToExcel()">
            <i class="fa fa-external-link-alt"></i> Export XLSX
        </button>
        <button type="button" class="btn btn-danger btn-sm" (click)="markAsPrinted()">
            <i class="fa fa-check"></i> Mark as printed
        </button>
    </mat-card>

    <div style="display: none" #epltable>
        <table>
            <thead>
            <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>PassportNo</th>
                <th>Staff Group</th>
                <th>Discount Level</th>
                <th>Mag Stripe</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let r of excelTable">
                <td>{{r.firstname}} {{r.surname}}</td>
                <td>{{r.username}}</td>
                <td>{{r.cardNumber}}</td>
                <td>{{r.staffGroupName}}</td>
                <td>{{r.discountLevelName}}</td>
                <td>ICR1541{{r.cardNumber}}</td>
            </tr>
            </tbody>
        </table>


    </div>


    <div id="MyDIv" class="hidden" #pdfData style="margin-left: 45px;" class="main-container">
    </div>
</ng-container>

<app-confirmation *ngIf="showDeleteConfirmation" [data]="deleteConfirmData" (complete)="delete($event)"></app-confirmation>
