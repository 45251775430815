<mat-card class="breadcrumb">
    <div class="row">
      <div class="col-md-10">
        <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
        <!--<mat-icon>arrow_forward_ios</mat-icon>-->
        <a [routerLink]="['/feedback/invites']">Invites</a>
      </div>
      <div class="col-md-2 text-right">
        <!--<button id="pullright" mat-raised-button color="primary" routerLink="/hotels/create">Add Hotel</button>-->
      </div>
    </div>
  </mat-card>

  <mat-card class="text-right">
    <div class="row">
        <div class="col-md-3">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.name" (keyup)="filterSearch()" placeholder="Name">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.email" (keyup)="filterSearch()" placeholder="Email">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.template" (keyup)="filterSearch()" placeholder="Template">
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="filterSearch()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-1 text-right">
            <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>
        </div>
    </div>

</mat-card>


  <div class="margin-top-20">


    <table class="mat-elevation-z2 example-full-width data-listing">
      <thead>
          <tr>
              <td>Template</td>
              <th>Name</th>
              <th>Email Address</th>
              <th>Spend</th>
              <th>Date Sent</th>
              <th>Date Complete</th>
              <th></th>
          </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let invite of invites">
            <tr>
                <td>{{invite.templateName}}</td>
                <td> {{invite.recipient_name}}</td>
                <td> {{invite.recipient_email}}</td>
                <td>{{invite.spend | currency: 'GBP'}}</td>
                <td>{{invite.sent | date: 'dd/MM/yyyy @ HH:mm'}}</td>
                <td>{{invite.completed | date: 'dd/MM/yyyy @ HH:mm'}}</td>
                <td>
                    <a class="hover" (click)="toggleFeedback(invite)" *ngIf="invite.completed">
                        <i class="fa fa-chevron-down" *ngIf="!invite.showFeedback"></i>
                        <i class="fa fa-chevron-up" *ngIf="invite.showFeedback"></i>
                    </a>
                </td>
            </tr>
            <tr *ngIf="invite.showFeedback && answers.length">
                <td colspan="7">
                    <p class="mt-2 mb-2" style="font-size: 15px; font-weight: bold;">{{invite.recipient_name}} feedback responses</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Comment</th>
                                <th>Response</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let a of answers">
                                <td>{{a.text}}</td>
                                <td>{{a.comment}}</td>
                                <td>
                                    <span *ngIf="a.response !== 'tu' && a.response !== 'td' && a.response !== 'na'">{{a.response}}</span>
                                </td>
                                <td>
                                    <img *ngIf="a.response === 'tu'" class="thumb" src="https://feedback.norfolkpassport.com/assets/images/small_thumbsup_green.png" alt="Thumbs Up" />
                                    <img *ngIf="a.response === 'td'" class="thumb" src="https://feedback.norfolkpassport.com/assets/images/small_thumbsdown_red.png" alt="Thumbs Down" />
                                </td>
                            </tr>

                        </tbody>

                    </table>

                    <button *ngIf="(user$ | async)?.permissions['FeedbackAnswerDelete']" type="button" class="btn btn-danger btn-small mt-3 mb-3" (click)="deleteConfirm(invite)">
                        Delete Answers
                    </button>
                </td>

            </tr>
        </ng-container>
      </tbody>
    </table>

    <app-pagination [params]="params" (update)="search()"></app-pagination>

  </div>
  <app-confirmation *ngIf="toDelete" [data]="deleteConfirmationData" (complete)="deleteAnswers($event)"></app-confirmation>

