import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { OverlayService } from '../../services/overlay.service';
import { Store } from '../../store';
import { Router } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
    selector: 'app-product-groups',
    templateUrl: './product-groups.component.html',
    styleUrls: ['./product-groups.component.css']
})
export class ProductGroupsComponent implements OnInit {

    productGroups$ = this.store.select<any[]>('productGroups');
    hotels = [{ id: 'all', name: 'All Hotels' }];
    hotelsSelected = ['all'];

    constructor(
        private productService: ProductService,
        private overlayService: OverlayService,
        private hotelService: HotelService,
        private store: Store,
        private router: Router,
    ) { }

    ngOnInit() {
        this.groupsGet();
        this.getHotels();
    }

    groupsGet() {
        this.productService.groupsGetByHotel(this.hotelsSelected);
    }
    groupAdd() {
        this.store.set('productGroup', undefined);
        this.overlayService.open('productGroup');
        this.store.set('asideOpen', true);
    }

    groupUpdate(product_group) {
        this.store.set('productGroup', product_group);
        this.overlayService.open('productGroup');
        // this.store.set('asideOpen', true);
        this.router.navigate(['/', 'product-groups', product_group.ID, 'edit'])
    }
    groupDelete(product_group) {
        this.store.set('productGroup', product_group);
        this.overlayService.open('productGroupDelete');
        this.store.set('asideOpen', true);
    }
    getHotels() {
        this.hotelService.allLite().subscribe(data => {
            for (let i = 0; i < data.length; i++) {
                this.hotels.push(data[i])
            }
            // this.hotels = data;
        })
    }

    viewProducts(product_group) {
        this.router.navigate(['product-groups', product_group.ID, 'products']);
    }

    drop(e) {
        console.log(e)
        // moveItemInArray(this.images, event.previousIndex, event.currentIndex);
        this.updateSortOrder();
    }

    updateSortOrder() {
        // this.imagesService.updateSortOrder(this.images).subscribe();
    }

    isHotelSelected(id) {
        return this.hotelsSelected.indexOf(id);
    }

    toggleHotel(id) {
        this.hotelsSelected = [];
        this.hotelsSelected.push(id);

        // if (this.isHotelSelected(id) < 0) {
        //     this.hotelsSelected.push(id);
        // } else {
        //     this.hotelsSelected.splice(this.isHotelSelected(id), 1)
        // }
        if (!this.hotelsSelected.length) {
            this.hotelsSelected.push('all')
        }
        this.groupsGet();
    }

}
