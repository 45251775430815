import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '../store';

@Component({
    selector: 'app-confirm-export',
    templateUrl: './confirm-export.component.html',
    styleUrls: ['./confirm-export.component.css']
})
export class ConfirmExportComponent implements OnInit {
    @Input() confirmationData: any;
    @Output() complete = new EventEmitter();

    user$ = this.store.select<any>('user');

    constructor(
        private store: Store,
    ) { }

    ngOnInit() {
    }

    confirm() {
        this.complete.emit({ action: true });
    }
    cancel() {
        this.complete.emit({ action: false });
    }
}
