<div class="mb-3 mt-3">

    <ng-container>
        <div class="mat-card mt-4 mb-4 p-4 text-center">
            <div>
                <p>What type of data process would you like to run?</p>
                <button  mat-raised-button class="mr-3" [ngClass]="{'mat-warn' : exportType === 'export', 'btn-secondary' : exportType !== 'export'}" (click)="exportType = 'export'">Export to Excel</button>
                <button  mat-raised-button class="mr-3" [ngClass]="{'mat-warn' : exportType === 'segment', 'btn-secondary' : exportType !== 'segment'}" (click)="exportType = 'segment'">Campign Monitor Segment</button>
                <!-- <button  mat-raised-button class="mr-3" [ngClass]="{'mat-warn' : exportType === 'sync', 'btn-secondary' : exportType !== 'sync'}" (click)="exportType = 'sync'">Campaign Monitor Sync</button> -->
                <button  mat-raised-button class="mr-3" [ngClass]="{'mat-warn' : exportType === 'voucher', 'btn-secondary' : exportType !== 'voucher'}" (click)="exportType = 'voucher'">Voucher Assignment</button>
                <button  mat-raised-button class="mr-3" [ngClass]="{'mat-warn' : exportType === 'giftCard', 'btn-secondary' : exportType !== 'giftCard'}" (click)="exportType = 'giftCard'">Gift Card Assignment</button>

            </div>

            <div class="mt-4 p-2" *ngIf="exportType === 'segment'">
                <p>To sync with Campaign Monitor reporting segments either create a new template or select an existing one to modify</p>
                <div class="row">
                    <div class="col-3">

                    </div>

                    <div class="col-6">
                        <mat-form-field class="mb-0 padding-10 example-full-width">
                            <mat-label *ngIf="!segmentSelected">Select segment template</mat-label>
                            <mat-label *ngIf="segmentSelected">Modify {{segmentSelected.name}}</mat-label>
                            <mat-select [(ngModel)]="segmentSelected" (ngModelChange)="applyTemplate()">
                                <mat-option [value]="undefined">Create new template</mat-option>
                                <mat-option [value]="seg" *ngFor="let seg of segments">{{seg.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">

                    </div>
                </div>

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="exportType">

        <div class="p-3 mb-3" style="background-color: #f2f2f2;">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Passport parameters</h6>
                <button *ngIf="!bookingParams" type="button" mat-icon-button (click)="togglePassportParams()" matTooltip="Passport parameters">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button *ngIf="bookingParams" type="button" mat-icon-button (click)="togglePassportParams()" matTooltip="Passport parameters">
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>


            <div style="background-color: #f2f2f2;" *ngIf="passportParams">
                <div class="row">
                    <div class="col-3">
                        <mat-form-field class="mb-0 padding-10">
                            <mat-label>Active in CM</mat-label>
                            <mat-select [(ngModel)]="params.activeInCM" (ngModelChange)="filterSearch()">
                                <mat-option [value]="l.id" *ngFor="let l of yesNo">{{l.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>User name</mat-label>
                            <input matInput type="text" autocomplete="off" name="name" [(ngModel)]="params.name" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>Email address</mat-label>
                            <input matInput type="text" autocomplete="off" name="email" [(ngModel)]="params.email" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>County</mat-label>
                            <input matInput type="text" autocomplete="off" name="county" [(ngModel)]="params.county" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>Postcode</mat-label>
                            <input matInput type="text" autocomplete="off" name="postcode" [(ngModel)]="params.postcode" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field class="mb-0 padding-10 example-full-width">
                            <mat-select
                                [(ngModel)]="params.passportType"
                                (selectionChange)="filterSearch()"
                                placeholder="Passport type">
                                <mat-option [value]="''">All</mat-option>
                                <mat-option [value]="'np004'">Physical</mat-option>
                                <mat-option [value]="'np000'">Digital</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field class="mb-0 padding-10 example-full-width">
                                    <mat-select
                                        [(ngModel)]="params.confirmed"
                                        (selectionChange)="filterSearch()"
                                        placeholder="Passport confirmed">
                                        <mat-option [value]="0">All</mat-option>
                                        <mat-option [value]="1">Yes</mat-option>
                                        <mat-option [value]="2">No</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <div class="col-6">
                                <mat-form-field class="mb-0 padding-10 example-full-width">
                                    <mat-select
                                        [(ngModel)]="params.guest"
                                        (selectionChange)="filterSearch()"
                                        placeholder="Guest user">
                                        <mat-option [value]="0">All</mat-option>
                                        <mat-option [value]="1">Yes</mat-option>
                                        <mat-option [value]="2">No</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>

                    </div>
                    <div class="col-3">
                        <mat-form-field class="mb-0 padding-10">
                            <mat-select
                                [(ngModel)]="params.staffGroup"
                                (selectionChange)="filterSearch()"
                                placeholder="Staff?">
                                <mat-option [value]="0">All</mat-option>
                                <mat-option [value]="1">Yes</mat-option>
                                <mat-option [value]="2">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12">
                        <p style="color: rgba(0,0,0,.54); font-size: 16px;">User has subscribed to any of the following companies</p>

                        <button
                            class="mr-2 mb-2"
                            *ngFor="let company of companies"
                            mat-raised-button
                            [color]="isCompanySelected(company.id) > -1 ? 'primary' : 'grey'"
                            color="primary"
                            (click)="toggleCompany(company.id)">
                            {{company.name}}
                        </button>

                    </div>
                </div>
            </div>

        </div>

        <div class="p-3 mb-3" style="background-color: #f2f2f2;">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Booking parameters</h6>
                <button *ngIf="!bookingParams" type="button" mat-icon-button (click)="toggleBookingParams()" matTooltip="Booking parameters">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button *ngIf="bookingParams" type="button" mat-icon-button (click)="toggleBookingParams()" matTooltip="Booking parameters">
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>
            <div style="background-color: #f2f2f2;" *ngIf="bookingParams">
                <div class="row">

                    <div class="col-3">
                        <mat-form-field class="mb-0 padding-10">
                            <mat-label>User has stayed in a hotel</mat-label>
                            <mat-select [(ngModel)]="params.hasStayed" (ngModelChange)="filterSearch()">
                                <mat-option [value]="l.id" *ngFor="let l of yesNo">{{l.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="mb-0 padding-10">
                            <mat-select
                                [(ngModel)]="params.hotel"
                                (selectionChange)="filterSearch()"
                                placeholder="Hotel">
                                <mat-option value="">User last stayed at</mat-option>
                                <mat-option
                                    [value]="sy.name"
                                    *ngFor="let sy of (hotels$ | async)">
                                    {{sy.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field style="display: block" class="mb-0 padding-10">
                            <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="params.bookingDate.from" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Booking date from">
                            <mat-datepicker #startpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field style="display: block" class="mb-0 padding-10">
                            <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="params.bookingDate.to" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="booking date to">
                            <mat-datepicker #endpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p style="color: rgba(0,0,0,.54); font-size: 16px;">User has stayed at any of the selected hotels</p>

                        <button
                            class="mr-2 mb-2"
                            *ngFor="let hotel of hotels"
                            mat-raised-button
                            [color]="isHotelSelected(hotel.id) > -1 ? 'primary' : 'grey'"
                            color="primary"
                            (click)="toggleHotel(hotel.id)">
                            {{hotel.name}}
                        </button>

                    </div>
                    <div class="col-3">
                        <mat-form-field style="display: block" class="mb-0 padding-10">
                            <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="params.checkInDate.from" (click)="bookingStartpicker.open()" [matDatepicker]="bookingStartpicker" placeholder="Checked in from">
                            <mat-datepicker #bookingStartpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field style="display: block" class="mb-0 padding-10">
                            <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="params.checkInDate.to" (click)="bookingEndpicker.open()" [matDatepicker]="bookingEndpicker" placeholder="Checked in to">
                            <mat-datepicker #bookingEndpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </div>

        <div class="p-3 mb-3" style="background-color: #f2f2f2;">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Feedback parameters</h6>
                <button *ngIf="!bookingParams" type="button" mat-icon-button (click)="toggleFeedbackParams()" matTooltip="Booking parameters">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button *ngIf="bookingParams" type="button" mat-icon-button (click)="toggleFeedbackParams()" matTooltip="Booking parameters">
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>

            <div style="background-color: #f2f2f2;" *ngIf="feedbackParams">
                <div class="row">
                    <div class="col-3">
                        <mat-form-field class="mb-0 padding-10">
                            <mat-label>User has left feedback</mat-label>
                            <mat-select [(ngModel)]="params.hasLeftFeedback" (ngModelChange)="filterSearch()">
                                <mat-option [value]="l.id" *ngFor="let l of yesNo">{{l.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>Min feedback score (this qtr)</mat-label>
                            <input matInput type="text" autocomplete="off" name="minFeedbackPerc" [(ngModel)]="params.feedbackPerc.min" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>Max feedback score (this qtr)</mat-label>
                            <input matInput type="text" autocomplete="off" name="maxFeedbackPerc" [(ngModel)]="params.feedbackPerc.max" (keyup)="filterSearch()">
                        </mat-form-field>

                    </div>
                </div>
            </div>

        </div>

        <div class="p-3 mb-3" style="background-color: #f2f2f2;">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Spend parameters</h6>
                <button *ngIf="!spendParams" type="button" mat-icon-button (click)="toggleSpendParams()" matTooltip="Booking parameters">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button *ngIf="spendParams" type="button" mat-icon-button (click)="toggleSpendParams()" matTooltip="Booking parameters">
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>

            <div  style="background-color: #f2f2f2;" *ngIf="spendParams">
                <div class="row">
                    <div class="col-4">
                        <mat-form-field style="display: block" class="mb-0 padding-10">
                            <mat-label>Product name</mat-label>
                            <input matInput type="text" autocomplete="off" [(ngModel)]="params.spendProduct" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <mat-form-field style="display: block" class="mb-0 padding-10">
                            <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="params.spendDate.from" (click)="spendStartpicker.open()" [matDatepicker]="spendStartpicker" placeholder="Spend from">
                            <mat-datepicker #spendStartpicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field style="display: block" class="mb-0 padding-10">
                            <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="params.spendDate.to" (click)="spendEndpicker.open()" [matDatepicker]="spendEndpicker" placeholder="Spend to">
                            <mat-datepicker #spendEndpicker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>Total spend min</mat-label>
                            <input matInput type="text" autocomplete="off" [(ngModel)]="params.spend.min" (keyup)="filterSearch()">
                        </mat-form-field>

                        <mat-form-field class="padding-10">
                            <mat-label>Total spend max</mat-label>
                            <input matInput type="text" autocomplete="off" [(ngModel)]="params.spend.max" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>Average spend min</mat-label>
                            <input matInput type="text" autocomplete="off" [(ngModel)]="params.avgSpend.min" (keyup)="filterSearch()">
                        </mat-form-field>

                        <mat-form-field class="padding-10">
                            <mat-label>Average spend max</mat-label>
                            <input matInput type="text" autocomplete="off" [(ngModel)]="params.avgSpend.max" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <mat-form-field class="padding-10">
                            <mat-label>No of spends min</mat-label>
                            <input matInput type="text" autocomplete="off" [(ngModel)]="params.trans.min" (keyup)="filterSearch()">
                        </mat-form-field>

                        <mat-form-field class="padding-10">
                            <mat-label>No of spends max</mat-label>
                            <input matInput type="text" autocomplete="off" [(ngModel)]="params.trans.max" (keyup)="filterSearch()">
                        </mat-form-field>
                    </div>

                </div>

                <div class="row">
                    <div class="col-6">
                        <p style="color: rgba(0,0,0,.54); font-size: 16px;">User has spent at any of the selected hotels</p>

                        <button
                            class="mr-2 mb-2"
                            *ngFor="let hotel of hotels"
                            mat-raised-button
                            [color]="isSpendHotelSelected(hotel.id) > -1 ? 'primary' : 'grey'"
                            color="primary"
                            (click)="toggleSpendHotel(hotel.id)">
                            {{hotel.name}}
                        </button>

                    </div>

                </div>
            </div>

        </div>

        <div *ngIf="searchRunRequired && !searchWasRun" class="mt-3 p-3 text-center" style="background-color: #f2f2f2;">
            <button  mat-raised-button color="warn" class="mr-3" (click)="search()">
                <span *ngIf="!loading">Run search</span>
                <span *ngIf="loading">Running search <i class="fa fa-spin fa-spinner"></i></span>
            </button>
        </div>

        <div *ngIf="users.length && searchWasRun" class="mt-3 p-3 text-center" style="background-color: #f2f2f2;">
            <h6 class="mb-2">{{params.totalRecords}} retrieved - proceed with current results?</h6>
            <div>
               <button  mat-raised-button color="warn" class="mr-3" (click)="exportConfirm()"  *ngIf="exportType === 'export'">Export to Excel</button>
                <button  mat-raised-button color="warn" class="mr-3" (click)="exportConfirm()"  *ngIf="exportType === 'segment'">Run Segment Sync</button>
                <!-- <button  mat-raised-button color="warn" class="mr-3" (click)="exportConfirm()"  *ngIf="exportType === 'sync'">Run Sync</button> -->
                <button  mat-raised-button color="warn" class="mr-3" (click)="exportConfirm()"  *ngIf="exportType === 'voucher'">Assign Voucher</button>
                <button  mat-raised-button color="warn" class="mr-3" (click)="exportConfirm()"  *ngIf="exportType === 'giftCard'">Assign Gift Card</button>
            </div>
        </div>

    <div *ngIf="searchWasRun" class="mat-card mt-2 p-2">

        <div class="card mb-3 no-records" *ngIf="!loading && !users.length">
            No results for the search criteria entered
        </div>
        <div class="card mb-3 no-records" *ngIf="loading">
            Loading data <i class="fa fa-spinner fa-spin"></i>
        </div>


        <div class="records-selected-holder">
            <p style="margin-top: 0; margin-bottom: 0; color: #61c3d9" *ngIf="recordSelected.length && recordSelected.length < users.length">
                {{recordSelected.length}} Member<span *ngIf="recordSelected.length !== 1">s</span> selected
            </p>
            <p style="margin-top: 0; margin-bottom: 0; color: #61c3d9" *ngIf="recordSelected.length && recordSelected.length === users.length">
                All Member<span *ngIf="recordSelected.length !== 1">s</span> selected ({{recordSelected.length}})
            </p>
        </div>

        <div *ngIf="users.length">

            <!-- <div class="d-flex  justify-content-end">
                <button type="button" class="mr-2" [ngClass]="{'opacity-60' : !recordSelected.length}" mat-icon-button (click)="exportConfirm('segment')" matTooltip="Save as Campaign Monitor Reporting Segment">
                    <mat-icon>view_timeline</mat-icon>
                </button>
                <button type="button" class="mr-2" [ngClass]="{'opacity-60' : !recordSelected.length}" mat-icon-button (click)="exportConfirm('export')" matTooltip="Export to CSV file">
                    <mat-icon>save_alt</mat-icon>
                </button>
                <button type="button" class="mr-2" [ngClass]="{'opacity-60' : !recordSelected.length}" mat-icon-button (click)="exportConfirm('sync')" matTooltip="Sync to Campaign Monitor">
                    <mat-icon>sync</mat-icon>
                </button>
                <button type="button"  [ngClass]="{'opacity-60' : !recordSelected.length}" mat-icon-button (click)="voucherAssign()" matTooltip="Assign Voucher">
                    <mat-icon>credit_score</mat-icon>
                </button>
            </div> -->
            <app-pagination [params]="params" (update)="search()"></app-pagination>

            <table class="example-full-width data-listing">
                <thead>
                <tr>
                    <th style="min-width: 40px" class="text-center d-none d-lg-table-cell">
                        <!-- <a (click)="selectAll()">
                            <i class="fas fa-minus-square ui-checkbox" *ngIf="this.recordSelected.length && this.recordSelected.length !== this.users.length"></i>
                            <i class="far fa-square ui-checkbox" *ngIf="!this.recordSelected.length"></i>
                            <i class="fas fa-check-square ui-checkbox" *ngIf="this.recordSelected.length === this.users.length"></i>
                        </a> -->
                    </th>
                    <th>
                        <a *ngIf="params.sort === 'users.firstname DESC'" (click)="setSort('users.firstname')">Name <i class="fa fa-angle-up"></i></a>
                        <a *ngIf="params.sort !== 'users.firstname DESC'" (click)="setSort('users.firstname DESC')">Name <i class="fa fa-angle-down"></i></a>
                    </th>
                   <th class="d-none d-lg-table-cell">
                       <a *ngIf="params.sort === 'users.username DESC'" (click)="setSort('users.username')">Email <i class="fa fa-angle-up"></i></a>
                       <a *ngIf="params.sort !== 'users.username DESC'" (click)="setSort('users.username DESC')">Email <i class="fa fa-angle-down"></i></a>
                   </th>
                   <!-- <th>
                        <a *ngIf="params.sort === 'users.cardNumber DESC'" (click)="setSort('users.cardNumber')">Card number <i class="fa fa-angle-up"></i></a>
                        <a *ngIf="params.sort !== 'users.cardNumber DESC'" (click)="setSort('users.cardNumber DESC')">Card number <i class="fa fa-angle-down"></i></a>
                    </th> -->
                    <th class="text-right">Total<br />Bookings</th>
                    <th class="text-right">Total<br />Spend</th>
                    <th class="text-right">Total<br />Trans</th>
                    <th class="text-right">Avg<br />Spend</th>

                    <th style="min-width: 115px" class="d-none d-xl-table-cell">
                        <a *ngIf="params.sort === 'users.createdAt DESC'" (click)="setSort('users.createdAt')">createdAt <i class="fa fa-angle-up"></i></a>
                        <a *ngIf="params.sort !== 'users.createdAt DESC'" (click)="setSort('users.createdAt DESC')">createdAt <i class="fa fa-angle-down"></i></a>
                    </th>
                    <th style="min-width: 115px" class="d-none d-xl-table-cell">
                        <a *ngIf="params.sort === 'users.confirmedAt DESC'" (click)="setSort('users.confirmedAt')">Confirmed <i class="fa fa-angle-up"></i></a>
                        <a *ngIf="params.sort !== 'users.confirmedAt DESC'" (click)="setSort('users.confirmedAt DESC')">Confirmed <i class="fa fa-angle-down"></i></a>
                    </th>
                    <th style="min-width: 121px" class="d-none d-xl-table-cell">
                        <a *ngIf="params.sort === 'users.lastCmSync DESC'" (click)="setSort('users.lastCmSync')">Last Sync <i class="fa fa-angle-up"></i></a>
                        <a *ngIf="params.sort !== 'users.lastCmSync DESC'" (click)="setSort('users.lastCmSync DESC')">Last Sync <i class="fa fa-angle-down"></i></a>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of users; let i = index" [ngClass]="{'tr-odd': isOdd(i)}">
                    <td class="text-center d-none d-lg-table-cell">
                        <button *ngIf="!isExcluded(data.id)" class="text-success" type="button" mat-icon-button (click)="toggleExcluded(data.id)" matTooltip="Remove from export">
                            <mat-icon>check</mat-icon>
                        </button>
                        <button *ngIf="isExcluded(data.id)" class="text-danger" type="button" mat-icon-button (click)="toggleExcluded(data.id)" matTooltip="Include in export">
                            <mat-icon>remove</mat-icon>
                        </button>


                        <!-- <a (click)="setRecordSelected(data.Id)">
                            <i class="fas fa-check-square text-dark ui-checkbox" *ngIf="isRecordSelected(data.Id)"></i>
                            <i class="far fa-square ui-checkbox" *ngIf="!isRecordSelected(data.Id)"></i>
                        </a> -->
                    </td>
                    <td>{{data.firstname}} {{data.surname}}
                        <br />
                        Card: {{data.cardNumber}}
                    </td>
                    <td style="width: 175px">{{data.username}}</td>

                    <td class="text-right">{{data.totalBookings}}</td>
                    <td class="text-right">{{data.totalSpend | currency: 'GBP'}}</td>
                    <td class="text-right">{{data.totalTrans}}</td>
                    <td class="text-right">{{data.avgSpend | currency: 'GBP'}}</td>

                    <td class="d-none d-xl-table-cell text-right">
                        {{data.createdAt | date:'dd MMM yyyy'}}
                        <br />
                        {{data.createdAt | date:'HH:mm:ss'}}
                    </td>
                    <td class="d-none d-xl-table-cell text-right">
                        {{data.confirmedAt | date:'dd MMM yyyy'}}
                        <br />
                        {{data.confirmedAt | date:'HH:mm:ss'}}
                    </td>
                    <td class="d-none d-xl-table-cell text-right">
                        {{data.lastCmSync | date:'dd MMM yyyy'}}
                        <br />
                        {{data.lastCmSync | date:'HH:mm:ss'}}
                    </td>
                </tr>
                </tbody>
            </table>

            <app-pagination [params]="params" (update)="search()"></app-pagination>

        </div>
    </div>


    </ng-container>





<app-confirm-reporting-export *ngIf="exportConfirmationData.open" [segmentSelected]="segmentSelected" [params]="params" [exportConfirmationData]="exportConfirmationData" (complete)="exportConfirmationComplete($event)"></app-confirm-reporting-export>
<app-voucher-select *ngIf="showVoucherWindow" [recordsSelected]="recordsSelected" (complete)="voucherAssignAction($event)"></app-voucher-select>
<app-giftcard-select *ngIf="showGiftCardWindow" [recordsSelected]="recordsSelected" (complete)="giftCardAssignAction($event)"></app-giftcard-select>
<app-giftcard-create *ngIf="showGiftCardCreateWindow" (complete)="giftCardProductCreated($event)"></app-giftcard-create>
