import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../store';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Observable } from 'rxjs';
import { PassportService } from 'src/app/passport/passport.service';
import { BasketService } from 'src/app/services/basket.service';

@Component({
    selector: 'app-booking-user-edit',
    templateUrl: './booking-user-edit.component.html',
    styleUrls: ['./booking-user-edit.component.css'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ]
})
export class BookingUserEditComponent implements OnInit {
    @Input() passportId = '';

    form: UntypedFormGroup;

    feedbackCommentData;

    isNew = true;
    dupeEmail = false;

    passport;

    dob = new Date();
    submitted = false;
    passportCompanies = [];
    tmpPassportPreferences = [];
    transactions = [];
    feedback = [];
    bookings = [];
    giftcards = [];

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],  // custom dropdown
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };


    titles = [
        { id: 1, name: 'Mr' },
        { id: 2, name: 'Mrs' },
        { id: 3, name: 'Miss' },
        { id: 4, name: 'Ms' },
        { id: 5, name: 'Dr' },
        { id: 6, name: 'Other' },
    ];
    genders = [
        { id: 'M', name: 'Male' },
        { id: 'F', name: 'Female' },
        { id: 'BN', name: 'Non-Binary' },
        { id: 'O', name: 'Other' },
    ];
    discountLevels = [
        { id: 53, name: '10%' },
        { id: 54, name: '20%' },
        { id: 55, name: '30%' },
        { id: 56, name: '40%' },
        { id: 57, name: '50%' },
    ];


    get preferencesFormArray() {
        return this.form.controls.preferences as UntypedFormArray;
    }

    isGuest: boolean = true

    constructor(
        private store: Store,
        private passportService: PassportService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private basketService: BasketService,
    ) {
        this.form = this.fb.group({
            title: ['', [Validators.required]],
            firstname: ['', [Validators.required, Validators.minLength(1)]],
            surname: ['', [Validators.required, Validators.minLength(1)]],
            company: ['', []],
            referredBy: ['', []],
            dob: ['', []],
            dobDay: ['', []],
            dobMonth: ['', []],
            dobYear: ['', []],
            gender: ['', []],
            add1: ['', []],
            add2: ['', []],
            town: ['', []],
            county: ['', []],
            postcode: ['', []],
            username: ['', [Validators.required]],
            telephone: ['', []],
            mobile: ['', []],
            staffGroup: [0, []],
            discountLevel: [53, []],
            isSupplier: [false, []],
            preferences: new UntypedFormArray([]),
            notes: ['', []],
            isGuest: true
        });

    }


    ngOnInit() {

        if (this.passportId === 'new') {
            this.isNew = true;
            this.passport = {
                title: { id: 0 },
                firstname: '',
                surname: '',
                company: '',
                referredBy: '',
                add1: '',
                add2: '',
                town: '',
                county: '',
                postcode: '',
                gender: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                mobile: '',
                telephone: '',
                username: '',
                staffGroup: 0,
                discountLevel: 0,
                isSupplier: false,
                notes: '',
                isGuest: true,
            }
            this.getCompanies();
        } else {
            this.isNew = false;
            this.get();
        }

        const isGuestControl = this.form.get('isGuest');
        if (isGuestControl) {
            isGuestControl.valueChanges.subscribe(value => {
                this.isGuest = value;
                console.log(value, this.isGuest);
            });
        }
    }

    getCompanies() {
        this.passportService.getCompanies().subscribe(data => {
            this.passportCompanies = data.data;
            this.passportCompanies.forEach(company => {
                let v = false;
                if (this.tmpPassportPreferences.indexOf(company.id) >= 0) {
                    v = true;
                } else {
                    v = false;
                }
                this.preferencesFormArray.push(new UntypedFormControl(v));
            });
        })
    }

    get() {
        this.passportService.get(this.passportId).subscribe(data => {
            console.log(data.data)
            this.passport = data.data;
            this.form.patchValue({
                title: data.data.title.id,
                firstname: data.data.firstname,
                surname: data.data.surname,
                company: data.data.company,
                referredBy: data.data.referredBy,
                add1: data.data.add1,
                add2: data.data.add2,
                town: data.data.town,
                county: data.data.county,
                postcode: data.data.postcode,
                gender: data.data.gender,
                mobile: data.data.mobile,
                telephone: data.data.telephone,
                username: data.data.username,
                staffGroup: data.data.staffGroup,
                discountLevel: data.data.discountLevel,
                isSupplier: data.data.isSupplier,
                notes: data.data.notes,
                isGuest: data.data.isGuest,
            })
            if (data.data.dob) {
                this.form.patchValue({
                    dobDay: +data.data.dob.dobDay,
                    dobMonth: +data.data.dob.dobMonth,
                    dobYear: +data.data.dob.dobYear,
                    dob: new Date(data.data.dobYear, (+data.data.dobMonth - 1), data.data.dobDay),
                })
                console.log(`patched date as ${data.data.dobYear}${data.data.dobMonth - 1} ${data.data.dobDay}`)
            }
            this.tmpPassportPreferences = data.data.preferences;
            this.getCompanies();

        })
    }

    bstFix(d) {
        const fixedDate = new Date(d);
        if (fixedDate.getUTCHours() > 22) {
            fixedDate.setTime(fixedDate.getTime() + 6 * 60 * 60 * 1000);
        }
        return fixedDate;
    }

    onSubmit() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const selectedPassportCompanies = this.form.value.preferences
            .map((checked, i) => checked ? this.passportCompanies[i].id : null)
            .filter(v => v !== null);

        this.passport = {
            id: this.passportId,
            title: { id: this.form.value.title },
            username: this.form.value.username,
            firstname: this.form.value.firstname,
            surname: this.form.value.surname,
            company: this.form.value.company,
            referredBy: this.form.value.referredBy,
            add1: this.form.value.add1,
            add2: this.form.value.add2,
            town: this.form.value.town,
            county: this.form.value.county,
            postcode: this.form.value.postcode,
            dob: this.bstFix(new Date(this.form.value.dob)),
            gender: this.form.value.gender,
            mobile: this.form.value.mobile,
            telephone: this.form.value.telephone,
            isSupplier: this.form.value.isSupplier,
            staffGroup: this.form.value.staffGroup,
            discountLevel: this.form.value.discountLevel,
            preferences: selectedPassportCompanies,
            notes: this.form.value.notes,
            isGuest: this.form.value.isGuest,
        }

        let authObs: Observable<any>;
        if (this.isNew) {
            this.passport.id = '';
            authObs = this.passportService.create(this.passport);
        } else {
            authObs = this.passportService.update(this.passport);
        }

        authObs.subscribe(
            resData => {
                console.log(resData.data);
                this.select(resData.data);
            }
            , errorMessage => {
                console.log(errorMessage);
                // this.form.controls.password.setErrors({ 'incorrect': true });
            }
        );
    }

    select(user) {
        const basket = this.basketService.getBasket()
        console.log(user);
        basket.userId = user.id;
        basket.user = user;
        this.store.set('basket', basket);
        this.basketService.saveBasket(basket);

        this.router.navigate(['/bookings/create/availability'])
    }

    numberPad(v) {
        if (v > 10) {
            return v;
        }
        const paddedNumber = '0' + v;
        console.log(paddedNumber)
        return paddedNumber;
    }

    reprint() {
        this.passportService.reprint(this.passportId).subscribe(data => {

        })
    }

    dupeCheckEmail() {
        this.passportService.dupeCheckEmail(this.form.value.username, this.passportId).subscribe(data => {
            console.log(data);
            this.dupeEmail = data.data;
            if (data.data) {
                this.form.controls.username.setErrors({ 'exists': true });
            }
        })
    }

    dupeCheckCardNumber() {

    }







}
