import {Component, Input, OnInit} from '@angular/core';
import {GrowlerService} from '../services/growler.service';

@Component({
  selector: 'app-growler',
  templateUrl: './growler.component.html',
  styleUrls: ['./growler.component.css']
})
export class GrowlerComponent implements OnInit {
    @Input() growlerData;

  constructor(
      private growlerService: GrowlerService
  ) { }

  ngOnInit() {
  }

  close() {
      this.growlerService.clearMessage();
  }
}
