import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { RoomService } from '../../services/room.service';
import { OverlayService } from '../../services/overlay.service';
import { Store } from '../../store';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';

@Component({
    selector: 'app-rooms-edit',
    templateUrl: './rooms-edit.component.html',
    styleUrls: ['./rooms-edit.component.css']
})
export class RoomsEditComponent implements OnInit {

    room = this.store.selectForLocal('room');

    constructor(
        private overlayService: OverlayService,
        private roomService: RoomService,
        private store: Store,
        private router: Router,
        private cacheService: CacheService,
    ) {

    }

    ngOnInit() {
        // if (!this.room) {
        //     this.back();
        // }
        console.log(this.room);


    }



    save() {
        if (!this.room.isNew) {
            this.roomService.save(this.room);
        } else {
            this.roomService.create(this.room);
        }
        this.cacheService.clearCache().subscribe();
        // this.back();
    }

    back() {
        this.router.navigate(['/rooms']);
    }
}
