import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PackageService {
    defaultParams = {
        searchTerm: '',
        active: 1,
        name: '',
        county: '',
        limit: 10,
        limits: [10, 20, 30, 40],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'packages_group.name ASC',
        sorts: [
            { ID: 'packages_group.name ASC', name: 'Promotion name A-Z' },
            { ID: 'packages_group.name DESC', name: 'Promotion name Z-A' },
            { ID: 'hotel.name ASC', name: 'Hotel name A-Z' },
            { ID: 'hotel.name DESC', name: 'Hotel name Z-A' },
            { ID: 'packages_group.created ASC', name: 'Created ASC' },
            { ID: 'packages_group.created DESC', name: 'Created DESC' },
        ],
    };


    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) { }

    updateParams(params) {

    }
    resetParams() {
        return this.defaultParams;
    }
    search(params) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: params
        };
        return this.http.post(this.apiService.path() + 'packages/search', dataToSend).pipe(map((data: any) => {
            console.log(data.data)
            this.store.set('packages', data.data);
            return data;
            // this.store.set('packages', data.data);
            // const p = this.store.selectForLocal('packageParams');
            // p.pages = Math.ceil(data.params.totalRecords / p.limit);
            // p.totalRecords = data.params.totalRecords;
            // p.pageArray = [];
            // for (let i = 0; i < p.pages; i++) {
            //     p.pageArray.push(i);
            // }
            // this.store.set('packageParams', p);
        }));
    }

    get(Id) {
        return this.http.get(this.apiService.path() + 'packages/get/' + Id).pipe(map((data: any) => {
            return data.data;
        }));
    }



    save(data) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            package: data
        };
        return this.http.post(this.apiService.path() + 'packages/update', dataToSend).pipe(map((response: any) => {
            return response;
        }));
    }

    updateActive(data) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            package: data
        };
        return this.http.post(this.apiService.path() + 'packages/update/active', dataToSend).pipe(map((response: any) => {
            return response;
        }));
    }

    packageDelete(packageData) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            package: packageData
        };
        return this.http.post(this.apiService.path() + 'packages/delete', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    getRooms(hotel, packagedData) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            hotel: hotel,
            package: packagedData
        };
        return this.http.post(this.apiService.path() + 'packages/rooms', dataToSend)
            .pipe(map((response: any) => {
                return response;
            }));

    }

    toggleRoom(hotelId, roomId, packageId) {
        const dataToSend = {
            hotelId: hotelId,
            roomId: roomId,
            packageId: packageId
        };
        return this.http.post(this.apiService.path() + 'packages/room/toggle', dataToSend)
            .pipe(map((response: any) => {
                return response;
            }));

    }
}
