import { HotelService } from './../../services/hotel.service';
import { ToolsService } from './../../services/tools.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ImagesService } from 'src/app/services/images.service';
import { GrowlerService } from 'src/app/services/growler.service';
import { RatePlanService } from 'src/app/services/rate-plan.service';
import { RoomService } from 'src/app/services/room.service';

@Component({
    selector: 'app-edit-rate-plan',
    templateUrl: './edit-rate-plan.component.html',
    styleUrls: ['./edit-rate-plan.component.css']
})
export class EditRatePlanComponent implements OnInit {

    isNew = false;
    dataId = '';
    dataToEdit;
    errorString = '';
    hotels = [];
    rooms = [];

    data = {
        id: '',
        tmpId: '',
        ratePlanCode: '',
        name: '',
        description: '',
        overview: '',
        terms: '',
        upsellText: '',
        status: 1,
        allowPassport: 0,
        images: [],
        bannerImage: '',
        hotel: '',
        hotels: [],
        rooms: [],
        isDerivative: 0,
    };

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],  // custom dropdown
            ['bold', 'italic', 'underline', 'link'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    constructor(
        private toolsService: ToolsService,
        private router: Router,
        private route: ActivatedRoute,
        private imagesService: ImagesService,
        private hotelService: HotelService,
        private roomService: RoomService,
        private growlerService: GrowlerService,
        private ratePlanService: RatePlanService,
    ) { }

    ngOnInit() {
        this.getHotels();
        this.dataId = this.route.snapshot.params.id;
        if (this.dataId === 'create') {
            this.isNew = true;
            this.data.id = this.toolsService.secureId();
        } else {
            this.get()
        }
    }

    getHotels() {
        this.hotelService.allLite().subscribe(data => {
            this.hotels = data;
        })
    }

    getRooms(killSelection) {
        this.roomService.byHotelID(this.data.hotel).subscribe(data => {

            if (killSelection) {
                this.data.rooms = [];
            }

            this.rooms = [];
            data.data.forEach(r => {
                this.rooms.push({ id: r.id, name: r.name })
            });
        })
    }
    get() {
        this.ratePlanService.get(this.dataId).subscribe(data => {
            console.log(data);
            this.data = data;
            if (this.data.images.length) {
                this.setEventBannerImage(this.data.images[0])
            }
            this.getRooms(false)
        })
    }

    save() {
        let validated = true;

        console.log(this.data);
        if (!this.data.ratePlanCode) { validated = false; }
        if (!this.data.name) { validated = false; }
        if (!this.data.hotel) { validated = false; }
        if (!this.data.rooms.length) { validated = false; }
        // if (!this.data.hotels.length) { validated = false; }
        if (!this.data.bannerImage) { validated = false; }
        if (!this.data.overview) { validated = false; }
        if (!this.data.terms) { validated = false; }
        if (this.data.ratePlanCode !== 'BB' && !this.data.upsellText) { validated = false; }
        if (!this.data.description) { validated = false; }


        if (!validated) {
            this.growlerService.error(['Please fill out all required fields.']);
            this.errorString = 'Please fill out all required fields.';
            return;
        }

        if (this.isNew) {
            this.ratePlanService.create(this.data).subscribe(data => {
                this.growlerService.notification(['Rate plan created']);
                this.router.navigate(['/rateplans']);
            })
        } else {
            this.ratePlanService.update(this.data).subscribe(data => {
                this.growlerService.notification(['Rate plan updated']);
                this.router.navigate(['/rateplans']);
            })
        }
    }

    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }



    toggleStatus() {
        if (!this.data.status) {
            this.data.status = 1
        } else {
            this.data.status = 0
        }
    }
    setEventBannerImage(e) {
        console.log(e.URL);
        this.data.bannerImage = e.URL;

    }

    deleteImage(idx) {
        this.imagesService.delete(this.data.images[idx]).subscribe(data => { });
        this.data.images.splice(idx, 1);
    }

    isRoomSelected(id) {
        return this.data.rooms.indexOf(id);
    }

    toggleRoom(id) {
        if (this.isRoomSelected(id) < 0) {
            this.data.rooms.push(id);
        } else {
            this.data.rooms.splice(this.isRoomSelected(id), 1)
        }
    }


    drop(e) {
        // this.toSave = e;
        console.log(e)
        this.ratePlanService.sortSkus(e).subscribe(data => {
        });
    }

}

