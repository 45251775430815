import { BookingService } from './../../services/booking.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '../../store';
import { ActivatedRoute, Router } from '@angular/router';
import { PassportService } from 'src/app/passport/passport.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-customer-view',
    templateUrl: './customer-view.component.html',
    styleUrls: ['./customer-view.component.css']
})
export class CustomerViewComponent implements OnInit {

    isNew = true;
    dupeEmail = false;
    bookings = [];

    passport;
    passportId = '';
    dob = new Date();
    submitted = false;
    passportCompanies = [];
    tmpPassportPreferences = [];
    transactions = [];

    titles = [
        { id: 1, name: 'Mr' },
        { id: 2, name: 'Mrs' },
        { id: 3, name: 'Miss' },
        { id: 4, name: 'Ms' },
        { id: 5, name: 'Dr' },
        { id: 6, name: 'Other' },
    ];
    genders = [
        { id: 'M', name: 'Male' },
        { id: 'F', name: 'Female' },
        { id: 'BN', name: 'Non-Binary' },
        { id: 'O', name: 'Other' },
    ];
    discountLevels = [
        { id: 53, name: '10%' },
        { id: 54, name: '20%' },
        { id: 55, name: '30%' },
        { id: 56, name: '40%' },
        { id: 57, name: '50%' },
    ];

    bookingParams = {
        userId: '',
        searchTerm: '',
        name: '',
        hotelID: '',
        upgraded: '',
        hasPromo: '',
        hasExtras: '',
        county: '',
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'booking.created DESC',
        sorts: [
            { ID: 'booking.created DESC', name: 'Recent First' },
            { ID: 'booking.created ASC', name: 'Recent Last' },
            { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
            { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
            { ID: 'county.name DESC', name: 'County A-Z' },
            { ID: 'county.name ASC', name: 'County Z-A' },
        ],
    };

    transactionParams = {
        eposNowId: 0,
        searchTerm: '',
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'en_transaction.createdAt DESC',
        sorts: [
            { ID: 'en_transaction.createdAt DESC', name: 'Recent First' },
            { ID: 'en_transaction.createdAt ASC', name: 'Recent Last' },
            { ID: 'en_brand.name ASC', name: 'Brand Name A-Z' },
            { ID: 'en_brand.name DESC', name: 'Brand Name Z-A' },
        ],
    };

    constructor(
        private store: Store,
        private passportService: PassportService,
        private bookingService: BookingService,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
    ) {

    }


    ngOnInit() {
        this.store.set('area', 'passport');
        this.passportId = this.route.snapshot.params.id;
        this.isNew = false;
        this.get();
    }

    getCompanies() {
        this.passportService.getCompanies().subscribe(data => {
            this.passportCompanies = data.data;
        })
    }

    get() {
        this.passportService.get(this.passportId).subscribe(data => {
            console.log(data.data)
            this.passport = data.data;
            this.getCompanies();
            this.getBookings();
            this.getTransactions();
        })
    }

    getBookings() {
        this.bookingParams.userId = this.passportId;
        this.bookingService.byCustomer(this.passportId).subscribe(data => {
            this.bookings = data;
        })
    }

    getTransactions() {
        this.transactionParams.eposNowId = this.passport.eposNowId;
        this.userService.loadTransactions(this.transactionParams).subscribe(data => {
            console.log(data.data);
            this.transactions = data.data.data;


            this.transactionParams.pages = Math.ceil(
                data.data.totalRecords / this.transactionParams.limit
            );
            this.transactionParams.totalRecords = data.data.totalRecords;
            this.transactionParams.pageArray = [];
            for (let i = 0; i < this.transactionParams.pages; i++) {
                this.transactionParams.pageArray.push(i);
            }

        })
    }

    bstFix(d) {
        const fixedDate = new Date(d);
        if (fixedDate.getUTCHours() > 22) {
            fixedDate.setTime(fixedDate.getTime() + 6 * 60 * 60 * 1000);
        }
        return fixedDate;
    }


    numberPad(v) {
        if (v > 10) {
            return v;
        }
        const paddedNumber = '0' + v;
        console.log(paddedNumber)
        return paddedNumber;
    }

    edit(customer) {
        this.router.navigate(["/passport", customer.id]);

    }

    viewBooking(booking) {
        this.bookingService.setBooking(booking);
        this.router.navigate(['/bookings', booking.id]);
    }

}
