import {Component, Input, OnInit} from '@angular/core';
import {RoomService} from '../../services/room.service';
import {GrowlerService} from '../../services/growler.service';

@Component({
  selector: 'app-room-supplement',
  templateUrl: './room-supplement.component.html',
  styleUrls: ['./room-supplement.component.css']
})
export class RoomSupplementComponent implements OnInit {
    @Input() room;

    supplement: any = undefined;

  constructor(
      private roomService: RoomService,
      private growlerService: GrowlerService,
  ) { }

  ngOnInit() {
      this.resetSupplement();
      if (this.room.offers) {
          this.supplement = this.room.offers.supplement;
      }
  }

    resetSupplement() {
        this.supplement = {
            hotelID: this.room.hotel.id,
            roomID: this.room.id,
            cost: 0,
            description: '',
            limit: 1
        };
    }

    add() {
        this.supplement.hotelID = this.room.hotel.id;
        this.supplement.roomID =  this.room.id;

        this.roomService.addSupplement(this.supplement).subscribe(data => {
            if (data.errors.length) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['Room Supplement Updated']);
                // this.resetSupplement();
            }

        });
    }


    delete() {
        this.supplement.hotelID = this.room.hotel.id;
        this.supplement.roomID =  this.room.id;

        this.roomService.deleteSupplement(this.supplement).subscribe(data => {
            if (data.errors.length) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['Room Supplement Deleted']);
                this.resetSupplement();
            }

        });
    }

}
