import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removePence'
})
export class RemovePencePipe implements PipeTransform {

    transform(value: number): string {
        if (!value && value !== 0) {
            return '';
        }
        // Convert to string, split by decimal, and return only the integer part
        const pounds = Math.floor(value);
        return `£${pounds}`;
    }

}
