import { Store } from './../store';
import { Injectable } from '@angular/core';

const TOKEN_KEY = 'bp-auth-token';
const USER_KEY = 'bp-auth-user';
const BASKET_KEY = 'bp-basket';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(
        private store: Store,
    ) { }

    // auth / user
    signOut(): void {
        this.store.set('user', undefined);
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.removeItem(TOKEN_KEY);
    }

    public saveToken(token: string): void {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string {
        return localStorage.getItem(TOKEN_KEY);
    }

    public saveUser(user): void {
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.setItem(USER_KEY, JSON.stringify(user));
        this.store.set('user', user);
    }

    public getUser(): any {
        const user = JSON.parse(localStorage.getItem(USER_KEY));
        this.store.set('user', user)
        return JSON.parse(localStorage.getItem(USER_KEY));
    }

    // basket
    public getBasket(): string {
        return localStorage.getItem(BASKET_KEY);
    }

    public saveBasket(basket) {
        console.log(basket)
        window.localStorage.removeItem(BASKET_KEY);
        window.localStorage.setItem(BASKET_KEY, JSON.stringify(basket));
    }

}
