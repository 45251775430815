<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-8">
            <a [routerLink]="['/hotels/features']">Hotel Features</a>
        </div>
        <div class="col-md-4 text-right">
            <button mat-raised-button color="primary" (click)="sortFeatureGroups()">Sort Feature Groups</button>
            <button mat-raised-button color="primary" (click)="addFeatureGroup()">Add Feature Group</button>
        </div>
    </div>
</mat-card>

<mat-card class="no-records" *ngIf="!groups.featureGroups.length">
    No Hotel Features Listed
</mat-card>

<ng-container *ngIf="groups.featureGroups.length">
    
    <div class="margin-top-20">
    
        <div class="card-columns">
            <div class="card" *ngFor="let g of groups.featureGroups">
                <div class="card-body">
                        <div class="row">
                            <div class="col-9">
                                <h3>{{g.name}}</h3>
                            </div>
                            <div class="col-3 text-right">
                                <a  (click)="editFeatureGroup(g)">
                                    <mat-icon>edit</mat-icon>
                                </a>
                            </div>
                        </div>
        
                        <mat-list>
                            <mat-list-item *ngFor="let f of g.features" style="height: 35px">
                                <mat-icon mat-list-icon (click)="setFeatureToDelete(f.id, g.id)">delete</mat-icon>
                                <h4 mat-line>{{f.name}}</h4>
                            </mat-list-item>
                        </mat-list>
        
        
                        <div class="row" *ngIf="featureToDelete.groupID === 0">
                            <div class="col-6 text-center">
                                <button mat-raised-button color="warn" (click)="openAddFeature(g.id)">Add Feature</button>
                            </div>
                            <div class="col-6 text-center">
                                <button *ngIf="newFeature.groupID === g.id" mat-raised-button color="primary" (click)="cancelAddFeature()">Cancel Add</button>
                            </div>
                        </div>
        
                        <div class="row" *ngIf="featureToDelete.groupID === g.id">
                            <div class="col-6 text-center">
                                <button mat-raised-button color="warn" (click)="doDelete()">Delete Feature</button>
                            </div>
                            <div class="col-6 text-center">
                                <button mat-raised-button color="primary" (click)="cancelDelete()">Cancel Delete</button>
                            </div>
                        </div>
        
                        <ng-container *ngIf="newFeature.groupID === g.id">
                            <div class="row" style="margin-top:20px">
                                <div class="col-8">
                                    <mat-form-field class="example-full-width">
                                        <input matInput [(ngModel)]="newFeature.name" placeholder="Feature Name">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <button mat-raised-button color="primary" (click)="addFeature()">Save</button>
                                </div>
                            </div>
                        </ng-container>
    
                </div>
            </div>
        </div>
        
    
    </div>

</ng-container>


