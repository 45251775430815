<mat-card class="breadcrumb mt-3">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/events']">Back to all events</a>
        </div>
        <div class="col-md-5 text-right">
            <button type="button" class="btn btn-success mr-2" (click)="exportConfirm('purchasers')" matTooltip="Export buyers to CSV file">
                Export Buyers
             </button>
            <button type="button" class="btn btn-success" (click)="exportConfirm('attendees')" matTooltip="Export attendees to CSV file">
                Export Attendees
             </button>

        </div>
    </div>
</mat-card>

<mat-card class="text-right">

    <div class="row">
        <div class="col-md-2">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.searchText" (keyup)="filterSearch()" placeholder="Search....">
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <!-- <mat-form-field>
                <mat-select
                        [(ngModel)]="params.source"
                        (selectionChange)="filterSearch()"
                        placeholder="Source">
                    <mat-option value="">All sources</mat-option>
                    <mat-option [value]="'booking'">Room Booking</mat-option>
                    <mat-option [value]="'events'">Events Module</mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="filterSearch()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-select [(ngModel)]="params.limit" (ngModelChange)="filterSearch()" placeholder="Items per page">
                    <mat-option
                        [value]="l"
                        *ngFor="let l of params.limits"
                    >
                        {{ l }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-1 text-right">
            <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>

        </div>
    </div>


</mat-card>

<mat-card class="no-records" *ngIf="!data.length">
    No Attendees Listed
</mat-card>

<ng-container *ngIf="data.length">

    <div class="margin-top-20">
        <app-pagination [params]="params" (update)="search()"></app-pagination>


        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th></th>
                <th>Created</th>
                <th>Booking ID</th>
                <th>Source</th>
                <th>Event</th>
                <th>Tickets</th>
                <th>Booked By</th>
                <th>Email</th>
                <th>Telephone</th>
            </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let booking of data">
                    <tr class="hover" (click)="revealTickets(booking)">
                        <td>
                            <i class="fa fa-chevron-down" *ngIf="!booking.showTickets"></i>
                            <i class="fa fa-chevron-up" *ngIf="booking.showTickets"></i>
                        </td>
                        <td>{{booking.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td>{{booking.id}}</td>
                        <td>{{booking.source}}</td>
                        <td>{{booking.eventName}}</td>
                        <td>{{booking.totalTickets}}</td>
                        <td> {{booking.firstname}} {{booking.surname}}</td>
                        <td>{{booking.username}}</td>
                        <td>{{booking.telephone}}</td>
                    </tr>
                    <ng-container *ngIf="booking.showTickets">
                        <tr style="background-color: #e2e2e2;">
                            <td colspan="6">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Ticket Type</th>
                                            <th>Attendee</th>
                                            <th>Ticket Qty</th>
                                            <th>Cost</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let t of booking.tickets">
                                            <td>{{t.skuName}}</td>
                                            <td>{{t.attendee}}</td>
                                            <td>{{t.qty}}</td>
                                            <td>{{t.gross | currency: 'GBP'}}</td>
                                            <td>
                                                <span *ngIf="t.deleted" class="text-danger">Cancelled</span>
                                                <a class="hover text-danger" *ngIf="!t.deleted" (click)="deleteConfirm(t)">
                                                    <i class="fa fa-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </td>
                            <td colspan="3"></td>
                        </tr>
                    </ng-container>

                </ng-container>
            </tbody>
        </table>

        <app-pagination [params]="params" (update)="search()"></app-pagination>
    </div>


</ng-container>
<app-confirm-export *ngIf="exportConfirmationData.open" [confirmationData]="exportConfirmationData" (complete)="exportConfirmationComplete($event)"></app-confirm-export>
<app-confirmation *ngIf="ticketToDelete" [data]="deleteConfirmationData" (complete)="deleteTicket($event)"></app-confirmation>
