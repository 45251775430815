import {Component, OnInit, ViewChild} from '@angular/core';
import {HotelService} from '../../services/hotel.service';
import {Router} from '@angular/router';
import {Store} from '../../store';
import {OverlayService} from '../../services/overlay.service';

@Component({
  selector: 'app-hotels-list',
  templateUrl: './hotels-list.component.html',
  styleUrls: ['./hotels-list.component.css']
})
export class HotelsListComponent implements OnInit {

    hotels$ = this.store.select<any[]>('hotels');
    params$ = this.store.select<any>('hotelParams');

    constructor(
        private hotelService: HotelService,
        private router: Router,
        private store: Store,
        private overlayService: OverlayService,
    ) {}

    ngOnInit() {

    }
    edit(hotel) {
        this.hotelService.setHotel(hotel);
        this.router.navigate(['/hotels/edit']);
    }
    delete(hotel) {
        this.overlayService.open('hotelDelete');
    }
}
