<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <a [routerLink]="['/product-groups']">Room extras</a>
            <mat-icon>arrow_forward_ios</mat-icon>
            <a [routerLink]="['/products']">Extras</a>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary"  (click)="productAdd()">Add extra</button>
        </div>
    </div>
</mat-card>

<mat-card class="no-records" *ngIf="!(products$ | async).length">
    No extras for this group
</mat-card>

<ng-container *ngIf="(products$ | async).length">

    <div class="margin-top-20">
        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Extra Name</th>
                <th>Terms</th>
                <th>Extra Cost</th>
                <th>Text box?</th>
                <th>Question</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let p of (products$ | async)">
                <td style="width: 40%;">{{p.name}}</td>
                <td>{{p.terms}}</td>
                <td style="width: 120px;">{{p.cost}}</td>
                <td style="width: 120px;">
                    <span *ngIf="p.inputRequired">Yes</span>
                    <span *ngIf="!p.inputRequired">No</span>
                </td>
                <td>
                    <span *ngIf="p.inputRequired">{{p.question}}</span>
                </td>
                <td style="width: 120px;" class="text-right">
                    <a class="hover" (click)="productUpdate(p)">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a class="hover" (click)="productDelete(p)">
                        <mat-icon>delete</mat-icon>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

</ng-container>



