import { ToolsService } from './../../services/tools.service';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '../../store';
import { BookingService } from '../../services/booking.service';
import { debounce } from 'lodash';
import { RatePlanService } from 'src/app/services/rate-plan.service';

@Component({
    selector: 'app-bookings-filter',
    templateUrl: './bookings-filter.component.html',
    styleUrls: ['./bookings-filter.component.css']
})
export class BookingsFilterComponent implements OnInit {
    @Input() params;
    hotels$ = this.store.select<any[]>('hotels');
    counties$ = this.store.select<any[]>('counties');
    ratePlans = [];

    startDate;
    endDate;
    bookingStartDate;
    bookingEndDate;

    constructor(
        private store: Store,
        private bookingService: BookingService,
        private toolsService: ToolsService,
        private ratePlanService: RatePlanService,
    ) {
    }

    ngOnInit() {
        this.updateParams = debounce(this.updateParams, 350);
        this.getRatePlans();
        // this.updateParams();
    }

    updateParams() {
        if (this.startDate) {
            this.params.startDate = this.toolsService.dateToEpochDay(this.startDate)
        }
        if (this.endDate) {
            this.params.endDate = this.toolsService.dateToEpochDay(this.endDate)
        }
        if (this.bookingStartDate) {
            this.params.bookingStartDate = this.toolsService.buildDateString(this.bookingStartDate);
        }
        if (this.bookingEndDate) {
            this.params.bookingEndDate = this.toolsService.buildDateString(this.bookingEndDate);
        }
        this.search();

    }

    search() {
        this.bookingService.updateParams(this.params);
    }

    getRatePlans() {
        this.ratePlanService.getAllGrouped().subscribe(data => {
            this.ratePlans = data;
        })
    }

    resetParams() {
        this.startDate = undefined;
        this.endDate = undefined;
        this.bookingStartDate = undefined;
        this.bookingEndDate = undefined;

        this.bookingService.resetParams();
    }
}
