import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportingService {

    env = environment;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) {
    }


    search(params) {
        const dataToSend = {
            params
        }
        return this.http.post(this.env.apiV2Path + 'data/search', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }

    searchRRSync(params) {
        const dataToSend = {
            params
        }
        return this.http.post(this.env.apiV2Path + 'data/search/rrapi', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }

    export(params, segmentSelected, filename, type, voucher) {
        const dataToSend = {
            params,
            segmentSelected,
            filename,
            type,
            voucher
        }
        return this.http.post(this.env.apiV2Path + 'data/export', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    getSegments() {
        return this.http.get(this.env.apiV2Path + 'data/segments').pipe(map((data: any) => {
            return data.data;
        }));
    }

    getCompanies() {
        return this.http.get(this.env.apiV2Path + 'companies').pipe(map((data: any) => {
            return data.data;
        }));
    }

}
