import { ToolsService } from './tools.service';
import { StorageService } from './../services/storage.service';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BasketTotalsService } from './../services/basket-totals.service';

@Injectable({
    providedIn: 'root'
})
export class BasketService {

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
        private storageService: StorageService,
        private toolsService: ToolsService,
        private basketTotalsService: BasketTotalsService,
    ) { }

    create() {
        const basket = {
            version: 1,
            fixedPackageSelected: false,
            fixedPackageHidden: false,
            fixedPackageMode: 'banner',
            fixedPackageApplied: false,
            comments: '',
            packagesSelected: [],
            packagesQualified: [],
            items: [],
            giftCards: [],
            voucher: undefined,
            userId: '',
            discountCs: {
                rateOnly: false,
                type: 'perc',
                percValue: 0,
                typeValue: 0,
                discountValue: 0,
            },
            checkoutTotals: {
                paid: {
                    giftCard: 0,
                    voucher: 0,
                    card: 0,
                    events: 0,
                    treatments: 0,
                    total: 0,
                },
                unpaid: {
                    total: 0,
                },
                deposit: {
                    originalRoom: 0,
                    checkout: 0,
                },
                discount: {
                    package: 0,
                    passport: 0,
                    cs: 0,
                    voucher: 0,
                    addOns: 0,
                    events: 0,
                    treatments: 0,
                    total: 0,
                },
                costs: {
                    room: 0,
                    addOns: 0,
                    events: 0,
                    treatments: 0,
                    supplements: {
                        child: 0,
                        dog: 0,
                        bubbleAndSqueak: 0,
                        guest: 0,
                        total: 0,
                    },
                    total: 0,
                },
            },
            totals: {
                toPay: 0,
                deposit: 0,
                discount: 0,
                giftCard: undefined,
                giftCardDiscount: 0,
                discountPackage: 0,
                discountPassport: 0,
                paid: 0,
                total: 0,
                addOnsTotal: 0,
                supplements: {
                    child: 0,
                    dog: 0,
                }
            },
            fullPaymentRequired: false,
            allowPaymentChoice: false,
            fullPaymentSelected: false,
            depositFixedValue: 0,
            depositPaymentType: 'standard',
        }

        return basket;
    }


    attachUser(userId) {
        let basket = this.getBasket();
        basket.userId = userId;
        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
    }
    detachUser() {
        let basket = this.getBasket();
        basket.userId = '';
        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
    }

    addingAlternateHotel(items, hotelId) {
        console.log(items)
        for (let i = 0; i < items.length; i++) {
            if (items[i].room.hotelId !== hotelId) {
                return true;
            }
        }
        return false;
    }
    cleanAvSearch(avSearch) {

        delete avSearch.room.rules;
        delete avSearch.room.shortDescription;
        delete avSearch.room.description;
        delete avSearch.room.addressDescription;
        delete avSearch.room.features;
        delete avSearch.room.floorplanImages;
        delete avSearch.room.deposit_text;

        return avSearch;
    }
    addToBasket(availabilitySearch, booking) {
        let basket = this.store.selectForLocal('basket');

        const modules = this.store.selectForLocal('modules');

        basket.fullPaymentRequired = availabilitySearch.hotel.fullPaymentRequired;
        basket.allowPaymentChoice = availabilitySearch.hotel.allowPaymentChoice;

        if (availabilitySearch.hotel.allowPaymentChoice) {
            basket.fullPaymentSelected = true;
        } else {
            basket.fullPaymentSelected = false;
        }


        basket.items = [];
        basket.events = [];

        basket.voucher = undefined;

        availabilitySearch.checkOut = new Date(availabilitySearch.checkIn);
        availabilitySearch.checkOut.setDate(availabilitySearch.checkIn.getDate() + +availabilitySearch.nights);

        if (this.addingAlternateHotel(basket.items, availabilitySearch.hotel.id)) {
            basket.items = [];
        }

        let avSearch = JSON.parse(JSON.stringify(availabilitySearch));
        avSearch = this.cleanAvSearch(avSearch)
        console.log(avSearch)
        console.log(booking)
        const itemRoom = avSearch.room

        let itemToAdd = {
            fullPaymentRequired: availabilitySearch.hotel.fullPaymentRequired,
            fullPaymentSelected: basket.fullPaymentSelected,
            allowPaymentChoice: availabilitySearch.hotel.allowPaymentChoice,
            depositPaymentType: 'standard',
            room: itemRoom,
            booking,
            packagesSelected: [],
            addOns: availabilitySearch.addOns,
            giftCards: [],
            events: [],
            spa: {},
            availabilitySearch: avSearch,
            algorithm_uplift: 0,
            comments: '',
            checkoutTotals: this.store.selectForLocal('checkoutTotals'),
            totals: {
                toPay: 0,
                deposit: 0,
                depositForRoom: 0,
                discount: 0,
                giftCard: undefined,
                giftCardDiscount: 0,
                discountPackage: 0,
                discountPassport: 0,
                paid: 0,
                total: 0,
                addOnsTotal: 0,
                supplements: {
                    child: 0,
                    dog: 0,
                }
            }

        }

        itemToAdd.totals.depositForRoom = +avSearch.room.deposit;
        itemToAdd.totals.deposit = +avSearch.room.deposit;
        if (!itemToAdd.totals.giftCardDiscount) {
            itemToAdd.totals.giftCardDiscount = 0;
        }
        if (this.store.selectForLocal('user')) {
            const user = this.store.selectForLocal('user');
            if (user.id === '2f5bbdf4-16c5-463d-ae32-3fbbc9e636c4') {
                itemToAdd.totals.deposit = 0.50;
            }
        }


        itemToAdd.checkoutTotals = this.basketTotalsService.calculateItemTotals(basket, itemToAdd, basket.user)
        console.log(itemToAdd)
        basket.items.push(itemToAdd);


        basket.totals.depositForRoom = +avSearch.room.deposit;
        basket.totals.deposit = +avSearch.room.deposit;
        if (!basket.totals.giftCardDiscount) {
            basket.totals.giftCardDiscount = 0;
        }
        if (basket.user) {
            if (basket.user.id === '2f5bbdf4-16c5-463d-ae32-3fbbc9e636c4') {
                basket.totals.deposit = 0.50;
            }
        }
        basket = this.clearGiftCard(basket);
        basket = this.basketTotalsService.totalBasketMultiCheckout(basket);
        this.saveBasket(basket);

        const bookingId = this.toolsService.newUUID();
        this.store.set('bookingId', bookingId);
        return basket;
    }


    checkForRoomInBasket() {
        this.store.set('roomInBasket', false);
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');
        const basket = this.store.selectForLocal('basket');
        if (availabilitySearch.room) {
            for (let i = 0; i < basket.items.length; i++) {
                if (basket.items[i].room.id === availabilitySearch.room.id && basket.items[i].availabilitySearch.checkInDay === availabilitySearch.checkInDay) {
                    this.store.set('roomInBasket', true);
                }
            }

        }

    }



    clearEvents() {
        let basket = this.store.selectForLocal('basket');

        basket.events = [];

        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
    }

    addEvent(event) {
        let basket = this.store.selectForLocal('basket');

        // if (!basket.events) {
        //     basket.events = [];
        // }
        basket.events = [];
        basket.events.push(event)
        console.log(basket.events)
        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);

    }

    cancelBooking(i) {
        let basket = this.store.selectForLocal('basket');
        basket.items.splice(i, 1);
        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
        return basket;
    }

    addAddOn(product, itemIdx) {
        let basket = this.store.selectForLocal('basket');

        if (+product.maxQty === 1) {
            product.qtySelected = 1;
        } else {
            if (+product.qtySelected < +product.maxQty) {
                product.qtySelected = +product.qtySelected + 1;
            }
        }
        const currentPosition = this.basketAddOnPosition(product, itemIdx);

        if (currentPosition < 0) {
            basket.items[itemIdx].addOns.push(product);
        } else {
            basket.items[itemIdx].addOns[currentPosition] = product;
        }

        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
        // this.gaService.addProduct(product, basket.items[itemIdx])
    }

    removeAddOn(product, itemIdx) {
        let basket = this.store.selectForLocal('basket');

        if (product.qtySelected > 0) {
            product.qtySelected = +product.qtySelected - 1;
        }

        const currentPosition = this.basketAddOnPosition(product, itemIdx);

        if (+product.qtySelected === 0) {
            basket.items[itemIdx].addOns.splice(currentPosition, 1)
        } else {
            basket.items[itemIdx].addOns[currentPosition] = product;
        }

        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
        // this.gaService.removeProduct(product, basket.items[itemIdx])
    }

    basketAddOnPosition(product, itemIdx) {
        const basket = this.store.selectForLocal('basket');
        if (!basket.items.length) {
            return -1;
        }
        for (let i = 0; i < basket.items[itemIdx].addOns.length; i++) {
            if (basket.items[itemIdx].addOns[i].id === product.id) {
                return i;
            }
        }
        return -1;
    }

    updateBasketComments(comments, itemIdx) {
        let basket = this.store.selectForLocal('basket');
        basket.items[itemIdx].comments = comments;
        this.saveBasket(basket);
    }


    addTreatment(product, itemIdx) {
        let basket = this.store.selectForLocal('basket');

        const currentPosition = this.basketTreatmentPosition(product, itemIdx);
        if (!basket.items[itemIdx].treatments) {
            basket.items[itemIdx].treatments = [];
        }
        if (currentPosition < 0) {
            basket.items[itemIdx].treatments.push(product);
        } else {
            basket.items[itemIdx].treatments[currentPosition] = product;
        }

        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
    }

    removeTreatment(product, itemIdx) {
        let basket = this.store.selectForLocal('basket');

        if (product.qtySelected > 0) {
            product.qtySelected = +product.qtySelected - 1;
        }

        const currentPosition = this.basketTreatmentPosition(product, itemIdx);

        if (+product.qtySelected === 0) {
            basket.items[itemIdx].treatments.splice(currentPosition, 1)
        } else {
            basket.items[itemIdx].treatments[currentPosition] = product;
        }

        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
    }

    basketTreatmentPosition(product, itemIdx) {
        const basket = this.store.selectForLocal('basket');
        if (!basket.items.length) {
            return -1;
        }
        if (!basket.items[itemIdx].treatments) {
            basket.items[itemIdx].treatments = [];
        }
        for (let i = 0; i < basket.items[itemIdx].treatments.length; i++) {
            if (basket.items[itemIdx].treatments[i].serviceId === product.serviceId) {
                return i;
            }
        }
        return -1;
    }


    getBasket() {
        let basket;
        basket = this.storageService.getBasket();

        if (basket) {
            basket = JSON.parse(basket)
            if (!basket.version) {
                basket = this.store.selectForLocal('basket');
            }

            basket = this.basketTotalsService.totalBasketMultiCheckout(basket);
            this.store.set('basket', basket);
        } else {
            basket = this.store.selectForLocal('basket');
            basket = this.basketTotalsService.totalBasketMultiCheckout(basket);
            this.storageService.saveBasket(basket);
        }
        this.saveBasket(basket);
        return basket;
    }

    clearGiftCard(basket) {
        basket.totals.giftCard = undefined;
        basket.giftCards = [];
        basket.totals.giftCardDiscount = 0;
        return basket;
    }

    closeBasket() {
        let basket;
        basket = this.storageService.getBasket();
        basket = this.basketTotalsService.totalBasket(JSON.parse(basket));
        // save a copy for the checkout confirmation page;
        this.store.set('basketCopy', JSON.parse(JSON.stringify(basket)));

        basket.totals.giftCard = undefined;
        basket.giftCards = [];
        basket.totals.giftCardDiscount = 0;

        basket.items = [];
        basket.packagesQualified = [];
        delete basket.payment;
        basket = this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
    }

    deleteIntent() {
        const basket = this.store.selectForLocal('basket');
        delete basket.intent;
        this.saveBasket(basket);
    }

    applyPackage(packageSelected) {
        const basket = this.store.selectForLocal('basket');

        // lets force only one package at a time
        basket.packagesSelected = [];


        if (!basket.packagesSelected) {
            basket.packagesSelected = [];
        }
        if (!basket.packagesQualified) {
            basket.packagesQualified = [];
        }

        let packageAlreadySelected = false;
        basket.packagesSelected.forEach(p => {
            if (p.id === packageSelected.id) {
                packageAlreadySelected = true;
            }
        })
        if (!packageAlreadySelected) {
            basket.packagesSelected.push(packageSelected);
        }
        this.saveBasket(basket);
    }

    removePackage(packageSelected) {
        const basket = this.store.selectForLocal('basket');
        if (!basket.packagesSelected) {
            basket.packagesSelected = [];
        }
        if (!basket.packagesQualified) {
            basket.packagesQualified = [];
        }
        basket.packagesSelected.forEach((p, idx) => {
            if (p.id === packageSelected.id) {
                basket.packagesSelected.splice(idx, 1);
            }
        })
        basket.packagesQualified.forEach((p, idx) => {
            if (p.id === packageSelected.id) {
                basket.packagesQualified.splice(idx, 1);
            }
        })
        this.saveBasket(basket);
    }
    clearAllPackages() {
        const basket = this.store.selectForLocal('basket');
        basket.packagesSelected = [];
        basket.packagesQualified = [];
        this.saveBasket(basket);

    }

    checkPackagesForQualification() {
        const basket = this.store.selectForLocal('basket');
        basket.packagesQualified = [];
        basket.packagesSelected.forEach(p => {
            const matches = true;
            // must be the same hotelId
            // if (p.hotel.id === basket.)
            basket.packagesQualified.push(p);
        });
        this.saveBasket(basket);
    }

    checkSelectedPackageIsStillValid() {

        const packages = this.store.selectForLocal('packages');
        const packagesFlatRate = this.store.selectForLocal('packagesFlatRate');
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');

        let basket = this.store.selectForLocal('basket');

        if (basket) {
            for (let p = 0; p < basket.packagesSelected.length; p++) {

                if (basket.packagesSelected[p].flatRate) {
                    // is the package that was selected still valid and in the store (search dates may have changed)
                    let packageStillExists = false;
                    for (let fr = 0; fr < packagesFlatRate.length; fr++) {
                        if (packagesFlatRate[fr].id === basket.packagesSelected[p].id) {
                            packageStillExists = true;
                        }
                    }

                    if (!packageStillExists) {
                        basket.packagesSelected.splice(p, 1);
                        this.store.set('packageSelected', undefined);
                    } else {
                        // continue checking for more elements

                        //is the room selected a valid room?
                        let roomFound = false;

                        for (let r = 0; r < basket.packagesSelected[p].rooms.length; r++) {
                            if (availabilitySearch.room.id === basket.packagesSelected[p].rooms[r].id) {
                                roomFound = true;
                            }
                        }
                        if (!roomFound) {
                            basket.packagesSelected.splice(p, 1);
                            this.store.set('packageSelected', undefined);
                        } else {
                            // check the dates are still valid
                            for (let e = 0; e < basket.packagesSelected[p].datesToExclude.length; e++) {
                                if (availabilitySearch.checkInDay === this.toolsService.dateToEpochDay(basket.packagesSelected[p].datesToExclude[e].dateToExclude)) {
                                    basket.packagesSelected.splice(p, 1);
                                    this.store.set('packageSelected', undefined);
                                }
                            }

                            if (+availabilitySearch.nights !== +basket.packagesSelected[p].nights) {
                                basket.packagesSelected.splice(p, 1);
                                this.store.set('packageSelected', undefined);
                            } else {
                                // console.log('nights match so leaving')
                            }

                        }


                    }

                } else {

                    // new code that went in from promo bug which did not detect qty change.
                    //is the room selected a valid room?
                    let roomFound = false;

                    for (let r = 0; r < basket.packagesSelected[p].rooms.length; r++) {
                        if (availabilitySearch.room.id === basket.packagesSelected[p].rooms[r].id) {
                            roomFound = true;
                        }
                    }
                    if (!roomFound) {
                        basket.packagesSelected.splice(p, 1);
                        this.store.set('packageSelected', undefined);
                    } else {
                        // check the dates are still valid
                        for (let e = 0; e < basket.packagesSelected[p].datesToExclude.length; e++) {
                            if (availabilitySearch.checkInDay === this.toolsService.dateToEpochDay(basket.packagesSelected[p].datesToExclude[e].dateToExclude)) {
                                basket.packagesSelected.splice(p, 1);
                                this.store.set('packageSelected', undefined);
                            }
                        }

                        if (+availabilitySearch.nights !== +basket.packagesSelected[p].nights) {
                            basket.packagesSelected.splice(p, 1);
                            this.store.set('packageSelected', undefined);
                        } else {
                        }

                    }


                }

            }
        }

        // if there is no package selected and the nights is equal to a package.nights then auto apply it
        if (!basket.packagesSelected.length) {
            for (let p = 0; p < packagesFlatRate.length; p++) {
                if (+availabilitySearch.nights === +packagesFlatRate[p].nights) {
                    basket.packagesSelected.push(packagesFlatRate[p]);
                    // this.store.set('packagesFlatRateDismissed', false);

                }
            }
        }






        basket.packagesQualified.forEach((qualifiedPackage, idx) => {
            let qualifiedPackageFound = false;
            packages.forEach(packageToCheck => {
                if (qualifiedPackage.id === packageToCheck.id) {
                    qualifiedPackageFound = true;
                }
            })
            if (!qualifiedPackageFound) {
                basket.packagesQualified.splice(idx)
            }
        })

        // check to see is any packages have been applied
        // if they have then make sure they still feature in the packagesQualified
        // if they don't remove them from packagesSelected
        for (let ps = 0; ps < basket.packagesSelected.length; ps++) {
            let packageStillQualifies = false;
            for (let pq = 0; pq < packages.length; pq++) {
                if (basket.packagesSelected[ps].id === packages[pq].id) {
                    packageStillQualifies = true;
                }
            }
            for (let pq = 0; pq < packagesFlatRate.length; pq++) {
                if (basket.packagesSelected[ps].id === packagesFlatRate[pq].id) {
                    packageStillQualifies = true;
                }
            }
            if (!packageStillQualifies) {
                basket.packagesSelected.splice(ps);
            }
        }

        this.basketTotalsService.totalBasket(basket);
        this.saveBasket(basket);
    }


    saveBasket(basket) {
        this.store.set('basket', basket);
        this.storageService.saveBasket(basket);
    }

    saveBasketToDB(basket) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            basket,
            user
        }
        return this.http.post(environment.apiV2Path + 'basket', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }
}
