
<ng-container *ngIf="passport">


    <form [formGroup]="form" autocomplete="off" novalidate [autocomplete]="'off'" (ngSubmit)="onSubmit()">

        <div class="row">
            <div class="col-xl-5">
                <mat-card>

                    <h2 class="mat-title">Personal Details</h2>

                    <div class="row mb-3">
                        <div class="col-xl-4">

                            <label>Register for Norfolk Passport?</label>
                            <select
                                formControlName="isGuest"
                                id="isGuest"
                                class="form-control form-control-sm"
                                placeholder="Norfolk Passport">
                                <option [ngValue]="false">Yes</option>
                                <option [ngValue]="true">No</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-4">
                            <label>Title</label>
                            <select
                            formControlName="title"
                            class="form-control form-control-sm"
                            placeholder="Title">
                            <option
                                [value]="c.id"
                                *ngFor="let c of titles">
                                {{c.name}}
                            </option>
                        </select>
                            </div>
                        <div class="col-xl-4">
                            <label>First name</label>
                            <input class="form-control form-control-sm" formControlName="firstname" placeholder="First name">
                        </div>
                        <div class="col-xl-4">
                            <label>Surname</label>
                            <input class="form-control form-control-sm" formControlName="surname" placeholder="Surname">
                        </div>
                    </div>


                    <div class="form-group row mt-4">
                        <label class="col-sm-3 col-form-label">Email address</label>
                        <div class="col-sm-9">
                            <input class="form-control form-control-sm" formControlName="username" (keyup)="dupeEmail = false;" (blur)="dupeCheckEmail()" placeholder="Email address">
                            <p class="text-danger" *ngIf="dupeEmail">Email address in use</p>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-6">
                            <label>Telephone number</label>
                            <input class="form-control form-control-sm" formControlName="telephone" placeholder="Telephone">
                        </div>
                        <div class="col-6">
                            <label>Mobile number</label>
                            <input class="form-control form-control-sm" formControlName="mobile" placeholder="Mobile">
                        </div>
                    </div>


                    <div class="form-group row mt-4">
                        <label class="col-sm-3 col-form-label">Address 1</label>
                        <div class="col-sm-9">
                            <input class="form-control form-control-sm" formControlName="add1" placeholder="Address Line 1">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Address 2</label>
                        <div class="col-sm-9">
                            <input class="form-control form-control-sm" formControlName="add2" placeholder="Address Line 2">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Town</label>
                        <div class="col-sm-9">
                            <input class="form-control form-control-sm" formControlName="town" placeholder="Town/City">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">County</label>
                        <div class="col-sm-9">
                            <input class="form-control form-control-sm" formControlName="county" placeholder="County">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Postcode</label>
                        <div class="col-sm-9">
                            <input class="form-control form-control-sm" formControlName="postcode" placeholder="Postcode">
                        </div>
                    </div>



                    <div class="row" *ngIf="!isGuest">
                        <div class="col-xl-6">
                            <label>Gender</label>
                            <select
                                formControlName="gender"
                                class="form-control form-control-sm"
                                placeholder="Gender">
                                <option
                                    [value]="c.id"
                                    *ngFor="let c of genders">
                                    {{c.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-xl-6">
                                <label>Date of birth</label>
                                <input class="form-control form-control-sm"
                                       autocomplete="off"
                                       formControlName="dob"
                                       (click)="dob.open()"
                                       [matDatepicker]="dob">
                                <!--<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
                                <mat-datepicker touchUi #dob [startView]="'multi-year'" [startAt]="dob"></mat-datepicker>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="col-xl-7">
                <mat-card class="mb-3" *ngIf="!form.get('isGuest').value">
                    <h2 class="mat-title">Register Interest</h2>

                    <div class="row">
                        <label formArrayName="preferences" class="col-4" *ngFor="let preference of preferencesFormArray.controls; let i = index">
                            <input type="checkbox" [formControlName]="i">
                            {{passportCompanies[i].name}}
                        </label>
                    </div>


                <h2 class="mat-title mt-4">Notes</h2>

                <quill-editor [modules]="config" formControlName="notes" [style]="{'height':'150px'}"></quill-editor>



            </mat-card>

            <mat-card>
                <div class="text-center">
                    <button mat-raised-button color="warn" type="submit">Save details</button>
                </div>

            </mat-card>

            </div>
        </div>


    </form>






</ng-container>

