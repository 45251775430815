<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>

    <h2 class="mat-title">Edit template</h2>

    <form [formGroup]="form" autocomplete="off" novalidate [autocomplete]="'off'" (ngSubmit)="save()">
        <div class="form-group">
            <label>Template name</label>
            <input
                type="text"
                autocomplete="new-password"
                class="form-control"
                [ngClass]="{'is-invalid' : form.controls.name.invalid && form.controls.name.dirty}"
                formControlName="name"
                placeholder="Enter name">
            <div class="invalid-feedback">Enter a valid name</div>
        </div>

        <div class="form-group">
            <label>Email invite subject</label>
            <input
                type="text"
                autocomplete="new-password"
                class="form-control"
                [ngClass]="{'is-invalid' : form.controls.invite_email_subject.invalid && form.controls.invite_email_subject.dirty}"
                formControlName="invite_email_subject"
                placeholder="Enter email subject">
            <div class="invalid-feedback">Enter a valid subject</div>
        </div>


        <div class="form-group">
            <label>Recipients</label>

               <textarea
                   rows="8"
                   type="text"
                   autocomplete="new-password"
                   class="form-control"
                   [ngClass]="{'is-invalid' : form.controls.email_recipients.invalid && form.controls.email_recipients.dirty}"
                   formControlName="email_recipients"
                   placeholder="Enter recipients (separated with comma)"></textarea>
            <div class="invalid-feedback">Recipients must be entered</div>
        </div>

        <div class="mt-2">
            <a (click)="toggleStatus()">
                <i
                    class="toggle fa"
                    [ngClass]="{
                    'fa-toggle-on text-success': form.value.is_active,
                    'fa-toggle-off' : !form.value.is_active}"></i>
                Active?
            </a>
        </div>

        <div class="mt-4 text-right">
            <button
                [disabled]="form.invalid"
                class="btn btn-round btn-primary mt-4"
                type="submit"
                tabindex="3">
                Save
            </button>
        </div>

    </form>

</div>
