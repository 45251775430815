import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { PassportService } from "../passport.service";
import { debounce } from "lodash";
import * as xlsx from "xlsx";
import { jsPDF } from "jspdf";
import { Store } from "../../store";

@Component({
    selector: "app-reprints",
    templateUrl: "./reprints.component.html",
    styleUrls: ["./reprints.component.css"],
})
export class ReprintsComponent implements OnInit {
    @ViewChild("epltable") epltable: ElementRef;
    @ViewChild("pdfData") pdfData: ElementRef;

    params = {
        type: "all",
        unconfirmed: false,
        printed: true,
        staffGroup: 0,
        isSupplier: false,
        reprint: true,
        signUp: false,
        searchText: "",
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "users.eposNowId DESC",
        sorts: [
            { ID: "users.eposNowId DESC", name: "ID First" },
            { ID: "users.eposNowId ASC", name: "ID Last" },
            { ID: "users.createdAt DESC", name: "Recent First" },
            { ID: "users.createdAt ASC", name: "Recent Last" },
            { ID: "users.firstname ASC", name: "Name A-Z" },
            { ID: "users.firstname DESC", name: "Name Z-A" },
        ],
    };

    type = "all";

    passports = [];
    routerSubscription: any;
    selected = [];
    excelTable = [];

    showDeleteConfirmation = false;
    deleteConfirmData = {
        title: "Confirm deletion",
        detail: "Are you sure you want to delete?",
        buttonText: "Confirm",
    };
    toDelete;

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private passportService: PassportService
    ) {
        this.filterSearch = debounce(this.filterSearch, 350);

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });
    }

    ngOnInit() {
        this.store.set("area", "passport");
        this.params.type = this.route.snapshot.params.type;
        this.filterSearch();
    }

    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
    filterSearch() {
        this.params.page = 0;
        this.search();
    }

    search() {
        this.passportService.search(this.params).subscribe((data: any) => {
            this.passports = data.data.data;
            this.params.pages = Math.ceil(
                data.data.totalRecords / this.params.limit
            );
            this.params.totalRecords = data.data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }

            this.excelTable = [];
        });
    }

    edit(passport) {
        this.router.navigate(["/passport", passport.id]);
    }

    resetParams() {
        this.params.searchText = "";
        this.params.page = 0;
        this.search();
    }

    setSelected(id) {
        if (this.selected.indexOf(id) > -1) {
            this.selected.splice(this.selected.indexOf(id), 1);
        } else {
            this.selected.push(id);
        }
        this.loadExcelData();
    }
    isSelected(id) {
        if (this.selected.indexOf(id) < 0) {
            return false;
        } else {
            return true;
        }
    }
    selectAll() {
        // if (this.selected.length === this.passports.length) {
        //     this.selected = [];
        // } else if (!this.selected.length) {
        //     this.selected = [];
        //     for (let i = 0; i < this.passports.length; i++) {
        //         this.selected.push(this.passports[i].id);
        //     }
        // } else {
        //     this.selected = [];
        // }
        this.selected = [];
        for (let i = 0; i < this.passports.length; i++) {
            this.selected.push(this.passports[i].id);
        }
        this.loadExcelData();
    }
    clearSelected() {
        this.selected = [];
        this.excelTable = [];
    }

    loadExcelData() {
        this.excelTable = [];
        this.passports.forEach((p) => {
            console.log(
                "looking for " + p.id + " - " + this.selected.indexOf(p.id)
            );
            if (this.selected.indexOf(p.id) >= 0) {
                this.excelTable.push(p);
            }
        });
    }

    exportToExcel() {
        const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(
            this.epltable.nativeElement
        );
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Worksheet");
        const dateToDay = new Date();
        const filename = "PassportReprints.xlsx";
        xlsx.writeFile(wb, filename);
    }

    markAsPrinted() {
        this.passportService.markAsPrinted(this.selected).subscribe((data) => {
            this.filterSearch();
            this.excelTable = [];
            this.selected = [];
        });
    }

    exportAsPDF() {
        const leftMargin = 20;
        let lineHeight = 12;
        const docHeight = 160;
        const docWidth = 212.5;

        this.loadExcelData();
        let data = this.pdfData.nativeElement;
        let pdf = new jsPDF("l", "pt", [docWidth, docHeight]);

        pdf.setFont("helvetica");
        this.excelTable.forEach((e) => {
            let cardNumber = "";
            if (e.staffGroup > 0 || e.isSupplier) {
                cardNumber = `S${e.cardNumber} `;
            } else {
                cardNumber = e.cardNumber + " ";
            }
            let y = 40;
            lineHeight = 20;
            pdf.setFontSize(6);
            pdf.text(cardNumber, leftMargin, y);

            pdf.setFontSize(10);
            y = y + lineHeight;
            pdf.text(e.firstname + " " + e.surname, leftMargin, y);
            lineHeight = 12;
            y = y + lineHeight;
            pdf.text(e.add1, leftMargin, y);
            y = y + lineHeight;
            if (e.add2) {
                pdf.text(e.add2, leftMargin, y);
                y = y + lineHeight;
            }
            pdf.text(e.town, leftMargin, y);
            y = y + lineHeight;
            pdf.text(e.county, leftMargin, y);
            y = y + lineHeight;
            pdf.text(e.postcode, leftMargin, y);

            pdf.addPage();
        });

        pdf.output("dataurlnewwindow");
    }

    deleteConfirmation(passport) {
        this.toDelete = passport;
        this.showDeleteConfirmation = true;
    }
    delete(e) {
        this.showDeleteConfirmation = false;
        if (e.action) {
            this.passportService.delete(this.toDelete).subscribe((data) => {
                this.filterSearch();
            });
        }
    }
}
