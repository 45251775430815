<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a *ngIf="isNew" [routerLink]="['/rateplans']">Create Rate Plan</a>
            <a *ngIf="!isNew" [routerLink]="['/rateplans']">Edit Rate Plan</a>
        </div>
        <div class="col-md-5 text-right">
        </div>
    </div>
</mat-card>


<mat-card class="mt-3">
    <div class="row" *ngIf="data">
        <div class="col-6">
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="data.ratePlanCode" placeholder="Plan Code">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="data.name" placeholder="Plan Name">
            </mat-form-field>


            <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                matTooltip="The hotel that this rate applies to"
                                [(ngModel)]="data.hotel"
                                (ngModelChange)="getRooms(true)"
                                placeholder="Hotel">
                            <mat-option [value]="hotel.id" *ngFor="let hotel of hotels">{{hotel.name}}</mat-option>
                        </mat-select>
                        <mat-hint>The hotel that this rate applies to</mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-6">
                </div>

            </div>

            <h3 class="mat-title mb-1 mt-3">Room selection</h3>
            <p style="color: rgba(0,0,0,.54); font-size: 11px;">Select the rooms that this rate applies to</p>
            <div class="mb-4 mt-1">

                <button
                    class="mr-2 mb-2"
                    *ngFor="let room of rooms"
                    mat-raised-button
                    [color]="isRoomSelected(room.id) > -1 ? 'primary' : 'grey'"
                    color="primary"
                    (click)="toggleRoom(room.id)">
                    {{room.name}}
                </button>

            </div>


            <div class="row mb-3">
                <div class="col-4">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.status"
                                placeholder="Active?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                        <mat-hint>Is this rate active?</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.isDerivative"
                                placeholder="Derivative rate?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                        <mat-hint>Is this rate a derivative?</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.allowPassport"
                                placeholder="Allow passport discount?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                        <mat-hint>Allow passport discount for this rate?</mat-hint>
                    </mat-form-field>
                </div>
            </div>



            <h3 class="mat-title">Lead Banner Image</h3>
            <div class="row">
                <div class="col-md-4">
                    <img *ngIf="!data.bannerImage" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
                    <img *ngIf="data.bannerImage" [src]="data.bannerImage" class="img-fluid" style="max-height: 150px" />
                </div>
                <div class="col-md-8">
                    <app-image-upload *ngIf="data.id" [ownerID]="data.id" [type]="'ratePlan'" [text]="'Click here to upload image'" (imageUploaded)="setEventBannerImage($event)"></app-image-upload>
                </div>
            </div>



        </div>
        <div class="col-6">

            <div class="mb-3 mt-4">
                <h3 class="mat-title">Plan Overview</h3>
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="data.overview" placeholder="Plan overview">
                    <mat-hint>This is displayed to the customer when choosing a rate on checkout. A short one-liner only.</mat-hint>
                </mat-form-field>
                <!-- <quill-editor [modules]="config" [(ngModel)]="data.overview" [style]="{'height':'200px'}"></quill-editor> -->
            </div>

            <div class="mb-3 mt-4">
                <h3 class="mat-title">Plan Description</h3>
                <quill-editor [modules]="config" [(ngModel)]="data.description" [style]="{'height':'200px'}"></quill-editor>
                <div>
                    <mat-hint class="mt-1" style="font-size: 12px;">This is displayed when a customer clicks the 'i' button and will popup to reveal full information.</mat-hint>
                </div>
            </div>


            <div class="mb-4">
                <h3 class="mat-title">Terms and Conditions</h3>
                <quill-editor [modules]="config" [(ngModel)]="data.terms" [style]="{'height':'200px'}"></quill-editor>
                <div>
                    <mat-hint class="mt-1" style="font-size: 12px;">These are displayed when a customer clicks the 'i' button and will popup to reveal full information.</mat-hint>
                </div>
            </div>

            <div class="mb-4">
                <h3 class="mat-title">Promo tile text</h3>
                <quill-editor [modules]="config" [(ngModel)]="data.upsellText" [style]="{'height':'200px'}"></quill-editor>
                <div>
                    <mat-hint class="mt-1" style="font-size: 12px;">This text is displayed when a customer searches 1 night and promo rates are available for 2.</mat-hint>
                </div>
            </div>


        </div>
    </div>




    <div class="text-right mb-4">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
        <div class="text-danger mt-2" *ngIf="errorString">{{errorString}}</div>
    </div>

    <!-- <app-event-sku-edit *ngIf="dataToEdit" [data]="dataToEdit" (complete)="dataEditComplete($event)"></app-event-sku-edit> -->
</mat-card>

