<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{data.title}}</h5>
                <a class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <p *ngIf="data.detail">{{data.detail}}</p>
                    <button class="btn btn-primary mt-3 mb-3" (click)="confirm()">
                        <span *ngIf="data.buttonText">{{data.buttonText}}</span>
                        <span *ngIf="!data.buttonText">Confirm</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>
