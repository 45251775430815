<div class="row" *ngIf="supplement">
    <div class="col-md-5">
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="supplement.description" placeholder="Description">
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <mat-form-field class="example-full-width">
            <input matInput type="number" [(ngModel)]="supplement.cost" placeholder="Cost (&pound;)">
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <mat-form-field class="example-full-width">
            <input matInput type="number" [(ngModel)]="supplement.limit" placeholder="No.of.nights">
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <button mat-raised-button color="primary" (click)="add()">Save Supplement</button>
    </div>
    <div class="col-md-1">
        <button mat-raised-button color="warn" (click)="delete()">Delete</button>
    </div>

</div>
