<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/events/types']">Event Types</a>
        </div>
        <div class="col-md-5 text-right">
            <a mat-raised-button color="primary" (click)="create()">Create Type</a>

            <!-- <button *ngIf="(params$ | async).startDate || (params$ | async).endDate" type="button" class="btn btn-success" (click)="exportConfirm()" matTooltip="Export to CSV file">
               Export
            </button> -->

        </div>
    </div>
</mat-card>


  <div class="mat-card mt-2 p-2">



    <div class="card mb-3 no-records" *ngIf="loading">
        Loading data <i class="fa fa-spinner fa-spin"></i>
    </div>


    <div *ngIf="data.length">


        <table class="example-full-width data-listing">
            <thead>
            <tr>
                <th>Event Type</th>
                <th>Event Count</th>
                <!-- <th style="min-width: 90px" class="d-none d-xl-table-cell">
                    <a *ngIf="params.sort === 'event.createdAt DESC'" (click)="setSort('event.createdAt')">Created <i class="fa fa-angle-up"></i></a>
                    <a *ngIf="params.sort !== 'event.createdAt DESC'" (click)="setSort('event.createdAt DESC')">Created <i class="fa fa-angle-down"></i></a>
                </th> -->
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of data; let i = index">
                <td class="hover" (click)="update(data)">
                    {{data.name}}
                </td>
                <td class="hover" (click)="update(data)">
                    {{data.eventCount}}
                </td>

                <!-- <td class="d-none d-xl-table-cell">{{data.createdAt | date:'dd MMM yyyy'}}</td> -->
                  <td class="text-right">
                      <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="update(data)">
                              <mat-icon>edit</mat-icon>
                              <span>Edit</span>
                          </button>
                          <button mat-menu-item (click)="delete(data)">
                              <mat-icon>delete</mat-icon>
                              <span>Delete</span>
                          </button>
                      </mat-menu>
                  </td>
            </tr>
            </tbody>
        </table>

    </div>

  </div>
