import {Component, Input, OnInit} from '@angular/core';
import {Store} from '../../store';
import {HotelService} from '../../services/hotel.service';
import {OverlayService} from '../../services/overlay.service';

@Component({
    selector: 'app-hotel-features-sort',
    templateUrl: './hotel-features-sort.component.html',
    styleUrls: ['./hotel-features-sort.component.css']
})
export class HotelFeaturesSortComponent implements OnInit {

    featureGroups = this.store.selectForLocal('featureGroups');

    constructor(
        private hotelService: HotelService,
        private store: Store,
        private overlayService: OverlayService,
    ) {
    }

    ngOnInit() {
    }

    close() {
        this.overlayService.closeAll();
    }

    drop(e) {
        console.log(e);
        this.updateSortOrder();
    }

    updateSortOrder() {
        this.hotelService.sortHotelFeatures(this.featureGroups).subscribe();
        // this.imagesService.updateSortOrder(this.images).subscribe();
    }

}
