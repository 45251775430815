import { SettingsService } from './../services/settings.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-site-down',
    templateUrl: './site-down.component.html',
    styleUrls: ['./site-down.component.css']
})
export class SiteDownComponent implements OnInit {

    siteDown = false;
    confirming = false;
    valToAction = false;

    constructor(
        private settingsService: SettingsService,
    ) { }

    ngOnInit() {
        this.getSetting();
    }

    getSetting() {
        this.settingsService.getSetting('siteDown').subscribe(data => {
            console.log(data.data);
            this.siteDown = data.data.value;
        })
    }

    setSetting() {
        this.siteDown = this.valToAction
        this.settingsService.setSetting('siteDown', this.valToAction).subscribe(data => {
            console.log(data.data);
        })
    }

    confirm(val) {
        this.valToAction = val;
        this.confirming = true;
    }

    doAction(val) {
        this.confirming = false;
        this.setSetting();
    }
}
