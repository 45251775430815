<mat-card class="breadcrumb">
  <div class="row">
    <div class="col-md-10">
      <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
      <!--<mat-icon>arrow_forward_ios</mat-icon>-->
      <a [routerLink]="['/hotels']">Hotels</a>
    </div>
    <div class="col-md-2 text-right">
      <!--<button id="pullright" mat-raised-button color="primary" routerLink="/hotels/create">Add Hotel</button>-->
    </div>
  </div>
</mat-card>

<mat-card class="text-right">
  <app-hotels-filter [params]="(params$ | async)"></app-hotels-filter>
</mat-card>

<mat-card class="no-records" *ngIf="!(hotels$ | async).length">
  No Hotels Listed
</mat-card>

<ng-container *ngIf="(hotels$ | async).length">

  <div class="margin-top-20">


    <table class="mat-elevation-z2 example-full-width data-listing">
      <thead>
      <tr>
        <th>rrID</th>
        <th>Hotel Name</th>
        <th>Town</th>
          <th>Telephone</th>
          <th class="text-right">No. Rooms</th>
        <th class="text-right">Bookings (for mth)</th>
        <th class="text-right">Revenue (for mth)</th>
          <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let hotel of (hotels$ | async)">
          <td (click)="edit(hotel)">{{hotel.rrId}}</td>
        <td (click)="edit(hotel)">{{hotel.name}}</td>
        <td (click)="edit(hotel)"> {{hotel.address.town}}</td>
          <td (click)="edit(hotel)"> {{hotel.contact.telephone}}</td>
          <td (click)="edit(hotel)" class="text-right">0</td>
          <td (click)="edit(hotel)" class="text-right">0</td>
          <td (click)="edit(hotel)" class="text-right">0</td>
          <td class="text-right">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="edit(hotel)">
                      <mat-icon>edit</mat-icon>
                      <span>View</span>
                  </button>
                  <button mat-menu-item (click)="delete(hotel)">
                      <mat-icon>delete</mat-icon>
                      <span>Delete</span>
                  </button>
              </mat-menu>
          </td>
      </tr>
      </tbody>
    </table>

  </div>

</ng-container>

