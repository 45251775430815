
<div class="row">
    <div class="col-4">
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="feature.name" placeholder="Feature Name">
        </mat-form-field>
    </div>
    <div class="col-2 text-left">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
</div>

<ng-container *ngIf="rooms.length">
    <table class="mat-elevation-z2 example-full-width data-listing" style="margin-bottom: 30px; margin-top: 30px">
        <thead>
        <tr>
            <th style="width: 20%">Feature</th>
            <th style="width: 10%"></th>
            <th style="" *ngFor="let r of rooms">{{r.roomName}}</th>
            <!--<th style="width: 33%">Feature Name</th>-->
            <!--<th style="width: 33%">Selected</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let rf of rooms[0].features; let i = index">
            <td>{{rf.name}}
            </td>
            <td><a class="ms-3 hover" (click)="toggleAll(rf)">[toggle all]</a></td>
            <td *ngFor="let r of rooms">
                <a *ngIf="r.features[i].selected" (click)="toggleFeature(r.roomID, r.features[i])">
                    <mat-icon style="color: #28a745">check_circle</mat-icon>
                </a>
                <a *ngIf="!r.features[i].selected" (click)="toggleFeature(r.roomID, r.features[i])">
                    <mat-icon style="color: #dddddd">check_circle_outline</mat-icon>
                </a>
            </td>
        </tr>

        </tbody>
    </table>


</ng-container>

