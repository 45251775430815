import { Component, OnInit } from '@angular/core';
import {ProductService} from '../../services/product.service';
import {Store} from '../../store';
import {OverlayService} from '../../services/overlay.service';

@Component({
  selector: 'app-product-delete',
  templateUrl: './product-delete.component.html',
  styleUrls: ['./product-delete.component.css']
})
export class ProductDeleteComponent implements OnInit {

    product = {
        ID: '',
        name: '',
    };

    constructor(
        private productService: ProductService,
        private store: Store,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
        const product = this.store.selectForLocal('product');
        if (product) {
            this.product = product;
        }
    }
    close() {
        this.overlayService.closeAll();
    }
    delete() {
        this.productService.productDelete(this.product).subscribe(data => {
            this.close();
        });
    }
}
