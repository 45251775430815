import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GiftCardService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
    ) {
    }

    giftCardsByUser(userId) {
        return this.http.get(this.env.apiV2Path + 'gift-card/orders/user/' + userId)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getAllProducts() {
        return this.http.get(this.env.apiV2Path + 'gift-card/product')
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    searchProducts(params) {
        const dataToSend = {
            params,
        }
        return this.http.post(this.env.apiV2Path + 'gift-card/product/search', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    deleteProduct(id) {
        return this.http.delete(this.env.apiV2Path + 'gift-card/product/' + id)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));

    }


    createGiftCardProduct(product) {
        const dataToSend = {
            product,
        }
        return this.http.post(this.env.apiV2Path + 'gift-card/product', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    assignGiftCardToDataSelection(params, giftCard) {
        const dataToSend = {
            params,
            giftCard
        }
        return this.http.post(this.env.apiV2Path + 'gift-card/assign/data', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

}
