<mat-card class="breadcrumb">
	<div class="row">
		<div class="col-md-10">
			<a [routerLink]="['/rategroups']">Rate groups</a>
			<mat-icon>arrow_forward_ios</mat-icon>
			Edit rate group
		</div>
		<div class="col-md-2 text-right">
		</div>
	</div>
</mat-card>

<mat-card>
	
	<div class="row margin-top-20">
		<div class="col-6">
			<button mat-raised-button (click)="back()">Back</button>
		</div>
		<div class="col-6 text-right">
			<button mat-raised-button color="warn" (click)="save()">Update</button>
		</div>
	</div>
</mat-card>

<mat-card class=" margin-top-20" *ngIf="rateGroup">
	<div class="row">
		<div class="col-md-6">
			
			<div>
				<h2 class="mat-title">Room Details</h2>
				
				<mat-form-field class="example-full-width" *ngIf="rateGroup.isNew">
					<input matInput [(ngModel)]="rateGroup.Id" placeholder="Group Id">
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<input matInput [(ngModel)]="rateGroup.name" placeholder="Group Name">
				</mat-form-field>
				
				<mat-form-field class="example-full-width">
					<mat-select
							[(ngModel)]="rateGroup.hotel.Id"
							(ngModelChange)="updateRooms()"
							placeholder="Select Hotel">
						<mat-option
								[value]="c.id"
								*ngFor="let c of hotels">
							{{c.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
				
				<div class="row">
					<div class="col-md-6">
						<mat-form-field class="example-full-width">
							<input type="number" matInput name="minShift" [(ngModel)]="rateGroup.minShift" placeholder="Minimum uplift %">
						</mat-form-field>
					</div>
					<div class="col-md-6">
						<mat-form-field class="example-full-width">
							<input type="number" matInput name="maxShift" [(ngModel)]="rateGroup.maxShift" placeholder="Maximum uplift %">
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		
		<div class="col-md-6">
				<h2 class="mat-title">Occupancy percentage group selection</h2>
				<p>The percentage breakpoints to be used for base price increase/decrease calculations</p>
				<ng-container *ngIf="!occupancyPercentagesConfirmed">
					<div class="row">
						<div class="col-2" *ngFor="let o of occupancyPercentages">
							<button
									(click)="toggleOccupancyPercentage(o)"
									class="btn mt-2"
									[ngClass]="{'btn-success' : occupancyPercentageSelected(o), 'btn-light' : !occupancyPercentageSelected(o)}">
								{{o}}%
							</button>
						
						</div>
					</div>
					<button *ngIf="rateGroup.occupancyPercentages.length" class="btn btn-success mt-4" (click)="occupancyPercentagesConfirm(true)">
						Confirm selection
					</button>
				</ng-container>

				<ng-container *ngIf="occupancyPercentagesConfirmed">
					<div class="row">
						<div class="col-2" *ngFor="let o of rateGroup.occupancyPercentages">
							<button
									class="btn btn-success mt-2">
								{{o}}%
							</button>
						
						</div>
					</div>
					<button class="btn btn-danger mt-4" (click)="occupancyPercentagesConfirm(false)">
						Rebuild selection
					</button>
					<div class="alert alert-danger mt-4">Warning! This will delete existing rates</div>
				</ng-container>
				
		</div>


	</div>

</mat-card>

<mat-card class="mt-4" *ngIf="rateGroup && rateGroup.rooms.length">
	<div class="row">
		<div class="col-md-6">
			<h2 class="mat-title">Rooms to include
				<button mat-raised-button color="warn" (click)="selectAllRooms()">
					<span *ngIf="!rateGroup.rooms[0].selected">Select all</span>
					<span *ngIf="rateGroup.rooms[0].selected">Deselect all</span>
				</button>
			</h2>
			
			<mat-list>
				<ng-container *ngFor="let r of rateGroup.rooms">
					<mat-list-item>
						<mat-checkbox
								class="example-margin"
								[(ngModel)]="r.selected">
							{{r.name}}
						</mat-checkbox>
					</mat-list-item>
					<mat-divider></mat-divider>
				</ng-container>
			</mat-list>
			
		</div>
		<div class="col-md-6">
			<h2 class="mat-title">Dates to exclude</h2>
			<app-calendar [datesSelected]="this.rateGroup.dates" (dateWasSelected)="dateWasSelected($event)"></app-calendar>
			<div>
				<mat-chip-list #chipList aria-label="Date selection">
					<mat-chip color="primary" style="float: right;" *ngFor="let d of rateGroup.dates; let i = index" [removable]="true" (removed)="removeDate(i)">
						{{d}}
						<mat-icon matChipRemove>cancel</mat-icon>
					</mat-chip>
				</mat-chip-list>
			</div>
			
		</div>
	</div>


</mat-card>

<mat-card class="mt-4" *ngIf="rateGroup && rateGroup.uplifts.length">
	<h2 class="mat-title">Base price increase/decrease</h2>
	<p>Enter a percentage for each occupancy percentage and booking days ahead</p>
	<table class="table">
		<thead>
			<tr>
				<th>Occupancy</th>
				<th *ngFor="let d of rateGroup.uplifts[0].dayGroups">{{d.label}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let u of rateGroup.uplifts">
				<td>{{u.occupancyLevel}}%</td>
				<td *ngFor="let d of u.dayGroups">
					<input type="number" [(ngModel)]="d.uplift" class="form-control">
				</td>
			</tr>
		</tbody>
	</table>
</mat-card>
