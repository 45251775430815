import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Store } from '../store';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { OverlayService } from '../services/overlay.service';
import { ToolsService } from '../services/tools.service';
import { HttpClient } from '@angular/common/http';
import { AdyenService } from '../services/adyen.service';
import { environment } from '../../environments/environment';
import AdyenCheckout from '@adyen/adyen-web';

@Component({
    selector: 'app-adyen',
    templateUrl: './adyen.component.html',
    styleUrls: ['./adyen.component.css'],
    encapsulation: ViewEncapsulation.None, // Disable view encapsulation

})
export class AdyenComponent implements OnInit {
    @Input() paymentData = undefined;

    @Output() adyenResult = new EventEmitter;

    @Output() setStep = new EventEmitter;
    @ViewChild('hook', { static: true }) hook: ElementRef;
    type: string = 'card';

    processing = false;

    // adyen specific
    sessionId: string = '';
    redirectResult: string = '';
    clientKey: string = '';
    merchant: string = '';

    constructor(
        private store: Store,
        private router: Router,
        private fb: FormBuilder,
        private overlayService: OverlayService,
        private toolsService: ToolsService,
        // adyen specific
        private route: ActivatedRoute,
        private http: HttpClient,
        private adyenService: AdyenService,
    ) {
        this.hook = new ElementRef('');
    }

    ngOnInit(): void {
        this.finaliseInit()
    }

    finaliseInit() {
        this.type = this.route.snapshot.queryParamMap.get('type') || '';
        this.redirectResult = this.route.snapshot.queryParamMap.get('redirectResult') || '';

        const url = `${environment.apiV2Path}adyen/config/${this.paymentData.hotel.id}/${this.paymentData.room.id}`

        this.http
            .get<any>(url, { observe: 'response' })
            .subscribe((resp: any) => {
                console.log(resp.body.clientKey)
                this.clientKey = resp.body.clientKey;
                this.merchant = resp.body.merchant;
                this.buildSession();
            });


    }

    buildSession() {
        if (!this.sessionId) {
            this.adyenService.sessions(this.paymentData.lineItems, this.paymentData.shopper, this.merchant, this.paymentData.value, this.paymentData.metadata, true).subscribe(
                (async res => {
                    console.log(res)

                    const checkout = await this.createAdyenCheckout(res)
                    console.log('checkout')
                    console.log(checkout)
                    console.log('eo checkout')

                    await checkout.create('card').mount(this.hook.nativeElement);
                }),
                (async error => {
                    console.log('Error is: ', error);
                })
            );
        }
        else {
            this.finalizeCheckout();
        }

    }






    // adyen specific
    async createAdyenCheckout(session: any) {

        console.log('session')
        console.log(session)
        console.log('eo session',)
        console.log(this.paymentData)

        const configuration = {
            clientKey: this.clientKey,
            locale: "en_GB",
            environment: "live",  // change to live for production
            showPayButton: true,
            session: session,
            shopperInteraction: 'Ecommerce',

            enableRecurring: true,
            storePaymentMethod: true,
            recurringProcessingModel: 'UnscheduledCardOnFile',
            storePaymentMethodMode: 'enabled',
            shopperReference: this.paymentData.user.id,
            metadata: this.paymentData.metadata,
            paymentMethodsConfiguration: {
                ideal: {
                    showImage: true
                },
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    name: "Credit or debit card",
                    amount: {
                        value: this.paymentData.value,
                        currency: "GBP"
                    }
                },
                paypal: {
                    amount: {
                        currency: "GBP",
                        value: this.paymentData.value
                    },
                    environment: "live",
                    countryCode: "GB"   // Only needed for test. This will be automatically retrieved when you are in production.
                }
            },
            onPaymentCompleted: (state: any, component: any) => {
                this.handleServerResponse(state, component);
            },
            onError: (error: any, component: any) => {
                console.error(error.name, error.message, error.stack, component);
            }
        };

        return await AdyenCheckout(configuration);
    }

    // Some payment methods use redirects. This is where we finalize the operation
    async finalizeCheckout() {
        try {
            const checkout = await this.createAdyenCheckout({ id: this.sessionId });
            checkout.submitDetails({ details: this.redirectResult });
        } catch (error) {
            console.error(error);
            alert("Error occurred. Look at console for details");
        }
    }


    handleServerResponse(res: any, component: any) {
        console.log(res)
        if (res.action != null) {
            component.handleAction(res.action);
        } else {
            res.metadata = this.paymentData.metadata;

            if (res.resultCode === 'Authorised' && this.paymentData.sendBookingLink) {
                this.createBookingLink()
            }

            this.adyenResult.emit(res)
        }
    }

    createBookingLink() {
        this.adyenService.createBookingLink(this.paymentData.metadata).subscribe(data => {
            console.log(data)
        })
    }
}
