import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {AuthenticationService} from './authentication.service';
import {Store} from '../store';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
    chartColours = [
        'rgba(254,134,114, 0.5)',
        'rgba(230,125,108, 0.5)',
        'rgba(254,184,172, 0.5)',
        'rgba(254,155,138, 0.5)',
        'rgba(254,163,148, 0.5)',
        'rgba(225,96,75, 0.5)'
    ];

  constructor(
      private apiService: ApiService,
      private http: HttpClient,
      private authService: AuthenticationService,
      private store: Store,
  ) { }



    initialLoadStore() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
        };
        return this.http.post(this.apiService.path() + 'charts/overview', dataToSend).subscribe((data: any) => {
            this.store.set('chartData', data.data);
        });

    }


}
