<div class="page-block">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>
    
    <h2 class="mat-title">Please Confirm</h2>
    
    <div class="text-center">
        <p>Are you sure you want to delete this image?</p>
        <button mat-raised-button color="warn" (click)="confirm()">Delete</button>
    </div>
    
</div>

