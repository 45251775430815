<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <h2 class="mat-title mb-0">Customer overview</h2>
        </div>
        <div class="col-md-2 text-right" *ngIf="passport">
            <button type="button" class="btn btn-warning btn-sm" (click)="edit(passport)">
                <i class="fa fa-edit"></i> Edit
            </button>

        </div>
    </div>
</mat-card>

<ng-container *ngIf="passport">
    <mat-card class="margin-top-20" *ngIf="!isNew">
        <div class="row">
            <div class="col-lg-6">
                <p class="mb-1"><span class="strong">Card printed:</span> <span *ngIf="passport.cardPrintedAt">{{passport.cardPrintedAt | date: 'dd/MM/yyyy'}}</span></p>
                <p class="mb-1"><span class="strong">Epos Now ID:</span> {{passport.eposNowId}}</p>
                <p class="mb-1"><span class="strong">Passport number:</span> {{passport.cardNumber}}</p>
            </div>
            <div class="col-lg-6">
                <p class="mb-1"><span class="strong">Email:</span> {{passport.username}}</p>
                <p class="mb-1"><span class="strong">Activated?:</span>
                    <span *ngIf="passport.confirmed"> YES</span>
                    <span *ngIf="!passport.confirmed"> NO</span>
                </p>
                <p class="mb-1"><span class="strong">Initiated Password Reset?:</span>
                    <span *ngIf="passport.passwordReset"> YES</span>
                    <span *ngIf="!passport.passwordReset"> NO</span>
                </p>
            </div>
        </div>
    </mat-card>


    <div class="row">
        <div class="col-xl-6 h-100">
            <mat-card class="margin-top-20">

                <h2 class="mat-title">Personal Details</h2>

                <!-- <label>Name</label> -->
                <p>{{passport.title.name}} {{passport.firstname}} {{passport.surname}}</p>

                <!-- <label>Gender</label>
                <p>{{passport.gender}}</p>

                <label>Date of Birth</label>
                <p>{{passport.dob}}</p>

                <h2 class="mat-title">Contact Details</h2> -->

                <!-- <label>Email</label> -->
                <p>{{passport.username}}</p>
                <!-- <label>Telephone</label> -->
                <p>{{passport.telephone}}</p>
                <!-- <label>Mobile</label> -->
                <p>{{passport.mobile}}</p>

            </mat-card>
        </div>
        <div class="col-xl-6 h-100">
            <mat-card class="margin-top-20">
                <h2 class="mat-title">Address</h2>
                <p class="mb-0" *ngIf="passport.add1">{{passport.add1}}</p>
                <p class="mb-0" *ngIf="passport.add2">{{passport.add2}}</p>
                <p class="mb-0" *ngIf="passport.add3">{{passport.add3}}</p>
                <p class="mb-0" *ngIf="passport.town">{{passport.town}}</p>
                <p class="mb-0" *ngIf="passport.county">{{passport.county}}</p>
                <p class="mb-0" *ngIf="passport.postcode">{{passport.postcode}}</p>


            </mat-card>
        </div>
    </div>

    <mat-card class="margin-top-50 text-center" *ngIf="!bookings.length">
        <h2 class="mat-title">No bookings to report</h2>
    </mat-card>


    <div class="row" *ngIf="bookings.length">
        <div class="col-xl-12">
            <mat-card class="margin-top-50">
                <h2 class="mat-title">Bookings</h2>
                <table class="example-full-width data-listing">
                    <thead>
                    <tr>
                        <th>Check In</th>
                        <th>Hotel</th>
                        <th>Room</th>
                        <th>Nights</th>
                        <th>Adults / Children</th>
                        <th>Gross (&pound;)</th>
                        <th>Depost Paid (&pound;)</th>
                        <th>Created</th>
                        <!-- <th>Extras?</th>
                        <th>Promo</th> -->
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let booking of bookings">
                        <td class="hover" (click)="viewBooking(booking)">{{booking.checkIn | date: 'dd/MM/yyyy'}}</td>
                        <td class="hover" (click)="viewBooking(booking)">{{booking.hotel.name}}</td>
                        <td class="hover" (click)="viewBooking(booking)">{{booking.room.name}}</td>
                        <td class="hover" (click)="viewBooking(booking)">{{booking.nights}}</td>
                        <td class="hover" (click)="viewBooking(booking)">{{booking.adults}} / {{booking.children}}</td>
                        <td class="hover" (click)="viewBooking(booking)">&pound;{{booking.payment.gross}}</td>
                        <td class="hover" (click)="viewBooking(booking)">&pound;{{booking.payment.deposit}}</td>
                        <td class="hover" (click)="viewBooking(booking)">{{booking.created | date: 'dd/MM/yyyy'}}</td>
                        <!-- <td class="hover" (click)="viewBooking(booking)">
                            <i class="fa fa-check text-success" *ngIf="booking.hasExtras"></i>
                        </td> -->

                        <!-- <td class="hover" (click)="viewBooking(booking)">
                            <i class="fa fa-check text-success" *ngIf="booking.hasUpgrade"></i>
                        </td> -->
                        <!-- <td class="hover" (click)="viewBooking(booking)">
                            <i class="fa fa-check text-success" *ngIf="booking.promoCode"></i>
                        </td> -->
                        <td class="text-right">

                            <button type="button" class="btn btn-warning btn-sm" (click)="viewBooking(booking)">
                                <i class="fa fa-search"></i> View
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>


            </mat-card>
        </div>
    </div>


    <mat-card class="margin-top-50 mb-4 text-center" *ngIf="!transactions.length">
        <h2 class="mat-title">No transactions to report</h2>
    </mat-card>

    <div class="row mb-4" *ngIf="transactions.length">
        <div class="col-xl-12">
            <mat-card class="margin-top-50">
                <h2 class="mat-title">Transactions</h2>
                <table class="example-full-width data-listing">
                    <thead>
                    <tr>
                        <th>Date/time</th>
                        <th>Location</th>
                        <th>Item</th>
                        <th>Category</th>
                        <th>Quantity</th>
                        <th>Gross</th>
                        <th>Discount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let t of transactions">
                        <td class="hover">{{t.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td class="hover">{{t.brandName}}</td>
                        <td class="hover">{{t.name}}</td>
                        <td class="hover">{{t.categoryName}}</td>
                        <td class="hover">{{t.quantity}}</td>
                        <td class="hover">{{((t.unitPrice * t.quantity) - t.discountAmount) | currency: 'GBP'}}</td>
                        <td class="hover">{{t.discountAmount | currency: 'GBP'}}</td>
                    </tr>
                    </tbody>
                </table>


            </mat-card>

            <app-pagination [params]="transactionParams" (update)="getTransactions()"></app-pagination>


        </div>
    </div>



    <!-- <mat-card class="margin-top-50">
        <div class="row">
            <label formArrayName="preferences" class="col-3" *ngFor="let preference of preferencesFormArray.controls; let i = index">
                <input type="checkbox" [formControlName]="i">
                {{passportCompanies[i].name}}
            </label>
        </div>
    </mat-card> -->


</ng-container>


