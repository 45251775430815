import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';

@Component({
    selector: 'app-event-booking',
    templateUrl: './event-booking.component.html',
    styleUrls: ['./event-booking.component.css']
})
export class EventBookingComponent implements OnInit {

    id = '';
    booking: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private eventsService: EventsService,
    ) { }

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.getBooking();
    }

    getBooking() {
        this.eventsService.getBooking(this.id).subscribe(data => {
            this.booking = data;
            console.log(this.booking)
        });

    }

}
