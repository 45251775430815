<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Assign Voucher</h5>
              <a class="close" (click)="cancel()">
                  <span aria-hidden="true">&times;</span>
              </a>
          </div>
          <div class="modal-body">
              <p class="text-left">Clicking apply will apply the selected voucher to {{recordsSelected}} user records</p>

              <mat-form-field appearance="outline">
                <mat-label>Select voucher</mat-label>
                <mat-select [(ngModel)]="voucherSelected">
                    <mat-option [value]="v" *ngFor="let v of vouchers">{{v.name}} ({{v.type}})</mat-option>
                </mat-select>
            </mat-form-field>


              <div class="text-center">
                  <button class="btn btn-success btn-width-50 mt-3 mb-3" (click)="confirm()">
                      Apply Voucher
                  </button>
              </div>

          </div>
      </div>
  </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>

