<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <h2 class="mat-title mb-0">Stats</h2>
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>
<div class="row">
    <div class="col-xl-8">

        <div class="text-center" *ngIf="!stats">
            <i class="fa fa-spinner fa-spin"></i> Loading data
        </div>

        <mat-card *ngIf="stats">
            <h5 class="mb-4">Total number of physical passports sold: {{stats.totalSold}} / {{stats.totalSpend | currency: 'GBP'}}</h5>

            <table class="mat-elevation-z2 example-full-width data-listing">
                <thead>
                    <th></th>
                    <th>Total</th>
                    <th>Digital</th>
                    <th>Physical</th>
                </thead>
                <tbody>
                <!-- <tr>
                    <td>Total Number of Activated Passports</td>
                    <td>{{(+stats.activated[0].totalRecords + +stats.activated[1].totalRecords) | number: '1.0-0'}}</td>
                    <td>{{+stats.activated[0].totalRecords | number: '1.0-0'}}</td>
                    <td>{{+stats.activated[1].totalRecords | number: '1.0-0'}}</td>
                </tr> -->
                <!-- <tr>
                    <td>Activated Passports after 30/01/2017</td>
                    <td>{{stats.afterRebuild}}</td>
                    <td>{{stats.afterRebuild}}</td>
                    <td>{{stats.afterRebuild}}</td>
                </tr>
                <tr>
                    <td>Attached Passports (Legacy)</td>
                    <td>{{stats.legacy}}</td>
                    <td>{{stats.legacy}}</td>
                    <td>{{stats.legacy}}</td>
                </tr> -->
                <tr>
                    <td>Total number of confirmed users</td>
                    <td>{{(+stats.confirmed[0].totalRecords + +stats.confirmed[1].totalRecords) | number: '1.0-0'}}</td>
                    <td>{{+stats.confirmed[0].totalRecords | number: '1.0-0'}}</td>
                    <td>{{+stats.confirmed[1].totalRecords | number: '1.0-0'}}</td>
                </tr>
                </tbody>
            </table>

            <hr />
            <h5>Comms Preferences</h5>
            <table class="mat-elevation-z2 example-full-width data-listing">
                <thead>
                <tr>
                    <th>Preference</th>
                    <th>Total</th>
                    <th>Digital</th>
                    <th>Physical</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let p of stats.preferences">
                    <td>{{p.name}}</td>
                    <td>{{(+p.digital + +p.physical) | number: '1.0-0'}}</td>
                    <td>{{+p.digital | number: '1.0-0'}}</td>
                    <td>{{+p.physical | number: '1.0-0'}}</td>
                </tr>
                </tbody>
            </table>
        </mat-card>

    </div>
</div>
