<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/hotels']">Hotels</a>
            <mat-icon>arrow_forward_ios</mat-icon>
            Edit Hotel
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card>

    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>
    <hr />

    <mat-tab-group *ngIf="hotel">
        <mat-tab label="Overview">
            <div class="container margin-top-30">
                <app-hotel-overview [hotel]="hotel"></app-hotel-overview>
            </div>
        </mat-tab>


        <mat-tab label="Location">
            <div class="container margin-top-30">
                <app-hotel-address-form [hotel]="hotel"></app-hotel-address-form>
            </div>
        </mat-tab>

        <mat-tab label="Hotel Gallery">
            <div class="container margin-top-30">
                <app-images [allowSelection]="false" [type]="'hotel'" [ownerID]="this.hotel.id"></app-images>
            </div>
        </mat-tab>

        <mat-tab label="Brand Banner">
            <div class="container margin-top-30">
                <app-brand-banner [hotel]="this.hotel"></app-brand-banner>
            </div>
        </mat-tab>

        <mat-tab label="Hotel Features">
            <div class="container margin-top-30">
                <app-hotel-features-edit [hotelID]="this.hotel.id"></app-hotel-features-edit>
            </div>
        </mat-tab>
        <mat-tab label="Room Features">
            <div class="container-fluid margin-top-30">
                <app-hotel-room-features [hotelID]="this.hotel.id"></app-hotel-room-features>
            </div>
        </mat-tab>
        <mat-tab label="Room extras">
            <div class="container-fluid margin-top-30">
                <app-product-index [hotelID]="this.hotel.id"></app-product-index>
            </div>
        </mat-tab>
        <mat-tab label="Prebook Items">
            <div class="container margin-top-30">
                <app-prebook [hotelID]="this.hotel.id"></app-prebook>
            </div>
        </mat-tab>
    </mat-tab-group>


    <!--<app-chart-assessment-scores></app-chart-assessment-scores>-->


    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>

</mat-card>





