<div class="row">
    <div class="col-md-6 margin-bottom-30">
        <app-chart-pp-spend [chartData]="(chartData$ | async).schoolYear"></app-chart-pp-spend>
    </div>
    <div class="col-md-6 margin-bottom-30">
        <app-chart-assessment-scores></app-chart-assessment-scores>
    </div>
</div>
<div class="row">
    <div class="col-md-4 margin-bottom-30">
    </div>
    <div class="col-md-4 margin-bottom-30">
    </div>
    <div class="col-md-4 margin-bottom-30">
    </div>
</div>



