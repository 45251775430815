
<div class="text-center" style="margin-top: 60px;">

    <h5 class="mb-4">Please proceed with caution - changing this will change the live running of the Booking Portal</h5>

    <button class="btn btn-danger" *ngIf="!siteDown" (click)="confirm(true)">
        Pull site down
    </button>

    <button class="btn btn-success" *ngIf="siteDown" (click)="confirm(false)">
        Put site online
    </button>

</div>

<div class="text-center mt-4" *ngIf="confirming">
    <h5>Please confirm this action</h5>
    <button class="btn btn-danger" (click)="doAction()">
        Confirm
    </button>

</div>
