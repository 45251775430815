import { Component, OnInit } from '@angular/core';
import {OverlayService} from '../../services/overlay.service';
import {GrowlerService} from '../../services/growler.service';
import {Store} from '../../store';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {

    user = {
        firstname: '',
        surname: '',
        email: '',
        password: '',
        created: new Date(),
        status: 1,
        permissions: [],
    };

    permissions = [];

    constructor(
        private usersService: UserService,
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
        this.loadPermissions();
    }

    loadPermissions() {
        this.usersService.loadPermissions().subscribe(data => {
            this.user.permissions = data;
        });
    }



    submit() {
        this.usersService.create(this.user).subscribe(data => {
            if (data.status === 500) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['User Created']);
                this.close();
            }

        });
    }

    close() {
        this.overlayService.closeAll();
    }
}
