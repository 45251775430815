import { VoucherService } from './../services/voucher.service';
import { ReportingService } from './../services/reporting.service';
import { Router } from '@angular/router';
import { GrowlerService } from './../services/growler.service';
import { OverlayService } from './../services/overlay.service';
import { Store } from './../store';
import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash';
import { HotelService } from '../services/hotel.service';
import { GiftCardService } from '../services/gift-card.service';

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit {

    hotels$ = this.store.select<any[]>('hotels');
    segments = [];
    segmentSelected = undefined;
    searchRunRequired = false;
    searchWasRun = false;

    exportType = '';
    hotels = [];
    companies = [];

    spendParams = false;
    bookingParams = false;
    feedbackParams = false;
    passportParams = true;

    loading = false;
    showVoucherWindow = false;
    showGiftCardWindow = false;
    showGiftCardCreateWindow = false;
    recordsSelected = 0;

    yesNo = [{ id: '', name: 'Please Select' }, { id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }]
    params = {
        activeInCM: 99,
        passportType: '',
        excludeList: [],
        spendProduct: '',
        spend: { min: 0, max: 99999 },
        trans: { min: 0, max: 99999 },
        avgSpend: { min: 0, max: 99999 },
        passportDate: { from: undefined, to: undefined },
        bookingDate: { from: undefined, to: undefined },
        checkInDate: { from: undefined, to: undefined },
        spendDate: { from: undefined, to: undefined },
        hasStayed: 0,
        hasLeftFeedback: 0,
        hotel: '',
        hotels: [],
        spendHotels: [],
        companies: [],
        room: '',
        county: '',
        postcode: '',
        name: '',
        email: '',
        surname: '',
        type: "all",
        feedback: '',
        feedbackPerc: { min: 0, max: 99999 },
        bookings: 0,
        confirmed: 0,
        guest: 0,
        printed: true,
        staffGroup: 0,
        isSupplier: false,
        reprint: false,
        signUp: false,
        searchText: '',
        limit: 100,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "users.createdAt DESC",
        sorts: [
            { ID: "users.eposNowId DESC", name: "ID First" },
            { ID: "users.eposNowId ASC", name: "ID Last" },
            { ID: "users.createdAt DESC", name: "Recent First" },
            { ID: "users.createdAt ASC", name: "Recent Last" },
            { ID: "users.firstname ASC", name: "Name A-Z" },
            { ID: "users.firstname DESC", name: "Name Z-A" },
            { ID: "users.feedback_qt DESC", name: "By feedback score" },
        ],
    };

    users = [];
    user$ = this.store.select<any>('user');
    asideOpen$ = this.store.select<boolean>('asideOpen');
    overlays$ = this.store.select<any>('overlays');

    recordSelected = [];

    exportConfirmationData = {
        type: '',
        open: false,
        title: '',
        detail: '',
        data: undefined,
    };

    constructor(
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
        private router: Router,
        private reportingService: ReportingService,
        private voucherService: VoucherService,
        private giftcardService: GiftCardService,
        private hotelService: HotelService,
    ) {
        this.filterSearch = debounce(this.filterSearch, 450);
    }

    ngOnInit() {
        this.getHotels();
        this.getSegments();
        this.getCompanies();
        // this.filterSearch();
    }

    getCompanies() {
        this.reportingService.getCompanies().subscribe(data => {
            this.companies = data;
        })
    }
    isCompanySelected(companyId) {
        if (!this.params.companies) {
            this.params.companies = [];
        }
        return this.params.companies.indexOf(companyId);
    }

    toggleCompany(companyId) {
        if (this.isCompanySelected(companyId) < 0) {
            this.params.companies.push(companyId);
        } else {
            this.params.companies.splice(this.isCompanySelected(companyId), 1)
        }
        this.filterSearch();
    }


    getHotels() {
        this.hotelService.allLite().subscribe(data => {
            this.hotels = data;
        })
    }

    isHotelSelected(hotelId) {
        if (!this.params.hotels) {
            this.params.hotels = [];
        }
        return this.params.hotels.indexOf(hotelId);
    }

    toggleHotel(hotelId) {
        if (this.isHotelSelected(hotelId) < 0) {
            this.params.hotels.push(hotelId);
        } else {
            this.params.hotels.splice(this.isHotelSelected(hotelId), 1)
        }
        this.filterSearch();
    }

    isSpendHotelSelected(hotelId) {
        if (!this.params.spendHotels) {
            this.params.spendHotels = [];
        }
        return this.params.spendHotels.indexOf(hotelId);
    }

    toggleSpendHotel(hotelId) {
        if (this.isSpendHotelSelected(hotelId) < 0) {
            this.params.spendHotels.push(hotelId);
        } else {
            this.params.spendHotels.splice(this.isSpendHotelSelected(hotelId), 1)
        }
        this.filterSearch();
    }


    getSegments() {
        this.reportingService.getSegments().subscribe(data => {
            this.segments = data;
        })
    }
    applyTemplate() {
        console.log(this.segmentSelected);
        if (this.segmentSelected) {
            this.params = { ...this.segmentSelected.params };
        }
        this.filterSearch();
    }

    setRecordSelected(Id) {
        if (this.recordSelected.indexOf(Id) > -1) {
            this.recordSelected.splice(this.recordSelected.indexOf(Id), 1);
        } else {
            this.recordSelected.push(Id);
        }
    }
    isRecordSelected(Id) {
        if (this.recordSelected.indexOf(Id) < 0) {
            return false;
        } else {
            return true;
        }
    }
    selectAll() {
        if (this.recordSelected.length === this.users.length) {
            this.recordSelected = [];
        } else if (!this.recordSelected.length) {
            this.recordSelected = [];
            for (let i = 0; i < this.users.length; i++) {
                this.recordSelected.push(this.users[i].Id);
            }
        } else {
            this.recordSelected = [];
        }
    }
    setSort(v) {
        this.params.sort = v;
        this.search();
    }

    setParam(name, value) {
        this.params[name] = '';
        this.search();
    }

    filterSearch() {
        this.params.page = 0;
        this.searchRunRequired = true;
        this.searchWasRun = false;
        // this.search();
    }

    search() {
        this.loading = true;

        this.reportingService.search(this.params).subscribe(data => {
            console.log(data.data)
            this.users = data.data;
            this.searchRunRequired = false;
            this.searchWasRun = true;
            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
            window.scroll(0, 0);
            this.loading = false;
        });
    }

    setStatus(data) {
    }

    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }

    processAction(e) {
        if (e.action === 'reload') {
            this.search();
        }
    }

    exportConfirm() {
        this.exportConfirmationData.type = this.exportType;
        if (this.exportType === 'voucher') {
            this.voucherAssign()
        }
        if (this.exportType === 'giftCard') {
            this.giftCardAssign()
        }

        if (this.exportType === 'export') {
            this.exportConfirmationData.title = 'Export Records';
            this.exportConfirmationData.data = '';
            this.exportConfirmationData.detail = 'Clicking confirm will create a CSV file and return a download link';
            this.exportConfirmationData.open = true;
        }
        if (this.exportType === 'sync') {
            this.exportConfirmationData.title = 'Sync to Campaign Monitor';
            this.exportConfirmationData.data = '';
            this.exportConfirmationData.detail = 'Clicking confirm will sync the data selection with Campaign Monitor';
            this.exportConfirmationData.open = true;
        }
        if (this.exportType === 'segment') {
            this.exportConfirmationData.title = 'Campaign Monitor Segment';
            this.exportConfirmationData.data = '';
            this.exportConfirmationData.detail = 'Clicking confirm will create a new segment in Campaign Monitor';
            this.exportConfirmationData.open = true;
        }
    }
    exportConfirmationComplete(e) {
        this.exportConfirmationData.open = false;
        if (e.action) {
        } else {
            console.log('cancelled');
        }
        this.getSegments();
        this.segmentSelected = undefined;
    }


    toggleSpendParams() {
        if (!this.spendParams) {
            this.spendParams = true;
        } else {
            this.spendParams = false;
        }
    }

    toggleBookingParams() {
        if (!this.bookingParams) {
            this.bookingParams = true;
        } else {
            this.bookingParams = false;
        }
    }

    togglePassportParams() {
        if (!this.passportParams) {
            this.passportParams = true;
        } else {
            this.passportParams = false;
        }
    }

    toggleFeedbackParams() {
        if (!this.feedbackParams) {
            this.feedbackParams = true;
        } else {
            this.feedbackParams = false;
        }
    }

    isExcluded(userId) {
        // console.log(userId.indexOf(this.params.excludeList))
        if (this.params.excludeList.indexOf(userId) < 0) {
            return false;
        }
        return true;
    }

    toggleExcluded(userId) {
        const pos = this.params.excludeList.indexOf(userId);
        console.log(userId, pos)
        if (pos < 0) {
            this.params.excludeList.push(userId)
        } else {
            this.params.excludeList.splice(pos, 1)
        }
        console.log(this.params.excludeList)
    }

    voucherAssign() {
        this.showVoucherWindow = true;
        this.recordsSelected = +this.params.totalRecords - this.params.excludeList.length;
    }
    voucherAssignAction(e) {
        console.log(e)
        if (e) {
            this.voucherService.assignVoucherToDataSelection(this.params, e.voucher).subscribe(data => {
                console.log(data);
                if (data.success) {
                    this.growlerService.notification(['Vouchers have been assigned']);
                } else {
                    this.growlerService.error(['Sorry - there was a problem assigning vouchers']);
                }
            })

        }
        this.showVoucherWindow = false;
    }

    giftCardAssign() {
        this.showGiftCardWindow = true;
        this.recordsSelected = +this.params.totalRecords - this.params.excludeList.length;
    }
    giftCardAssignAction(e) {
        console.log(e)
        if (e.action) {
            this.giftcardService.assignGiftCardToDataSelection(this.params, e.giftCard).subscribe(data => {
                console.log(data);
                if (data.success) {
                    this.growlerService.notification(['Giftcards have been assigned']);
                } else {
                    this.growlerService.error(['Sorry - there was a problem assigning giftcards']);
                }
            })
            this.showGiftCardWindow = false;
        } else {
            this.showGiftCardWindow = false;
            if (e.create) {
                this.showGiftCardCreateWindow = true;
            }
        }
        this.showGiftCardWindow = false;
    }

    giftCardProductCreated(e) {
        this.showGiftCardCreateWindow = false;
        if (e.action) {
            this.showGiftCardWindow = true;
        }
    }
}
