<app-booking-navbar [step]="3"></app-booking-navbar>

<div class="row mt-3" *ngIf="basket">
    <div class="col-12 text-right">
        <button (click)="addToBasket()" class="btn btn-sm btn-primary">Continue</button>
    </div>
</div>
<div class="row h-100 mt-3" *ngIf="basket">
    <div class="col-5">

        <mat-card class="margin-bottom-30">
            <mat-card-content>
                <h2 class="mat-title mb-2">Booker Details</h2>

                <div class="row mb-2">
                    <div class="col-6">
                        <p class="mb-0">{{basket.user.firstname}} {{basket.user.surname}}</p>
                        <p class="mb-0">{{basket.user.username}}</p>
                        <p class="mb-4">{{basket.user.telephone}}</p>
                        <p class="mb-4">{{basket.user.username}}</p>

                    </div>
                    <div class="col-6">
                        <p class="mb-0" *ngIf="basket.user.add1">{{basket.user.add1}}</p>
                        <p class="mb-0" *ngIf="basket.user.add2">{{basket.user.add2}}</p>
                        <p class="mb-0" *ngIf="basket.user.add3">{{basket.user.add3}}</p>
                        <p class="mb-0" *ngIf="basket.user.town">{{basket.user.town}}</p>
                        <p class="mb-0" *ngIf="basket.user.county">{{basket.user.county}}</p>
                        <p class="mb-0" *ngIf="basket.user.postcode">{{basket.user.postcode}}</p>

                    </div>
                </div>


                <h2 class="mat-title mb-2">Guest Details</h2>

                <div class="mt-2">
                    <ng-container *ngFor="let g of guests; let i = index">
                        <p class="mb-0">Guest {{i + 1}}</p>
                        <div class="row mb-2">
                            <div class="col-xl-4 pr-1">
                                <input class="form-control form-control-sm" [(ngModel)]="g.firstname" placeholder="First name">
                            </div>
                            <div class="col-xl-4 pr-1 pl-1">
                                <input class="form-control form-control-sm" [(ngModel)]="g.surname" placeholder="Surname">
                            </div>
                            <div class="col-xl-4 pr-1 pl-1">
                                <input class="form-control form-control-sm" [(ngModel)]="g.email" placeholder="Email">
                            </div>
                        </div>

                    </ng-container>
                </div>

            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-content *ngIf="(availabilitySearch$ | async)">
                <h2 class="mat-title mb-2">Room Overview</h2>
                <p class="mb-0">{{(availabilitySearch$ | async).hotel.name}} - {{(availabilitySearch$ | async).room.name}}</p>
                <p class="mb-0">Check-in: {{(availabilitySearch$ | async).checkIn | date: 'dd/MM/yyyy'}}</p>

                <p class="mb-0">Nights: {{(availabilitySearch$ | async).nights}}</p>
                <p class="mb-0">Adults: {{(availabilitySearch$ | async).adults}}</p>
                <p class="mb-0">Children: {{(availabilitySearch$ | async).children}}</p>

            </mat-card-content>
        </mat-card>

    </div>
    <div class="col-5">
        <mat-card class="margin-bottom-30">
            <mat-card-content>
                <h2 class="mat-title mb-2">Select rate</h2>
                <table class="table table-sm">
                    <tr *ngFor="let ratePlan of ratePlans">

                        <td align="left"><p class="mb-0 fs-5 fs-regular">{{ratePlan.name}}</p></td>
                        <td align="left"><p class="mb-0 fs-5 fs-regular">{{ratePlan.costOfStay | currency : 'GBP'}}</p></td>
                        <td align="right">
                            <ng-container *ngIf="(booking$ | async).planSelected">
                                <button type="button" *ngIf="ratePlan.id === planSelected?.id || (booking$ | async).planSelected && (booking$ | async).planSelected.id === ratePlan.id" class="btn btn-sm btn-primary" (click)="selectRatePlan(ratePlan)">
                                    <i class="fa fa-check"></i>
                                </button>
                                <button type="button" *ngIf="ratePlan.id !== planSelected?.id  && (booking$ | async).planSelected && (booking$ | async).planSelected.id !== ratePlan.id" class="btn btn-sm btn-light" (click)="selectRatePlan(ratePlan)">
                                    <i class="fa fa-check"></i>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!(booking$ | async).planSelected">
                                <button type="button" class="btn btn-sm btn-light" (click)="selectRatePlan(ratePlan)">
                                    <i class="fa fa-check"></i>
                                </button>

                            </ng-container>

                        </td>
                    </tr>

                </table>

            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-content>
                <h2 class="mat-title mb-2">Add Ons</h2>
                <app-add-ons [addOns]="addOns"></app-add-ons>
            </mat-card-content>

        </mat-card>

    </div>
</div>
