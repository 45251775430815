import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { debounce } from 'lodash';
import { ToolsService } from 'src/app/services/tools.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-event-bookings',
    templateUrl: './event-bookings.component.html',
    styleUrls: ['./event-bookings.component.css']
})
export class EventBookingsComponent implements OnInit {

    data = [];
    startDate;
    endDate;

    params = {
        source: '',
        searchText: '',
        startDate: undefined,
        endDate: undefined,
        name: '',
        email: '',
        template: '',
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "event_booking.createdAt DESC",
        sorts: [
            { ID: "event_booking.createdAt DESC", name: "Created first" },
            { ID: "event_booking.createdAt ASC", name: "Created last" },
            { ID: "event.name ASC", name: "Event name first" },
            { ID: "event.name DESC", name: "Event name last" },
        ],
    };

    constructor(
        private eventsService: EventsService,
        private toolsService: ToolsService,
        private router: Router,
    ) {
        this.filterSearch = debounce(this.filterSearch, 350);

    }

    ngOnInit() {
        this.filterSearch();
    }

    filterSearch() {
        this.params.page = 0;

        if (this.startDate) {
            this.params.startDate = this.startDate;
        }
        if (this.endDate) {
            this.params.endDate = this.endDate;
        }

        this.search();
    }

    search() {
        this.eventsService.searchBookings(this.params).subscribe((data: any) => {
            console.log(data)
            this.data = data.data;
            this.params.pages = Math.ceil(
                data.totalRecords / this.params.limit
            );
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
        });
    }

    resetParams() {
        this.params.source = '';
        this.params.searchText = '';
        this.params.startDate = undefined;
        this.params.endDate = undefined;
        this.params.page = 0;
        this.search();
    }

    edit(booking) {
        this.router.navigate(['/events', 'bookings', booking.id]);
    }

}
