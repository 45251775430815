import { Router } from '@angular/router';
import { FeedbackService } from './../../services/feedback.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-feedback-business',
    templateUrl: './feedback-business.component.html',
    styleUrls: ['./feedback-business.component.css']
})
export class FeedbackBusinessComponent implements OnInit {

    brands = [];

    constructor(
        private feedbackService: FeedbackService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.getBusinesses()
    }

    getBusinesses() {
        this.feedbackService.getBusinesses().subscribe(data => {
            this.brands = data;
        })
    }

    edit(business) {
        this.router.navigate(['/feedback/business', business.id]);
    }
}
