import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvailabilityService } from 'src/app/services/availability.service';
import { BasketService } from 'src/app/services/basket.service';
import { HotelService } from 'src/app/services/hotel.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { ToolsService } from 'src/app/services/tools.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-availability-map',
    templateUrl: './availability-map.component.html',
    styleUrls: ['./availability-map.component.css']
})
export class AvailabilityMapComponent implements OnInit {
    @Input() hotelId = 'HOTEL001';

    @Input() hotelID = null;
    @Input() hotels;
    @Input() multi = false;
    @Input() availabilitySearch;

    availabilitySearchMultiDay = undefined;

    startDate = new Date();
    availability$ = this.store.select<any>('availability');
    passportValidated$ = this.store.select<boolean>('passportValidated');

    localAvailability$ = this.store.select<any>('availabilitySevenDay');
    loading = true;
    adjustments$ = this.store.select<any[]>('adjustments');
    maxDays = 7;
    btnHotel = false;
    localParams;
    dateKeySelected;
    currentHotel: any;

    dayToday = 0
    dateKeys = [];

    stay = {
        adults: 2,
        children: 0,
        nights: 1,
        checkInDay: this.toolsService.dateToEpochDay(new Date()),
        checkInDate: new Date(),
    }



    constructor(
        private overlayService: OverlayService,
        private basketService: BasketService,
        private store: Store,
        private hotelService: HotelService,
        private availabilityService: AvailabilityService,
        private toolsService: ToolsService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.availabilitySearch = this.store.selectForLocal('availabilitySearch')
        console.log(this.availabilitySearch.checkIn)
        this.getHotels();
        this.dayToday = this.toolsService.dateToEpochDay(this.availabilitySearch.checkIn);
        console.log(this.availabilitySearch.checkIn)
        this.stay.checkInDay = this.dayToday;
        this.stay.checkInDate = this.availabilitySearch.checkIn;

        console.log(this.dayToday)


    }
    updateStayParams() {
        this.availabilitySearch.nights = this.stay.nights;
        this.availabilitySearchMultiDay.nights = this.stay.nights;
        this.availabilitySearchMultiDay.adults = this.stay.adults;
        this.availabilitySearchMultiDay.children = this.stay.children;
        this.constructAvailability();
        this.getAvailability()
        console.log(this.stay)
    }

    updateCheckInDate() {
        this.stay.checkInDay = this.toolsService.dateToEpochDay(this.stay.checkInDate)
        console.log(this.stay)
        this.availabilitySearch.checkInDay = this.stay.checkInDay;
        this.availabilitySearchMultiDay.checkInDay = this.stay.checkInDay;
        this.constructAvailability();
        this.getAvailability()
    }

    getHotels() {
        this.hotelService.allLite().subscribe(data => {
            console.log(data);
            this.hotels = data;

            if (!this.availabilitySearch.hotel) {
                this.availabilitySearch.hotel = this.hotels[0];
            }
            this.availabilitySearch.checkInDay = this.dayToday;
            // this.availabilitySearch.nights = 1;

            this.availabilitySearch.nightsToStay = this.buildNightsToStay(this.availabilitySearch.checkInDay, this.availabilitySearch.nights);
            this.availabilitySearchMultiDay = JSON.parse(JSON.stringify(this.availabilitySearch));

            this.hotels.forEach(h => {
                if (h.id === this.availabilitySearch.hotel.id) {
                    this.setHotel(h)
                }
            })

        })
    }


    constructAvailability() {
        this.populateDateKeys();

        this.maxDays = this.availabilityService.getMaxDays();
        this.startDate = this.availabilitySearchMultiDay.checkIn;
        this.currentHotel = this.availabilitySearchMultiDay.hotel;
        this.localParams = JSON.parse(JSON.stringify(this.availabilitySearchMultiDay));

    }

    populateDateKeys() {
        this.dateKeys = [];
        for (let i = 0; i < 7; i++) {
            this.dateKeys.push(
                {
                    startDay: this.availabilitySearchMultiDay.checkInDay + i,
                    startDate: this.toolsService.dateFromEpochDay(this.availabilitySearchMultiDay.checkInDay + i),
                    endDate: this.toolsService.dateFromEpochDay(this.availabilitySearchMultiDay.checkInDay + (i + 1))
                }
            )
        }
        console.log(this.dateKeys)
    }

    toggleHotelButton() {
        if (this.btnHotel) {
            this.btnHotel = false;
        } else {
            this.btnHotel = true;
        }
    }
    closeHotelButton(e: Event) {
        this.btnHotel = false;
    }

    isSearchDate(dateToCheck) {
        if (!this.localParams.checkIn) {
            return false;
        } else {
            const oneDay = 24 * 60 * 60 * 1000;
            const secondDate = new Date(dateToCheck);
            const originalSearchDate = new Date(this.localParams.checkIn);
            const diffDays = Math.round(Math.abs((originalSearchDate.getTime() - secondDate.getTime()) / (oneDay)));
            if (diffDays === 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    canGoBack() {
        if (this.dateKeys.length && (this.dateKeys[0].startDay > this.dayToday + (this.maxDays - 1))) {
            return true;
        } else {
            return false;
        }
    }
    canGoBackMobile() {
        if (this.dateKeys.length && (this.dateKeys[0].startDay > this.dayToday + (2))) {
            return true;
        } else {
            return false;
        }

    }
    back(days) {
        const c = this.availabilitySearchMultiDay;
        c.checkInDay = +c.checkInDay - days;
        c.checkIn = this.toolsService.dateFromEpochDay(c.checkInDay);
        this.store.set('availabilitySearchMultiDay', c);
        this.populateDateKeys();
        this.getAvailability();

    }
    forward(days) {
        const c = this.availabilitySearchMultiDay;
        c.checkInDay = +c.checkInDay + days;
        c.checkIn = this.toolsService.dateFromEpochDay(c.checkInDay);
        this.store.set('availabilitySearchMultiDay', c);
        this.populateDateKeys();
        this.getAvailability();
    }

    getAvailability() {
        this.store.set('availabilitySearchMultiDay', this.availabilitySearchMultiDay);

        this.availabilityService.loadAvailability('multiDay').subscribe(data => {
            const availabilitySevenDay = this.store.selectForLocal('availabilitySevenDay');
            const objKeys = Object.keys(data.rooms);
            for (let i = 0; i < objKeys.length; i++) {
                availabilitySevenDay[objKeys[i]] = data.rooms[objKeys[i]];
            }
            this.store.set('availabilitySevenDay', availabilitySevenDay);

            this.closeLoadingScreen();
        })

    }


    closeLoadingScreen() {
        setTimeout(() => {
            this.loading = false;
        }, 3000);
    }

    viewRoom(hotel, room, dateKey) {
        console.log(hotel)
        const availabilitySearch = this.availabilitySearchMultiDay;
        availabilitySearch.checkInDay = dateKey;
        availabilitySearch.checkIn = this.toolsService.dateFromEpochDay(dateKey);
        availabilitySearch.hotel = hotel;
        availabilitySearch.room = room;
        this.store.set('availabilitySearch', availabilitySearch);

        this.loadRates()
    }

    loadRates() {
        this.store.set('ratePlansUpsell', []);
        // this.store.set('availabilitySearch', this.availabilitySearch)
        this.availabilityService.loadRates('multiDay.component.ts').subscribe(data => {
            if (this.availabilitySearch.nights < 2) {
                this.loadUpsellRates();
            }
            // this.basketService.checkForRoomInBasket()
            this.router.navigate(['bookings/create/room-overview']);

        })
    }

    loadUpsellRates() {
        this.availabilityService.loadUpsellRates('multiDay.component.ts').subscribe(data => {
        })
    }



    setHotel(hotel) {
        this.currentHotel = hotel;
        this.hotelID = hotel.id;
        console.log(hotel)

        for (let i = 0; i < this.hotels.length; i++) {
            if (this.hotels[i].id === hotel.id) {
                // this.store.set('hotel', this.hotels[i]);
                // this.store.set('searchHotel', this.hotels[i]);
                this.availabilitySearch.hotel = this.hotels[i];
                this.availabilitySearchMultiDay.hotel = this.hotels[i];
            }
        }
        this.store.set('availabilitySearchMultiDay', this.availabilitySearchMultiDay);
        this.store.set('availabilitySearch', this.availabilitySearch)
        this.constructAvailability();
        this.getAvailability()
    }

    buildNightsToStay(firstNight, days) {
        const nightsToStay = [];
        for (let n = 0; n < days; n++) {
            const night = {
                checkInDay: firstNight + n,
                available: false,
            }
            nightsToStay.push(night)
        }
        return nightsToStay;
    }
}
