<mat-card class="breadcrumb">
    <div class="row">
      <div class="col-md-10">
        <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
        <!--<mat-icon>arrow_forward_ios</mat-icon>-->
        <a [routerLink]="['/feedback/business']">Brands</a>
      </div>
      <div class="col-md-2 text-right">
        <!--<button id="pullright" mat-raised-button color="primary" routerLink="/hotels/create">Add Hotel</button>-->
      </div>
    </div>
  </mat-card>

  <mat-card class="no-records" *ngIf="!brands.length">
    No Brands Listed
  </mat-card>

  <ng-container *ngIf="brands.length">

    <div class="margin-top-20">


      <table class="mat-elevation-z2 example-full-width data-listing">
        <thead>
            <tr>
                <th>Brand name</th>
                <th>Email from</th>
                <th class="text-right">Feedback score</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let brand of brands">
          <td class="hover" (click)="edit(brand)">{{brand.name}}</td>
          <td class="hover" (click)="edit(brand)"> {{brand.email_from}}</td>
            <td class="hover" (click)="edit(brand)" class="text-right">
                <span class="mr-1">{{brand.thumbs_percent}}%</span>
                <img *ngIf="brand.thumbs_percent >= 98" src="https://feedback.norfolkpassport.com/assets/images/small_thumbsup_gold.png" style="height: 20px" />
                <img *ngIf="brand.thumbs_percent < 98 && brand.thumbs_percent >= 95" src="https://feedback.norfolkpassport.com/assets/images/small_thumbsup_green.png" style="height: 20px" />
                <img *ngIf="brand.thumbs_percent < 95" src="https://feedback.norfolkpassport.com/assets/images/small_thumbsup_red.png" style="height: 20px" />
            </td>

            <td class="text-right">
                <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="edit(hotel)">
                        <mat-icon>edit</mat-icon>
                        <span>View</span>
                    </button>
                    <button mat-menu-item (click)="delete(hotel)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu> -->
            </td>
        </tr>
        </tbody>
      </table>

    </div>

  </ng-container>

