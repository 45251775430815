import {Component, Input, OnInit} from '@angular/core';
import {OverlayService} from '../../services/overlay.service';

@Component({
  selector: 'app-room-features',
  templateUrl: './room-features.component.html',
  styleUrls: ['./room-features.component.css']
})
export class RoomFeaturesComponent implements OnInit {
    @Input() room;

  constructor(
      private overlayService: OverlayService,
  ) { }

  ngOnInit() {
  }
    close() {
        this.overlayService.closeAll();
    }

}
