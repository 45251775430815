import { Component, OnInit, Input } from '@angular/core';
import {Store} from '../../store';
import {HotelService} from '../../services/hotel.service';

@Component({
  selector: 'app-hotels-filter',
  templateUrl: './hotels-filter.component.html',
  styleUrls: ['./hotels-filter.component.css']
})
export class HotelsFilterComponent implements OnInit {
    @Input() params;
    counties$ = this.store.select<any[]>('counties');

    constructor(
        private store: Store,
        private hotelService: HotelService,
    ) { }

    ngOnInit() {
    }

    updateParams() {
        this.hotelService.updateParams(this.params);
    }
    resetParams() {
        this.hotelService.resetParams();
    }
}
