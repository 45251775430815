import { Component, Input, OnInit, Output, EventEmitter, } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback.service';
import { OverlayService } from 'src/app/services/overlay.service';

@Component({
    selector: 'app-feedback-comment',
    templateUrl: './feedback-comment.component.html',
    styleUrls: ['./feedback-comment.component.css']
})
export class FeedbackCommentComponent implements OnInit {
    @Input() feedbackData = undefined;
    @Output() feedbackCommentDone: EventEmitter<any> = new EventEmitter();

    staffResponse = '';

    result = {
        refresh: false,
        staffResponse: ''
    }
    constructor(private feedbackService: FeedbackService) { }

    ngOnInit() {
        if (this.feedbackData.invite.staffResponse) {
            this.staffResponse = this.feedbackData.invite.staffResponse
        }
    }

    submit() {
        console.log(this.feedbackData)
        this.feedbackService.saveResponse(this.feedbackData.invite.id, this.staffResponse).subscribe(data => {
            this.result.refresh = true;
            this.result.staffResponse = this.staffResponse;
            this.close()
        })
    }
    close() {
        this.feedbackCommentDone.emit(this.result);
    }


}
