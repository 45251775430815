import { Component, OnInit } from '@angular/core';
import { BookingService } from '../services/booking.service';
import { ToolsService } from '../services/tools.service';
import { Store } from '../store';

@Component({
    selector: 'app-giftcard-search',
    templateUrl: './giftcard-search.component.html',
    styleUrls: ['./giftcard-search.component.css']
})
export class GiftcardSearchComponent implements OnInit {

    hotels$ = this.store.select<any[]>('hotels');
    user$ = this.store.select<any>('user');

    cards = [];
    totals = [];
    startDate;
    endDate;
    viewPromoGiftCards
    dataToResend;
    confirmationData = {
        title: 'Confirm resend',
        detail: 'Are you sure you want to resend this gift card email?'
    }

    params = {
        searchText: '',
        campaign: '',
        shopifyOnly: 1,
        cardStatus: '',
        hasSpend: -1,
        hotelId: undefined,
        startDate: undefined,
        endDate: undefined,
        status: 1,
        limit: 32,
        limits: [8, 16, 24, 32],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "giftcard_order.id DESC",
        sorts: [
            { id: "giftcard_orders.firstname", name: "Name A-Z" },
            { id: "giftcard_orders.firstname DESC", name: "Name Z-A" },
            { id: "giftcard_orders.createdAt DESC", name: "Created Soonest" },
            { id: "giftcard_orders.createdAt", name: "Created Latest" },
        ],
    };

    constructor(
        private bookingService: BookingService,
        private toolsService: ToolsService,
        private store: Store,
    ) { }

    ngOnInit() {
        this.getCards();
    }

    paginate(e) {
        this.params = e;
        this.getCards();
    }


    getCards() {
        if (this.startDate) {
            this.params.startDate = this.startDate;
        }
        if (this.endDate) {
            this.params.endDate = this.endDate;
        }

        this.bookingService.searchGiftCards(this.params).subscribe(data => {

            this.cards = data.data;
            this.totals = data.totals;
            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }

        })
    }

    resend(data) {
        this.dataToResend = data;
    }
    resendAction(e) {

        if (e.action) {
            this.bookingService.resendGiftCardEmail(this.dataToResend.id).subscribe(data => {
                this.dataToResend = undefined;
                this.getCards();
            })
        } else {
            this.dataToResend = undefined;
        }
    }

}
