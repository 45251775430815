<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a *ngIf="isNew" [routerLink]="['/events/types']">Create Event Type</a>
            <a *ngIf="!isNew" [routerLink]="['/events/types']">Edit Event Type</a>
        </div>
        <div class="col-md-5 text-right">
        </div>
    </div>
</mat-card>


<mat-card class="mt-3">
    <div class="row" *ngIf="data">
        <div class="col-6">
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="data.name" placeholder="Event Name">
            </mat-form-field>

            <div class="mb-3">
                <h3 class="mat-title">Description</h3>

                <quill-editor [modules]="config" [(ngModel)]="data.description" [style]="{'height':'200px'}"></quill-editor>

            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.status"
                                placeholder="Active?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                </div>
            </div>

        </div>

        <div class="col-6">

            <h3 class="mat-title">Lead Banner Image</h3>
            <div class="row">
                <div class="col-md-4">
                    <img *ngIf="!data.leadImage" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
                    <img *ngIf="data.leadImage" [src]="data.leadImage" class="img-fluid" style="max-height: 150px" />
                </div>
                <div class="col-md-8">
                    <app-image-upload *ngIf="data.id" [ownerID]="data.id" [type]="'eventType'" [text]="'Click here to upload image'" (imageUploaded)="setEventBannerImage($event)"></app-image-upload>
                </div>
            </div>

        </div>
    </div>


    <div class="text-right mb-4">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>

</mat-card>
