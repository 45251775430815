<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/cms']">CMS Snippets</a>
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card class="no-records" *ngIf="!snippets.length">
    No CMS Snippets Listed
</mat-card>

<ng-container *ngIf="snippets.length">

    <div class="margin-top-20">
        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Name</th>
                <th>Pages / Plugin</th>
                <th>Type</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let snippet of snippets">
                <td>{{snippet.name}}</td>
                <td>{{snippet.pages}}</td>
                <td>{{snippet.type}}</td>
                <td class="text-right">
                    <button mat-icon-button (click)="edit(snippet)">
                        <mat-icon>search</mat-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

</ng-container>

