import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { ImagesService } from '../../services/images.service';
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
    @Input() type;
    @Input() ownerID;
    @Input() text = 'Click or drop files here to upload';
    @Output() imageUploaded: EventEmitter<any> =  new EventEmitter<any>();
    user: any;
    uploadConfig: any;
    resizeWidth: 1000;

    constructor(
        private imagesService: ImagesService,
        private apiService: ApiService,
    ) {
        }

    ngOnInit() {
        this.initUpload(this.user);
        if (this.type !== 'cms') {
            this.uploadConfig.resizeWidth = this.resizeWidth;
        }
    }

    initUpload(user) {
        this.uploadConfig = {
            url: this.apiService.path() + 'uploads/image',
            acceptedFiles: 'image/*',
            parallelUploads: 1,
            resizeWidth: this.resizeWidth,
            maxFiles: 8,
            maxFilesize: 2048,
            params: {
                type: this.type,
                ownerID: this.ownerID
            },
        };
    }
    onUploadError(e) {
    }
    onUploadSuccess(e) {
        console.log(e[1]);
        this.imageUploaded.emit(e[1]);
    }
    uploadprogress(e) {
        console.log(e);
    }
}
