import { Router, ActivatedRoute } from '@angular/router';
import { VoucherService } from './../services/voucher.service';
import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'app-voucher-users',
  templateUrl: './voucher-users.component.html',
  styleUrls: ['./voucher-users.component.css']
})
export class VoucherUsersComponent implements OnInit {

  data = [];

  loading = false;

  params = {
    voucherId: '',
    searchText: '',
    limit: 50,
    limits: [50, 100, 250],
    page: 0,
    pages: 0,
    pageArray: [],
    totalRecords: 0,
    sort: "users.firstname",
    sorts: [
      { ID: "users.firstname ASC", name: "Name A-Z" },
      { ID: "users.firstname DESC", name: "Name Z-A" },
      { ID: "voucher_user.createdAt DESC", name: "Recent First" },
      { ID: "voucher_user.createdAt ASC", name: "Recent Last" },
    ],
  };

  constructor(
    private voucherService: VoucherService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.filterSearch = debounce(this.filterSearch, 450);
  }

  ngOnInit() {
    this.params.voucherId = this.route.snapshot.params['id'];
    this.filterSearch();
  }

  setSort(v) {
    this.params.sort = v;
    this.search();
  }

  setParam(name, value) {
    this.params[name] = '';
    this.search();
  }

  filterSearch() {
    this.params.page = 0;
    this.search();
  }

  search() {
    this.loading = true;

    this.voucherService.searchVoucherUsers(this.params).subscribe(data => {
      console.log(data.data)
      this.data = data.data;

      this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
      this.params.totalRecords = data.totalRecords;
      this.params.pageArray = [];
      for (let i = 0; i < this.params.pages; i++) {
        this.params.pageArray.push(i);
      }
      window.scroll(0, 0);
      this.loading = false;
    });
  }
  isOdd(num) {
    if (num % 2 === 1) {
      return false;
    } else {
      return true;
    }
  }
  create() {

  }

  delete(user) {
    this.voucherService.deleteUser(user.id, this.route.snapshot.params['id']).subscribe(data => {
      this.search();
    })
  }

  clearList() {
    this.voucherService.deleteAllUsers(this.route.snapshot.params['id']).subscribe(data => {
      this.search();
    })

  }
}
