
<div class="row">
    <div class="col-6">
        <h2 class="mat-title">Brand banner</h2>

        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="hotel.brandBannerTitle" placeholder="Brand banner title">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="hotel.brandBannerDescription" placeholder="Brand banner subtitle">
        </mat-form-field>
    </div>

    <div class="col-6">
        <h2 class="mat-title">Banner background</h2>
        <div class="row">
            <div class="col-12">

                <img *ngIf="!hotel.brandBannerImage" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
                <img *ngIf="hotel.brandBannerImage" [src]="hotel.brandBannerImage" class="img-fluid" style="max-height: 150px" />
            </div>
            <div class="col-12" style="margin-top: 20px">
                <app-image-upload
                [ownerID]="hotel.id" [type]="'brandBanner'" [text]="'Click here to upload brand banner'"
                (imageUploaded)="setImage($event)"></app-image-upload>
            </div>
        </div>


    </div>
</div>
