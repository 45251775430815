<div class="page-header-banner">
    <h3 *ngIf="questions.length" class="mt-3">Feedback Questions: {{questions[0].templateName}}</h3>
</div>

<div class="data-container">
    <div class="text-right">
        <button class="btn btn-primary-reverse btn-round btn-slim" (click)="addQuestion()">Add new Question</button>
    </div>

    <div class="no-results" *ngIf="!questions.length">
        <p>No questions found</p>
    </div>

    <div class="mt-4" *ngIf="questions.length">

        <div class="dd">
            <ol class="dd-list mt-2"
                dragula="priCategory"
                [(dragulaModel)]="questions"
                (dragulaModelChange)="drop($event)">
                <li *ngFor="let q of questions"
                    class="dd-item"
                    [ngClass]="{'dd-collapsed' : !q.open}">
                    <div class="dd-handle">
                        <!-- <mat-list-item class="mat-list-item mat-list-item-avatar mat-list-item-with-avatar ng-star-inserted" style="height: 35px">
                            <div class="mat-list-item-content">
                                <div class="mat-list-item-ripple mat-ripple" mat-ripple=""></div>
                                <mat-icon (click)="deleteConfirm(q)" class="mat-icon mat-list-icon material-icons" mat-list-icon="" role="img" aria-hidden="true">
                                    delete
                                </mat-icon>
                                <mat-icon (click)="editQuestion(q)" class="mat-icon mat-list-icon material-icons" mat-list-icon="" role="img" aria-hidden="true">
                                    edit
                                </mat-icon>
                                <div class="mat-list-text">
                                    <h4 class="mat-line" mat-line="">
                                        {{q.subject}} - {{q.template}}
                                    </h4>
                                </div>
                            </div>
                        </mat-list-item> -->

                        <span class="label label-info mr-3">
                            <i class="fa fa-bars"></i>
                        </span>

                        <span class="float-right">
                            <a (click)="deleteConfirm(q)" style="margin-left: 10px">
                                <i class="fa fa-trash"></i>
                            </a>
                        </span>

                        <span class="float-right">
                            <a class="ml-3" (click)="editQuestion(q)">
                                <i class="fa fa-edit"></i>
                            </a>
                        </span>

                        <span class="float-right">
                            <a class="ml-3" (click)="toggleStatus(q)">
                            <i
                                class="toggle fa"
                                [ngClass]="{
                                'fa-toggle-on text-success': q.is_active,
                                'fa-toggle-off' : !q.is_active}"></i>
                            </a>
                        </span>
                        <span class="float-right">{{q.thumbs_percent}}</span>

                        {{q.text}}

                    </div>
                </li>

            </ol>
        </div>





    </div>
<!--        <app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>-->

</div>


<app-confirmation *ngIf="this.confirmationData.open" (complete)="confirmAction($event)" [data]="confirmationData"></app-confirmation>


