import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { ApiService } from './api.service';
import {HttpClient} from '@angular/common/http';
import {Store} from '../store';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ImagesService {

    pagination = {
        searchTerm: '',
        fileType: '',
        limit: 25,
        limits: [5, 10, 25, 50],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'docs.friendlyName',
        sorts: [
            {ID: 'docs.friendlyName ASC', name: 'Name A-Z'},
            {ID: 'docs.friendlyName DESC', name: 'Name Z-A'},
            {ID: 'docs.created ASC', name: 'Created Oldest'},
            {ID: 'docs.created DESC', name: 'Created Newest'}
        ]
    };

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private apiService: ApiService,
        private store: Store,
    ) { }

    getPagination() {
        return this.pagination;
    }
    setPagination(field, value) {
        this.pagination[field] = value;
    }


    get(type, ownerID) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            user: user,
            type: type,
            ownerID: ownerID,
            pagination: this.pagination
        };
        return this.http.post(this.apiService.path() + 'images/binary', dataToSend)
        .pipe(map((data: any) => {
            return data;
        }));
    }
    delete(image) {
        return this.http.post(this.apiService.path() + 'images/delete', image)
        .pipe(map((data: any) => {
            return data;
        }));
    }
    crop(image) {
        return this.http.post(this.apiService.path() + 'images/crop', image)
            .pipe(map((data: any) => {
                return data;
            }));

    }

    updateSortOrder(images) {
        const dataToSend = {
            images: images
        };
        return this.http.post(this.apiService.path() + 'images/sort', dataToSend)
            .pipe(map((data: any) => {
                return data;
            }));
    }
}
