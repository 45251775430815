import { Store } from './../store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VoucherService } from '../services/voucher.service';

@Component({
  selector: 'app-voucher-select',
  templateUrl: './voucher-select.component.html',
  styleUrls: ['./voucher-select.component.css']
})
export class VoucherSelectComponent implements OnInit {
  @Input() recordsSelected = 0;
  @Output() complete = new EventEmitter();

  user$ = this.store.select<any>('user');

  vouchers = [];
  voucherSelected;

  constructor(
    private store: Store,
    private voucherService: VoucherService
  ) { }

  ngOnInit() {
    this.getVouchers();
  }

  getVouchers() {
    this.voucherService.getVouchers().subscribe(data => {
      this.vouchers = data.data;
    })
  }

  confirm() {
    this.complete.emit({ action: true, voucher: this.voucherSelected });
  }
  cancel() {
    this.complete.emit({ action: false, voucher: this.voucherSelected });
  }

}
