<div class="row" *ngIf="pb">
    <div class="col-md-6">
        
        <h2 class="mat-title">Overview Details</h2>
        
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="pb.strapline" placeholder="Main Strapline">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="pb.sub_title" placeholder="Subtitle (if applicable)">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <textarea matInput [(ngModel)]="pb.body" rows="4" placeholder="Body text (if applicable)"></textarea>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <textarea matInput [(ngModel)]="pb.optin" rows="4" placeholder="Opt-in Text"></textarea>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="pb.items_title" placeholder="Title above items to select">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <textarea matInput [(ngModel)]="pb.terms" rows="4" placeholder="Terms and conditions (if applicable)"></textarea>
        </mat-form-field>
    
        <mat-form-field class="example-full-width">
            <mat-select
                [(ngModel)]="pb.type"
                placeholder="Select type">
                <mat-option [value]="'multiple'">Multiple items</mat-option>
                <mat-option [value]="'single'">Single item (not lists)</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="row">
            <div class="col">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="pb.chargePerPerson"
                        placeholder="Charge per person?">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Yes</mat-option>
                    </mat-select>
                </mat-form-field>
                
            </div>
            <div class="col">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="pb.chargePerNight"
                        placeholder="Charge per night?">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Yes</mat-option>
                    </mat-select>
                </mat-form-field>
    
            </div>
        </div>
    
        <mat-form-field>
            <input matInput [(ngModel)]="pb.chargeAmount" placeholder="Amount to charge">
        </mat-form-field>
    
    
        <mat-form-field>
            <input matInput [(ngModel)]="pb.checkoutText" placeholder="Checkout basket description">
        </mat-form-field>
    
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="pb.active"
                        placeholder="Activate?">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Yes</mat-option>
                    </mat-select>
                </mat-form-field>
        
            </div>
        </div>
        
        
    
        <mat-form-field class="example-full-width">
            <textarea matInput [(ngModel)]="pb.email" rows="4" placeholder="Email recipients (separate with comma)"></textarea>
        </mat-form-field>

        
        <button mat-flat-button color="warn" (click)="updateOverview()">
            Update Overview
        </button>


    </div>
    <div class="col-md-6">
        
        <h2 class="mat-title">Feature Image</h2>
        <div class="row">
            <div class="col-md-6">
                
                <img *ngIf="!pb.image.URL" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
                <img *ngIf="pb.image.URL" [src]="pb.image.URL" class="img-fluid" style="max-height: 150px" />
            </div>
            <div class="col-md-6">
                <app-image-upload [ownerID]="hotelID" [type]="'prebook'" [text]="'Click here to upload feature image'" (imageUploaded)="setFeatureImage($event)"></app-image-upload>
            </div>
        </div>
    
    </div>
</div>

<hr />

<div class="row" *ngIf="pb">
    <div class="col-12 col-md-4" *ngFor="let group of pb.groups; let g = index">
        <ul
            style="padding-inline-start: 0;"
            dragula="roomImages"
            [(dragulaModel)]="group.items"
            (dragulaModelChange)="drop($event, group)">
            <li class="" *ngFor="let item of group.items" style="float: left; list-style-type: none; width: 100%;">
                <mat-form-field class="example-full-width">
                    <span matPrefix style="cursor: move"><mat-icon matSuffix>drag_indicator</mat-icon></span>
                    <table cellspacing="0" style="width: 100%">
                        <tr>
                            <td style="width: 75%">
                                <input matInput type="text" [(ngModel)]="item.name" placeholder="Selection item">
                            </td>
                            <td style="width: 25%">
                                <input matInput [(ngModel)]="item.cost" placeholder="Item cost">
                            </td>
                        </tr>
                    </table>
                    <mat-icon matSuffix (click)="deleteItem(item, g)">delete</mat-icon>
                    <mat-icon matSuffix (click)="saveItem(item)">save</mat-icon>
                </mat-form-field>
            </li>
        </ul>
        <br style="clear: both" />
        <button mat-flat-button color="warn" (click)="addItem(g)">
            Add new item
        </button>

        
<!--        <mat-card>-->
<!--&lt;!&ndash;            <mat-form-field class="example-full-width">&ndash;&gt;-->
<!--&lt;!&ndash;                <input matInput [(ngModel)]="group.name" placeholder="Group Name">&ndash;&gt;-->
<!--&lt;!&ndash;                <button mat-flat-button color="warn" matSuffix>&ndash;&gt;-->
<!--&lt;!&ndash;                    Save&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;            <hr />&ndash;&gt;-->
<!--            <mat-form-field class="example-full-width" *ngFor="let item of group.items">-->
<!--                <input matInput [(ngModel)]="item.name" placeholder="Selection Item">-->
<!--                <mat-icon matSuffix (click)="deleteItem(item, g)">delete</mat-icon>-->
<!--                <mat-icon matSuffix (click)="saveItem(item)">save</mat-icon>-->
<!--&lt;!&ndash;                <button mat-flat-button color="warn" matSuffix (click)="saveItem(item)">&ndash;&gt;-->
<!--&lt;!&ndash;                    Save&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->
<!--            </mat-form-field>-->
<!--    -->
<!--            <button mat-flat-button color="warn" (click)="addItem(g)">-->
<!--                Add new item-->
<!--            </button>-->

<!--        </mat-card>-->
    </div>
</div>


<hr />
