import { Component, OnInit, Input } from '@angular/core';
import { OverlayService } from '../../services/overlay.service';
import { GrowlerService } from '../../services/growler.service';
import { Store } from '../../store';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
    @Input() user;

    constructor(
        private userService: UserService,
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
    }

    generateRandomPassword() {
        const length = 10;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!=%$?';
        let password = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }

        return password;
    }

    submit() {
        const password = this.generateRandomPassword();
        console.log(password)
        const dataToSend = {
            userId: this.user.id,
            password,
        }
        this.userService.updatePassword(dataToSend).subscribe(data => {
            if (data.status === 500) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['Password updated and email sent']);
                this.close();
            }

        });
    }


    close() {
        this.overlayService.closeAll();
    }

}
