import { FeedbackService } from './../../services/feedback.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '../../store';
import { OverlayService } from '../../services/overlay.service';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'app-feedback-templates',
    templateUrl: './feedback-templates.component.html',
    styleUrls: ['./feedback-templates.component.css']
})
export class FeedbackTemplatesComponent implements OnInit {

    topic;
    topicId = '';
    template;
    toSave = [];
    templates = [];

    confirmationData = {
        open: false,
        buttonText: 'Confirm',
        title: '',
        detail: '',
        data: undefined,
    };

    overlays$ = this.store.select<any>('overlays');

    constructor(
        private overlayService: OverlayService,
        private feedbackService: FeedbackService,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private menuService: MenuService,
    ) { }

    ngOnInit(): void {
        this.find();
    }

    find() {
        this.feedbackService.getTemplates().subscribe(data => {
            this.templates = data;
            console.log(this.templates)
        })
    }

    toggleStatus(template) {
        if (template.is_active) {
            template.is_active = false;
        } else {
            template.is_active = true;
        }
        this.feedbackService.updateTemplate(template).subscribe();
    }

    addTemplate() {
        this.store.set('feedbackTemplate', {
            subject: '',
            template: '',
            answer: '',
            status: 1,
            deleted: 0,
        });
        this.overlayService.open('feedbackTemplate');
        this.store.set('asideOpen', true);
    }

    editTemplate(template) {
        this.store.set('feedbackTemplate', template);
        this.overlayService.open('feedbackTemplate');
        this.store.set('asideOpen', true);
    }

    closeAction(e) {
        if (e.reload) {
            this.find();
        }
    }

    drop(e) {
        this.toSave = e;
        this.feedbackService.sortTemplates(this.toSave).subscribe(data => {
        });
    }

    deleteConfirm(template) {
        this.store.set('feedbackTemplate', template);
        this.overlayService.open('faqDelete');
        this.store.set('asideOpen', true);
    }

    confirmAction(e) {
        if (e.action) {
            this.template.deleted = 1;
            this.feedbackService.updateTemplate(this.template).subscribe(data => {
                this.find();
            });
        }
        this.confirmationData.open = false;
    }

}
