import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Store } from '../../store';
import { OverlayService } from '../../services/overlay.service';
import { GrowlerService } from 'src/app/services/growler.service';

@Component({
    selector: 'app-product-edit',
    templateUrl: './product-edit.component.html',
    styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {
    @Input() data = undefined;
    @Output() complete = new EventEmitter();

    constructor(
        private productService: ProductService,
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
        // const product = this.store.selectForLocal('product');
        if (this.store.selectForLocal('product')) {
            this.data = this.store.selectForLocal('product');
            console.log(this.data)
        } else {
            this.data.product_group = this.store.selectForLocal('productGroup');
            console.log(this.data)
        }
    }
    close() {
        this.complete.emit(
            { action: false, data: this.data }
        );
    }

    save() {
        if (this.data.ID) {
            this.productService.productUpdate(this.data).subscribe(data => {
                this.growlerService.notification(['Your changes have been saved']);
                this.complete.emit(
                    { action: true, data: this.data }
                );
            });
        } else {
            this.productService.productAdd(this.data).subscribe(data => {
                this.growlerService.notification(['Your changes have been saved']);
                this.complete.emit(
                    { action: true, data: this.data }
                );
            });
        }
    }

    dayChecked(day) {
        const index = this.data.daysOfWeek.indexOf(day);
        if (index > -1) {
            return true;
        } else {
            return false;
        }
    }
    toggleDay(day) {
        const index = this.data.daysOfWeek.indexOf(day);
        if (index > -1) {
            this.data.daysOfWeek.splice(index, 1);
        } else {
            this.data.daysOfWeek.push(day);
        }
    }

}
