import { Component, OnInit, Input } from '@angular/core';
import { Store } from '../../store';

@Component({
    selector: 'app-hotel-overview',
    templateUrl: './hotel-overview.component.html',
    styleUrls: ['./hotel-overview.component.css']
})
export class HotelOverviewComponent implements OnInit {
    @Input() hotel;
    counties$ = this.store.select<any[]>('counties');

    startDate = new Date(1990, 0, 1);

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    constructor(
        private store: Store,
    ) { }

    ngOnInit() {
    }

    setHotelLogo(e) {
        console.log(e);
        this.hotel.logo = e;
    }

    addMessage() {
        this.hotel.contactMessages.push({ id: 0, message: '' })
    }
    deleteMessage(idx) {
        this.hotel.contactMessages.splice(idx, 1);
    }

}
