<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>

    <h2 class="mat-title">Edit User</h2>

        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="user.firstname" placeholder="Enter Firstname">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="user.surname" placeholder="Enter Surname">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input type="email" matInput [(ngModel)]="user.email" placeholder="Email Address">
        </mat-form-field>


        <h2 class="aside">Permissions</h2>

        <mat-list role="list">
            <ng-container *ngFor="let p of user.permissionGroup">
                <mat-list-item role="listitem">
                    <mat-slide-toggle style="margin: 7px;"
                                      [color]="'warn'"
                                      [(ngModel)]="p.allow"
                                      class="example-margin"
                                      (change)="checkSubPerms($event, p)"
                                      [checked]="p.allow">
                        {{p.description}}
                    </mat-slide-toggle>
                </mat-list-item>
                <mat-list role="list" style="margin-left: 30px;">
                    <mat-list-item role="listitem" style="height: 32px;" *ngFor="let pi of p.permissions">
                        <mat-slide-toggle style="margin: 7px;"
                                          [color]="'warn'"
                                          [(ngModel)]="pi.allow"
                                          (change)="checkParentPerms($event,p, pi)"
                                          class="example-margin"
                                          [checked]="pi.allow">
                            {{pi.description}}
                        </mat-slide-toggle>
                    </mat-list-item>
                </mat-list>

            </ng-container>


        </mat-list>



        <!-- <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let p of user.permissions">
                <mat-slide-toggle style="margin: 7px;"
                                  [color]="'warn'"
                                  [(ngModel)]="p.allow"
                                  class="example-margin"
                                  [checked]="p.allow">
                    {{p.description}}
                </mat-slide-toggle>
            </mat-list-item>
        </mat-list> -->

        <div class="text-right margin-top-20">
            <button mat-raised-button color="warn" (click)="submit()">Submit</button>
        </div>

</div>


