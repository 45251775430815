import { FeedbackService } from './../../services/feedback.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayService } from '../../services/overlay.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '../../store';

@Component({
    selector: 'app-feedback-template',
    templateUrl: './feedback-template.component.html',
    styleUrls: ['./feedback-template.component.css']
})
export class FeedbackTemplateComponent implements OnInit {
    @Output() closeAction = new EventEmitter();

    form: UntypedFormGroup;
    question;

    topics = [];
    newItem = true;
    data: any = {
        topic: { id: 0 },
    };

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    pages = [];

    constructor(
        private feedbackService: FeedbackService,
        private store: Store,
        private overlayService: OverlayService,
        private fb: UntypedFormBuilder,
    ) {
        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(3)]],
            email_recipients: ['', [Validators.required, Validators.minLength(3)]],
            invite_email_subject: ['', [Validators.required]],
            is_active: [true, [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.data = this.store.selectForLocal('feedbackTemplate');

        this.form.patchValue({
            name: this.data.name,
            invite_email_subject: this.data.invite_email_subject,
            email_recipients: this.data.email_recipients,
            is_active: this.data.is_active,
        });
    }

    getTopics() {
        this.feedbackService.findAllTopics().subscribe(data => {
            this.topics = data.data.data;
        });
    }

    close() {
        this.overlayService.closeAll();
    }

    toggleStatus() {
        if (!this.form.value.is_active) {
            this.form.patchValue({ is_active: true });
        } else {
            this.form.patchValue({ is_active: false });
        }
    }

    save() {
        this.data.name = this.form.value.name;
        this.data.invite_email_subject = this.form.value.invite_email_subject;
        this.data.email_recipients = this.form.value.email_recipients;
        this.data.is_active = this.form.value.is_active;

        if (!this.data.id) {
            this.feedbackService.createTemplate(this.data).subscribe(data => {
                this.closeAction.emit({ reload: true });
                this.close();
            });
        } else {
            this.feedbackService.updateTemplate(this.data).subscribe(data => {
                this.closeAction.emit({ reload: true });
                this.close();
            });
        }
    }


}
