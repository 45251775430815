<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Ticket Type</h5>
                <a class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body" *ngIf="sku">

                <div>
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="sku.name" placeholder="Ticket type name">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="sku.price" placeholder="Price">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="sku.capacity" placeholder="Max tickets for sale">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="sku.maxTickets" placeholder="Max tickets per purchase">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="sku.notes" placeholder="Notes (Max 10 words)">
                        <mat-hint class="text-danger" *ngIf="sku.notes.split(' ').length > 7">Using {{sku.notes.split(' ').length}} of 10 words</mat-hint>
                    </mat-form-field>

                </div>
                <!-- <div>
                    <mat-form-field style="display: block">
                        <input type="search" matInput [(ngModel)]="sku.stDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Starts">
                        <mat-datepicker #startpicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field style="display: block">
                        <input type="search" matInput [(ngModel)]="sku.endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Ends">
                        <mat-datepicker #endpicker></mat-datepicker>
                    </mat-form-field>
                </div> -->

                <div class="text-right">
                    <button mat-raised-button color="primary" (click)="save()">Save</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>
