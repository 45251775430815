import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    env = environment;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) {
    }

    getSetting(id) {
        return this.http.get(this.env.apiV2Path + 'session/setting/' + id).pipe(map((data: any) => {
            return data;
        }));
    }

    setSetting(id, val) {
        const dataToSend = { id, val }
        return this.http.put(this.env.apiV2Path + 'session/setting', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }


}
