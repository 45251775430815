<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>
    
    <h2 class="mat-title">Please Confirm</h2>
    
    <p>Are you sure you want to delete {{product.name}}?</p>
    
    <div class="margin-top-20 text-right">
        <button mat-raised-button color="warn" (click)="delete()">Confirm Deletion</button>
    </div>

</div>
