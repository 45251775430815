import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProductService} from '../../services/product.service';
import {Store} from '../../store';
import {OverlayService} from '../../services/overlay.service';
import {PackageService} from '../../services/package.service';

@Component({
  selector: 'app-packages-delete',
  templateUrl: './packages-delete.component.html',
  styleUrls: ['./packages-delete.component.css']
})
export class PackagesDeleteComponent implements OnInit {
    @Output() dataDeleted: EventEmitter<any> =  new EventEmitter<any>();

    packageToDelete = {
        Id: '',
        name: '',
    };

    constructor(
        private packageService: PackageService,
        private store: Store,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
        const packageToDelete = this.store.selectForLocal('packageToDelete');
        if (packageToDelete) {
            this.packageToDelete = packageToDelete;
        }
    }
    close() {
        this.overlayService.closeAll();
    }
    delete() {
        this.packageService.packageDelete(this.packageToDelete).subscribe(data => {
            this.close();
            this.dataDeleted.emit(true);
        });
    }
}
