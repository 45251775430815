import {Component, Input, OnInit} from '@angular/core';
import {HotelService} from '../../services/hotel.service';

@Component({
  selector: 'app-hotel-features-edit',
  templateUrl: './hotel-features-edit.component.html',
  styleUrls: ['./hotel-features-edit.component.css']
})
export class HotelFeaturesEditComponent implements OnInit {
    @Input() hotelID;

    featureGroups = []

    constructor(
        private hotelService: HotelService
    ) { }

    ngOnInit() {
        this.get();
    }

    get() {
        this.hotelService.hotelFeatures(this.hotelID).subscribe(data => {
            this.featureGroups = data;
            console.log(this.featureGroups);
        });
    }

    toggleFeature(f) {
        if (f.selected) {
            f.selected = false;
        } else {
            f.selected = true;
        }
        this.hotelService.toggleFeature(f, this.hotelID).subscribe(data => {

        });
    }

}
