<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <ng-container *ngIf="data.product_group">
                    <h5 class="modal-title" *ngIf="data.ID === ''">Add Product to {{data.product_group.name}}</h5>
                    <h5 class="modal-title" *ngIf="data.ID !== ''">Edit Product in {{data.product_group.name}}</h5>
                </ng-container>

                <a class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body" *ngIf="data">



                <mat-form-field class="example-full-width">
                    <textarea matInput [(ngModel)]="data.name" style="height: 60px" placeholder="Product Name"></textarea>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="data.rrName" placeholder="RR Name">
                </mat-form-field>

                <p>Which days of the week is this extra available?</p>
                <div class="mb-4">
                    <a (click)="toggleDay(1)" class="mr-2">
                        <i
                            class="toggle hover fa"

                            [ngClass]="{
                            'fa-toggle-on text-success': dayChecked(1),
                            'fa-toggle-off' : !dayChecked(1)}"></i>
                        Mon
                    </a>
                    <a (click)="toggleDay(2)" class="mr-2">
                        <i
                            class="toggle hover fa"

                            [ngClass]="{
                            'fa-toggle-on text-success': dayChecked(2),
                            'fa-toggle-off' : !dayChecked(2)}"></i>
                        Tue
                    </a>
                    <a (click)="toggleDay(3)" class="mr-2">
                        <i
                            class="toggle hover fa"

                            [ngClass]="{
                            'fa-toggle-on text-success': dayChecked(3),
                            'fa-toggle-off' : !dayChecked(3)}"></i>
                        Wed
                    </a>
                    <a (click)="toggleDay(4)" class="mr-2">
                        <i
                            class="toggle hover fa"

                            [ngClass]="{
                            'fa-toggle-on text-success': dayChecked(4),
                            'fa-toggle-off' : !dayChecked(4)}"></i>
                        Thu
                    </a>
                    <a (click)="toggleDay(5)" class="mr-2">
                        <i
                            class="toggle hover fa"

                            [ngClass]="{
                            'fa-toggle-on text-success': dayChecked(5),
                            'fa-toggle-off' : !dayChecked(5)}"></i>
                        Fri
                    </a>
                    <br />
                    <a (click)="toggleDay(6)" class="mr-2">
                        <i
                            class="toggle hover fa"

                            [ngClass]="{
                            'fa-toggle-on text-success': dayChecked(6),
                            'fa-toggle-off' : !dayChecked(6)}"></i>
                        Sat
                    </a>
                    <a (click)="toggleDay(0)">
                        <i
                            class="toggle hover fa"

                            [ngClass]="{
                            'fa-toggle-on text-success': dayChecked(0),
                            'fa-toggle-off' : !dayChecked(0)}"></i>
                        Sun
                    </a>
                </div>

                <!-- <mat-form-field class="example-full-width" style="display: block">
                    <input type="search" matInput [(ngModel)]="data.stDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Start date">
                    <mat-datepicker #startpicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="example-full-width" style="display: block">
                    <input type="search" matInput [(ngModel)]="data.endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="End date">
                    <mat-datepicker #endpicker></mat-datepicker>
                </mat-form-field> -->


                <mat-form-field class="example-full-width">
                    <textarea matInput [(ngModel)]="data.terms" style="height: 100px" placeholder="Product description"></textarea>
                </mat-form-field>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="data.cost" placeholder="Product Cost">
                        </mat-form-field>

                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="data.maxQty" placeholder="Max Allowed">
                        </mat-form-field>

                    </div>
                </div>


                <mat-form-field>

                    <mat-select
                        [(ngModel)]="data.inputRequired"
                        placeholder="Requires text box?">
                        <mat-option [value]="0">No</mat-option>
                        <mat-option [value]="1">Yes</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width" *ngIf="data.inputRequired">
                    <input matInput [(ngModel)]="data.question" placeholder="Question to ask">
                </mat-form-field>


                <!-- <div class="margin-top-20 text-right">
                    <button mat-raised-button color="warn" (click)="save()">Save</button>
                </div> -->
                <div class="text-right">
                    <button mat-raised-button color="primary" (click)="save()">Save</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>




