import { Component, OnInit } from '@angular/core';
import {Store} from '../store';
import {ChartService} from '../services/chart.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    chartData$ = this.store.select<any[]>('chartData');

    constructor(
        private store: Store,
        private chartService: ChartService,
    ) { }

    ngOnInit() {
    }

}
