import { GrowlerService } from './../../services/growler.service';
import { Component, OnInit } from '@angular/core';
import { BookingService } from '../../services/booking.service';
import { Router } from '@angular/router';
import { Store } from '../../store';
import { OverlayService } from '../../services/overlay.service';
import { debounce } from 'lodash';

@Component({
    selector: 'app-bookings-list',
    templateUrl: './bookings-list.component.html',
    styleUrls: ['./bookings-list.component.css']
})
export class BookingsListComponent implements OnInit {
    bookings$ = this.store.select<any[]>('bookings');
    params$ = this.store.select<any>('bookingParams');
    rrBacklog$ = this.store.select<any>('rrBacklog');

    exportConfirmationData = {
        open: false,
        title: '',
        detail: '',
        data: undefined,
    };

    constructor(
        private bookingService: BookingService,
        private router: Router,
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
    ) {

    }

    ngOnInit() {
        // this.search();
    }


    search() {
        this.bookingService.search();
    }


    edit(booking) {
        this.bookingService.setBooking(booking);
        this.router.navigate(['/bookings', booking.id]);
    }
    reconcile(booking) {
        if (booking.reconciled) {
            booking.reconciled = false;
        } else {
            booking.reconciled = true;
        }
        this.bookingService.reconcile(booking);
    }

    markAsChecked(booking) {
        if (booking.checked) {
            booking.checked = false;
        } else {
            booking.checked = true;
        }
        this.bookingService.markAsChecked(booking.id).subscribe();
    }

    exportConfirm() {
        this.exportConfirmationData.title = 'Export Booking Data';
        this.exportConfirmationData.data = '';
        this.exportConfirmationData.detail = 'Clicking confirm will create a CSV file of your bookings selection and deliver by email';
        this.exportConfirmationData.open = true;
    }

    exportConfirmationComplete(e) {
        this.exportConfirmationData.open = false;
        if (e.action) {
            this.bookingService.exportBookings().subscribe(data => {
                this.search();
                if (data.success) {
                    this.growlerService.notification(['Your export has completed and will be emailed to you']);
                } else {
                    this.growlerService.error(['Sorry - there was a problem exporting your data']);
                }
                console.log(data);
            });
        } else {
            console.log('cancelled');
        }

    }

}
