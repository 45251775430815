<div class="row">
    <div class="col-12">
        <div class="addons-container" style="padding: 0 8px;">

            <div class="booking-addon-group" *ngFor="let pg of productGroups">

                <div class="product-group">
                    <!-- <app-chkout-addons-slider class="d-none d-sm-inline-block" [images]="pg.images"></app-chkout-addons-slider> -->
                    <!-- <div class="logo" [ngStyle]="{'background-image': 'url(' + pg.logo + ')'}"></div> -->

                    <div class="products">
                        <!-- <img [src]="pg.logo" /> -->
                        <h4 class="mat-title mt-3 mb-0" style="font-size: 15px;">{{ pg.name }}</h4>

                        <ng-container *ngFor="let product of pg.products">

                            <div class="product w-100 d-flex flex-column">
                                <div class="d-none d-sm-inline-block w-100">
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        <div class="fs-light fs-4" style="width: 50%;">
                                            {{ product.name }}
                                            <!-- <div *ngIf="product.terms">
                                                <i style="opacity: 0.7;">{{product.terms}}</i>
                                            </div> -->

                                        </div>
                                        <div class="cost" style="width: 30%;">
                                            <ng-container *ngIf="product.allowPassport === 1">
                                                <div *ngIf="(passportValidated$ | async)">
                                                <span class="old-price">
                                                    <span>{{ product.cost | currency: 'GBP' }}</span>
                                                </span>
                                                    <span> &pound;{{ product.pricePassport }}</span>
                                                </div>
                                                <div *ngIf="!(passportValidated$ | async)">
                                                    <span *ngIf="product.qtySelected <= 1">{{ product.cost | currency: 'GBP' }}</span>
                                                    <span *ngIf="product.qtySelected > 1">{{ product.cost * product.qtySelected | currency: 'GBP' }}</span>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="product.allowPassport === 0">
                                                <span *ngIf="product.qtySelected <= 1">{{ product.cost | currency: 'GBP' }}</span>
                                                <span *ngIf="product.qtySelected > 1">{{ product.cost * product.qtySelected | currency: 'GBP' }}</span>
                                        </ng-container>
                                        </div>


                                        <ng-container *ngIf="product.maxQty < 2">
                                            <button
                                            *ngIf="basketPosition(product) < 0"
                                            class="btn btn-sm btn-outline-primary"
                                            (click)="basketAdd(product, pg.maxPerGuest)">
                                            Add
                                        </button>
                                        <button
                                            *ngIf="basketPosition(product) >= 0"
                                            class="btn btn-sm btn-outline-secondary"
                                            (click)="basketRemove(product, pg.maxPerGuest)">
                                            Remove
                                        </button>
                                        </ng-container>

                                        <ng-container *ngIf="product.maxQty > 1">
                                            <div class="d-flex justify-content-between addOnQtyCtn">
                                                <div class="addOnQtyBtnLeft">
                                                    <a class="hover" (click)="basketRemove(product, pg.maxPerGuest)">
                                                        <i class="far fa-minus-square"></i>
                                                    </a>
                                                </div>
                                                <div class="addOnQty">
                                                    <span>{{product.qtySelected}}</span>
                                                </div>
                                                <div class="addOnQtyBtnRight">
                                                    <a class="hover" (click)="basketAdd(product, pg.maxPerGuest)">
                                                        <i class="far fa-plus-square"></i>
                                                    </a>
                                                </div>
                                            </div>

                                        </ng-container>

                                    </div>

                                </div>
                                <!-- mobile version -->
                                 <div class="d-block d-sm-none w-100" style="position: relative;">
                                    <div class="info-holder" (click)="info(product, pg)">
                                        <i class="fa fa-info"></i>
                                    </div>

                                    <div class="d-flex justify-content-between flex-column">
                                        <div class="pr-4">
                                            {{ product.name }}
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center flex-row w-100 mt-2">
                                            <div class="cost w-50">
                                                <ng-container *ngIf="product.allowPassport === 1">
                                                    <div *ngIf="(passportValidated$ | async)">
                                                    <span class="old-price">
                                                        <span>{{ product.cost | currency: 'GBP' }}</span>
                                                    </span>
                                                        <span> &pound;{{ product.pricePassport }}</span>
                                                    </div>
                                                    <div *ngIf="!(passportValidated$ | async)">
                                                        <span *ngIf="product.qtySelected <= 1">{{ product.cost | currency: 'GBP' }}</span>
                                                        <span *ngIf="product.qtySelected > 1">{{ product.cost * product.qtySelected | currency: 'GBP' }}</span>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="product.allowPassport === 0">
                                                    <span *ngIf="product.qtySelected <= 1">{{ product.cost | currency: 'GBP' }}</span>
                                                    <span *ngIf="product.qtySelected > 1">{{ product.cost * product.qtySelected | currency: 'GBP' }}</span>
                                                </ng-container>
                                            </div>
                                            <div class="d-flex justify-content-end w-50">
                                                <ng-container *ngIf="product.maxQty < 2">
                                                    <button
                                                    *ngIf="basketPosition(product) < 0"
                                                    class="btn btn-outline-primary btn-small"
                                                    (click)="basketAdd(product, pg.maxPerGuest)">
                                                    Add
                                                </button>
                                                <button
                                                    *ngIf="basketPosition(product) >= 0"
                                                    class="btn btn-outline-secondary btn-small"
                                                    (click)="basketRemove(product, pg.maxPerGuest)">
                                                    Remove
                                                </button>
                                                </ng-container>

                                                <ng-container *ngIf="product.maxQty > 1">
                                                    <div class="d-flex justify-content-between addOnQtyCtn">
                                                        <div class="addOnQtyBtnLeft">
                                                            <a class="hover" (click)="basketRemove(product, pg.maxPerGuest)">
                                                                <i class="far fa-minus-square"></i>
                                                            </a>
                                                        </div>
                                                        <div class="addOnQty">
                                                            <span>{{product.qtySelected}}</span>
                                                        </div>
                                                        <div class="addOnQtyBtnRight">
                                                            <a class="hover" (click)="basketAdd(product, pg.maxPerGuest)">
                                                                <i class="far fa-plus-square"></i>
                                                            </a>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                            </div>

                                        </div>

                                    </div>

                                 </div>

                                <p *ngIf="product.answer">{{product.answer}}</p>
                                <div class="w-100 mt-4 d-flex justify-content-between align-items-center" *ngIf="product.inputRequired === 1 && basketPosition(product) >= 0">
                                    <p class="mb-0" style="width: 70%; padding-right: 10px;">{{product.question}}</p>
                                    <input class="form-control w-25" style="width: 30%;" [(ngModel)]="product.answer">
                                </div>


                            </div>


                        </ng-container>
                    </div>
                </div>

            </div>


            <p class="text-danger">{{error}}</p>
        </div>

    </div>
</div>
