import { Store } from './../store';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TestimonialService {
    params = {
        searchTerm: '',
        customerEmail: '',
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'testimonial.created DESC',
        sorts: [
            { ID: 'testimonial.created ASC', name: 'Created Asc' },
            { ID: 'testimonial.created DESC', name: 'Created Desc' },
            { ID: 'testimonial.rating ASC', name: 'Rating Asc' },
            { ID: 'testimonial.rating DESC', name: 'Rating Desc' },
            { ID: 'testimonial.customerEmail DESC', name: 'Email A-Z' },
            { ID: 'testimonial.customerEmail ASC', name: 'Email Z-A' }
        ]
    };

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store
    ) {}

    search(params) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: params
        };
        return this.http
            .post(this.apiService.path() + 'testimonial/search', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }
}
