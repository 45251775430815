<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/rooms']">Rooms</a>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary" (click)="edit(undefined)">Add Room</button>
        </div>
    </div>
</mat-card>

<mat-card class="text-right">
    <app-rooms-filter [params]="(params$ | async)"></app-rooms-filter>
</mat-card>

<mat-card class="no-records" *ngIf="!(rooms$ | async).length">
    No rooms listed
</mat-card>

<ng-container *ngIf="(rooms$ | async).length">

    <div class="margin-top-20">


        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Room Name</th>
                <th>Hotel Name</th>
                <th>rrID</th>
                <th class="text-center">Top rated</th>
                <th class="text-center">Max<br />Adults</th>
                <th class="text-center">Max<br />Children</th>
                <th class="text-center">Max<br />Occupancy</th>
                <!-- <th class="text-center">Self Catering</th> -->
                <!-- <th class="text-center">Family Room</th> -->
                <th class="text-right">Bookings (for mth)</th>
                <th class="text-right">Revenue (for mth)</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let r of (rooms$ | async)">
                <td (click)="edit(r)">{{r.name}}</td>
                <td (click)="edit(r)"> {{r.hotel.name}}</td>
                <td (click)="edit(r)">{{r.roomType.id}}</td>
                <td class="text-center" (click)="edit(r)">
                    <mat-icon class="pointer" [ngClass]="r.topRated ? 'active' : 'inactive'">check_circle</mat-icon>
                </td>
                <td class="text-center" (click)="edit(r)">{{r.maxAdults}}</td>
                <td class="text-center" (click)="edit(r)">{{r.maxChildren}}</td>
                <td class="text-center" (click)="edit(r)">{{r.maxGuests}}</td>

                <!-- <td class="text-center" (click)="edit(r)">
                    <mat-icon class="pointer" [ngClass]="r.SC ? 'active' : 'inactive'">check_circle</mat-icon>
                </td>
                <td class="text-center" (click)="edit(r)">
                    <mat-icon class="pointer" [ngClass]="r.familyRoom ? 'active' : 'inactive'">check_circle</mat-icon>
                </td> -->
                <td class="text-right">0</td>
                <td class="text-right" (click)="edit(r)">0</td>
                <td class="text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="edit(r)">
                            <mat-icon>edit</mat-icon>
                            <span>View</span>
                        </button>
                        <button mat-menu-item (click)="editFeatures(r)">
                            <mat-icon>add_to_queue</mat-icon>
                            <span>Room Features</span>
                        </button>
                        <button mat-menu-item (click)="delete(r)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

</ng-container>

