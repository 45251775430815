import {Injectable} from '@angular/core';
import {Store} from '../store';

@Injectable({
  providedIn: 'root'
})
export class GrowlerService {

    constructor(
        private store: Store
    ) {
    }

    error(errors) {
        const g = this.store.selectForLocal('growler');
        g.errors = errors;
        g.notifications = [];
        this.store.set('growler', g);
    }
    notification(notifications) {
        const g = this.store.selectForLocal('growler');
        g.errors = [];
        g.notifications = notifications;
        this.store.set('growler', g);

        setTimeout(() => {
            this.clearMessage();
        }, 4000);

    }

    clearMessage() {
        const g = this.store.selectForLocal('growler');
        g.errors = [];
        g.notifications = [];
        this.store.set('growler', g);
    }


}
