<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Feedback Comment</h5>
                <a class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">


                <div *ngIf="feedbackData">

                        <!-- <div class="form-group">
                            <label>Question</label>
                            <p>{{feedbackData.answer.text}}</p>
                        </div> -->

                        <!-- <p>{{feedbackData.answer.comments}}</p> -->

                        <div class="form-group">
                               <textarea
                                   rows="4"
                                   type="text"
                                   autocomplete="new-password"
                                   class="form-control"
                                   [(ngModel)]="staffResponse"
                                   placeholder="Comments"></textarea>
                        </div>

                        <div class="text-right">
                            <button
                                [disabled]="!staffResponse"
                                (click)="submit()"
                                class="btn btn-round btn-primary"
                                type="button"
                                tabindex="3">
                                Submit
                            </button>
                        </div>


                        <hr />

                        <table class="example-full-width">
                            <thead>
                            <tr>
                                <th>Question</th>
                                <th>Comments</th>
                                <th>Response</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let a of feedbackData.invite.answers">
                                <td class="hover" style="width: 45%;">{{a.text}}</td>
                                <td class="hover" style="width: 45%;">{{a.comments}}</td>
                                <td class="hover">
                                <ng-container *ngIf="a.question_type_id < 4">
                                    <img *ngIf="a.response === 'tu'" [src]="'https://feedback.787512.co.uk/assets/images/small_thumbsup_green.png'" />
                                    <img *ngIf="a.response === 'td'" [src]="'https://feedback.787512.co.uk/assets/images/small_thumbsdown_red.png'" />
                                </ng-container>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                </div>


            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>




