<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/packages']">Promotions</a>
            <mat-icon>arrow_forward_ios</mat-icon>
            Edit Promotion
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card class="mb-3">

    <ng-container *ngIf="data">
        <div class="row">
            <div class="col-md-6">

                <div>
                    <h2 class="mat-title">Promotion Details</h2>

					<div class="row">
						<div class="col-6">
							<mat-form-field class="example-full-width">
								<mat-select
										[(ngModel)]="data.hotelId"
										(ngModelChange)="updateHotel()"
										placeholder="Select hotel">
									<mat-option value="">Please select</mat-option>
									<mat-option *ngFor="let h of hotels" [value]="h.id">{{h.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>


                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="data.name" placeholder="Package Name">
                    </mat-form-field>
<!--					<mat-form-field class="example-full-width">-->
<!--						<input matInput [(ngModel)]="data.welcomeRoomRate" placeholder="Welcome Rate ID">-->
<!--					</mat-form-field>-->

					<!-- <mat-form-field class="example-full-width">
						<mat-select
								[(ngModel)]="data.brand_mode"
								placeholder="Display mode">
							<mat-option value="">Please select</mat-option>
							<mat-option [value]="1">Brand colour background</mat-option>
							<mat-option [value]="0">White background</mat-option>
						</mat-select>
					</mat-form-field> -->

					<!-- <mat-form-field class="example-full-width">
						<mat-select
								[(ngModel)]="data.banner_type"
								placeholder="Banner type">
							<mat-option value="">Please select</mat-option>
							<mat-option value="Both">Popup and on page</mat-option>
							<mat-option value="On page">On page only</mat-option>
							<mat-option value="Popup">Popup only</mat-option>
						</mat-select>
					</mat-form-field> -->

					<!-- <mat-form-field class="example-full-width">
						<input matInput [(ngModel)]="data.button_text" placeholder="Banner button text">
					</mat-form-field> -->



					<h2 class="mat-title">Promo image</h2>
					<div class="row">
						<div class="col-md-4">

							<img *ngIf="!data.logo.URL" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
							<img *ngIf="data.logo.URL" [src]="data.logo.URL" class="img-fluid" style="max-height: 150px" />
						</div>
						<div class="col-md-8">
							<app-image-upload [ownerID]="data.Id" [type]="'packages'" [text]="'Click here to upload image'" (imageUploaded)="setLogo($event)"></app-image-upload>
						</div>
					</div>



				</div>
            </div>
            <div class="col-md-6">

				<mat-form-field class="example-full-width">
						<input matInput [(ngModel)]="data.strapline" placeholder="Strapline">
				</mat-form-field>

				<h2 class="mat-title">Overview</h2>
				<quill-editor [modules]="config" [(ngModel)]="data.overview" [style]="{'height':'100px'}"></quill-editor>


				<h2 class="mat-title mt-3">Terms and conditions</h2>
				<quill-editor [modules]="config" [(ngModel)]="data.terms" [style]="{'height':'100px'}"></quill-editor>

				<h2 class="mat-title mt-3">Checkout and email confirmation text</h2>
				<mat-form-field class="example-full-width">
					<input matInput [(ngModel)]="data.checkout_text" placeholder="Text to be displayed on the payments page and within the booking confirmation email">
				</mat-form-field>

				<!-- <h2 class="mat-title mt-3">Promo badge text</h2>
				<mat-form-field class="example-full-width">
					<input matInput [(ngModel)]="data.promo_badge_text" placeholder="Text to be displayed in the yellow circle">
				</mat-form-field> -->
				<!-- <h2 class="mat-title mt-3">Tada button text</h2>
				<mat-form-field class="example-full-width">
					<input matInput [(ngModel)]="data.tada_button" placeholder="Text to be displayed in the tada button">
				</mat-form-field> -->

            </div>
        </div>

    </ng-container>
</mat-card>


<mat-card class="mb-3" *ngIf="data && data.packages.length">
	<table class="mat-elevation-z2 example-full-width data-listing">
		<thead>
		<tr>
			<th>Promotion Name</th>
			<th>Starts</th>
			<th>Ends</th>
			<th>Price type</th>
			<th>Price / Discount</th>
			<th>Nights</th>
			<th>Active?</th>
			<th></th>
		</tr>
		</thead>
		<tbody>
		<ng-container *ngFor="let p of data.packages; let i = index">
			<tr [ngClass]="{'bg-warning' : packageToEdit === i}" >
				<td>{{p.name}}</td>
				<td (click)="editPackage(i)"> {{p.startDate | date: 'dd/MM/yyyy'}}</td>
				<td (click)="editPackage(i)"> {{p.endDate | date: 'dd/MM/yyyy'}}</td>
				<td (click)="editPackage(i)"> {{p.priceType}}</td>
				<td (click)="editPackage(i)">
					<span *ngIf="p.priceType === 'Fixed'">{{p.price |currency: 'GBP'}}</span>
					<span *ngIf="p.priceType === 'Discount'">{{p.discount}}%</span>
				</td>
				<td (click)="editPackage(i)">{{p.nights}}</td>
				<td (click)="editPackage(i)">
					<i class="fa fa-check text-success" *ngIf="p.active === 1"></i>
					<i class="fa fa-minus" *ngIf="p.active === 0"></i>
				</td>
				<td>
					<a *ngIf="!p.deleted" (click)="useAsTemplate(i)" class="hover mr-2" matTooltip="Use as template for new promo">
						<i class="fa fa-file-import"></i>
					</a>
					<a *ngIf="!p.deleted" (click)="delete(p, i)" class="hover" matTooltip="delete">
						<i class="fa fa-trash"></i>
					</a>
					<a *ngIf="p.deleted" (click)="undoDelete(i)" class="hover" matTooltip="Undo delete">
						<i class="fa fa-undo"></i>
					</a>

				</td>
			</tr>

		</ng-container>
		</tbody>
	</table>
</mat-card>
<div class="mt-2 mb-2 text-center">
	<button mat-raised-button color="primary" (click)="setMode('create')">Add new promotion</button>
</div>

	<ng-container *ngIf="packageToEdit >= 0 || mode === 'create'">
		<ng-container *ngIf="package">
			<mat-card class="mb-3" *ngIf="package.rooms.length">
				<hr />
				<div class="row">
					<div class="col-lg-4">
						<h2 class="mat-title">Promotion details</h2>

						<mat-form-field class="example-full-width">
							<input type="text" matInput [(ngModel)]="package.name" placeholder="Short description">
						</mat-form-field>
						<mat-form-field class="example-full-width">
							<input type="text" matInput [(ngModel)]="package.promoText" placeholder="Promo text">
						</mat-form-field>
						<!-- <mat-form-field class="example-full-width">
							<input matInput [(ngModel)]="package.welcomeRoomRate" placeholder="Welcome Rate ID">
						</mat-form-field> -->

						<mat-form-field class="example-full-width">
							<input type="number" matInput [(ngModel)]="package.nights" placeholder="Nights">
						</mat-form-field>

						<mat-form-field class="example-full-width">
							<mat-select
									[(ngModel)]="package.priceType"
									placeholder="Discount or fixed price?">
								<mat-option value="">Please select</mat-option>
								<mat-option value="Fixed">Fixed price</mat-option>
								<mat-option value="Discount">Discount</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="example-full-width" *ngIf="package.priceType === 'Fixed'">
							<input type="number" matInput [(ngModel)]="package.price" placeholder="Package price">
						</mat-form-field>

						<mat-form-field class="example-full-width" *ngIf="package.priceType === 'Discount'">
							<input type="number" matInput [(ngModel)]="package.discount" placeholder="Discount (%)">
						</mat-form-field>

						<mat-form-field class="example-full-width">
							<mat-select
									[(ngModel)]="package.passport_required"
									placeholder="Passport required?">
								<mat-option [value]="">Please select</mat-option>
								<mat-option [value]="1">Yes</mat-option>
								<mat-option [value]="0">No</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="example-full-width">
							<mat-select
									[(ngModel)]="package.allow_passport_discount"
									placeholder="Allow further passport discount?">
								<mat-option [value]="">Please select</mat-option>
								<mat-option [value]="1">Yes</mat-option>
								<mat-option [value]="0">No</mat-option>
							</mat-select>
						</mat-form-field>


						<mat-form-field class="example-full-width">
							<mat-select
									[(ngModel)]="package.active"
									placeholder="Promotion active?">
								<mat-option [value]="1">Yes</mat-option>
								<mat-option [value]="0">No</mat-option>
							</mat-select>
						</mat-form-field>



						<h2 class="mat-title">Promotion dates</h2>

						<mat-form-field class="example-full-width">
							<mat-select
									[(ngModel)]="package.date_type"
									placeholder="Date range type">
								<mat-option [value]="">Please select</mat-option>
								<mat-option [value]="1">Booking date</mat-option>
								<mat-option [value]="0">Arrival/departure date</mat-option>
							</mat-select>
						</mat-form-field>

						<div class="row">
							<div class="col-6">
								<mat-form-field style="display: block">
									<input matInput (dateChange)="buildDateMatrix()" [(ngModel)]="package.startDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Starts">
									<mat-datepicker #startpicker [startAt]="package.startDate"></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field style="display: block">
									<input matInput (dateChange)="buildDateMatrix()" [(ngModel)]="package.endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Ends">
									<mat-datepicker #endpicker [startAt]="package.startDate"></mat-datepicker>
								</mat-form-field>
							</div>
						</div>



					</div>
					<div class="col-lg-4">
						<h2 class="mat-title">Rooms to include
							<button mat-raised-button color="warn" (click)="selectAllRooms()">
								<span *ngIf="!package.rooms[0].selected">Select all</span>
								<span *ngIf="package.rooms[0].selected">Deselect all</span>
							</button>
						</h2>

						<mat-list>
							<ng-container *ngFor="let r of package.rooms">
								<mat-list-item >
									<mat-checkbox
											class="example-margin"
											[(ngModel)]="r.selected">
										{{r.name}}
									</mat-checkbox>
								</mat-list-item>
								<mat-divider></mat-divider>
							</ng-container>
						</mat-list>
					</div>
					<div class="col-lg-4">
						<h2 class="mat-title">Dates to exclude</h2>

						<div class="calendar-holder">
							<div style="background-color: #ccc; height: 36px; padding: 10px 0;">
								<div class="calendar-cell" (click)="toggleDayOfWeek(1)">M</div>
								<div class="calendar-cell" (click)="toggleDayOfWeek(2)">T</div>
								<div class="calendar-cell" (click)="toggleDayOfWeek(3)">W</div>
								<div class="calendar-cell" (click)="toggleDayOfWeek(4)">T</div>
								<div class="calendar-cell" (click)="toggleDayOfWeek(5)">F</div>
								<div class="calendar-cell" (click)="toggleDayOfWeek(6)">S</div>
								<div class="calendar-cell" (click)="toggleDayOfWeek(0)">S</div>

							</div>
							<div class="calendar-cell" *ngFor="let d of blankDates">
								<div style="color: #383838; background-color: #fff"></div>
							</div>
							<div class="calendar-cell" *ngFor="let d of package.dates; let i = index">
								<div
										style="color: white"
										(click)="setDateStatus(i)"
										[ngClass]="{'bg-success' : d.active, 'bg-danger' : !d.active}">
									<div class="calendar-cell-text">
										{{d.day | date: 'dd'}} ({{d.dow}})
										<br />
										{{d.day | date: 'MMM'}}
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
				<div *ngIf="packageToEdit < 0">
					<button class="mr-2" mat-raised-button color="primary" (click)="cancelAddPackage()">Cancel</button>
					<button mat-raised-button color="warn" (click)="addPackage()">Save promotion</button>
				</div>
				<div *ngIf="packageToEdit >= 0">
					<button class="mr-2" mat-raised-button color="primary" (click)="cancelPackageChanges()">Cancel</button>
					<button mat-raised-button color="warn" (click)="savePackageChanges()">Save changes</button>
				</div>

			</mat-card>

		</ng-container>

	</ng-container>

<mat-card class="mb-3" *ngIf="packageToEdit < 0">
    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>

</mat-card>

