<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <h2 class="mat-title mb-0">All Passports</h2>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary" [routerLink]="['/passport','new']">
                <i class="fa fa-plus"></i> New passport
            </button>
        </div>
    </div>
</mat-card>

<mat-card class="text-right">
    <div class="row">
        <div class="col-md-5">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.searchText" (keyup)="filterSearch()" placeholder="Passport number, firstname, surname, email, postcode">
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.feedback"
                    (selectionChange)="filterSearch()"
                    placeholder="Feedback provided?">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option [value]="1">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.hasNotes"
                    (selectionChange)="filterSearch()"
                    placeholder="Has notes?">
                    <mat-option [value]="0">All</mat-option>
                    <mat-option [value]="1">Yes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="filterSearch()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-1 text-right">
            <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>
        </div>
    </div>

</mat-card>

<mat-card class="no-records" *ngIf="!passports.length">
    No passports listed
</mat-card>

<ng-container *ngIf="passports.length">

    <div class="margin-top-20">


        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Number</th>
                <th>Name</th>
                <th>Email</th>
                <th class="text-center">Registered</th>
                <th class="text-center">Account?</th>
                <th class="text-center">Bookings</th>
                <th class="text-center">Feedback</th>
                <th class="text-center">Spend</th>
                <th class="text-center">Card type</th>
                <th class="text-right">Edit</th>
                <th class="text-right">Delete</th>
<!--                <th class="text-center">-->
<!--                    Selected ({{selected.length}})<br />-->
<!--                    <a class="hover" (click)="selectAll()">All</a> | <a class="hover" (click)="clearSelected()">None</a>-->
<!--                </th>-->
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let r of passports">
                <td (click)="edit(r)">{{r.cardNumber}}</td>
                <td (click)="edit(r)"> {{r.firstname}} {{r.surname}}</td>
                <td (click)="edit(r)">{{r.username}}</td>
                <td (click)="edit(r)">{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td class="text-center" (click)="edit(r)">
                    Yes
                </td>
                <td class="text-center" (click)="edit(r)">{{r.totalBookings}}</td>
                <td class="text-center" (click)="edit(r)">
                    <span *ngIf="r.feedback_qt > 0" class="mr-2">{{r.feedback_qt}}%</span>
                    <ng-container *ngIf="r.feedback_qt > 0">
                        <img *ngIf="r.feedback_qt >= 98" [src]="'https://feedback.787512.co.uk/assets/images/large_thumbsup_gold.png'" />
                        <img *ngIf="r.feedback_qt < 98 && r.feedback_qt >= 95" [src]="'https://feedback.787512.co.uk/assets/images/large_thumbsup_green.png'" />
                        <img *ngIf="r.feedback_qt < 95" [src]="'https://feedback.787512.co.uk/assets/images/large_thumbsup_red.png'" />
                    </ng-container>
                </td>
                <td class="text-center" (click)="edit(r)">{{r.totalSpend | currency : 'GBP'}}</td>
                <td (click)="edit(r)">
                    <span *ngIf="r.planId === 'np000'">Digital</span>
                    <span class="text-danger" *ngIf="r.planId === 'np004'">Physical</span>
                    <span *ngIf="r.planId === 'np002'">Gold</span>
                </td>

                <td class="text-right">
                    <button type="button" class="btn btn-warning btn-sm" (click)="edit(r)">
                        <i class="fa fa-edit"></i> Edit
                    </button>
                </td>
                <td class="text-right">
                    <button type="button" class="btn btn-danger btn-sm" (click)="deleteConfirmation(r)">
                        <i class="fa fa-trash"></i> Delete
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <app-pagination [params]="params" (update)="search()"></app-pagination>

    </div>


</ng-container>

<app-confirmation *ngIf="showDeleteConfirmation" [data]="deleteConfirmData" (complete)="delete($event)"></app-confirmation>
