import {Component, EventEmitter, Output, Input, OnInit, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, OnDestroy {
    @Input() dateSelected;
    @Input() datesSelected;
    @Output() dateWasSelected = new EventEmitter();
    @Output() close = new EventEmitter();

    body = document.getElementsByTagName('body')[0];
    modalHolder = document.getElementById('myModal');
    view = 'calendar';
    dateToday = new Date();
    theDay = this.dateToday.getDate();
    theMonth = this.dateToday.getMonth() + 1;
    theYear = this.dateToday.getFullYear();
    theHour = this.dateToday.getHours();
    theMinute = this.dateToday.getMinutes();

    dateToOutput: any;

    daysInMonth = 31;
    weeksInMonth = 5;
    firstDayOfMonth = 0;
    days = [];
    monthName = '';

    monthNames = {
        1: {name: 'Jan'},
        2: {name: 'Feb'},
        3: {name: 'Mar'},
        4: {name: 'Apr'},
        5: {name: 'May'},
        6: {name: 'Jun'},
        7: {name: 'Jul'},
        8: {name: 'Aug'},
        9: {name: 'Sep'},
        10: {name: 'Oct'},
        11: {name: 'Nov'},
        12: {name: 'Dec'},
    };
    monthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    yearsArray = [];

    constructor(
    ) { }

    ngOnInit() {
        this.parseDate(this.dateSelected);
        this.buildYearsArray();
    }
    ngOnDestroy() {
    }

    setView(v) {
        this.view = v;
        if (v === 'year') {
            // this.buildYearsArray();
        }
    }

    isDateSelected(d, m, y) {
        console.log(d, m, y);
        const dateToCheck = d + '/' + m + '/' + y;
        if (this.datesSelected.indexOf(dateToCheck) > -1) {
            return true;
        } else {
            return false;
        }
    }
    parseDate(d) {
        if (d) {
            d = new Date(d);
            this.theDay = d.getDate();
            this.theMonth = d.getMonth() + 1;
            this.theYear = d.getFullYear();
            this.theHour = d.getHours();
            this.theMinute = d.getMinutes();
        } else {
            this.theDay = this.dateToday.getDate();
            this.theMonth = this.dateToday.getMonth() + 1;
            this.theYear = this.dateToday.getFullYear();
            this.theHour = this.dateToday.getHours() + 1;
            this.theMinute = 0;
        }
        this.parseForOutput();

        this.dateSelected = new Date(this.theYear, this.theMonth - 1, this.theDay);
        this.setMonthName(this.theMonth);
        this.setDaysInMonth();

        console.log(this.daysInMonth, this.weeksInMonth)
        this.buildDayArray();

    }

    parseForOutput() {
        this.dateToOutput = {
            'd': this.theDay,
            'm': this.theMonth,
            'y': this.theYear,
            'h': this.theHour,
            'n': this.theMinute,
            'json': new Date(this.theYear, this.theMonth, this.theDay, this.theHour, this.theMinute, 0, 0),
            'formatted': this.theDay + '/' + (this.theMonth)  + '/' + this.theYear
        };
        console.log(this.dateToOutput)
        return this.dateToOutput;
    }

    buildDayArray() {
        this.days = [];
        this.firstDayOfMonth = new Date(this.theYear, this.theMonth - 1, 1).getDay();
        this.setDaysInMonth();
        for (let i = 1; i < this.firstDayOfMonth; i++) {
            this.days.push(0);
        }
        for (let i = 1; i <= this.daysInMonth; i++) {
            this.days.push(i);
        }
    }
    buildYearsArray() {
        this.yearsArray = [];
        const startYear = this.theYear;
        const endYear = this.theYear + 15;
        for (let i = startYear; i <= endYear; i++) {
            this.yearsArray.push(i);
        }
    }

    setMonthName(theMonth) {
        this.monthName = this.monthNames[theMonth].name;
    }
    setDaysInMonth() {
        this.daysInMonth = new Date(this.theYear, this.theMonth, 0).getDate();
        this.weeksInMonth = Math.ceil((this.daysInMonth / 7));
    }


    setDay(day) {
        this.theDay = day;
        this.dateWasSelected.emit(this.parseForOutput());
    }
    setMonth(theMonth) {
        this.theMonth = theMonth;
        this.setMonthName(this.theMonth);
        this.buildDayArray();

        if (this.theDay > this.daysInMonth) {
            this.theDay = 1;
        }
        this.parseForOutput();
        this.setView('calendar');
    }
    setYear(theYear) {
        this.theYear = theYear;
        this.parseForOutput();
        this.setView('calendar');
    }

    monthBack() {
        this.theMonth--;
        if (this.theMonth < 1) {
            this.theMonth = 12;
            this.theYear--;
        }
        this.setMonthName(this.theMonth);
        this.buildDayArray();
    }
    monthForward() {
        this.theMonth++;
        if (this.theMonth > 12) {
            this.theMonth = 1;
            this.theYear++;
        }
        this.setMonthName(this.theMonth);
        this.buildDayArray();
    }
    confirm() {
        const dataToEmit = {
            date: this.dateToOutput
        };
        this.close.emit(dataToEmit);
    }
}
