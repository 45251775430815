import { Component, OnInit } from '@angular/core';
import {PackageService} from '../../services/package.service';
import {OverlayService} from '../../services/overlay.service';
import {Store} from '../../store';
import {ActivatedRoute, Router} from '@angular/router';
import {ToolsService} from '../../services/tools.service';
import {HotelService} from '../../services/hotel.service';
import {GrowlerService} from '../../services/growler.service';

@Component({
  selector: 'app-packages-edit',
  templateUrl: './packages-edit.component.html',
  styleUrls: ['./packages-edit.component.css']
})
export class PackagesEditComponent implements OnInit {

    Id = '';
    data;
    hotels = [];
    hotel;
    rooms = [];
    blankDates = [];
    dates = [];
    package;
    packageToEdit = -1;
    mode = '';

    newPackage = {
        Id: this.toolsService.secureId(),
        name: '',
        promoText: '',
        rooms: [],
        dates: [],
        welcomeRoomRate: '',
        startDate: new Date(),
        endDate: new Date(),
        active: 1,
        nights: 1,
        price: 0,
        discount: 0,
        priceType: 'fixed',
        passport_required: 0,
        allow_passport_discount: 0,
        deleted: false,
    };

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['clean']
        ]
    };


    constructor(
        private overlayService: OverlayService,
        private packageService: PackageService,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private toolsService: ToolsService,
        private hotelService: HotelService,
        private growlerService: GrowlerService,
    ) {

    }

    ngOnInit() {
        this.getHotels();
        this.package = JSON.parse(JSON.stringify(this.newPackage));
        if (this.route.snapshot.params['Id'] !== 'new') {
            this.Id = this.route.snapshot.params['Id'];
            this.get();

        } else {
            this.data = {
                Id: this.toolsService.secureId(),
                isNew: 1,
                name: '',
                overview: '',
                terms: '',
                strapline: '',
                welcomeRoomRate: '',
                checkout_text: '',
                promo_badge_text: '',
                tada_button: '',
                logo: {URL: ''},
                brand_mode: 1,
                hotelId: '',
                discount: 0,
                nights: 1,
                price: 0,
                priceType: 'Fixed',
                passport_required: 0,
                active: 0,
                allow_passport_discount: 0,
                startDate: new Date(),
                endDate: new Date(),
                date_type: 0,
                packages: []
            };
        }
    }

    get() {
        this.packageService.get(this.Id).subscribe(data => {
            this.data = data.data;
            this.data.isNew = 0;
            console.log(this.data);
            this.package = JSON.parse(JSON.stringify(this.newPackage));
            this.package.Id = this.toolsService.secureId();
            this.packageToEdit = -1;

            this.hotelService.all().subscribe(hData => {
                this.hotels = hData.data;
                this.updateHotel();
            });

        });
    }

    setLogo(e) {
        console.log(e);
        this.data.logo = e;
    }
    setMode(m) {
        this.mode = m;
    }

    addPackage() {
        this.data.packages.push(JSON.parse(JSON.stringify(this.package)));
        this.package = JSON.parse(JSON.stringify(this.newPackage));
        this.package.Id = this.toolsService.secureId();
        this.addRoomsToPackage();
        this.setMode('');

    }
    cancelAddPackage() {
        // this.package = JSON.parse(JSON.stringify(this.newPackage));
        // this.package.Id = this.toolsService.secureId();
        this.setMode('');
    }
    editPackage(i) {
        this.setMode('');
        this.packageToEdit = i;
        this.package = JSON.parse(JSON.stringify(this.data.packages[i]));
        this.buildBlankDates();
        console.log(this.package);
    }
    useAsTemplate(i) {
        this.package = JSON.parse(JSON.stringify(this.data.packages[i]));
        this.buildBlankDates();
        this.package.name = '';
        this.package.Id = this.toolsService.secureId();
        this.setMode('create');
    }
    savePackageChanges() {
        this.data.packages[this.packageToEdit] = JSON.parse(JSON.stringify(this.package));
        this.package = JSON.parse(JSON.stringify(this.newPackage));
        this.package.Id = this.toolsService.secureId();
        this.addRoomsToPackage();
        this.packageToEdit = -1;
        this.setMode('');
    }
    cancelPackageChanges() {
        this.package = JSON.parse(JSON.stringify(this.newPackage));
        this.package.Id = this.toolsService.secureId();
        this.addRoomsToPackage();
        this.packageToEdit = -1;
        this.setMode('');
    }
    save() {
        this.setMode('');
        for (let p = 0; p < this.data.packages.length; p++) {
            for (let d = 0; d < this.data.packages[p].dates.length; d++) {
                this.data.packages[p].dates[d].day = new Date(this.data.packages[p].dates[d].day);
            }
        }

        this.packageService.save(this.data).subscribe(data => {
            if (data.errors.length) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['Package details updated']);
                this.router.navigate(['packages']);
            }

        });
        // this.back();
    }
    delete(p, idx) {
        this.data.packages[idx].deleted = true;
        // this.data.packages.splice(idx, 1);
        // this.store.set('packageToDelete', p);
        // this.overlayService.open('packageDelete');
        // this.store.set('asideOpen', true);
    }
    undoDelete(idx) {
        this.data.packages[idx].deleted = false;
        // this.data.packages.splice(idx, 1);
        // this.store.set('packageToDelete', p);
        // this.overlayService.open('packageDelete');
        // this.store.set('asideOpen', true);
    }

    back() {
        this.router.navigate(['/packages']);
    }

    getHotels() {
        this.hotelService.all().subscribe(data => {
            console.log(data);
            this.hotels = data.data;
        });
    }

    updateHotel() {
        console.log(this.hotels);
        for (let i = 0; i < this.hotels.length; i++) {
            if (this.data.hotelId === this.hotels[i].id) {
                this.hotel = this.hotels[i];
                this.setHotel(this.hotels[i]);
                return;
            }
        }
    }

    setHotel(h) {
        this.hotel = h;
        this.getRooms(h);
    }

    getRooms(h) {
        this.packageService.getRooms(h, this.data).subscribe(data => {
            this.rooms = data.data;
            this.addRoomsToPackage();
        });
    }
    addRoomsToPackage() {
        this.package.rooms = this.rooms;
        this.package.rooms.forEach(room => {
            room.selected = false;
        });
    }
    selectAllRooms() {
        if (this.package.rooms[0].selected) {
            this.package.rooms.forEach(room => {
                room.selected = false;
            });
        } else {
            this.package.rooms.forEach(room => {
                room.selected = true;
            });
        }
    }
    toggleRoom(room) {
        if (!room.selected) {
            room.selected = true;
        } else {
            room.selected = false;
        }
        this.packageService.toggleRoom(room.hotelId, room.Id, this.data.Id).subscribe();
    }

    buildBlankDates() {
        let dayOfWeek = new Date(this.package.startDate).getDay();
        console.log(dayOfWeek);
        if (dayOfWeek === 0 ) { dayOfWeek = 7; }
        this.blankDates = [];
        for (let i = 1; i < dayOfWeek; i++) {
            const d = {
                day: this.minusDays(this.package.startDate, i),
                active: true
            };
            this.blankDates.unshift(d);
        }
        console.log(this.blankDates);

    }
    buildDateMatrix() {
        this.blankDates = [];
        this.package.dates = [];

        const totalDays = this.datediff(this.package.startDate, this.package.endDate);
        console.log(this.package.startDate, this.package.endDate, totalDays);

        this.buildBlankDates();

        for (let i = 0; i <= totalDays; i++) {
            const thisDay = this.addDays(this.package.startDate, i);
            const d = {
                day: thisDay,
                dow: thisDay.getDay(),
                active: true
            };
            this.package.dates.push(d);
        }
        console.log(this.package.dates);
        console.log('ARRHHHH', new Date(this.package.startDate).getDay(), new Date(this.package.endDate).getDay())
        if (new Date(this.package.startDate) > new Date(this.package.endDate)) {
            console.log('data needs moving on');
            this.package.endDate = this.package.startDate;
        }
    }
    setDateStatus(i) {
        if (!this.package.dates[i].active) {
            this.package.dates[i].active = true;
        } else {
            this.package.dates[i].active = false;
        }
    }
    datediff(first, second) {
        const fd: any = new Date(first);
        const ld: any = new Date(second);
        return Math.round((ld - fd ) / (1000 * 60 * 60 * 24));
    }
    addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    minusDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    toggleDayOfWeek(d) {
        let firstActiveResultFound = false;
        let firstActiveResult = false;
        for (let i = 0; i < this.package.dates.length; i++) {
            this.package.dates[i].day = new Date(this.package.dates[i].day);

            if (this.package.dates[i].dow === d) {
                if (!firstActiveResultFound) {
                    firstActiveResult = this.package.dates[i].active;
                }
                firstActiveResultFound = true;
                if (firstActiveResult) {
                    this.package.dates[i].active = false;
                } else {
                    this.package.dates[i].active = true;
                }
            }
        }
    }
}
