import { ToolsService } from './../../services/tools.service';
import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Store } from '../../store';
import { OverlayService } from '../../services/overlay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { GrowlerService } from 'src/app/services/growler.service';

@Component({
    selector: 'app-product-group-edit',
    templateUrl: './product-group-edit.component.html',
    styleUrls: ['./product-group-edit.component.css']
})
export class ProductGroupEditComponent implements OnInit {

    dataId = '';
    dataToEdit;
    loaded = false;
    mode = '';

    product_group = {
        ID: '',
        name: '',
        addDesc: '',
        description: '',
        product_group: this.store.selectForLocal('productGroup'),
        logo: undefined,
        hotels: [],
    };
    products = [];
    hotels = [];

    constructor(
        private productService: ProductService,
        private store: Store,
        private toolsService: ToolsService,
        private hotelService: HotelService,
        private overlayService: OverlayService,
        private router: Router,
        private route: ActivatedRoute,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
        // const product_group = this.store.selectForLocal('productGroup');
        // if (product_group) {
        //     this.product_group = product_group;
        // }
        this.mode = this.route.snapshot.params.mode;
        if (this.route.snapshot.params.mode === 'edit') {
            this.groupGet()
        } else {
            this.initNew();
        }
    }
    initNew() {
        this.product_group.ID = this.toolsService.secureId();
        console.log(this.product_group)
        this.loaded = true;
        this.getHotels();
        this.store.set('productGroup', this.product_group)
    }

    groupGet() {
        this.productService.groupGet(this.route.snapshot.params.id).subscribe(data => {
            this.loaded = true;
            console.log(data);
            this.store.set('productGroup', data);
            this.product_group = data;
            this.productsGet();
            this.getHotels();
        })
    }
    productsGet() {
        this.productService.productsGet(this.product_group.ID).subscribe(data => {
            this.products = data;
        })
    }

    getHotels() {
        this.hotelService.allLite().subscribe(data => {

            this.hotels = data;
        })
    }

    close() {
        this.overlayService.closeAll();
    }
    setGroupImage(e) {
        console.log(e);
        this.product_group.logo = e;
    }

    isHotelSelected(id) {
        return this.product_group.hotels.indexOf(id);
    }

    toggleHotel(id) {
        if (this.isHotelSelected(id) < 0) {
            this.product_group.hotels.push(id);
        } else {
            this.product_group.hotels.splice(this.isHotelSelected(id), 1)
        }
    }
    productAdd() {
        this.dataToEdit = {
            ID: '',
            name: '',
            rrName: '',
            terms: '',
            product_group: { id: '', name: '' },
            cost: 0,
            maxQty: 1,
            inputRequired: 0,
            question: '',
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        }
        // this.store.set('product', undefined);
        // this.overlayService.open('product');
        // this.store.set('asideOpen', true);
    }
    productUpdate(product) {
        this.dataToEdit = product;
        // this.store.set('product', product);
        // this.overlayService.open('product');
        // this.store.set('asideOpen', true);
    }
    productDelete(product) {
        this.store.set('product', product);
        this.overlayService.open('productDelete');
        this.store.set('asideOpen', true);
    }

    drop(e) {
        // this.toSave = e;
        console.log(e)
        this.productService.sortProducts(e).subscribe(data => {
        });
    }

    save() {
        if (this.mode === 'edit') {
            this.productService.groupUpdate(this.product_group).subscribe(data => {
                this.growlerService.notification(['Your changes have been saved']);
            });
        } else {
            this.productService.groupAdd(this.product_group).subscribe(data => {
                this.mode = 'edit'
                this.growlerService.notification(['Your changes have been saved']);
            });
        }
    }


    dataEditComplete(e) {

        this.dataToEdit = undefined;
        console.log(e)
        if (e.action) {
            this.productsGet()
            // if (!e.data.id) {
            //     this.data.skus.push(e.data);
            // } else {
            //     for (let i = 0; i < this.data.skus.length; i++) {
            //         if (this.data.skus[i].id === e.data.id) {
            //             this.data.skus[i] = e.data;
            //         }
            //     }
            // }
        }
    }

}
