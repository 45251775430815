<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/events/bookings']">Event Bookings</a>
        </div>
        <div class="col-md-5 text-right">

            <!-- <div class="d-flex" *ngIf="!(params$ | async).startDate || !(params$ | async).endDate">
                Select checkin dates to export
                <button [disabled]="true" type="button" class="ml-3 btn btn-success" (click)="exportConfirm()" matTooltip="Export to CSV file">
                    Export
                 </button>

            </div>
            <button *ngIf="(params$ | async).startDate || (params$ | async).endDate" type="button" class="btn btn-success" (click)="exportConfirm()" matTooltip="Export to CSV file">
               Export
            </button> -->

        </div>
    </div>
</mat-card>

<mat-card class="text-right">

    <div class="row">
        <div class="col-md-2">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.searchText" (keyup)="filterSearch()" placeholder="Search....">
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                        [(ngModel)]="params.source"
                        (selectionChange)="filterSearch()"
                        placeholder="Source">
                    <mat-option value="">All sources</mat-option>
                    <mat-option [value]="'booking'">Room Booking</mat-option>
                    <mat-option [value]="'events'">Events Module</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="startDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Created from">
                <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Created to">
                <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="filterSearch()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-select [(ngModel)]="params.limit" (ngModelChange)="filterSearch()" placeholder="Items per page">
                    <mat-option
                        [value]="l"
                        *ngFor="let l of params.limits"
                    >
                        {{ l }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
    <div class="mt-2">
        <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>
    </div>


</mat-card>

<mat-card class="no-records" *ngIf="!data.length">
    No Bookings Listed
</mat-card>

<ng-container *ngIf="data.length">

    <div class="margin-top-20">
        <app-pagination [params]="params" (update)="search()"></app-pagination>


        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Created</th>
                <th>Source</th>
                <th>Event</th>
                <th>Customer Name</th>
                <th>Starts</th>
                <th>Tickets</th>
                <th>Gross (&pound;)</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let booking of data">
                <td class="hover" (click)="edit(booking)">{{booking.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="hover" (click)="edit(booking)">{{booking.source}}</td>
                <td class="hover" (click)="edit(booking)">{{booking.eventName}}</td>
                <td class="hover" (click)="edit(booking)"> {{booking.firstname}} {{booking.surname}}</td>
                <td class="hover" (click)="edit(booking)">{{booking.stDate | date: 'dd/MM/yyyy'}} {{booking.stTime}}</td>
                <td class="hover" (click)="edit(booking)">{{booking.qty}}</td>
                <td class="hover" (click)="edit(booking)">&pound;{{booking.gross}}</td>

                <td class="text-right">
                    <button mat-icon-button (click)="edit(booking)">
                        <mat-icon>search</mat-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <app-pagination [params]="params" (update)="search()"></app-pagination>
    </div>


</ng-container>

