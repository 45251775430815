import {Component, Input, OnInit} from '@angular/core';
import {OverlayService} from '../../services/overlay.service';
import {RoomService} from '../../services/room.service';

@Component({
  selector: 'app-room-type-edit',
  templateUrl: './room-type-edit.component.html',
  styleUrls: ['./room-type-edit.component.css']
})
export class RoomTypeEditComponent implements OnInit {
    @Input() roomType

    constructor(
        private overlayService: OverlayService,
        private roomService: RoomService,
    ) { }

    ngOnInit() {
    }


    save() {
        this.roomService.addRoomType(this.roomType).subscribe(data => {
            this.close();
        });
    }

    close() {
        this.overlayService.closeAll();
    }
}
