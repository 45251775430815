import { Injectable } from "@angular/core";
import { Store } from "../store";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
import { HttpClient } from "@angular/common/http";
import { GrowlerService } from "./growler.service";
import { OverlayService } from "./overlay.service";
import { map } from "rxjs/operators";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root",
})
export class UserService {

    env = environment;

    params = {
        searchTerm: "",
        status: 1,
        limit: 32,
        limits: [8, 16, 24, 32],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "user.firstname",
        sorts: [
            { ID: "user.firstname", name: "Name A-Z" },
            { ID: "user.firstname DESC", name: "Name Z-A" },
            { ID: "user.created DESC", name: "Created Descending" },
            { ID: "user.created", name: "Created Ascending" },
            { ID: "user.lastLogin DESC", name: "Last Login Descending" },
            { ID: "user.lastLogin", name: "Last Login Ascending" },
            { ID: "user.status", name: "Status Active" },
            { ID: "user.status DESC", name: "Status Inactive" },
        ],
    };

    constructor(
        private apiService: ApiService,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService
    ) { }

    loadUsers(params) {
        const dataToSend = {
            params: params,
            user: this.store.selectForLocal("user"),
        };
        return this.http
            .post(this.apiService.path() + "users", dataToSend)
            .subscribe((data: any) => {
                this.store.set("users", data.data);
            });
    }


    loadFeedback(cardNumber) {
        return this.http
            .get(this.env.apiV2Path + "feedback/user/responses/" + cardNumber)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    searchFeedback(params) {
        const dataToSend = {
            params: params,
        };
        return this.http
            .post(this.env.apiV2Path + "feedback/user/responses", dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }


    loadTransactions(params) {
        const dataToSend = {
            params: params,
        };
        return this.http
            .post(this.env.apiV2Path + "users/en/transactions", dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    loadTransactionItems(id) {
        return this.http
            .get(this.env.apiV2Path + "users/en/transactionItems/" + id)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    create(user) {
        const dataToSend = {
            adminUser: this.store.selectForLocal("user"),
            user: user,
        };
        return this.http
            .post(this.apiService.path() + "users/create", dataToSend)
            .pipe(
                map((data: any) => {
                    if (data.status === 200) {
                        const u = this.store.selectForLocal("users");
                        user.id = data.data.id;
                        u.push(user);
                        this.store.set("users", u);
                    }
                    return data;
                })
            );
    }

    update(user) {
        const u = this.store.selectForLocal("users");
        for (let i = 0; i < u.length; i++) {
            if (u[i].id === user.id) {
                u[i] = user;
            }
        }
        this.store.set("users", u);

        const dataToSend = {
            adminUser: this.store.selectForLocal("user"),
            user: user,
        };
        return this.http
            .post(this.apiService.path() + "users/update", dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    updatePassword(data) {

        const dataToSend = {
            data,
        };
        return this.http
            .post(this.apiService.path() + "users/updatePassword", dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }


    loadPermissions() {
        return this.http.get(this.apiService.path() + "users/permissions").pipe(
            map((data: any) => {
                return data.data;
            })
        );
    }

    delete(user) {
        const dataToSend = {
            user
        }
        return this.http.post(this.apiService.path() + "users/delete", dataToSend).pipe(
            map((data: any) => {
                return data.data;
            })
        );
    }


    searchUsers(params) {
        const dataToSend = {
            params
        }
        return this.http.post(environment.apiV2Path + "users/search", dataToSend).pipe(
            map((data: any) => {
                return data.data;
            })
        );
    }

}
