import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VoucherService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
    ) {
    }


    getVoucher(id) {
        return this.http.get(this.env.apiV2Path + 'voucher/' + id)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getVouchers() {
        return this.http.get(this.env.apiV2Path + 'voucher')
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    search(params) {
        const dataToSend = {
            params,
        }
        return this.http.post(this.env.apiV2Path + 'voucher/search', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    assignVoucherToDataSelection(params, voucher) {
        const dataToSend = {
            params,
            voucher
        }
        return this.http.post(this.env.apiV2Path + 'voucher/assign/data', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    create(voucher) {
        const dataToSend = {
            voucher
        }
        return this.http.post(this.env.apiV2Path + 'voucher', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    update(voucher) {
        const dataToSend = {
            voucher
        }
        return this.http.put(this.env.apiV2Path + 'voucher', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    delete(voucher) {
        return this.http.delete(this.env.apiV2Path + 'voucher/' + voucher.id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    searchVoucherUsers(params) {
        const dataToSend = {
            params
        }

        return this.http.post(this.env.apiV2Path + 'voucher/users/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    deleteUser(userId, voucherId) {
        return this.http.delete(this.env.apiV2Path + 'voucher/user/' + userId + '/' + voucherId)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    deleteAllUsers(voucherId) {
        return this.http.delete(this.env.apiV2Path + 'voucher/users/' + voucherId)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    getVoucherPromotions(params) {
        const dataToSend = {
            params
        }

        return this.http.post(this.env.apiV2Path + 'packages/voucher', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    getUserVouchers(userId, hotelId, roomId, checkInDay) {
        return this.http.get(environment.apiV2Path + 'voucher/public/' + userId + '/' + hotelId + '/' + roomId + '/' + checkInDay).pipe(map((data: any) => {

            this.store.set('vouchersAvailable', data.data);
            // is a selected voucher still valid?
            const voucherSelected = this.store.selectForLocal('voucherSelected');

            let voucherSelectedFound = false;
            if (voucherSelected) {
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].id === voucherSelected.id) {
                        voucherSelectedFound = true;
                    }
                }
            }

            return data.data;
        }));
    }

}
