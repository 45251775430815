<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/hotels']">Room Types</a>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary" (click)="add()">Add Room Type</button>
        </div>
    </div>
</mat-card>

<mat-card class="no-records" *ngIf="!(roomTypes$ | async).length">
    No room types listed
</mat-card>

<ng-container *ngIf="(roomTypes$ | async).length">
    
    <div class="margin-top-20">
        
        
        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Room Type</th>
                <th>Created</th>
                <th>WelcomeID</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let r of (roomTypes$ | async)">
                <td>{{r.name}}</td>
                <td> {{r.created | date: 'dd MMM yyyy'}}</td>
                <td>{{r.welcomeID}}</td>
                <td class="text-right">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="edit(r)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="delete(r)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </td>
            </tr>
            </tbody>
        </table>
    
    </div>

</ng-container>


