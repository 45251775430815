import {Component, OnInit} from '@angular/core';
import {RateGroupService} from '../../services/rate-group.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HotelService} from '../../services/hotel.service';
import {GrowlerService} from '../../services/growler.service';
import {PackageService} from '../../services/package.service';
import {RoomService} from '../../services/room.service';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
    selector: 'app-rate-group-edit',
    templateUrl: './rate-group-edit.component.html',
    styleUrls: ['./rate-group-edit.component.css']
})
export class RateGroupEditComponent implements OnInit {

    newRateGroup = {
        isNew: true,
        Id: '',
        name: '',
        hotel: {
            Id: '',
            name: '',
        },
        minShift: 0,
        maxShift: 0,
        status: 1,
        occupancyPercentages: [],
        uplifts: [],
        rooms: [],
        dates: [],
    };
    calendarOpen = false;
    dateSelected;
    dates = [];

    occupancyPercentagesConfirmed = false;

    Id = '';
    rateGroup;
    hotels = [];
    occupancyPercentages = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95];
    uplifts = [
        {label: 'On the day', minDays: 0, maxDays: 0, uplift: 100},
        {label: '1-3 days', minDays: 1, maxDays: 3, uplift: 100},
        {label: '4-7 days', minDays: 4, maxDays: 7, uplift: 100},
        {label: '8-12 days', minDays: 8, maxDays: 12, uplift: 100},
        {label: '13-20 days', minDays: 13, maxDays: 20, uplift: 100},
        {label: '21-30 days', minDays: 21, maxDays: 30, uplift: 100},
        {label: '31-45 days', minDays: 31, maxDays: 45, uplift: 100},
        {label: '46-60 days', minDays: 46, maxDays: 60, uplift: 100},
    ];
    rooms = [];
    blankDates = [];

    constructor(
        private rateGroupService: RateGroupService,
        private hotelService: HotelService,
        private router: Router,
        private route: ActivatedRoute,
        private growlerService: GrowlerService,
        private packageService: PackageService,
        private roomService: RoomService,
    ) {
    }

    ngOnInit() {
        this.getHotels();

        if (this.route.snapshot.params['Id'] !== 'new') {
            this.Id = this.route.snapshot.params['Id'];
            this.get();
            this.occupancyPercentagesConfirmed = true;
        } else {
            this.rateGroup = JSON.parse(JSON.stringify(this.newRateGroup));
        }
    }

    getRooms(h) {
        this.roomService.byHotelID(h).subscribe(data => {
            this.rooms = data.data;
        });
    }
    updateRooms() {
        this.roomService.byHotelID(this.rateGroup.hotel.Id).subscribe(data => {
            this.rateGroup.rooms = data.data;
        });
    }
    addRoomsToPackage() {
        this.rateGroup.rooms = this.rooms;
        this.rateGroup.rooms.forEach(room => {
            room.selected = false;
        });
    }
    selectAllRooms() {
        if (this.rateGroup.rooms[0].selected) {
            this.rateGroup.rooms.forEach(room => {
                room.selected = false;
            });
        } else {
            this.rateGroup.rooms.forEach(room => {
                room.selected = true;
            });
        }
    }



    toggleOccupancyPercentage(o) {
        console.log(o);
        if (this.rateGroup.occupancyPercentages.indexOf(o) >= 0) {
            this.rateGroup.occupancyPercentages.splice(this.rateGroup.occupancyPercentages.indexOf(o), 1);
        } else {
            this.rateGroup.occupancyPercentages.push(o);
        }
        this.rateGroup.occupancyPercentages.sort()
        console.log(this.rateGroup.occupancyPercentages);
    }

    occupancyPercentageSelected(o) {
        if (this.rateGroup.occupancyPercentages.indexOf(o) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    occupancyPercentagesConfirm(v) {
        this.occupancyPercentagesConfirmed = v;
        if (v) {
            for (let op = 0; op < this.rateGroup.occupancyPercentages.length; op++) {
                const newUplift = {
                    occupancyLevel: this.rateGroup.occupancyPercentages[op],
                    dayGroups: [],
                };
                for (let u = 0; u < this.uplifts.length; u++) {
                    newUplift.dayGroups.push(this.uplifts[u]);
                }
                this.rateGroup.uplifts.push(JSON.parse(JSON.stringify(newUplift)));
            }

        } else {
            this.rateGroup.occupancyPercentages = [];
            this.rateGroup.uplifts = [];
        }
        console.log(this.rateGroup);
    }



    get() {
        this.rateGroupService.pricingGroup(this.Id).subscribe(data => {
            this.rateGroup = data.data;
            this.getRooms(data.data.hotel.Id);
        });
    }

    getHotels() {
        this.hotelService.all().subscribe(data => {
            this.hotels = data.data;
        });
    }


    save() {
        if (this.route.snapshot.params['Id'] !== 'new') {
            this.rateGroupService.PricingGroupSave(this.rateGroup).subscribe(data => {
                if (data.errors.length) {
                    this.growlerService.error(data.errors);
                } else {
                    this.growlerService.notification(['Rate group updated']);
                }

            });
        } else {
            this.rateGroupService.PricingGroupCreate(this.rateGroup).subscribe(data => {
                if (data.errors.length) {
                    this.growlerService.error(data.errors);
                } else {
                    this.growlerService.notification(['Rate group created']);
                }
            });
        }

    }

    back() {
        this.router.navigate(['rategroups']);
    }
    

    dateWasSelected(e) {
        if (this.rateGroup.dates.indexOf(e.formatted) > -1) {
            this.rateGroup.dates.splice(this.rateGroup.dates.indexOf(e.formatted), 1);
        } else {
            this.rateGroup.dates.push(e.formatted);
        }
    }
    removeDate(i) {
        this.rateGroup.dates.splice(i, 1);
    }
}
