import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GiftCardService } from 'src/app/services/gift-card.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-giftcard-create',
    templateUrl: './giftcard-create.component.html',
    styleUrls: ['./giftcard-create.component.css']
})
export class GiftcardCreateComponent implements OnInit {
    @Output() complete = new EventEmitter();

    hotels = [
        { id: 'HOTEL001', name: 'Byfords', productCode: 'I8784501', emailKey: 'ced91b1f-eeca-4fb2-bbdb-172a510d7ad0', headerImg: 'header-byfords.jpg', logoImg: 'logo-byfords.png' },
        { id: 'HOTEL002', name: 'Pigs', productCode: 'I8784901', emailKey: '24a17817-b598-40a7-9f8a-8ef12b8609d0', headerImg: 'header-pigs.jpg', logoImg: 'logo-pigs.png' },
        { id: 'HOTEL003', name: 'The Assembly House', productCode: 'I8784601', emailKey: 'c8254e71-24f7-411a-8cba-cb275165bdd0', headerImg: 'header-ah.jpg', logoImg: 'logo-ah.png' },
        { id: 'HOTEL005', name: 'Ffolkes', productCode: 'I8784801', emailKey: 'e5582cc8-bd9d-4c57-b3d1-876e443e8de6', headerImg: 'header-ffolkes.jpg', logoImg: 'logo-ffolkes.png' },
    ]

    product = {
        name: '',
        cardValue: 0,
        emailText: '<p>Well aren\'t you just awesome! You\'re one of our top spenders over the past 12 months so as a reward we decided you deserve a little treat on us</p>',
        pdfText: 'Well aren\'t you just awesome! You\'re one of our top spenders over the past 12 months so as a reward we decided you deserve a little treat on us',
        emailSubject: 'Hey, it\'s reward time',
        hotel: undefined,
    }

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };
    constructor(
        private giftCardService: GiftCardService,
        private store: Store,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
    }

    confirm() {
        this.giftCardService.createGiftCardProduct(this.product).subscribe(data => {
            console.log(data);
            this.complete.emit({ action: true, productId: data });
        })
    }
    cancel() {
        this.complete.emit({ action: false });
    }

}
