<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            Giftcards
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card class="margin-top-10">
    <div class="row">
        <div class="col-md-4">
                <mat-form-field class="text-left" style="width: 100%">
                    <input matInput [(ngModel)]="params.searchText" (keyup)="getCards()" placeholder="Freetext search">
                </mat-form-field>

        </div>
            <div class="col-md-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.hotelId"
                        (selectionChange)="getCards()"
                        placeholder="Brands">
                        <mat-option [value]="undefined">All Brands</mat-option>
                        <mat-option
                            [value]="sy.id"
                            *ngFor="let sy of (hotels$ | async)">
                            {{sy.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2" *ngIf="(user$ | async)?.permissions['viewPromoGiftCards']">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.shopifyOnly"
                        (selectionChange)="getCards()"
                        placeholder="Source">
                        <mat-option [value]="-1">All Sources</mat-option>
                        <mat-option [value]="1">Shopify</mat-option>
                        <mat-option [value]="0">Marketing Promo</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.campaign"
                        (selectionChange)="getCards()"
                        placeholder="Campaign">
                        <mat-option [value]="''">Non campaign</mat-option>
                        <mat-option [value]="'WB'">Welcome Back</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.cardStatus"
                        (selectionChange)="getCards()"
                        placeholder="Card Status">
                        <mat-option [value]="''">All Status</mat-option>
                        <mat-option [value]="'Normal'">Normal</mat-option>
                        <mat-option [value]="'Inactive'">Inactive</mat-option>
                        <mat-option [value]="'Expired'">Expired</mat-option>
                        <mat-option [value]="'Blocked'">Blocked</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


        </div>
        <div class="row">
            <div class="col-md-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.hasSpend"
                        (selectionChange)="getCards()"
                        placeholder="Spend status">
                        <mat-option [value]="-1">All Spend Status</mat-option>
                        <mat-option [value]="1">Has Spent</mat-option>
                        <mat-option [value]="0">Not Spent</mat-option>
                        <mat-option [value]="2">Zero Balance</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="getCards()" [(ngModel)]="startDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Purchased from">
                <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field style="display: block">
                <input type="search" matInput (dateChange)="getCards()" [(ngModel)]="endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Purchased to">
                <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="getCards()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div>
</mat-card>

<div class="row margin-top-10">
    <div class="col-md-3" *ngFor="let total of totals">
        <mat-card class=" text-center">
            <p class="mb-1">{{total.brand}}</p>
            <p class="mb-1">{{total.totalCards}} Cards</p>
            <p class="mb-1">{{total.totalSpend | currency: 'GBP'}} Purchased</p>
            <p class="mb-1">{{total.totalSpent | currency: 'GBP'}} Spent</p>
        </mat-card>

    </div>
</div>


    <div class="row">


        <div class="col-md-12">
            <mat-card class="margin-top-10">
                <app-pagination [params]="params" [term]="'Cards'" (update)="paginate($event)"></app-pagination>
            </mat-card>


            <mat-card class="margin-top-10">
                <div *ngIf="cards.length">
                    <table class="table example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>Created</th>
                            <th>Contact</th>
                            <th>Card Number/Type</th>
                            <th>Value</th>
                            <th>Balance</th>
                            <th>Status</th>
                            <th>Order No</th>
                            <!-- <th>Order Line</th> -->
                            <th>Completed</th>
                            <th>Email Sent</th>
                            <th>Resend Email</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let card of cards; let i = index">
                            <td style="width: 175px;">
                                {{card.createdAt | date:'dd MMM yyyy @ HH:mm'}}
                                <br />
                                <span *ngIf="!card.campaign && card.shopifyId" class="text-warning">
                                    <a style="color: unset;" [href]="'https://admin.shopify.com/store/norfolkpassport/orders/' + card.shopifyId" target="_blank">
                                        Shopify {{card.shopifyId}}
                                    </a>
                                </span>
                                <span *ngIf="card.campaign" class="text-warning">{{card.campaign}}</span>
                                <span *ngIf="!card.campaign && !card.shopifyId" class="text-warning">Promo</span>

                            </td>
                            <td>
                                {{card.firstname}} {{card.surname}}
                                <br />
                                {{card.email}}
                            </td>
                            <td>{{card.cardNumber}}
                                <br />
                                {{card.brand}} {{card.cardName}}
                            </td>
                            <td>{{card.productValue | currency: 'GBP'}}</td>
                            <td>{{card.balance | currency: 'GBP'}}</td>
                            <td>
                                <span *ngIf="card.cardStatus !== 'Normal'" class="text-danger">{{card.cardStatus}}</span>
                                <span *ngIf="card.cardStatus === 'Normal'" class="text-success">{{card.cardStatus}}</span>
                            </td>
                            <td>{{card.orderId}}</td>
                            <!-- <td>{{card.orderLineId}}</td> -->
                            <td>
                                <i class="fa fa-check text-success" *ngIf="card.completed"></i>
                            </td>
                            <td>
                                <i class="fa fa-check text-success" *ngIf="card.emailSent"></i>
                            </td>
                            <td>
                                <button mat-raised-button color="warn" (click)="resend(card)">Resend</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>

<mat-card class="margin-top-10">
    <app-pagination [params]="params" [term]="'Cards'" (update)="paginate($event)"></app-pagination>
</mat-card>

<app-confirmation *ngIf="dataToResend" [data]="confirmationData" (complete)="resendAction($event)"></app-confirmation>


