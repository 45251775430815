import { Component, HostListener, OnInit } from '@angular/core';
import { OverlayService } from '../../services/overlay.service';
import { Store } from '../../store';
import { Router } from '@angular/router';
import { PackageService } from '../../services/package.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-packages-list',
    templateUrl: './packages-list.component.html',
    styleUrls: ['./packages-list.component.css']
})
export class PackagesListComponent implements OnInit {

    data$ = this.store.select<any[]>('packages');
    params;
    overlays$ = this.store.select<any>('overlays');
    totals = {
        totalBooked: 0,
        totalBookedValue: 0
    };
    hotels = [];

    constructor(
        private packageService: PackageService,
        private router: Router,
        private store: Store,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
        this.params = this.packageService.defaultParams;
        this.search();
    }
    search() {
        this.packageService.search(this.params).subscribe(data => {
            console.log(data)
            this.hotels = data.hotels;
            this.hotels[0].show = true;
            this.totals = data.totals;
            // this.data = data.data;
            // this.params.pages = Math.ceil(data.params.totalRecords / this.params.limit);
            // this.params.totalRecords = data.params.totalRecords;
            // this.params.pageArray = [];
            // for (let i = 0; i < this.params.pages; i++) {
            //     this.params.pageArray.push(i);
            // }
        });
    }
    edit(Id) {
        this.router.navigate(['/packages/edit', Id]);
    }
    delete(p) {
        this.store.set('packageToDelete', p);
        this.overlayService.open('packageDelete');
        this.store.set('asideOpen', true);
    }
    updateActive(p) {
        if (p.active === 0) {
            p.active = 1;
        } else {
            p.active = 0;
        }
        this.packageService.updateActive(p).subscribe();
    }
    updateParams() {
        this.packageService.updateParams(this.params);
    }
    resetParams() {
        this.params = this.packageService.resetParams();
    }

    packageDeleted(e) {
        this.search();
    }

    toggleHotel(h) {
        if (!h.show) {
            h.show = true;
        } else {
            h.show = false;
        }
    }

}
