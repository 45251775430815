<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/rooms']">Rooms</a>
            <mat-icon>arrow_forward_ios</mat-icon>
            Edit Room
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card>
    
    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>
    <hr />
    
    <mat-tab-group *ngIf="room">
        <mat-tab label="Room Details">
            <div class="container margin-top-30">
                <app-room-overview [room]="room"></app-room-overview>
            </div>
        </mat-tab>
        
        
        <mat-tab label="Room Gallery">
            <div class="container margin-top-30">
                <div class="page-header">
                    <h2 class="mat-title">Room Images :
                        <small>Upload images and drag into the desired order</small>
                    </h2>
                </div>
                
                <app-images [allowSelection]="false" [type]="'room'" [ownerID]="this.room.id"></app-images>
            </div>
        </mat-tab>
    
        <mat-tab label="Room Supplements">
            <div class="container margin-top-30">
                <app-room-supplement [room]="room"></app-room-supplement>
            </div>
        </mat-tab>

    </mat-tab-group>
    
    
    <!--<app-chart-assessment-scores></app-chart-assessment-scores>-->
    
    
    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>

</mat-card>





