<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>
    
    <h2 class="mat-title">Edit Room Type</h2>
    
    <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="roomType.name" placeholder="Description">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="roomType.welcomeID" placeholder="Welcome ID">
    </mat-form-field>
    
    <div class="margin-top-20 text-right">
        <button mat-raised-button color="warn" (click)="save()">Save</button>
    </div>

</div>
