import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-event-sku-edit',
    templateUrl: './event-sku-edit.component.html',
    styleUrls: ['./event-sku-edit.component.css']
})
export class EventSkuEditComponent implements OnInit {
    @Input() data;
    @Output() complete = new EventEmitter();

    sku;

    constructor() { }

    ngOnInit() {
        this.sku = JSON.parse(JSON.stringify(this.data));
    }

    close() {
        this.complete.emit(
            { action: true }
        );

    }
    save() {
        this.complete.emit(
            { action: true, data: this.sku }
        );
    }
}
