import {Component, Input, OnInit} from '@angular/core';
import {OverlayService} from '../../services/overlay.service';
import {Store} from '../../store';
import {HotelService} from '../../services/hotel.service';

@Component({
  selector: 'app-hotel-features-group',
  templateUrl: './hotel-features-group.component.html',
  styleUrls: ['./hotel-features-group.component.css']
})
export class HotelFeaturesGroupComponent implements OnInit {

    featureGroup: any;

    constructor(
        private hotelService: HotelService,
        private overlayService: OverlayService,
        private store: Store,
    ) {
    }

    ngOnInit() {
        this.featureGroup = this.store.selectForLocal('featureGroup');
    }
    save() {
        this.hotelService.saveFeatureGroup(this.featureGroup).subscribe(data => {
            this.close();
        });
    }
    close() {
        this.store.set('featureGroup', undefined);
        this.overlayService.closeAll();
    }
}
