import { Component, OnInit, Input } from '@angular/core';
import { OverlayService } from '../../services/overlay.service';
import { GrowlerService } from '../../services/growler.service';
import { Store } from '../../store';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
    @Input() user;

    constructor(
        private userService: UserService,
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
    }

    submit() {
        this.userService.update(this.user).subscribe(data => {
            if (data.status === 500) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['User Updated']);
                this.close();
            }

        });
    }

    checkSubPerms(e, p) {
        console.log(e)
        console.log(p)

        if (!p.allow) {
            for (let i = 0; i < p.permissions.length; i++) {
                p.permissions[i].allow = false;
            }
            return;
        }

        for (let i = 0; i < p.permissions.length; i++) {
            p.permissions[i].allow = true;
        }
        return;
    }

    checkParentPerms(e, p, pi) {
        let hasNonSelected = true;
        for (let i = 0; i < p.permissions.length; i++) {

            if (p.permissions[i].allow) {
                hasNonSelected = false;
            }
        }
        if (hasNonSelected) {
            p.allow = false;
        } else {
            p.allow = true;
        }
    }

    close() {
        this.overlayService.closeAll();
    }
}
