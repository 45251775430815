<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/products-groups']">Room add-ons</a>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary" class="hover" [routerLink]="['/','product-groups', '', 'new']">Add Group</button>
        </div>
    </div>
</mat-card>

<div class="mb-4 mt-1" *ngIf="hotels.length">

    <button
        class="mr-2 mb-2"
        *ngFor="let hotel of hotels"
        mat-raised-button
        [color]="isHotelSelected(hotel.id) > -1 ? 'primary' : 'grey'"
        color="primary"
        (click)="toggleHotel(hotel.id)">
        {{hotel.name}}
    </button>

</div>

<mat-card class="no-records" *ngIf="!(productGroups$ | async).length">
    No Room Extras
</mat-card>

<ng-container *ngIf="(productGroups$ | async).length">



    <div class="margin-top-20">
        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
            <tr>
                <th>Image</th>
                <th>Group Name</th>
                <th>Brands</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let pg of (productGroups$ | async)">
                <td class="hover" (click)="groupUpdate(pg)">
                <img *ngIf="!pg.logo.URL" src="/assets/images/nia.png" class="img-fluid" style="max-height: 30px" />
                <img *ngIf="pg.logo.URL" [src]="pg.logo.URL" class="img-fluid" style="max-height: 30px" />

                </td>
                <td class="hover" (click)="groupUpdate(pg)">{{pg.name}}</td>
                <td class="hover" (click)="groupUpdate(pg)">
                    <span *ngFor="let h of pg.hotels; let i = index">
                        <span *ngIf="i">, </span>{{h.name}}
                    </span>
                </td>

                <td class="text-right">
                    <!-- <button mat-flat-button color="primary" (click)="viewProducts(pg)">View extras</button> -->
                    <a class="hover" [routerLink]="['/','product-groups', pg.ID, 'edit']">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a class="hover" (click)="groupDelete(pg)">
                        <mat-icon>delete</mat-icon>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

</ng-container>


