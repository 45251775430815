<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Assign Gift Card</h5>
                <a class="close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">

                <ng-container *ngIf="!choiceMade">
                    <div class="text-center">
                        <p class="mb-4">How you would like to proceed?</p>

                        <button class="btn btn-success btn-width-50 mt-3 mb-3" (click)="create()">
                            Create new giftcard
                        </button>
                        <hr />
                        <button class="btn btn-success btn-width-50 mt-3 mb-3" (click)="selectExisting()">
                            Select from existing giftcards
                        </button>

                    </div>

                </ng-container>

                <ng-container *ngIf="choiceMade">

                    <p class="text-left">Clicking apply will apply the selected gift card to {{recordsSelected}} user records</p>

                    <mat-form-field appearance="outline" class="example-full-width">
                      <mat-label>Select gift card</mat-label>
                      <mat-select [(ngModel)]="giftCardSelected" style="width: 100%;">
                        <mat-option [value]="undefined">Select gift card</mat-option>
                        <mat-option [value]="v" *ngFor="let v of giftCards">{{v.brand}} - {{v.name}}</mat-option>
                      </mat-select>
                  </mat-form-field>

                  <mat-form-field class="example-full-width">
                    <input type="search" matInput [(ngModel)]="giftCardExpiry" (click)="datePicker.open()" [matDatepicker]="datePicker" placeholder="Expiry Date">
                    <mat-datepicker #datePicker></mat-datepicker>
                    <!-- <input matInput [(ngModel)]="giftCardExpiry" placeholder="Expiry Date"> -->
                </mat-form-field>

                <div class="text-center" *ngIf="giftCardSelected">
                    <button class="btn btn-success btn-width-50 mt-3 mb-3" (click)="confirm()">
                        Apply Selection
                    </button>
                </div>

                <hr />
                <p style="font-size: 11px; font-style: italic;">Click <a (click)="create()" class="hover text-warning">here</a> to create a new giftcard</p>
                </ng-container>



            <!-- <div class="mt-3 mb-3" *ngIf="!giftCardSelected">
                <p class="mb-3">Click 'Create Card' to create a new digital gift card which can be selected and assigned</p>
                <button class="btn btn-primary btn-width-50 mt-3 mb-3" (click)="create()">
                    Create New Card
                </button>

            </div> -->




            </div>
        </div>
    </div>
  </div>

  <div class="modal-backdrop fade show modal-slide-show"></div>

