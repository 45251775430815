<ng-container *ngIf="booking">
	<mat-card class="breadcrumb">
		<div class="row">
			<div class="col-md-10">
				<!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
				<!--<mat-icon>arrow_forward_ios</mat-icon>-->
				<a [routerLink]="['/bookings']">Event tickets</a>
				<mat-icon>arrow_forward_ios</mat-icon>
				<a>View Tickets</a>
			</div>
			<div class="col-md-2 text-right">
				<button id="pullright" mat-raised-button color="warn" routerLink="/events/bookings">Back</button>
			</div>
		</div>
	</mat-card>

	<!-- <mat-card class="breadcrumb">
		<div class="row">
			<div class="col-md-10">
				<button *ngIf="booking.idPrev !== ''" mat-raised-button color="warn"
						(click)="getBooking(booking.idPrev)">Previous
				</button>
			</div>
			<div class="col-md-2 text-right">
				<button *ngIf="booking.idNext !== ''" mat-raised-button color="warn"
						(click)="getBooking(booking.idNext)">Next
				</button>
			</div>
		</div>
	</mat-card> -->


	<div class="row">
		<div class="col-lg-6">
			<mat-card class="margin-bottom-30">
				<mat-card-header>
					<mat-card-title>Purchase Details</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<tbody>
						<tr>
							<th style="width: 170px;">Created</th>
							<td>{{booking.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
						</tr>
						<tr>
							<th style="width: 170px;">Event Name</th>
							<td>{{booking.eventName}}</td>
						</tr>

                        <tr>
							<th>Event Starts</th>
							<td>{{booking.stDate | date: 'dd/MM/yyyy'}} {{booking.stTime}}</td>
						</tr>
						<tr>
							<th>Event Ends</th>
							<td>{{booking.endDate | date: 'dd/MM/yyyy'}} {{booking.endTime}}</td>
						</tr>

                        <tr>
							<td>Passport Discount</td>
							<td>{{booking.passportDiscount | currency : 'GBP'}}</td>
						</tr>

                        <tr>
							<td>Total Cost</td>
							<td>{{booking.gross | currency : 'GBP'}}</td>
						</tr>

						</tbody>
					</table>

				</mat-card-content>
			</mat-card>



			<mat-card class="margin-bottom-30">
				<mat-card-header>
					<mat-card-title>Tickets</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<thead>
						<tr>
							<th>Event</th>
							<th>Tickets</th>
							<th class="text-right">Cost</th>
							<th class="text-right">Discount</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let i of booking.skus">
							<td>
                                {{booking.eventName}}
                                <span *ngIf="i.skuName"> ({{i.skuName}})</span>
                            </td>
							<td>{{i.qty}}</td>
							<td class="text-right">{{i.gross | currency : 'GBP'}}</td>
							<td class="text-right">{{i.passportDiscount | currency : 'GBP'}}</td>
						</tr>
						</tbody>
					</table>
				</mat-card-content>
			</mat-card>


		</div>
		<div class="col-lg-6">
			<mat-card>
				<mat-card-header>
					<mat-card-title>Customer Details</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<tbody>
                            <tr>
                                <th style="width: 170px;">Card number</th>
                                <td>{{booking.cardNumber}}</td>
                            </tr>
                            <tr>
							<th style="width: 170px;">Name</th>
							<td>{{booking.firstname}} {{booking.surname}}</td>
						</tr>
						<tr>
							<th>Email Address</th>
							<td>{{booking.username}}</td>
						</tr>
						<tr>
							<th>Telephone</th>
							<td>{{booking.telephone}}</td>
						</tr>
						<tr>
							<th>Address</th>
							<td style="padding: 15px ">
								{{booking.add1}}
								<br/>
								<span *ngIf="booking.add2">{{booking.add2}}</span>
								<br/>
								{{booking.town}}
								<br/>
								{{booking.postcode}}
							</td>
						</tr>
						</tbody>
					</table>


				</mat-card-content>
			</mat-card>

		</div>
	</div>

</ng-container>

