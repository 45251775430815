import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Store} from '../store';
import {GrowlerService} from './growler.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrebookService {

    defaultParams = {
        searchTerm: '',
        name: '',
        hotelID: '',
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'prebooking.created DESC',
        sorts: [
            {ID: 'prebooking.created DESC', name: 'Recent First'},
            {ID: 'prebooking.created ASC', name: 'Recent Last'},
            {ID: 'hotel.name ASC', name: 'Hotel Name A-Z'},
            {ID: 'hotel.name DESC', name: 'Hotel Name Z-A'},
        ],
    };

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) { }

    updateParams(params) {
        this.store.set('preBookedParams', params);
        this.searchPrebooked();
    }
    resetParams() {
        this.store.set('preBookedParams', JSON.parse(JSON.stringify(this.defaultParams)));
        this.searchPrebooked();
    }

    searchPrebooked() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('preBookedParams')
        };
        return this.http.post(this.apiService.path() + 'prebookings/search', dataToSend).subscribe((data: any) => {
            this.store.set('prebookings', data.data);
            const p = this.store.selectForLocal('preBookedParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (let i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            this.store.set('preBookedParams', p);
        });
    }
    togglePrebookedActioned(booking) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            booking: booking,
        };
        return this.http.post(this.apiService.path() + 'prebookings/actioned', dataToSend).subscribe((data: any) => {
        });

    }


    groupsGet() {
        const dataToSend = {
            data: {}
        };
        return this.http.post(this.apiService.path() + '/prebook/groups', dataToSend).subscribe((data: any) => {
            this.store.set('productGroups', data.data);
        });
    }
    groupGetByHotel(id) {
        return this.http.get(this.apiService.path() + '/prebook/group/hotel/' + id).pipe(map((data: any) => {
            return data.data;
        }));
    }

    groupAdd(product_group) {
        const dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/prebook/group/add', dataToSend).pipe(map((data: any) => {
            this.groupsGet();
            return data.data;
        }));
    }
    groupUpdate(product_group) {
        const dataToSend = {
            product_group: product_group
        };
        return this.http.post(this.apiService.path() + '/prebook/group/update', dataToSend).pipe(map((data: any) => {
            this.groupsGet();
            return data.data;
        }));
    }
    groupDelete(product_group) {
        const dataToSend = {
            id: product_group.ID
        };
        return this.http.post(this.apiService.path() + '/prebook/group/delete', dataToSend).pipe(map((data: any) => {
            this.groupsGet();
            return data.data;
        }));
    }
    itemAdd(item) {
        const dataToSend = {
            item: item
        };
        return this.http.post(this.apiService.path() + '/prebook/item/add', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    itemUpdate(item) {
        const dataToSend = {
            item: item
        };
        return this.http.post(this.apiService.path() + '/prebook/item/update', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    itemDelete(item) {
        const dataToSend = {
            id: item.id
        };
        return this.http.post(this.apiService.path() + '/prebook/item/delete', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }

    updateOverview(pb) {
        return this.http.post(this.apiService.path() + '/prebook/update', pb).pipe(map((data: any) => {
            return data.data;
        }));
    }

    updateSortOrder(group) {
        const dataToSend = {
            group
        };
        return this.http.post(this.apiService.path() + '/prebook/item/sort', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
}

