import { Injectable } from '@angular/core';
import { Store } from '../store';

@Injectable({
    providedIn: 'root'
})
export class BasketTotalsService {

    constructor(
        private store: Store,
    ) { }


    calculateItemTotals(basket, item, user) {
        let isGuest = 1;
        console.log('item.checkoutTotals.deposit.checkout', item.checkoutTotals.deposit.checkout, item.depositFixed)
        console.log(item.checkoutTotals.discount)
        if (user) {
            isGuest = user.isGuest;
        }
        if (!item.giftCards) {
            item.giftCards = [];
        }

        item.checkoutTotals = JSON.parse(JSON.stringify(this.store.selectForLocal('checkoutTotals')));
        console.log(item.totals)
        item.checkoutTotals.deposit.originalRoom = +item.totals.deposit;
        // item.checkoutTotals.paid.card = 0;
        // item.checkoutTotals.paid.giftCard = 0;
        // item.checkoutTotals.paid.voucher = 0;
        // item.checkoutTotals.paid.total = 0;

        let total = 0;
        let discount = 0;
        let addOnsTotal = 0;
        let addOnsDiscount = 0;
        let treatmentTotal = 0;
        let treatmentDiscount = 0;
        let eventsDiscount = 0;
        let discountPassport = 0;
        let discountPackage = 0;
        let discountFixedPromo = 0;
        let discountVoucher = 0;
        let discountCS = 0;
        let eventsTotal = 0;

        let supplements = {
            child: 0,
            dog: 0,
            bubbleAndSqueak: 0,
            guest: 0,
            singleNight: 0,
        };

        total = total + +item.booking.roomPricing.roomCostForStay;
        item.checkoutTotals.costs.room = +item.booking.roomPricing.roomCostForStay;

        if (item.userId !== '' && isGuest === 0 && !item.packagesSelected.length) {

            // lets not apply discount if it's xmas
            let canApplyNP = true;

            if (item.availabilitySearch.checkInDay === 18985) {
                if (
                    item.availabilitySearch.room.hotel.id === 'HOTEL001'
                    || item.availabilitySearch.room.hotel.id === 'HOTEL002'
                    || item.availabilitySearch.room.hotel.id === 'HOTEL003'
                ) {
                    canApplyNP = false;
                }
            }

            if (canApplyNP) {
                if (!item.booking.planSelected) {
                    discountPassport = +discountPassport + (+item.booking.roomPricing.roomCostForStay / 10);
                    item.checkoutTotals.discount.passport = (+item.booking.roomPricing.roomCostForStay / 10);
                }
                if (item.booking.planSelected && item.booking.planSelected.allowPassport) {
                    discountPassport = +discountPassport + (+item.booking.roomPricing.roomCostForStay / 10);
                    item.checkoutTotals.discount.passport = (+item.booking.roomPricing.roomCostForStay / 10);
                }
            }
        }


        item.checkoutTotals.costs.supplements.child = +item.booking.roomPricing.childSupplement;
        item.checkoutTotals.costs.supplements.dog = +item.booking.roomPricing.dogSupplement;
        item.checkoutTotals.costs.supplements.bubbleAndSqueak = +item.booking.roomPricing.bubbleAndSqueakSupplement;
        item.checkoutTotals.costs.supplements.guest = +item.booking.roomPricing.guestSupplement;
        item.checkoutTotals.costs.supplements.singleNight = +item.booking.roomPricing.singleNightSupplement;

        item.checkoutTotals.costs.supplements.total =
            + +item.checkoutTotals.costs.supplements.child
            + +item.checkoutTotals.costs.supplements.dog
            + +item.checkoutTotals.costs.supplements.bubbleAndSqueak
            + +item.checkoutTotals.costs.supplements.guest
            + +item.checkoutTotals.costs.supplements.singleNight

        item.checkoutTotals.costs.room = +item.checkoutTotals.costs.room - +item.checkoutTotals.costs.supplements.total;

        supplements.child = +supplements.child + item.booking.roomPricing.childSupplement;
        supplements.dog = +supplements.dog + item.booking.roomPricing.dogSupplement;
        supplements.bubbleAndSqueak = +supplements.bubbleAndSqueak + item.booking.roomPricing.bubbleAndSqueakSupplement
        supplements.guest = +supplements.guest + item.booking.roomPricing.guestSupplement
        supplements.singleNight = +supplements.singleNight + item.booking.roomPricing.singleNightSupplement


        for (let a = 0; a < item.addOns.length; a++) {
            item.addOns[a].totalCost = +item.addOns[a].cost * +item.addOns[a].qtySelected;
            addOnsTotal = addOnsTotal + +item.addOns[a].totalCost;
            addOnsDiscount = addOnsDiscount + +item.addOns[a].passportDiscount;
        }

        item.checkoutTotals.costs.addOns = +addOnsTotal;
        item.checkoutTotals.discount.addOns = +addOnsDiscount;

        if (item.spa && item.spa.trybeBasket) {
            item.checkoutTotals.costs.treatments = +item.spa.trybeBasket.totals.gross_total / 100;
            item.checkoutTotals.discount.treatments = +item.spa.trybeBasket.totals.discounts / 100;
        }

        if (item.voucher) {
            if (item.voucher.discountType === 'POUND') {
                discountVoucher = item.voucher.discountValue;
            }
            if (item.voucher.discountType === 'PERC') {
                discountVoucher = (+total / 100) * +item.voucher.discountValue;
            }
            item.checkoutTotals.discount.voucher = +discountVoucher;
        }


        // lets check for fixed package discount
        if (!item.packagesSelected.length) {
            discountFixedPromo = 0;
        }
        for (let p = 0; p < item.packagesSelected.length; p++) {
            if (item.packagesSelected[p].flatRate) {
                discountFixedPromo = +discountFixedPromo + ((+item.booking.roomPricing.roomCostForStay / 100) * +item.packagesSelected[p].discount);
                discountPassport = 0;
                item.checkoutTotals.discount.package = +item.checkoutTotals.discount.package + ((+item.booking.roomPricing.roomCostForStay / 100) * +item.packagesSelected[p].discount);
                item.checkoutTotals.discount.passport = 0;
            }

            if (!item.packagesSelected[p].flatRate && item.packagesSelected[p].priceType === 'Discount') {
                discountPackage = +discountPackage + ((+item.booking.roomPricing.roomCostForStay / 100) * +item.packagesSelected[p].discount);
                discountPassport = 0;
                item.checkoutTotals.discount.package = +item.checkoutTotals.discount.package + ((+item.booking.roomPricing.roomCostForStay / 100) * +item.packagesSelected[p].discount);
                item.checkoutTotals.discount.passport = 0;
            }
        }

        if (item.events) {
            item.checkoutTotals.discount.events = 0;
            for (let e = 0; e < item.events.length; e++) {
                for (let s = 0; s < item.events[e].skuSelected.length; s++) {
                    let eventDiscount = 0;

                    item.events[e].skuSelected[s].discount = 0;
                    eventsTotal = +eventsTotal + (+item.events[e].skuSelected[s].price * +item.events[e].skuSelected[s].qty);

                    if (item.events[e].allowPassportDiscount && !isGuest) {

                        const totalCost = (+item.events[e].skuSelected[s].price * +item.events[e].skuSelected[s].qty);
                        if (user) {
                            eventDiscount = ((+totalCost / 100) * +user.discountPerc);
                        }
                        item.events[e].skuSelected[s].discount = +eventDiscount;
                        item.checkoutTotals.discount.events = +item.checkoutTotals.discount.events
                            + eventDiscount;

                    }
                }
            }
        }
        item.checkoutTotals.costs.events = eventsTotal;

        item.checkoutTotals.costs.total =
            +item.checkoutTotals.costs.room
            + +item.checkoutTotals.costs.addOns
            + +item.checkoutTotals.costs.events
            + +item.checkoutTotals.costs.treatments
            + +item.checkoutTotals.costs.supplements.total;


        console.log(item.checkoutTotals)
        console.log('calculating  discount', basket.discountCs.rateOnly, basket.discountCs.typeValue)

        if (basket.discountCs.rateOnly && +basket.discountCs.typeValue) {
            console.log('calculating rate only discount', item.booking.roomPricing.roomCostForStay)
            if (basket.discountCs.type === 'perc') {
                item.checkoutTotals.discount.cs = +((basket.discountCs.typeValue / 100) * item.booking.roomPricing.roomCostForStay);
            } else {
                item.checkoutTotals.discount.cs = +basket.discountCs.typeValue;
            }
        }

        if (!basket.discountCs.rateOnly && +basket.discountCs.typeValue) {

            console.log('calculating booking value discount', basket.discountCs.type, basket.discountCs.typeValue, item.checkoutTotals.costs.total)
            if (basket.discountCs.type === 'perc') {
                item.checkoutTotals.discount.cs = +((basket.discountCs.typeValue / 100) * (item.checkoutTotals.costs.total - item.checkoutTotals.discount.passport));
            } else {
                item.checkoutTotals.discount.cs = +basket.discountCs.typeValue;
            }
        }
        console.log(item.checkoutTotals)


        item.checkoutTotals.discount.total =
            +item.checkoutTotals.discount.package
            + +item.checkoutTotals.discount.passport
            + +item.checkoutTotals.discount.addOns
            + +item.checkoutTotals.discount.events
            + +item.checkoutTotals.discount.treatments
            + +item.checkoutTotals.discount.voucher
            + +item.checkoutTotals.discount.cs;

        console.log('item.fullPaymentSelected', item.fullPaymentSelected, item.depositPaymentType, basket.depositFixedValue)
        if (item.fullPaymentRequired) {
            item.checkoutTotals.deposit.checkout = 0;
            item.checkoutTotals.deposit.fixed = 0;
            item.checkoutTotals.paid.card = +item.checkoutTotals.costs.total - +item.checkoutTotals.discount.total;
        } else {
            if (item.fullPaymentSelected) {
                item.checkoutTotals.deposit.checkout = 0;
                item.checkoutTotals.deposit.fixed = 0;
                item.checkoutTotals.paid.card = +item.checkoutTotals.costs.total - +item.checkoutTotals.discount.total;
            } else {
                console.log(item.depositPaymentType, item.checkoutTotals.deposit.checkout, basket.depositFixedValue)
                if (item.depositPaymentType === 'fixed') {
                    console.log('IS FIXED')
                    item.checkoutTotals.deposit.checkout = +basket.depositFixedValue;
                }
                console.log(item.depositPaymentType, item.checkoutTotals.deposit.checkout, basket.depositFixedValue)
                item.checkoutTotals.paid.card = +item.checkoutTotals.deposit.checkout + +item.checkoutTotals.costs.treatments;
            }
        }



        item.totals.netTotal = 0;
        item.totals.eventsTotal = eventsTotal;
        item.totals.toPay = 0;
        item.totals.discountVoucher = +discountVoucher;
        item.totals.depositToPay = +item.totals.deposit;

        item.totals.addOnsTotal = +addOnsTotal;
        item.totals.addOnsDiscount = +addOnsDiscount;
        item.totals.treatmentTotal = +treatmentTotal;
        item.totals.treatmentDiscount = +treatmentDiscount;
        item.totals.discountPackage = discountPackage;
        item.totals.discountPassport = discountPassport;
        item.totals.discountFixedPromo = +discountFixedPromo;

        item.totals.total = +total + +addOnsTotal + +eventsTotal + treatmentTotal;

        item.totals.discount = +discount + +addOnsDiscount + +treatmentDiscount + +discountPassport + +discountPackage + +discountFixedPromo + +discountCS;
        item.totals.supplements = supplements;

        item.totals.toPay =
            parseFloat(item.totals.total)
            - parseFloat(item.totals.discountVoucher)
            - parseFloat(item.totals.discount)
        // - parseFloat(item.totals.giftCardDiscount);


        item.totals.toPayPostGiftCard = +item.totals.toPay;
        item.totals.depositPostGiftCard = +item.totals.deposit;

        if (+item.totals.toPay < 0) {
            item.totals.depositToPay = +(+item.totals.depositToPay + +item.totals.toPay).toFixed(2);
            item.totals.toPay = 0;
            // item.checkoutTotals.deposit.checkout = +(+item.totals.depositToPay + +item.totals.toPay).toFixed(2);
        }

        item.checkoutTotals = this.depositRequiredCheck(item);
        // item.checkoutTotals = this.applyGiftCards(item);

        // if (+item.totals.giftCardDiscount) {
        //     let giftCardRemainder = 0;

        //     if (+item.totals.giftCardDiscount > +item.totals.depositToPay) {
        //         item.totals.depositToPay = 0;
        //         giftCardRemainder = +item.totals.giftCardDiscount - +item.totals.depositToPay;

        //     } else {
        //         item.totals.depositToPay = +item.totals.depositToPay - +item.totals.giftCardDiscount;
        //     }

        //     if (+giftCardRemainder) {
        //         item.totals.toPay = +item.totals.toPay - +giftCardRemainder;
        //     }
        // }


        item.checkoutTotals.paid.total = +item.checkoutTotals.paid.card + +item.checkoutTotals.paid.giftCard + +item.checkoutTotals.paid.voucher;
        item.checkoutTotals.unpaid.total = (+item.checkoutTotals.costs.total - +item.checkoutTotals.discount.total) - +item.checkoutTotals.paid.total;

        item.totals.toPay = item.totals.toPay - parseFloat(item.totals.depositToPay);

        item.totals.paid = +item.totals.depositToPay + +item.totals.giftCardDiscount;
        item.totals.netTotal = +item.totals.total - +item.totals.discount;

        return item.checkoutTotals;
    }

    totalBasketMultiCheckout(basket) {

        basket.checkoutTotals = JSON.parse(JSON.stringify(this.store.selectForLocal('checkoutTotals')));

        for (let i = 0; i < basket.items.length; i++) {

            basket.items[i].checkoutTotals = this.calculateItemTotals(basket, basket.items[i], basket.user);

            basket.checkoutTotals.paid.giftCard = basket.checkoutTotals.paid.giftCard + basket.items[i].checkoutTotals.paid.giftCard;
            basket.checkoutTotals.paid.voucher = basket.checkoutTotals.paid.voucher + basket.items[i].checkoutTotals.paid.voucher;
            basket.checkoutTotals.paid.events = basket.checkoutTotals.paid.events + basket.items[i].checkoutTotals.paid.events;
            basket.checkoutTotals.paid.treatments = basket.checkoutTotals.paid.treatments + basket.items[i].checkoutTotals.paid.treatments;
            basket.checkoutTotals.paid.card = basket.checkoutTotals.paid.card + basket.items[i].checkoutTotals.paid.card;
            basket.checkoutTotals.paid.total = basket.checkoutTotals.paid.total + basket.items[i].checkoutTotals.paid.total;

            basket.checkoutTotals.deposit.originalRoom = basket.checkoutTotals.deposit.originalRoom + basket.items[i].checkoutTotals.deposit.originalRoom;
            basket.checkoutTotals.deposit.checkout = basket.checkoutTotals.deposit.checkout + basket.items[i].checkoutTotals.deposit.checkout;

            basket.checkoutTotals.discount.package = basket.checkoutTotals.discount.package + basket.items[i].checkoutTotals.discount.package;
            basket.checkoutTotals.discount.passport = basket.checkoutTotals.discount.passport + basket.items[i].checkoutTotals.discount.passport;
            basket.checkoutTotals.discount.voucher = basket.checkoutTotals.discount.voucher + basket.items[i].checkoutTotals.discount.voucher;
            basket.checkoutTotals.discount.addOns = basket.checkoutTotals.discount.addOns + basket.items[i].checkoutTotals.discount.addOns;
            basket.checkoutTotals.discount.events = basket.checkoutTotals.discount.events + basket.items[i].checkoutTotals.discount.events;
            basket.checkoutTotals.discount.treatments = basket.checkoutTotals.discount.treatments + basket.items[i].checkoutTotals.discount.treatments;
            basket.checkoutTotals.discount.cs = basket.checkoutTotals.discount.cs + basket.items[i].checkoutTotals.discount.cs;
            basket.checkoutTotals.discount.total = basket.checkoutTotals.discount.total + basket.items[i].checkoutTotals.discount.total;

            basket.checkoutTotals.costs.room = basket.checkoutTotals.costs.room + basket.items[i].checkoutTotals.costs.room;
            basket.checkoutTotals.costs.addOns = basket.checkoutTotals.costs.addOns + basket.items[i].checkoutTotals.costs.addOns;
            basket.checkoutTotals.costs.events = basket.checkoutTotals.costs.events + basket.items[i].checkoutTotals.costs.events;
            basket.checkoutTotals.costs.treatments = basket.checkoutTotals.costs.treatments + basket.items[i].checkoutTotals.costs.treatments;
            basket.checkoutTotals.costs.total = basket.checkoutTotals.costs.total + basket.items[i].checkoutTotals.costs.total;

            basket.checkoutTotals.costs.supplements.child = basket.checkoutTotals.costs.supplements.child + basket.items[i].checkoutTotals.costs.supplements.child;
            basket.checkoutTotals.costs.supplements.dog = basket.checkoutTotals.costs.supplements.dog + basket.items[i].checkoutTotals.costs.supplements.dog;
            basket.checkoutTotals.costs.supplements.bubbleAndSqueak = basket.checkoutTotals.costs.supplements.bubbleAndSqueak + basket.items[i].checkoutTotals.costs.supplements.bubbleAndSqueak;
            basket.checkoutTotals.costs.supplements.guest = basket.checkoutTotals.costs.supplements.guest + basket.items[i].checkoutTotals.costs.supplements.guest;
            basket.checkoutTotals.costs.supplements.total = basket.checkoutTotals.costs.supplements.total + basket.items[i].checkoutTotals.costs.supplements.total;

        }

        if (basket.events) {
            let eventsTotal = 0;
            console.log(basket.events)
            basket.checkoutTotals.discount.events = 0;
            for (let e = 0; e < basket.events.length; e++) {
                for (let s = 0; s < basket.events[e].skuSelected.length; s++) {
                    let eventDiscount = 0;

                    basket.events[e].skuSelected[s].discount = 0;
                    eventsTotal = +eventsTotal + (+basket.events[e].skuSelected[s].price * +basket.events[e].skuSelected[s].qty);
                    console.log(eventsTotal)
                    // if (basket.events[e].allowPassportDiscount && !isGuest) {

                    //     const totalCost = (+basket.events[e].skuSelected[s].price * +basket.events[e].skuSelected[s].qty);
                    //     if (profile) {
                    //         eventDiscount = ((+totalCost / 100) * +profile.discountPerc);
                    //     }
                    //     basket.events[e].skuSelected[s].discount = +eventDiscount;
                    //     basket.checkoutTotals.discount.events = +basket.checkoutTotals.discount.events
                    //         + eventDiscount;

                    // }
                }
            }
            basket.checkoutTotals.costs.events = eventsTotal;
            basket.checkoutTotals.costs.total = +basket.checkoutTotals.costs.total + basket.checkoutTotals.costs.events;
        }


        if (basket.fullPaymentRequired || !basket.items.length) {
            basket.checkoutTotals.deposit.checkout = 0;
            basket.checkoutTotals.paid.card = +basket.checkoutTotals.costs.total - +basket.checkoutTotals.discount.total;
        } else {
            if (basket.fullPaymentSelected) {
                basket.checkoutTotals.deposit.checkout = 0;
                basket.checkoutTotals.paid.card = +basket.checkoutTotals.costs.total - +basket.checkoutTotals.discount.total;
            } else {
                if (basket.depositPaymentType !== 'fixed') {
                    basket.checkoutTotals.deposit.checkout = +basket.checkoutTotals.deposit.originalRoom;
                }
                basket.checkoutTotals.paid.card = +basket.checkoutTotals.deposit.checkout + +basket.checkoutTotals.costs.treatments;
            }
        }

        basket.checkoutTotals = this.applyGiftCards(basket)

        let giftCardRemainder = 0

        if (basket.fullPaymentRequired) {
            basket.fullPaymentSelected = true;
        }
        if (basket.fullPaymentRequired || basket.fullPaymentSelected) {
            basket.checkoutTotals.paid.card = +(+basket.checkoutTotals.costs.total - +basket.checkoutTotals.discount.total - +basket.checkoutTotals.paid.giftCard).toFixed(2);
        } else {
            if (+basket.checkoutTotals.paid.giftCard > +basket.checkoutTotals.paid.card) {
                basket.checkoutTotals.paid.card = 0;
                giftCardRemainder = +basket.checkoutTotals.paid.giftCard - +basket.checkoutTotals.paid.card;
                // basket.checkoutTotals.paid.card = +giftCardRemainder;
            } else {
                basket.checkoutTotals.paid.card = +basket.checkoutTotals.paid.card - +basket.checkoutTotals.paid.giftCard;
            }
        }

        basket.checkoutTotals.paid.total = +basket.checkoutTotals.paid.card + +basket.checkoutTotals.paid.giftCard + +basket.checkoutTotals.paid.voucher;
        basket.checkoutTotals.unpaid.total = (+basket.checkoutTotals.costs.total - +basket.checkoutTotals.discount.total) - +basket.checkoutTotals.paid.total;

        if (basket.giftCards.length && basket.items.length) {
            // GF only applicable on single item booking so apply to item[0]
            basket.items[0].checkoutTotals.paid.card = basket.checkoutTotals.paid.card;
            basket.items[0].checkoutTotals.paid.giftCard = basket.checkoutTotals.paid.giftCard;
            basket.items[0].checkoutTotals.paid.total = basket.checkoutTotals.paid.total;
            basket.items[0].checkoutTotals.unpaid.total = basket.checkoutTotals.unpaid.total;
        }

        console.log(basket.checkoutTotals)
        // end of added for giftcard
        return basket;
    }

    totalBasket(basket) {
        const modules = this.store.selectForLocal('modules');

        const profile = this.store.selectForLocal('profile');
        let isGuest = 1;

        if (profile) {
            isGuest = profile.isGuest;
        }

        if (modules.multiCheckout) {
            basket = this.totalBasketMultiCheckout(basket);
            return basket;
        }

        if (!basket.giftCards) {
            basket.giftCards = [];
        }

        if (!this.store.selectForLocal('checkoutTotals')) {
            basket.checkoutTotals = {
                paid: {
                    giftCard: 0,
                    voucher: 0,
                    card: 0,
                    total: 0,
                },
                deposit: {
                    originalRoom: 0,
                    checkout: 0,
                },
                discount: {
                    package: 0,
                    passport: 0,
                    events: 0,
                    treatments: 0,
                    total: 0,
                },
                costs: {
                    room: 0,
                    addOns: 0,
                    treatments: 0,
                    events: 0,
                    supplements: {
                        child: 0,
                        dog: 0,
                    },
                    total: 0,
                },
            }
        } else {
            basket.checkoutTotals = JSON.parse(JSON.stringify(this.store.selectForLocal('checkoutTotals')));
        };


        basket.checkoutTotals.deposit.originalRoom = +basket.totals.deposit;
        // basket.checkoutTotals.paid.card = 0;
        // basket.checkoutTotals.paid.giftCard = 0;
        // basket.checkoutTotals.paid.voucher = 0;
        // basket.checkoutTotals.paid.total = 0;

        let total = 0;
        let discount = 0;
        let addOnsTotal = 0;
        let addOnsDiscount = 0;
        let treatmentTotal = 0;
        let treatmentDiscount = 0;
        let eventsDiscount = 0;
        let discountPassport = 0;
        let discountPackage = 0;
        let discountFixedPromo = 0;
        let discountVoucher = 0;
        let eventsTotal = 0;

        let supplements = {
            child: 0,
            dog: 0,
            bubbleAndSqueak: 0,
            guest: 0,
        };

        for (let i = 0; i < basket.items.length; i++) {
            total = total + +basket.items[i].booking.roomPricing.roomCostForStay;
            basket.checkoutTotals.costs.room = +basket.items[i].booking.roomPricing.roomCostForStay;

            if (basket.userId !== '' && isGuest === 0 && !basket.packagesSelected.length) {

                // lets not apply discount if it's xmas
                let canApplyNP = true;

                if (basket.items[i].availabilitySearch.checkInDay === 18985) {
                    if (
                        basket.items[i].availabilitySearch.room.hotel.id === 'HOTEL001'
                        || basket.items[i].availabilitySearch.room.hotel.id === 'HOTEL002'
                        || basket.items[i].availabilitySearch.room.hotel.id === 'HOTEL003'
                    ) {
                        canApplyNP = false;
                    }
                }

                if (canApplyNP) {
                    discountPassport = +discountPassport + (+basket.items[i].booking.roomPricing.roomCostForStay / 10);
                    basket.checkoutTotals.discount.passport = (+basket.items[i].booking.roomPricing.roomCostForStay / 10);

                }
            }

            basket.checkoutTotals.costs.supplements.child = +basket.items[i].booking.roomPricing.childSupplement;
            basket.checkoutTotals.costs.supplements.dog = +basket.items[i].booking.roomPricing.dogSupplement;
            basket.checkoutTotals.costs.supplements.bubbleAndSqueak = +basket.items[i].booking.roomPricing.bubbleAndSqueakSupplement;
            basket.checkoutTotals.costs.supplements.guest = +basket.items[i].booking.roomPricing.guestSupplement;

            basket.checkoutTotals.costs.supplements.total =
                + +basket.checkoutTotals.costs.supplements.child
                + +basket.checkoutTotals.costs.supplements.dog
                + +basket.checkoutTotals.costs.supplements.bubbleAndSqueak
                + +basket.checkoutTotals.costs.supplements.guest

            basket.checkoutTotals.costs.room = +basket.checkoutTotals.costs.room - +basket.checkoutTotals.costs.supplements.total;

            supplements.child = +supplements.child + basket.items[i].booking.roomPricing.childSupplement;
            supplements.dog = +supplements.dog + basket.items[i].booking.roomPricing.dogSupplement;
            supplements.bubbleAndSqueak = +supplements.bubbleAndSqueak + basket.items[i].booking.roomPricing.bubbleAndSqueakSupplement
            supplements.guest = +supplements.guest + basket.items[i].booking.roomPricing.guestSupplement


            for (let a = 0; a < basket.items[i].addOns.length; a++) {
                basket.items[i].addOns[a].totalCost = +basket.items[i].addOns[a].cost * +basket.items[i].addOns[a].qtySelected;
                addOnsTotal = addOnsTotal + +basket.items[i].addOns[a].totalCost;
                addOnsDiscount = addOnsDiscount + +basket.items[i].addOns[a].passportDiscount;
            }

            basket.checkoutTotals.costs.addOns = +addOnsTotal;
            basket.checkoutTotals.discount.addOns = +addOnsDiscount;


            if (!basket.items[i].treatments) {
                basket.items[i].treatments = [];
            }
            for (let a = 0; a < basket.items[i].treatments.length; a++) {
                treatmentTotal = treatmentTotal + +basket.items[i].treatments[a].price;
                // treatmentDiscount = treatmentDiscount + +basket.items[i].treatment[a].passportDiscount;
            }
            basket.checkoutTotals.costs.treatments = +treatmentTotal;
            basket.checkoutTotals.discount.treatments = +treatmentDiscount;

        }

        if (basket.voucher) {
            if (basket.voucher.discountType === 'POUND') {
                discountVoucher = basket.voucher.discountValue;
            }
            if (basket.voucher.discountType === 'PERC') {
                discountVoucher = (+total / 100) * +basket.voucher.discountValue;
            }
            basket.checkoutTotals.discount.voucher = +discountVoucher;
        }


        // lets check for fixed package discount
        if (!basket.packagesSelected.length) {
            discountFixedPromo = 0;
        }
        for (let i = 0; i < basket.items.length; i++) {
            for (let p = 0; p < basket.packagesSelected.length; p++) {
                if (basket.packagesSelected[p].flatRate) {
                    discountFixedPromo = +discountFixedPromo + ((+basket.items[i].booking.roomPricing.roomCostForStay / 100) * +basket.packagesSelected[p].discount);
                    discountPassport = 0;
                    basket.checkoutTotals.discount.package = +basket.checkoutTotals.discount.package + ((+basket.items[i].booking.roomPricing.roomCostForStay / 100) * +basket.packagesSelected[p].discount);
                    basket.checkoutTotals.discount.passport = 0;
                }

                if (!basket.packagesSelected[p].flatRate && basket.packagesSelected[p].priceType === 'Discount') {
                    discountPackage = +discountPackage + ((+basket.items[i].booking.roomPricing.roomCostForStay / 100) * +basket.packagesSelected[p].discount);
                    discountPassport = 0;
                    basket.checkoutTotals.discount.package = +basket.checkoutTotals.discount.package + ((+basket.items[i].booking.roomPricing.roomCostForStay / 100) * +basket.packagesSelected[p].discount);
                    basket.checkoutTotals.discount.passport = 0;
                }
            }

        }
        console.log(basket.events)
        if (basket.events) {
            basket.checkoutTotals.discount.events = 0;
            for (let e = 0; e < basket.events.length; e++) {
                for (let s = 0; s < basket.events[e].skuSelected.length; s++) {
                    let eventDiscount = 0;

                    basket.events[e].skuSelected[s].discount = 0;
                    eventsTotal = +eventsTotal + (+basket.events[e].skuSelected[s].price * +basket.events[e].skuSelected[s].qty);
                    console.log(eventsTotal)
                    if (basket.events[e].allowPassportDiscount && !isGuest) {

                        const totalCost = (+basket.events[e].skuSelected[s].price * +basket.events[e].skuSelected[s].qty);
                        if (profile) {
                            eventDiscount = ((+totalCost / 100) * +profile.discountPerc);
                        }
                        basket.events[e].skuSelected[s].discount = +eventDiscount;
                        basket.checkoutTotals.discount.events = +basket.checkoutTotals.discount.events
                            + eventDiscount;

                    }
                }
            }
        }
        basket.checkoutTotals.costs.events = eventsTotal;

        basket.checkoutTotals.costs.total =
            +basket.checkoutTotals.costs.room
            + +basket.checkoutTotals.costs.addOns
            + +basket.checkoutTotals.costs.events
            + +basket.checkoutTotals.costs.supplements.total

        basket.checkoutTotals.discount.total =
            +basket.checkoutTotals.discount.package
            + +basket.checkoutTotals.discount.passport
            + +basket.checkoutTotals.discount.addOns
            + +basket.checkoutTotals.discount.events
            + +basket.checkoutTotals.discount.voucher

        if (basket.fullPaymentRequired || !basket.items.length) {
            basket.checkoutTotals.deposit.checkout = 0;
            basket.checkoutTotals.paid.card = +basket.checkoutTotals.costs.total - +basket.checkoutTotals.discount.total;
        } else {
            if (basket.fullPaymentSelected) {
                basket.checkoutTotals.deposit.checkout = 0;
                basket.checkoutTotals.paid.card = +basket.checkoutTotals.costs.total - +basket.checkoutTotals.discount.total;
            } else {
                basket.checkoutTotals.deposit.checkout = +basket.checkoutTotals.deposit.originalRoom;
                basket.checkoutTotals.paid.card = +basket.checkoutTotals.deposit.checkout;
            }
        }



        basket.totals.netTotal = 0;
        basket.totals.eventsTotal = eventsTotal;
        basket.totals.toPay = 0;
        basket.totals.discountVoucher = +discountVoucher;
        basket.totals.depositToPay = +basket.totals.deposit;

        basket.totals.addOnsTotal = +addOnsTotal;
        basket.totals.addOnsDiscount = +addOnsDiscount;
        basket.totals.treatmentTotal = +treatmentTotal;
        basket.totals.treatmentDiscount = +treatmentDiscount;
        basket.totals.discountPackage = discountPackage;
        basket.totals.discountPassport = discountPassport;
        basket.totals.discountFixedPromo = +discountFixedPromo;

        basket.totals.total = +total + +addOnsTotal + +eventsTotal + treatmentTotal;

        basket.totals.discount = +discount + +addOnsDiscount + +treatmentDiscount + +discountPassport + +discountPackage + +discountFixedPromo;
        basket.totals.supplements = supplements;

        basket.totals.toPay =
            parseFloat(basket.totals.total)
            - parseFloat(basket.totals.discountVoucher)
            - parseFloat(basket.totals.discount)
        // - parseFloat(basket.totals.giftCardDiscount);


        basket.totals.toPayPostGiftCard = +basket.totals.toPay;
        basket.totals.depositPostGiftCard = +basket.totals.deposit;

        if (+basket.totals.toPay < 0) {
            basket.totals.depositToPay = +(+basket.totals.depositToPay + +basket.totals.toPay).toFixed(2);
            basket.totals.toPay = 0;
            // basket.checkoutTotals.deposit.checkout = +(+basket.totals.depositToPay + +basket.totals.toPay).toFixed(2);
        }

        basket.checkoutTotals = this.depositRequiredCheck(basket);
        basket.checkoutTotals = this.applyGiftCards(basket);

        if (+basket.totals.giftCardDiscount) {
            let giftCardRemainder = 0;

            if (+basket.totals.giftCardDiscount > +basket.totals.depositToPay) {
                basket.totals.depositToPay = 0;
                giftCardRemainder = +basket.totals.giftCardDiscount - +basket.totals.depositToPay;

            } else {
                basket.totals.depositToPay = +basket.totals.depositToPay - +basket.totals.giftCardDiscount;
            }

            if (+giftCardRemainder) {
                basket.totals.toPay = +basket.totals.toPay - +giftCardRemainder;
            }
        }
        basket.checkoutTotals.paid.total = +basket.checkoutTotals.paid.card + +basket.checkoutTotals.paid.giftCard + +basket.checkoutTotals.paid.voucher;
        basket.checkoutTotals.unpaid.total = (+basket.checkoutTotals.costs.total - +basket.checkoutTotals.discount.total) - +basket.checkoutTotals.paid.total;

        basket.totals.toPay = basket.totals.toPay - parseFloat(basket.totals.depositToPay);

        basket.totals.paid = +basket.totals.depositToPay + +basket.totals.giftCardDiscount;
        basket.totals.netTotal = +basket.totals.total - +basket.totals.discount;

        return basket;
    }


    depositRequiredCheck(basket) {


        return basket.checkoutTotals;
    }

    applyGiftCards(basket) {
        let giftCardRemainder = 0;

        let giftCardTotal = 0;

        for (let i = 0; i < basket.giftCards.length; i++) {
            giftCardTotal = +giftCardTotal + +basket.giftCards[i].valueToRedeem;
        }

        basket.checkoutTotals.paid.giftCard = +giftCardTotal;

        return basket.checkoutTotals;
    }

}
