import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-answers',
  templateUrl: './feedback-answers.component.html',
  styleUrls: ['./feedback-answers.component.css']
})
export class FeedbackAnswersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
