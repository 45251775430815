<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>
    
    <h2 class="mat-title">Feature Group</h2>
    
    <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="featureGroup.name" placeholder="Group Name">
    </mat-form-field>
    
    <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="featureGroup.icon" placeholder="Group Icon">
    </mat-form-field>

    <div class="text-right">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
</div>
