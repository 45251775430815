<div class="pagination">
    <p class="pagination__count">
        {{(params.page * params.limit) + 1}} –
        <span *ngIf="((params.page * params.limit) + params.limit) <= params.totalRecords">{{(params.page * params.limit) + params.limit}}</span>
        <span *ngIf="((params.page * params.limit) + params.limit) > params.totalRecords">{{params.totalRecords}}</span>
        of
        {{params.totalRecords}} {{term}}
    </p>

    <nav class="pagination__nav" *ngIf="params.pageArray.length > 1">
        <a
            class="pagination__nav-item"
            *ngIf="(params.page + 1) > 5"
            (click)="first()">First</a>

        <ng-container *ngFor="let p of params.pageArray">
            <a
                class="pagination__nav-item"
                *ngIf="showPageNumber(p)"
                [ngClass]="{'pagination__nav-item--active' : p === params.page}"
                (click)="setPage(p)">{{p + 1}}</a>

        </ng-container>
        <a
            class="pagination__nav-item"
            *ngIf="(params.page + 1) < params.pageArray.length"
            (click)="next()">Next</a>
    </nav>
</div>
