import { FeedbackService } from './../../services/feedback.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '../../store';
import { OverlayService } from '../../services/overlay.service';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'app-feedback-questions',
    templateUrl: './feedback-questions.component.html',
    styleUrls: ['./feedback-questions.component.css']
})
export class FeedbackQuestionsComponent implements OnInit {

    topic;
    topicId = '';
    template;
    toSave = [];
    questions = [];

    confirmationData = {
        open: false,
        buttonText: 'Confirm',
        title: '',
        detail: '',
        data: undefined,
    };

    overlays$ = this.store.select<any>('overlays');

    constructor(
        private overlayService: OverlayService,
        private feedbackService: FeedbackService,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private menuService: MenuService,
    ) { }

    ngOnInit(): void {
        this.find();
    }

    find() {
        this.feedbackService.getQuestions(this.route.snapshot.params.templateId).subscribe(data => {
            this.questions = data;
            console.log(this.questions)
        })
    }

    toggleStatus(template) {
        if (template.is_active) {
            template.is_active = false;
        } else {
            template.is_active = true;
        }
        this.feedbackService.updateQuestion(template).subscribe();
    }

    addQuestion() {
        this.store.set('feedbackQuestion', {
            subject: '',
            template: '',
            answer: '',
            status: 1,
            deleted: 0,
        });
        this.overlayService.open('feedbackQuestion');
        this.store.set('asideOpen', true);
    }

    editQuestion(template) {
        this.store.set('feedbackQuestion', template);
        this.overlayService.open('feedbackQuestion');
        this.store.set('asideOpen', true);
    }

    closeAction(e) {
        if (e.reload) {
            this.find();
        }
    }

    drop(e) {
        this.toSave = e;
        this.feedbackService.sortQuestions(this.toSave).subscribe(data => {
        });
    }

    deleteConfirm(template) {
        this.store.set('feedbackQuestion', template);
        this.overlayService.open('faqDelete');
        this.store.set('asideOpen', true);
    }

    confirmAction(e) {
        if (e.action) {
            this.template.deleted = 1;
            this.feedbackService.updateQuestion(this.template).subscribe(data => {
                this.find();
            });
        }
        this.confirmationData.open = false;
    }

}
