import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FaqService } from '../faq.service';
import { OverlayService } from '../../services/overlay.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '../../store';

@Component({
    selector: 'app-faq-question',
    templateUrl: './faq-question.component.html',
    styleUrls: ['./faq-question.component.css']
})
export class FaqQuestionComponent implements OnInit {
    @Output() closeAction = new EventEmitter();

    form: UntypedFormGroup;
    question;

    topics = [];
    newItem = true;
    data: any = {
        topic: { id: 0 },
    };

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    pages = [];

    constructor(
        private faqService: FaqService,
        private store: Store,
        private overlayService: OverlayService,
        private fb: UntypedFormBuilder,
    ) {
        this.form = this.fb.group({
            question: ['', [Validators.required, Validators.minLength(3)]],
            answer: ['', [Validators.required, Validators.minLength(3)]],
            subject: ['', [Validators.required]],
            status: [true, [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.data = this.store.selectForLocal('faqQuestion');

        this.form.patchValue({
            question: this.data.question,
            subject: this.data.subject,
            answer: this.data.answer,
            status: this.data.status,
        });
    }

    getTopics() {
        this.faqService.findAllTopics().subscribe(data => {
            this.topics = data.data.data;
        });
    }

    close() {
        this.overlayService.closeAll();
    }

    toggleStatus() {
        if (!this.form.value.status) {
            this.form.patchValue({ status: true });
        } else {
            this.form.patchValue({ status: false });
        }
    }

    save() {
        this.data.question = this.form.value.question;
        this.data.answer = this.form.value.answer;
        this.data.subject = this.form.value.subject;
        this.data.status = this.form.value.status;

        if (!this.data.id) {
            this.faqService.createQuestion(this.data).subscribe(data => {
                this.closeAction.emit({ reload: true });
                this.faqService.getQuestions().subscribe();
                this.close();
            });
        } else {
            this.faqService.updateQuestion(this.data).subscribe(data => {
                this.closeAction.emit({ reload: true });
                this.faqService.getQuestions().subscribe();
                this.close();
            });
        }
    }

}
