import { FeedbackService } from './../../services/feedback.service';
import { Component, OnInit } from '@angular/core';
import { debounce } from "lodash";
import { Store } from 'src/app/store';

@Component({
    selector: 'app-feedback-invites',
    templateUrl: './feedback-invites.component.html',
    styleUrls: ['./feedback-invites.component.css']
})
export class FeedbackInvitesComponent implements OnInit {

    toDelete;
    user$ = this.store.select<any>('user');

    deleteConfirmationData = {
        open: false,
        title: '',
        detail: '',
        data: undefined,
        invite: undefined,
    };

    params = {
        name: '',
        email: '',
        template: '',
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "feedback_invite.sent DESC",
        sorts: [
            { ID: "feedback_invite.sent DESC", name: "Sent First" },
            { ID: "feedback_invite.sent ASC", name: "Sent Last" },
            { ID: "feedback_invite.completed ASC", name: "Completed First" },
            { ID: "feedback_invite.completed DESC", name: "Completed Last" },
        ],
    };

    invites = []
    answers = [];

    constructor(
        private feedbackService: FeedbackService,
        private store: Store,
    ) {
        this.filterSearch = debounce(this.filterSearch, 350);

    }

    ngOnInit() {
        this.filterSearch();
    }

    filterSearch() {
        this.params.page = 0;
        this.search();
    }

    search() {
        this.feedbackService.searchInvites(this.params).subscribe((data: any) => {
            this.invites = data.data.data;
            this.params.pages = Math.ceil(
                data.data.totalRecords / this.params.limit
            );
            this.params.totalRecords = data.data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
        });
    }

    resetParams() {
        this.params.name = '';
        this.params.email = '';
        this.params.page = 0;
        this.search();
    }

    getInvite(invite) {
        this.feedbackService.getInvite(invite).subscribe(data => {
            console.log(data);
            this.answers = data.data.questions;
        })
    }
    toggleFeedback(invite) {
        this.answers = [];

        if (!invite.showFeedback) {
            invite.showFeedback = true;
            this.getInvite(invite);
        } else {
            invite.showFeedback = false;
        }

        this.invites.forEach(e => {
            if (e.id !== invite.id) {
                e.showFeedback = false;
            }
        })
    }

    deleteConfirm(invite) {
        this.toDelete = invite
        this.deleteConfirmationData.title = 'Confirm deletion';

        this.deleteConfirmationData.data = '';
        this.deleteConfirmationData.detail = 'Clicking confirm will remove feedback answers';
        this.deleteConfirmationData.open = true;
        this.deleteConfirmationData.invite = invite

    }

    deleteAnswers(e) {
        console.log(e)
        if (e.action) {
            this.feedbackService.deleteInviteAnswers(this.toDelete.id).subscribe(data => {
                console.log(this.deleteConfirmationData.invite)
                this.getInvite(this.deleteConfirmationData.invite);
                this.toDelete = undefined;
                this.invites.forEach(e => {
                    e.showFeedback = false;
                })

            })
        } else {
            this.toDelete = undefined;

        }
    }
}
