import { Component, Input, OnInit } from '@angular/core';
import { HotelService } from '../../services/hotel.service';
import { RoomService } from '../../services/room.service';

@Component({
    selector: 'app-hotel-room-features',
    templateUrl: './hotel-room-features.component.html',
    styleUrls: ['./hotel-room-features.component.css']
})
export class HotelRoomFeaturesComponent implements OnInit {
    @Input() hotelID;

    rooms = [];
    feature = {
        name: ''
    };


    constructor(
        private hotelService: HotelService,
        private roomService: RoomService,
    ) { }

    ngOnInit() {
        // this.getRooms();
        this.roomFeaturesByHotel();
    }

    getRooms() {
        this.roomService.byHotelID(this.hotelID).subscribe(data => {
            this.rooms = data.data;
        });
    }
    roomFeaturesByHotel() {
        this.roomService.roomFeaturesByHotel(this.hotelID).subscribe(data => {
            this.rooms = data.data;
            console.log(this.rooms)
        });
    }

    toggleFeature(roomID, f) {
        console.log(roomID, f);
        if (f.selected) {
            f.selected = false;
        } else {
            f.selected = true;
        }
        this.roomService.setRoomFeatures(roomID, f.id).subscribe(data => {
        });
    }

    save() {
        this.roomService.addRoomFeature(this.feature).subscribe(data => {
            this.feature.name = '';
            this.roomFeaturesByHotel();
        });
    }

    toggleAll(rf) {
        for (let i = 0; i < this.rooms.length; i++) {
            for (let f = 0; f < this.rooms[i].features.length; f++) {
                if (this.rooms[i].features[f].id === rf.id) {
                    if (this.rooms[i].features[f].selected) {
                        this.rooms[i].features[f].selected = false;
                        this.roomService.setRoomFeatures(this.rooms[i].roomID, rf.id).subscribe(data => {
                        });
                    } else {
                        this.roomService.setRoomFeatures(this.rooms[i].roomID, rf.id).subscribe(data => {
                        });
                        this.rooms[i].features[f].selected = true;
                    }
                }

            }
        }
    }
}
