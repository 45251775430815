
<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <a [routerLink]="['/product-groups']">Room add-ons</a>
            <mat-icon>arrow_forward_ios</mat-icon>
            <a>Manage</a>
        </div>
        <!-- <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary"  (click)="groupAdd()">Add Group</button>
        </div> -->
    </div>
</mat-card>

<div class="row">
    <div class="col-4">
        <mat-card>
            <!-- <h2 class="mat-title" *ngIf="product_group.ID === ''">Add Product Group</h2>
        <h2 class="mat-title" *ngIf="product_group.ID !== ''">Edit Product Group</h2> -->

        <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="product_group.name" placeholder="Group Name">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <textarea matInput [(ngModel)]="product_group.description" style="height: 120px" placeholder="Group Description"></textarea>
        </mat-form-field>

        <!-- <h3 class="mat-title mb-1">Room booking hotel selection</h3> -->
        <p>Select the hotels that should show this group at room booking</p>
        <div class="mb-4 mt-1">

            <button
                class="mr-2 mb-2"
                *ngFor="let hotel of hotels"
                mat-raised-button
                [color]="isHotelSelected(hotel.id) > -1 ? 'primary' : 'grey'"
                color="primary"
                (click)="toggleHotel(hotel.id)">
                {{hotel.name}}
            </button>

        </div>


        <!-- <div class="row" *ngIf="product_group.logo">
            <div class="col-md-6">
                <img *ngIf="!product_group.logo.URL" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
                <img *ngIf="product_group.logo.URL" [src]="product_group.logo.URL" class="img-fluid" style="max-height: 150px" />
            </div>
            <div class="col-md-6">
                <app-image-upload [ownerID]="product_group.ID" [type]="'productGroupLogo'" [text]="'Click here to upload image'" (imageUploaded)="setGroupImage($event)"></app-image-upload>
            </div>
        </div> -->

        <div class="container mt-3" *ngIf="loaded">
            <div class="page-header">
                <h2 class="mat-title"><small>Upload images and drag into the desired order</small></h2>
            </div>

            <app-images [allowSelection]="false" [smallThumb]="true" [type]="'productGroupLogo'" [ownerID]="product_group.ID"></app-images>
        </div>
        <div>
            <div class=" text-right">
                <button mat-raised-button color="warn" (click)="save()">Save</button>
            </div>

        </div>
    </mat-card>

    </div>
    <div class="col-8">
        <mat-card *ngIf="mode === 'edit'">
            <mat-card class="no-records" *ngIf="!products.length">
                No add-ons for this group
            </mat-card>


            <ng-container *ngIf="products.length">

                <div class=" text-small p-2">
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-6">Name</div>
                        <div class="col-1">Cost</div>
                        <div class="col-1">Text box?</div>
                        <div class="col-2">Question</div>
                        <div class="col-1"></div>
                    </div>
                    <div
                    class="row"
                    dragula="priCategory"
                    [(dragulaModel)]="products"
                    (dragulaModelChange)="drop($event)">
                    <ng-container *ngFor="let p of products; let i = index">
                        <div
                        class="col-12 mb-2">
                        <div class="row data-tile align-items-center">
                            <div class="col-1">
                                <span class="label label-info hover">
                                    <i class="fa fa-bars"></i>
                                </span>

                            </div>
                            <div  (click)="productUpdate(p)" class="col-6 hover">
                                {{p.name}}
                            </div>
                            <div class="col-1">{{p.cost | currency: 'GBP'}}</div>


                            <div  (click)="productUpdate(p)" class="col-1 hover">
                                <span *ngIf="p.inputRequired">Yes</span>
                                <span *ngIf="!p.inputRequired">No</span>
                            </div>
                            <div  (click)="productUpdate(p)" class="col-2 hover">
                                <span *ngIf="p.inputRequired">{{p.question}}</span>
                            </div>
                            <div class="col-1 text-right">
                                <a class="hover" (click)="productUpdate(p)">
                                    <mat-icon>edit</mat-icon>
                                </a>
                                <a class="hover" (click)="productDelete(p)">
                                    <mat-icon>delete</mat-icon>
                                </a>
                            </div>


                        </div>
                    </div>
                    </ng-container>
                </div>
            </div>



                <!-- <div class="margin-top-20">
                    <table class="example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Terms</th>
                            <th>Extra Cost</th>
                            <th>Text box?</th>
                            <th>Question</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let p of products">
                            <td style="width: 40%;">{{p.name}}</td>
                            <td>{{p.terms}}</td>
                            <td style="width: 120px;">{{p.cost | currency: 'GBP'}}</td>
                            <td style="width: 120px;">
                                <span *ngIf="p.inputRequired">Yes</span>
                                <span *ngIf="!p.inputRequired">No</span>
                            </td>
                            <td>
                                <span *ngIf="p.inputRequired">{{p.question}}</span>
                            </td>
                            <td style="width: 120px;" class="text-right">
                                <a class="hover" (click)="productUpdate(p)">
                                    <mat-icon>edit</mat-icon>
                                </a>
                                <a class="hover" (click)="productDelete(p)">
                                    <mat-icon>delete</mat-icon>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div> -->

            </ng-container>

            <button id="pullright" mat-raised-button color="primary"  (click)="productAdd()">Add extra</button>

        </mat-card>
    </div>

</div>

<app-product-edit *ngIf="dataToEdit" [data]="dataToEdit" (complete)="dataEditComplete($event)"></app-product-edit>

