import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GiftCardService } from 'src/app/services/gift-card.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-giftcard-select',
    templateUrl: './giftcard-select.component.html',
    styleUrls: ['./giftcard-select.component.css']
})
export class GiftcardSelectComponent implements OnInit {
    @Input() recordsSelected = 0;
    @Output() complete = new EventEmitter();

    user$ = this.store.select<any>('user');

    giftCards = [];
    giftCardSelected;
    giftCardExpiry = undefined;
    choiceMade = false;

    constructor(
        private store: Store,
        private giftcardService: GiftCardService
    ) { }

    ngOnInit() {
        this.getGiftCards();
    }

    getGiftCards() {
        this.giftcardService.getAllProducts().subscribe(data => {
            this.giftCards = data.data;
        })
    }
    selectExisting() {
        this.choiceMade = true;
    }
    confirm() {
        this.giftCardSelected.giftCardExpiry = this.giftCardExpiry;

        this.complete.emit({ action: true, giftCard: this.giftCardSelected });
    }

    cancel() {
        this.complete.emit({ action: false, giftCard: this.giftCardSelected });
    }

    create() {
        this.choiceMade = true;
        this.complete.emit({ action: false, create: true, giftCard: this.giftCardSelected });
    }

}
