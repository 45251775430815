
<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/packages']">Promotions</a>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary" routerLink="/packages/edit/new">Create Promotion</button>
        </div>
    </div>
</mat-card>

<mat-card class="text-right" *ngIf="params">

    <div class="row">
        <div class="col-md-3">
            <mat-form-field class="text-left" style="width: 100%">
                <input matInput [(ngModel)]="params.name" (keyup)="search()" placeholder="Promotion Name">
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.active"
                    (selectionChange)="search()"
                    placeholder="Active">
                    <mat-option [value]="1">Only active</mat-option>
                    <mat-option [value]="0">All</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="search()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3 text-right">
            <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>
        </div>
    </div>


</mat-card>


<ng-container *ngIf="(data$ | async) && (data$ | async).length">

    <div class="margin-top-20">

        <div class="mb-3" *ngFor="let hotel of hotels">
            <ng-container *ngIf="hotel.packages.length">
                <h5>
                    <a class="hover" (click)="toggleHotel(hotel)">
                        <i class="fa fa-chevron-up" *ngIf="hotel.show"></i>
                        <i class="fa fa-chevron-down" *ngIf="!hotel.show"></i>
                     {{hotel.name}}</a>
                </h5>

            </ng-container>


            <ng-container *ngIf="hotel.show">
                <mat-card class="no-records" *ngIf="!hotel.packages.length">
                    No Promotions Listed for {{hotel.name}}
                </mat-card>
                    <table class="mat-elevation-z2 example-full-width data-listing" *ngIf="hotel.packages.length">
                    <thead>
                    <tr>
                        <th style="width: 25%;">Promotion Name</th>
                        <th style="width: 15%;">Created</th>
                        <th style="width: 15%;">Total Booked<br /><strong class="text-danger">{{hotel.totals.totalBooked}}</strong></th>
                        <th style="width: 15%;">Total Booked Value<br /><strong class="text-danger">{{hotel.totals.totalBookedValue | currency : 'GBP'}}</strong></th>
                        <th style="width: 15%;">Active<br />Packages</th>
                        <th style="width: 5%;"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let d of hotel.packages; let i = index">
                        <td (click)="edit(d.Id)">{{d.name}}</td>
                        <td (click)="edit(d.Id)">{{d.created | date: 'dd/MM/yyyy'}}</td>
                        <td (click)="edit(d.Id)">{{d.totalBooked}}</td>
                        <td (click)="edit(d.Id)">{{d.totalBookedValue | currency: 'GBP'}}</td>
                        <td>{{d.activeCount}}</td>
                        <td class="text-right">
                            <button mat-icon-button (click)="delete(d)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </ng-container>

        </div>

    </div>

</ng-container>

