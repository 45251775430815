import {Component, Input, OnInit} from '@angular/core';
import {Store} from '../../store';
import {RoomService} from '../../services/room.service';

@Component({
  selector: 'app-rooms-filter',
  templateUrl: './rooms-filter.component.html',
  styleUrls: ['./rooms-filter.component.css']
})
export class RoomsFilterComponent implements OnInit {
    @Input() params;
    hotels$ = this.store.select<any[]>('hotels');

  constructor(
      private store: Store,
      private roomService: RoomService,
  ) { }

  ngOnInit() {
  }
    updateParams() {
        this.roomService.updateParams(this.params);
    }
    resetParams() {
        this.roomService.resetParams();
    }

}
