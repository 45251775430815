<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/hotels']">Hotels</a>
            <mat-icon>arrow_forward_ios</mat-icon>
            Add Hotel
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card>
    
    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>
    <hr />
    
    <mat-tab-group *ngIf="hotel">
        <mat-tab label="Personal Overview">
            <div class="container margin-top-30">
                <app-hotel-overview [hotel]="hotel"></app-hotel-overview>
            </div>
        </mat-tab>
        
        
        <mat-tab label="Location">
            <div class="container margin-top-30">
                <app-hotel-address-form [hotel]="hotel"></app-hotel-address-form>
            </div>
        </mat-tab>
        
        <mat-tab label="Hotel Gallery">
            <div class="container margin-top-30">
            </div>
        </mat-tab>
    </mat-tab-group>
    
    
    <!--<app-chart-assessment-scores></app-chart-assessment-scores>-->
    
    
    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>

</mat-card>





