<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/vouchers']">Vouchers</a>
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<div class="mb-3 mt-3">


    <div class="d-flex align-items-center">
        <h6 class="mb-0">Voucher</h6>
    </div>

    <ng-container *ngIf="voucher">

    <div class="mat-card mt-2 p-2">

        <div class="row">
            <div class="col-6">
                <mat-form-field class="example-full-width">
                    <h2 class="mat-title">Voucher name</h2>
                    <input matInput [(ngModel)]="voucher.name">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <h2 class="mat-title">Voucher name (As displayed to guest)</h2>
                    <input matInput [(ngModel)]="voucher.guestName">
                </mat-form-field>

                <h2 class="mat-title">Overview</h2>
				<quill-editor [modules]="config" [(ngModel)]="voucher.overview" [style]="{'height':'100px'}"></quill-editor>

                <div class="mb-4">
                    <h2 class="mat-title mt-3">Terms and conditions</h2>
                    <quill-editor [modules]="config" [(ngModel)]="voucher.terms" [style]="{'height':'100px'}"></quill-editor>

                </div>


                <div class="row">
                    <div class="col-6">
                        <mat-form-field style="display: block">
                            <input type="search" matInput [(ngModel)]="voucher.stDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Voucher valid from">
                            <mat-datepicker #startpicker></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="col-6">
                        <mat-form-field style="display: block">
                            <input type="search" matInput [(ngModel)]="voucher.endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Voucher valid to">
                            <mat-datepicker #endpicker></mat-datepicker>
                          </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <mat-form-field style="display: block">
                            <input type="search" matInput [(ngModel)]="voucher.stBookingDate" (click)="startBookingpicker.open()" [matDatepicker]="startBookingpicker" placeholder="Stay date from">
                            <mat-datepicker #startBookingpicker></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <div class="col-6">
                        <mat-form-field style="display: block">
                            <input type="search" matInput [(ngModel)]="voucher.endBookingDate" (click)="endBookingpicker.open()" [matDatepicker]="endBookingpicker" placeholder="Stay date to">
                            <mat-datepicker #endBookingpicker></mat-datepicker>
                          </mat-form-field>

                    </div>
                </div>

                <!-- <mat-form-field style="display: block">
                  <mat-select
                      (ngModelUpdate)="assignHotel()"
                      [(ngModel)]="voucher.hotel"
                      placeholder="Hotel">
                      <mat-option value="">All Hotels</mat-option>
                      <mat-option
                          [value]="sy.id"
                          *ngFor="let sy of (hotels$ | async)">
                          {{sy.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field> -->


              <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                            [(ngModel)]="voucher.discountType"
                            placeholder="Discount type">
                            <mat-option value="">Discount type</mat-option>
                            <mat-option [value]="'PERC'">Percentage off</mat-option>
                            <mat-option [value]="'POUND'">Value off</mat-option>
                        </mat-select>
                      </mat-form-field>
            </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="voucher.discountValue" placeholder="Discount value">
                    </mat-form-field>
            </div>
            </div>

              <!-- <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="voucher.type"
                                placeholder="Voucher type">
                            <mat-option value="">Voucher type</mat-option>
                            <mat-option [value]="'promo'">Promo</mat-option>
                            <mat-option [value]="'discount'">Discount</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col-6">
                    <ng-container *ngIf="voucher.type === 'promo'">
                        <mat-form-field style="display: block">
                            <mat-select
                                [(ngModel)]="voucher.promotion"
                                placeholder="Promotion">
                                <mat-option value="">Selection promotion</mat-option>
                                <mat-option
                                    [value]="promo.id"
                                    *ngFor="let promo of promotions">
                                    {{promo.name}}
                                </mat-option>
                            </mat-select>
                          </mat-form-field>

                    </ng-container>
                    <ng-container *ngIf="voucher.type !== 'promo'">
                        <mat-form-field style="display: block">
                            <mat-select
                                [(ngModel)]="voucher.discountType"
                                placeholder="Discount type">
                                <mat-option value="">Discount type</mat-option>
                                <mat-option [value]="'PERC'">Percentage off</mat-option>
                                <mat-option [value]="'POUND'">Value off</mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="voucher.discountValue" placeholder="Discount value">
                        </mat-form-field>
                    </ng-container>

                </div>
            </div> -->


            <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="voucher.active"
                                placeholder="Active?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                      </mat-form-field>

                </div>
            </div>



            </div>

            <div class="col-6">
                <h6 class="mb-4">Select rooms to include / exclude</h6>

                <ng-container *ngFor="let hotel of hotels">
                    <div class="row">
                        <div class="col-6">
                            <h6>{{hotel.name}}</h6>
                        </div>
                        <div class="col-6 text-right">
                            <a class="hover mr-4" style="font-size: 11px;" (click)="removeAllRooms(hotel.id)">Remove all</a>
                            <a class="hover" style="font-size: 11px;" (click)="addAllRooms(hotel.id)">Add all</a>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start flex-wrap mb-4" style="width:100%">
                        <div class="mb-2 mr-2 hover" (click)="toggleRoom(room.id)" style="width: 32%; font-size: 12px; border: 1px solid #eee; padding: 5px;" *ngFor="let room of hotel.rooms">
                            {{room.name}}
                            <span *ngIf="isRoomSelected(room.id)">
                                <i class="fa fa-check text-success"></i>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>



    <div class="text-right">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>





</div>
</ng-container>
</div>
