import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImagesService} from '../../services/images.service';
import {OverlayService} from '../../services/overlay.service';
import {RoomService} from '../../services/room.service';
import {Store} from '../../store';

@Component({
  selector: 'app-room-type-delete',
  templateUrl: './room-type-delete.component.html',
  styleUrls: ['./room-type-delete.component.css']
})
export class RoomTypeDeleteComponent implements OnInit {
    @Input() modalData;
    @Output() result: EventEmitter<any> =  new EventEmitter<any>();

    roomType$ = this.store.select<any>('roomType');

    dataToEmit = {
        data: {},
        success: false
    };

    constructor(
        private imagesService: ImagesService,
        private overlayService: OverlayService,
        private roomService: RoomService,
        private store: Store,
    ) { }

    ngOnInit() {
    }
    confirm() {
        this.roomService.deleteRoomType(this.modalData)
            .subscribe(data => {
                this.dataToEmit.success = true;
                this.close();
            });
    }
    close() {
        this.overlayService.closeAll();
        this.result.emit(this.dataToEmit);
    }

}
