import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    env = environment;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
    ) {
    }

    clearCache() {
        return this.http.delete(this.apiService.v2Path() + 'data/cache').pipe(map((data: any) => {
            return data;
        }));
    }


}
