import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
    ) {
    }

    saveResponse(inviteId, staffResponse) {
        const user = this.store.selectForLocal('user')
        const dataToSend = {
            inviteId,
            staffResponse,
            userId: user.ID,
        }
        return this.http.post(this.env.apiV2Path + 'feedback/comment/respond', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));

    }
    searchInvites(params) {
        const dataToSend = {
            params,
        }
        return this.http.post(this.env.apiV2Path + 'feedback/invites', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));

    }
    getInvite(invite) {
        return this.http.get(this.env.apiV2Path + 'feedback/invite/' + invite.uuid)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }
    deleteInviteAnswers(inviteId) {
        return this.http.delete(this.env.apiV2Path + 'feedback/invite/answers/' + inviteId)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getBusinesses() {
        return this.http.get(this.env.apiV2Path + 'feedback/businesses')
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getBusiness(id) {
        return this.http.get(this.env.apiV2Path + 'feedback/businesses/' + id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getBusinessStaff(organisation) {
        return this.http.get(this.env.apiV2Path + 'staff/organisation/' + organisation)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    excludeStaffFromDistro(organisation, staff) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/exclude/' + organisation + '/' + staff, {})
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    includeStaffInDistro(organisation, staff) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/include/' + organisation + '/' + staff, {})
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    excludeAllStaffFromDistro(organisation) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/exclude/' + organisation + '/all', {})
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    includeAllStaffInDistro(organisation) {
        return this.http.put(this.env.apiV2Path + 'feedback/business/include/' + organisation + '/all', {})
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    getStaffExclusions(organisation) {
        return this.http.get(this.env.apiV2Path + 'feedback/business/exclusions/' + organisation)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getNonBusinessStaff(organisation) {
        return this.http.get(this.env.apiV2Path + 'feedback/business/inclusions/' + organisation)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    addNonStaff(organisation, staff) {
        const dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation
        }

        return this.http.post(this.env.apiV2Path + 'feedback/business/include/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    removeNonStaff(organisation, staff) {
        const dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation
        }

        return this.http.post(this.env.apiV2Path + 'feedback/business/exclude/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    getCommentsStaff(organisation) {
        return this.http.get(this.env.apiV2Path + 'feedback/comments/inclusions/' + organisation)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    addCommentsStaff(organisation, staff) {
        const dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation
        }

        return this.http.post(this.env.apiV2Path + 'feedback/comments/include/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    removeCommentsStaff(organisation, staff) {
        const dataToSend = {
            name: staff.name,
            email: staff.email,
            organisation
        }

        return this.http.post(this.env.apiV2Path + 'feedback/comments/exclude/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }



    getTemplates() {
        return this.http.get(this.env.apiV2Path + 'feedback/template')
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getTemplate(id) {
        return this.http.get(this.env.apiV2Path + 'feedback/template/' + id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    createTemplate(template) {
        const dataToSend = {
            template
        }
        return this.http.post(this.env.apiV2Path + 'feedback/template', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }
    updateTemplate(template) {
        const dataToSend = {
            template
        }
        return this.http.put(this.env.apiV2Path + 'feedback/template', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    sortTemplates(templates) {
        const dataToSend = {
            templates
        };
        return this.http.post(this.env.apiV2Path + 'template/sort', dataToSend)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    // questions
    getQuestions(templateId) {
        return this.http.get(this.env.apiV2Path + 'feedback/template/' + templateId + '/questions')
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getQuestion(id) {
        return this.http.get(this.env.apiV2Path + 'feedback/question/' + id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    createQuestion(question) {
        const dataToSend = {
            question
        }
        return this.http.post(this.env.apiV2Path + 'feedback/question', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }
    updateQuestion(question) {
        const dataToSend = {
            question
        }
        return this.http.put(this.env.apiV2Path + 'feedback/question', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    findAllTopics() {
        const faq = this.store.selectForLocal('faq');
        const dataToSend = {
            params: faq.params,
        };
        return this.http.post(this.env.apiV2Path + 'faq/search', dataToSend)
            .pipe(
                map((data: any) => {
                    faq.data = data.data.data;

                    faq.params.pages = Math.ceil(data.data.totalRecords / faq.params.limit);
                    faq.params.totalRecords = data.data.totalRecords;
                    faq.params.pageArray = [];
                    for (let i = 0; i < faq.params.pages; i++) {
                        faq.params.pageArray.push(i);
                    }
                    this.store.set('faq', faq);

                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    findOne(id) {
        return this.http.get(this.env.apiV2Path + 'faq/' + id)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    create(topic) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            topic,
            user,
        };
        return this.http.post(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    update(topic) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            topic,
            user,
        };
        return this.http.put(this.env.apiV2Path + 'faq', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }





    sortQuestions(questions) {
        const dataToSend = {
            questions
        };
        return this.http.post(this.env.apiV2Path + 'faq/questions/sort', dataToSend)
            .pipe(map((data: any) => {
                return data;
            }));
    }


}
