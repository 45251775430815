<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            Giftcard Products
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card class="margin-top-10">
    <div class="row">
        <div class="col-md-4">
                <mat-form-field class="text-left" style="width: 100%">
                    <input matInput [(ngModel)]="params.searchText" (keyup)="getCards()" placeholder="Freetext search">
                </mat-form-field>

        </div>
            <div class="col-md-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.hotelId"
                        (selectionChange)="getCards()"
                        placeholder="Brands">
                        <mat-option [value]="undefined">All Brands</mat-option>
                        <mat-option
                            [value]="sy.id"
                            *ngFor="let sy of (hotels$ | async)">
                            {{sy.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.shopifyOnly"
                        (selectionChange)="getCards()"
                        placeholder="Source">
                        <mat-option [value]="-1">All Sources</mat-option>
                        <mat-option [value]="1">Shopify</mat-option>
                        <mat-option [value]="0">Marketing Promo</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="getCards()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.id"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="col-md-2 text-right">
            <a mat-raised-button color="primary" (click)="create()">Create Product</a>
        </div>

    </div>
</mat-card>

    <div class="row">
        <div class="col-md-12">

            <mat-card class="margin-top-10">
                <div *ngIf="cards.length">
                    <table class="table example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>Source</th>
                            <th>Brand</th>
                            <th>Card Name</th>
                            <th>Sku</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let card of cards; let i = index">
                            <td>
                                <span *ngIf="card.shopifyOnly">Shopify</span>
                                <span *ngIf="!card.shopifyOnly" class="text-warning">Promo</span>
                            </td>
                            <td>
                                {{card.brand}}
                            </td>
                            <td>{{card.name}}</td>
                            <td>{{card.sku}}</td>
                            <td>{{card.price | currency: 'GBP'}}</td>
                            <!-- <td>{{card.orderLineId}}</td> -->
                            <td>
                                <button *ngIf="!card.shopifyOnly" mat-raised-button color="warn" (click)="delete(card)">Delete</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>

<mat-card class="margin-top-10">
    <app-pagination [params]="params" [term]="'Cards'" (update)="paginate($event)"></app-pagination>
</mat-card>

<app-confirmation *ngIf="dataToDelete" [data]="confirmationData" (complete)="deleteAction($event)"></app-confirmation>

<app-giftcard-create *ngIf="showGiftCardCreateWindow" (complete)="giftCardProductCreated($event)"></app-giftcard-create>

