import { Component, OnInit } from '@angular/core';
import { FaqService } from '../faq.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '../../store';
import { OverlayService } from '../../services/overlay.service';
import { MenuService } from '../../services/menu.service';

@Component({
    selector: 'app-faq-questions',
    templateUrl: './faq-questions.component.html',
    styleUrls: ['./faq-questions.component.css']
})
export class FaqQuestionsComponent implements OnInit {

    topic;
    topicId = '';
    question;
    toSave = [];
    questions$ = this.store.select<any[]>('faqQuestions').subscribe(data => {
        this.questions = data;
    });
    questions = this.store.selectForLocal('faqQuestions');

    confirmationData = {
        open: false,
        buttonText: 'Confirm',
        title: '',
        detail: '',
        data: undefined,
    };

    overlays$ = this.store.select<any>('overlays');

    constructor(
        private overlayService: OverlayService,
        private faqService: FaqService,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private menuService: MenuService,
    ) { }

    ngOnInit(): void {
        this.find();
    }

    find() {
        this.faqService.getQuestions().subscribe(data => {
            console.log(data);
            this.questions = data;
            console.log(this.questions)
        })
    }

    toggleStatus(question) {
        if (question.status) {
            question.status = false;
        } else {
            question.status = true;
        }
        this.faqService.updateQuestion(question).subscribe();
    }

    addQuestion() {
        this.store.set('faqQuestion', {
            subject: '',
            question: '',
            answer: '',
            status: 1,
            deleted: 0,
        });
        this.overlayService.open('faqQuestion');
        this.store.set('asideOpen', true);
    }

    editQuestion(question) {
        this.store.set('faqQuestion', question);
        this.overlayService.open('faqQuestion');
        this.store.set('asideOpen', true);
    }

    closeAction(e) {
        if (e.reload) {
            this.find();
        }
    }

    drop(e) {
        this.toSave = e;
        this.faqService.sortQuestions(this.toSave).subscribe(data => {
        });
    }

    deleteConfirm(question) {
        this.store.set('faqQuestion', question);
        this.overlayService.open('faqDelete');
        this.store.set('asideOpen', true);
    }

    confirmAction(e) {
        if (e.action) {
            this.question.deleted = 1;
            this.faqService.updateQuestion(this.question).subscribe(data => {
                this.find();
            });
        }
        this.confirmationData.open = false;
    }

    togglePopular(q) {
        if (!q.popular) {
            q.popular = true;
        } else {
            q.popular = false;
        }
        this.faqService.updateQuestion(q).subscribe();
    }

}
