<mat-card class="breadcrumb">
	<div class="row">
		<div class="col-md-10">
			<!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
			<!--<mat-icon>arrow_forward_ios</mat-icon>-->
			<a [routerLink]="['/rategroups']">Pricing rate groups</a>
		</div>
		<div class="col-md-2 text-right">
			<button id="pullright" mat-raised-button color="primary" routerLink="/rategroup/edit/new">Add Group</button>
		</div>
	</div>
</mat-card>

<mat-card class="text-right">

</mat-card>

<mat-card class="no-records" *ngIf="!data.length">
	No groups listed
</mat-card>

<ng-container *ngIf="data.length">
	
	<div class="margin-top-20">
		
		
		<table class="mat-elevation-z2 example-full-width data-listing">
			<thead>
			<tr>
				<th>Group Name</th>
				<th>Hotel</th>
				<th>On/Off?</th>
				<th class="text-right">Bookings (for mth)</th>
				<th class="text-right">Revenue (for mth)</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let r of data">
				<td (click)="edit(r.Id)">{{r.name}}</td>
				<td (click)="edit(r.Id)">{{r.hotel.name}}</td>
				<td class="text-center" (click)="saveStatus(r)">
					<mat-icon class="pointer" [ngClass]="r.status === 1 ? 'active' : 'inactive'">check_circle</mat-icon>
				</td>
				<td (click)="edit(r.Id)" class="text-right">0</td>
				<td class="text-right" (click)="edit(r.Id)">0</td>
				<td class="text-right">
					<a class="hover" (click)="delete(r.Id)">
						<mat-icon>delete</mat-icon>
					</a>
				</td>
			</tr>
			</tbody>
		</table>
	
	</div>

</ng-container>


