<div class="full-screen-modal">
    
    <div class="modal fade show" id="exampleModalCenter" tabindex="-1">
        <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </a>
        <div class="modal-dialog modal-lg">
                    <div class="container" style="padding: 80px">
                        
                        <div class="row" style="margin: 0 auto">
                            <div class="col-8">
                                <image-cropper *ngIf="cropperReady"
                                               [imageBase64]="image.imgBase"
                                               [maintainAspectRatio]="true"
                                               [resizeToWidth]="600"
                                               [imageChangedEvent]="imageChangedEvent"
                                               [aspectRatio]="6 / 4"
                                               [imageQuality]="100"
                                               (imageCropped)="imageCropped($event)"
                                               (imageLoaded)="imageLoaded()"
                                               (loadImageFailed)="loadImageFailed()"
                                ></image-cropper>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12">
                                        <img [src]="croppedImage" style="max-width: 300px; margin-top: 5px" />
                                    </div>
                                    <div class="col-12 text-right">
                                        <button mat-raised-button color="primary" (click)="save()">Save Changes</button>
                                    </div>
                                </div>
        
                            </div>
                        </div>
                        
                    </div>

        </div>
    </div>
</div>
