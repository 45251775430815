import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RatePlanService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
    ) {
    }


    get(id) {
        return this.http.get(this.env.apiV2Path + 'rate-plan/' + id)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getAll() {
        return this.http.get(this.env.apiV2Path + 'rate-plan')
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }
    getAllGrouped() {
        return this.http.get(this.env.apiV2Path + 'rate-plan/grouped')
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    search(params) {
        const dataToSend = {
            params,
        }
        return this.http.post(this.env.apiV2Path + 'rate-plan/search', dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    create(ratePlan) {
        const dataToSend = {
            ratePlan
        }
        return this.http.post(this.env.apiV2Path + 'rate-plan', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    toggleStatus(ratePlan) {
        const dataToSend = {
            ratePlan
        }
        return this.http.post(this.env.apiV2Path + 'rate-plan/status', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    update(ratePlan) {
        const dataToSend = {
            ratePlan
        }
        return this.http.put(this.env.apiV2Path + 'rate-plan', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    delete(event) {
        return this.http.delete(this.env.apiV2Path + 'rate-plan/' + event.id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    sortSkus(skus) {
        const dataToSend = {
            skus
        }
        return this.http.post(this.env.apiV2Path + 'rate-plan/sort', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

}
