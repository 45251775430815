import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';

@Component({
    selector: 'app-event-types',
    templateUrl: './event-types.component.html',
    styleUrls: ['./event-types.component.css']
})
export class EventTypesComponent implements OnInit {

    data = [];
    dataToDelete;
    loading = false;

    constructor(
        private eventsService: EventsService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.getEventTypes();
    }

    getEventTypes() {
        this.loading = true;
        this.eventsService.getTypes().subscribe(data => {
            this.data = data;
            this.loading = false;
        })
    }

    delete(data) {
        this.dataToDelete = data;
        this.eventsService.deleteType(data).subscribe(data => {
            this.getEventTypes();
        })
    }

    create() {
        this.router.navigate(['/events/types', 'create'])
    }
    update(data) {
        this.router.navigate(['/events/types', data.slug])
    }

}
