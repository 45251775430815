import { RoomService } from './../../services/room.service';
import { Component, Input, OnInit } from '@angular/core';
import { HotelService } from '../../services/hotel.service';
import { ProductService } from '../../services/product.service';

@Component({
    selector: 'app-product-index',
    templateUrl: './product-index.component.html',
    styleUrls: ['./product-index.component.css']
})
export class ProductIndexComponent implements OnInit {
    @Input() hotelID;

    productGroups = [];
    rooms = [];

    constructor(
        private hotelService: HotelService,
        private roomService: RoomService,
        private productService: ProductService,
    ) { }

    ngOnInit() {
        this.get();
    }

    getRooms() {
        this.roomService.byHotelID(this.hotelID).subscribe(data => {
            this.rooms = data.data;
        });
    }

    get() {
        this.productService.getIndexedProducts(this.hotelID).subscribe(data => {
            console.log(data.data);
            this.rooms = data.data;
        });
    }

    toggleFeature(roomId, f) {
        console.log(roomId, f)
        if (f.selected) {
            f.selected = false;
        } else {
            f.selected = true;
        }
        this.productService.toggleProduct(roomId, f).subscribe(data => { });
    }

    toggleAll(product, groupIdx, productIdx) {
        // console.log(this.rooms)
        let isSelected = false;

        if (this.rooms[0].groups[groupIdx].products[productIdx].selected) {
            isSelected = true;
        }

        for (let i = 0; i < this.rooms.length; i++) {

            for (let f = 0; f < this.rooms[i].groups[groupIdx].products.length; f++) {
                if (this.rooms[i].groups[groupIdx].products[f].id === product.id) {

                    console.log(this.rooms[i].groups[groupIdx].products[f])
                    if (isSelected) {
                        this.rooms[i].groups[groupIdx].products[f].selected = false;
                    } else {
                        this.rooms[i].groups[groupIdx].products[f].selected = true;
                    }
                    this.productService.toggleProduct(this.rooms[i].roomID, this.rooms[i].groups[groupIdx].products[f]).subscribe(data => { });

                }
            }
        }
    }


}
