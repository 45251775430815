import { Component, OnInit } from '@angular/core';
import { PassportService } from '../passport.service';
import { Store } from '../../store';

@Component({
    selector: 'app-passport-stats',
    templateUrl: './passport-stats.component.html',
    styleUrls: ['./passport-stats.component.css']
})
export class PassportStatsComponent implements OnInit {

    stats;

    constructor(
        private store: Store,
        private passportService: PassportService,
    ) {
    }

    ngOnInit() {
        this.store.set('area', 'passport');
        this.getStats();
    }

    getStats() {
        this.passportService.getStats().subscribe(data => {
            this.stats = data.data;
            console.log(this.stats)
        })
    }
}
