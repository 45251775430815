<app-booking-navbar [step]="2" [layout]="'adhoc'"></app-booking-navbar>

<div class="row mt-3">
    <div class="col-5">
        <mat-card *ngIf="paymentLinkData">
            <h2 class="mat-title mb-2">Payment Overview</h2>

            <label class="mt-3">Select brand</label>
            <select class="form-control" [(ngModel)]="paymentLinkData.hotel">
                <option [ngValue]="undefined">Select Brand</option>
                <option *ngFor="let h of hotels" [ngValue]="h">{{h.name}}</option>
            </select>

            <label class="mt-3">Enter a brief description of payment reason</label>
            <textarea class="form-control" [(ngModel)]="paymentLinkData.description" rows="5"></textarea>

            <label class="mt-3">Amount to pay</label>
            <input type="text" class="form-control" [(ngModel)]="paymentLinkData.amountToPay" />

            <div class="row mt-3" *ngIf="paymentLinkData.amountToPay && paymentLinkData.hotel && paymentLinkData.description">
                <div class="col-6">
                    <button type="button" class="btn btn-sm btn-primary" (click)="setPaymentMethod('link')">Payment Link</button>
                </div>
                <div class="col-6 text-right">
                    <button type="button" class="btn btn-sm btn-primary" (click)="setPaymentMethod('card')">Card Payment</button>
                </div>
            </div>
        </mat-card>
    </div>

    <div class="col-7">

        <mat-card>

        </mat-card>

    </div>
</div>
<app-payment-modal *ngIf="showPaymentModal" [paymentData]="paymentData" (complete)="paymentComplete($event)"></app-payment-modal>
