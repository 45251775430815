import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Store} from '../store';

@Injectable({
  providedIn: 'root'
})
export class CountiesService {

  constructor(
      private http: HttpClient,
      private apiService: ApiService,
      private store: Store,
  ) { }

    all() {
      return this.http.get(this.apiService.path() + 'counties').subscribe((data: any) => {
          this.store.set('counties', data.data);
      });
    }

}
