import { Component, OnInit } from '@angular/core';
import {OverlayService} from '../../services/overlay.service';
import {RoomService} from '../../services/room.service';

@Component({
  selector: 'app-room-type-add',
  templateUrl: './room-type-add.component.html',
  styleUrls: ['./room-type-add.component.css']
})
export class RoomTypeAddComponent implements OnInit {

    roomType = {
        id: '',
        name: '',
        welcomeID: '',
    };

    constructor(
        private overlayService: OverlayService,
        private roomService: RoomService,
    ) { }

    ngOnInit() {
    }


    save() {
        this.roomService.addRoomType(this.roomType).subscribe(data => {
            this.close();
        });
    }

    close() {
        this.overlayService.closeAll();
    }
}
