<div class="row">
  <div class="col-md-3">
    <mat-form-field class="text-left" style="width: 100%">
      <input matInput [(ngModel)]="params.name" (keyup)="updateParams()" placeholder="Hotel Name">
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field>
      <mat-select
        [(ngModel)]="params.county"
        (selectionChange)="updateParams()"
        placeholder="County">
        <mat-option
          [value]="sy.id"
          *ngFor="let sy of (counties$ | async)">
          {{sy.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field>
      <mat-select
        [(ngModel)]="params.sort"
        (selectionChange)="updateParams()"
        placeholder="Sort By">
        <mat-option
          [value]="sort.ID"
          *ngFor="let sort of params.sorts">
          {{sort.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-3 text-right">
    <a mat-raised-button color="warn" (click)="this.resetParams()">Clear</a>
  </div>
</div>


