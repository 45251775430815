<div class="row">
    <div class="col-md-6">

        <div>
            <h2 class="mat-title">Room Details</h2>

            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="room.name" placeholder="Room Name">
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-select
                    [(ngModel)]="room.hotel.id"
                    placeholder="Select Hotel">
                    <mat-option
                        [value]="c.id"
                        *ngFor="let c of (hotels$ | async)">
                        {{c.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>




            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="room.checkIn" placeholder="Check in i.e 3pm to 9pm">
                    </mat-form-field>
                        </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="room.checkOut" placeholder="Checkout i.e Until 11am">
                    </mat-form-field>
                        </div>
            </div>


            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="room.welcome_room_type" placeholder="Welcome ID">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput type="number" [(ngModel)]="room.deposit" placeholder="Deposit (&pound;)">
                    </mat-form-field>
                </div>
            </div>



        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.minAdults"
                        placeholder="Min Adults">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.maxAdults"
                        placeholder="Max Adults">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.minChildren"
                        placeholder="Min Children">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.maxChildren"
                        placeholder="Max Children">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.maxGuests"
                        placeholder="Max Guests">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.rateIncludedAdults"
                        placeholder="Rate Included Adults">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.dogFriendly"
                        placeholder="Allow dogs?">
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
			<div class="col-md-6">
				<mat-form-field class="example-full-width">
					<mat-select
							[(ngModel)]="room.numberOfRooms"
							placeholder="Number of rooms?">
						<mat-option *ngFor="let q of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" [value]="q">{{q}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
        </div>



        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.minNights"
                        placeholder="Min Nights">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.minNightsSC"
                        placeholder="Min Nights SC">
                        <mat-option
                            [value]="c"
                            *ngFor="let c of totals">
                            {{c}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-9">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="room.urgency_message_text" placeholder="Urgency Message">
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.urgency_message"
                        placeholder="Show?">
                        <mat-option
                            [value]="y.id"
                            *ngFor="let y of yesNo">
                            {{y.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="row">
            <div class="col-md-3">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="room.topRated"
                        placeholder="Top rated room?">
                        <mat-option
                            [value]="y.id"
                            *ngFor="let y of yesNo">
                            {{y.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="room.deposit_text" placeholder="Booking footer / deposit text">
                </mat-form-field>
            </div>
        </div>


    </div>
    <div class="col-md-6">

        <h2 class="mat-title">Room overview</h2>
        <mat-form-field class="example-full-width">
            <textarea matInput [(ngModel)]="room.shortDescription" rows="5" placeholder="Short description"></textarea>
        </mat-form-field>

        <!-- <quill-editor [modules]="config" [(ngModel)]="room.shortDescription" [style]="{'height':'250px'}"></quill-editor> -->


        <h2 class="mat-title">Room description</h2>
        <quill-editor [modules]="config" [(ngModel)]="room.description" [style]="{'height':'250px'}"></quill-editor>

        <hr />
        <h2 class="mat-title">Room Floor Plan</h2>
        <div class="row">
            <div class="col-md-6">
                <img *ngIf="!room.floorPlan.URL" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px; max-width: 100%" />
                <img *ngIf="room.floorPlan.URL" [src]="room.floorPlan.URL" class="img-fluid" style="max-height: 150px; max-width: 100%" />
                <button mat-mini-fab color="warn" (click)="cropImage()" style="position: absolute; top: 5px; left: 5px">
                    <mat-icon>crop</mat-icon>
                </button>
            </div>
            <div class="col-md-6">
                <app-image-upload [ownerID]="room.id" [type]="'floorPlan'" [text]="'Click here to upload'" (imageUploaded)="setFloorPlan($event)"></app-image-upload>
            </div>
        </div>


    </div>
</div>


