import { Component, OnInit } from '@angular/core';
import {HotelService} from '../../services/hotel.service';
import {Store} from '../../store';
import {OverlayService} from '../../services/overlay.service';

@Component({
  selector: 'app-hotel-features-list',
  templateUrl: './hotel-features-list.component.html',
  styleUrls: ['./hotel-features-list.component.css']
})
export class HotelFeaturesListComponent implements OnInit {

    groups = {
        featureGroups: []
    };

    newFeature = {
        groupID: undefined,
        name: ''
    };
    featureToDelete = {
        id: 0,
        groupID: 0
    };

  constructor(
      private hotelService: HotelService,
      private store: Store,
      private overlayService: OverlayService,
  ) { }

  ngOnInit() {
      this.get();
  }

  get() {
      this.hotelService.hotelFeatures('').subscribe(data => {
          this.groups.featureGroups = data;
          console.log(data);
      });
    }
    editFeatureGroup(fg) {
        this.store.set('featureGroup', fg);
        this.store.set('asideOpen', true);
        this.overlayService.open('featureGroup');
    }
    addFeatureGroup() {
        this.store.set('featureGroup', {id: 0, name: '', icon: ''});
        this.store.set('asideOpen', true);
        this.overlayService.open('featureGroup');
    }
    sortFeatureGroups() {
        this.store.set('featureGroups', this.groups.featureGroups);
        this.store.set('asideOpen', true);
        this.overlayService.open('featureGroupsSort');
    }


    openAddFeature(groupID) {
        this.newFeature.groupID = groupID;

    }
    addFeature() {
        this.hotelService.addFeature(this.newFeature).subscribe(data => {
            this.newFeature = {
                groupID: undefined,
                name: ''
            };
            this.get();
        });
    }
    cancelAddFeature() {
        this.newFeature = {
            groupID: undefined,
            name: ''
        };
    }

    setFeatureToDelete(id, groupID) {
      console.log(id, groupID)
      this.featureToDelete = {
          id: id,
          groupID: groupID
      };
    }
    doDelete() {
      console.log(this.featureToDelete)
        this.hotelService.deleteFeature(this.featureToDelete).subscribe(data => {
            this.featureToDelete = {
                id: 0,
                groupID: 0
            };

            this.get();
        });

    }
    cancelDelete() {
        this.featureToDelete = {
            id: 0,
            groupID: 0
        };
    }


}
