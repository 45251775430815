<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>

    <h2 class="mat-title">Password Reset</h2>


        <p>Click the 'Generate Password' button to reset this users password.</p>
        <p>An email will be sent to {{user.username}} informing the user of the change.</p>

        <div class="text-center margin-top-20">
            <button mat-raised-button color="warn" (click)="submit()">Generate Password</button>
        </div>

</div>


