import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Store } from '../store';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private store: Store,
    ) { }

    path() {
        return 'https://bpt-api.787512.co.uk/';
        // return 'https://roomsapi.norfolkpassport.com/';
    }
    v2Path() {
        return 'https://bpv2api.787512.co.uk/';
    }

    getHttpOptions() {
        const token = this.store.selectForLocal('token');
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            })
        };
        return httpOptions;
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError(
            'Something bad happened; please try again later.');
    }

}
