import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RateGroupService {

  constructor(
      private apiService: ApiService,
      private http: HttpClient,
  ) { }

    pricingGroups() {
      return this.http.get(this.apiService.path() + 'pricing/groups').pipe(map((data: any) => {
          return data;
      }));
    }

    pricingGroup(Id) {
        return this.http.get(this.apiService.path() + 'pricing/group/' + Id).pipe(map((data: any) => {
            return data;
        }));
    }

    PricingGroupCreate(group) {
      const dataToSend = {
          group
      };
        return this.http.post(this.apiService.path() + 'pricing/group/create', dataToSend).pipe(map((data: any) => {
            return data;
        }));

    }

    PricingGroupSave(group) {
        const dataToSend = {
            group
        };
        return this.http.post(this.apiService.path() + 'pricing/group/save', dataToSend).pipe(map((data: any) => {
            return data;
        }));

    }

    PricingGroupSaveStatus(group) {
        const dataToSend = {
            group
        };
        return this.http.post(this.apiService.path() + 'pricing/group/save/status', dataToSend).pipe(map((data: any) => {
            return data;
        }));

    }

}
