import { Router, ActivatedRoute } from '@angular/router';
import { FeedbackService } from './../../services/feedback.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-feedback-business-distro',
    templateUrl: './feedback-business-distro.component.html',
    styleUrls: ['./feedback-business-distro.component.css']
})
export class FeedbackBusinessDistroComponent implements OnInit {

    businessId = '';
    business;
    businessStaff = [];
    businessStaffExcluded = [];
    nonBusinessStaff = [];

    staffToAdd = {
        name: '',
        email: '',
    }

    commentStaffToAdd = {
        name: '',
        email: '',
    }

    constructor(
        private feedbackService: FeedbackService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this.businessId = this.route.snapshot.params.id;
        this.getBusiness();

    }

    getBusiness() {
        this.feedbackService.getBusiness(this.businessId).subscribe(data => {
            this.business = data;
            this.getStaffByOrganisation();
            this.getNonBusinessStaff();
        })
    }

    getStaffByOrganisation() {
        this.feedbackService.getBusinessStaff(this.business.ppOrganisation).subscribe(data => {
            this.businessStaff = data;
            this.getStaffExclusions();
        })
    }

    getStaffExclusions() {
        this.feedbackService.getStaffExclusions(this.business.ppOrganisation).subscribe(data => {
            this.businessStaffExcluded = data;
        })
    }

    getNonBusinessStaff() {
        this.feedbackService.getNonBusinessStaff(this.business.ppOrganisation).subscribe(data => {
            this.nonBusinessStaff = data;
        })
    }

    addNonStaff() {
        this.feedbackService.addNonStaff(this.business.ppOrganisation, this.staffToAdd).subscribe(data => {
            this.staffToAdd = { name: '', email: '' };
            this.getNonBusinessStaff();
        })
    }

    removeNonStaff(staff) {
        this.feedbackService.removeNonStaff(this.business.ppOrganisation, staff).subscribe(data => {
            this.getNonBusinessStaff();
        })
    }


    // getCommentsStaff() {
    //     this.feedbackService.getCommentsStaff(this.business.ppOrganisation).subscribe(data => {
    //         this.commentStaff = data;
    //     })
    // }

    // addCommentsStaff() {
    //     this.feedbackService.addCommentsStaff(this.business.ppOrganisation, this.commentStaffToAdd).subscribe(data => {
    //         this.commentStaffToAdd = { name: '', email: '' };
    //         this.getCommentsStaff();
    //     })
    // }

    // removeCommentsStaff(staff) {
    //     this.feedbackService.removeCommentsStaff(this.business.ppOrganisation, staff).subscribe(data => {
    //         this.getCommentsStaff();
    //     })
    // }


    exclude(staff) {
        this.feedbackService.excludeStaffFromDistro(this.business.ppOrganisation, staff.id).subscribe(data => {
            this.getStaffByOrganisation();
        })
    }

    include(staff) {
        this.feedbackService.includeStaffInDistro(this.business.ppOrganisation, staff.id).subscribe(data => {
            this.getStaffByOrganisation();
        })
    }

    excludeAll() {
        this.feedbackService.excludeAllStaffFromDistro(this.business.ppOrganisation).subscribe(data => {
            this.getStaffByOrganisation();
        })
    }

    includeAll() {
        this.feedbackService.includeAllStaffInDistro(this.business.ppOrganisation).subscribe(data => {
            this.getStaffByOrganisation();
        })
    }

    isExcluded(staffId) {
        return this.businessStaffExcluded.indexOf(staffId)
    }

    removeAll() {

    }

    addAll() {

    }
}
