<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>
    
    <h2 class="mat-title">Add New User</h2>
    
    <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="user.firstname" placeholder="Enter Firstname">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="user.surname" placeholder="Enter Surname">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input type="email" matInput [(ngModel)]="user.email" placeholder="Email Address">
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <input type="password" matInput [(ngModel)]="user.password" placeholder="Password">
    </mat-form-field>

    
        <h2 class="aside">Permissions</h2>
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let p of user.permissions">
                <mat-slide-toggle style="margin: 7px;"
                                  [color]="'warn'"
                                  [(ngModel)]="p.allow"
                                  class="example-margin"
                                  [checked]="p.allow">
                    {{p.description}}
                </mat-slide-toggle>
            </mat-list-item>
        </mat-list>
        
        
        <div class="text-right margin-top-20">
            <button  mat-raised-button color="warn" (click)="submit()">Submit</button>
        </div>

</div>


