<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            Users
        </div>
        <div class="col-md-2 text-right">
            <button *ngIf="(user$ | async)?.permissions['usersCreate']" mat-raised-button color="warn" (click)="userCreate()">Add User</button>
        </div>
    </div>
</mat-card>

    <div class="row">
        <div class="col-md-4">
            <mat-card class="margin-top-10">
                <mat-form-field class="text-left" style="width: 100%">
                    <input matInput [(ngModel)]="params.searchTerm" (keyup)="loadUsers()" placeholder="Search Users">
                </mat-form-field>

                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.sort"
                        (selectionChange)="loadUsers()"
                        placeholder="Sort By">
                        <mat-option
                            [value]="sort.ID"
                            *ngFor="let sort of params.sorts">
                            {{sort.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card>
        </div>
        <div class="col-md-8">
            <mat-card class="margin-top-10">
                <div *ngIf="(users$ | async).length">
                    <table class="table example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Created</th>
                            <th>Last Login</th>
                            <th>Active?</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of (users$ | async); let i = index" [ngClass]="{'tr-odd': isOdd(i)}">
                            <td>
                                <span>{{user.firstname}} {{user.surname}}</span>
                            </td>
                            <td>
                        <span>
                            {{user.email}}
                        </span>
                            </td>
                            <td>{{user.created | date:'dd MMM yyyy'}}</td>
                            <td>{{user.lastLogin | date:'dd MMM yyyy HH:mm'}}</td>

                            <td>
                        <span>
                            <mat-slide-toggle
                                [color]="'warn'"
                                [(ngModel)]="user.status"
                                (ngModelChange)="setStatus(user)"
                                class="example-margin"
                                [checked]="user.status">
                            </mat-slide-toggle>

                            </span>
                            </td>
                            <td>
                                <button class="mr-2" *ngIf="(user$ | async)?.permissions['usersUpdate']" mat-icon-button (click)="passwordReset(user)">
                                    <mat-icon>padlock</mat-icon>
                                </button>

                                <button class="mr-2" *ngIf="(user$ | async)?.permissions['usersUpdate']" mat-icon-button (click)="userUpdate(user)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button *ngIf="(user$ | async)?.permissions['usersDelete']" mat-icon-button (click)="delete(user)">
                                    <mat-icon>delete</mat-icon>
                                </button>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>

<!--<mat-card class="margin-top-10">-->
    <!--<app-pagination [params]="params" [term]="'Users'" (update)="paginate($event)"></app-pagination>-->
<!--</mat-card>-->

<app-confirmation *ngIf="dataToDelete" [data]="deleteConfirmation" (complete)="deleteAction($event)"></app-confirmation>
