import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from './store';
import { AuthenticationService } from './services/authentication.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuService } from './services/menu.service';
import { OverlayService } from './services/overlay.service';
import { CountiesService } from './services/counties.service';
import { HotelService } from './services/hotel.service';
import { RoomService } from './services/room.service';
import { BookingService } from './services/booking.service';
import { PackageService } from './services/package.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    menuOpen$ = this.store.select<boolean>('menuOpen');
    menu$ = this.store.select<any[]>('menu');
    user$ = this.store.select<any>('user');
    userToEdit$ = this.store.select<any>('userToEdit');
    growler$ = this.store.select<any>('growler');
    overlays$ = this.store.select<any>('overlays');
    asideOpen$ = this.store.select<boolean>('asideOpen');
    roomType$ = this.store.select<any>('roomType');
    room$ = this.store.select<any>('room');
    cropImage$ = this.store.select<any>('cropImage');
    area$ = this.store.select<any>('area');

    userPermissions = [];

    constructor(
        private router: Router,
        private menuService: MenuService,
        private authService: AuthenticationService,
        private overlayService: OverlayService,
        private countiesService: CountiesService,
        private store: Store,
        private hotelService: HotelService,
        private roomService: RoomService,
        private bookingService: BookingService,
        private packageService: PackageService,
        changeDetectorRef: ChangeDetectorRef, media: MediaMatcher
    ) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.authService.cookieCheck();
        if (this.store.selectForLocal('user')) {
            this.store.set('showMenu', true);
            this.bookingService.search();
            this.hotelService.search();
            this.roomService.search();
            this.roomService.roomTypes();
        }
        this.countiesService.all();
        const user = this.store.selectForLocal('user');
        if (user) {
            this.userPermissions = user.permissions;
        }
    }

    ngOnInit() {
        console.log(this.store);
    }
    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
    refreshRoomTypes(e) {
        this.roomService.roomTypes();
    }
    navigate(option) {
        // this.menuService.closeMenu();
        this.store.set('menuOptionActive', option);
        this.router.navigate([option.route], { replaceUrl: true });
    }

    setArea(v, closeSideMenu) {
        this.store.set('area', v);
        if (v === 'bookings') {
            this.router.navigate(['/bookings'])
        }
        if (v === 'events') {
            this.router.navigate(['/events'])
        }
        if (v === 'passport') {
            this.router.navigate(['/passports'])
        }
        if (v === 'feedback') {
            this.router.navigate(['/feedback/invites'])
        }
        if (v === 'giftcards') {
            this.router.navigate(['/giftcards'])
        }
        if (v === 'marketing') {
            this.router.navigate(['/packages'])
        }
        if (v === 'settings') {
            this.router.navigate(['/users'])
        }
        if (closeSideMenu) {
            this.menuService.toggleMenu();
        }
    }

    logoutConfirmation() {
        this.menuService.closeMenu();
        this.authService.logout();
        this.router.navigate(['']);
    }
    toggleMenu() {
        this.menuService.toggleMenu();
    }
    toggleHeaderMenu() {
        this.menuService.toggleHeaderMenu();
    }
    closeAside() {
        this.store.set('asideOpen', false);
        this.overlayService.closeAll();
    }
    imageCropped(e) {
        console.log(e);
    }
    userHasPermission(option) {
        return this.userPermissions[option.permissionGroup];
    }
    packageDeleted(e) {
        console.log(e);
        const p = this.packageService.defaultParams;
        this.packageService.search(p).subscribe(data => {
        });
    }

}
