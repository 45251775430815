import { Component, OnInit } from '@angular/core';
import { BookingService } from '../services/booking.service';
import { ToolsService } from '../services/tools.service';
import { Store } from '../store';

@Component({
    selector: 'app-product-report',
    templateUrl: './product-report.component.html',
    styleUrls: ['./product-report.component.css']
})
export class ProductReportComponent implements OnInit {
    hotels$ = this.store.select<any[]>('hotels');

    cards = [];
    totals = [];
    startDate;
    endDate;
    stayStartDate;
    stayEndDate;

    dataToResend;
    confirmationData = {
        title: 'Confirm resend',
        detail: 'Are you sure you want to resend this gift card email?'
    }

    params = {
        searchText: '',
        hotelId: undefined,
        startDate: undefined,
        endDate: undefined,
        stayStartDate: undefined,
        stayEndDate: undefined,
        status: 1,
        limit: 32,
        limits: [8, 16, 24, 32],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "SUM(booking_items.itemCost) DESC",
        sorts: [
            { id: "booking_items.name", name: "Name A-Z" },
            { id: "booking_items.name DESC", name: "Name Z-A" },
            { id: "hotel.name", name: "Brand A-Z" },
            { id: "hotel.name DESC", name: "Brand Z-A" },
            { id: "SUM(booking_items.itemCost) DESC", name: "Value Highest" },
            { id: "SUM(booking_items.itemCost)", name: "Value Lowest" },
        ],
    };

    constructor(
        private bookingService: BookingService,
        private toolsService: ToolsService,
        private store: Store,
    ) { }

    ngOnInit() {
        this.getCards();
    }

    paginate(e) {
        this.params = e;
        this.getCards();
    }


    getCards() {
        if (this.startDate) {
            this.params.startDate = this.startDate;
        }
        if (this.endDate) {
            this.params.endDate = this.endDate;
        }

        if (this.stayStartDate) {
            this.params.stayStartDate = this.stayStartDate;
        }
        if (this.stayEndDate) {
            this.params.stayEndDate = this.stayEndDate;
        }

        this.bookingService.searchExtras(this.params).subscribe(data => {

            this.cards = data.data;
            this.totals = data.totals;

            let cumSpend = 0;
            let cumSpends = 0;

            data.totals.forEach(t => {
                cumSpend = +cumSpend + +t.totalSpend;
                cumSpends = +cumSpends + +t.totalSpends;
            })
            this.totals.push({
                brand: 'Total',
                totalSpend: cumSpend,
                totalSpends: cumSpends
            })
            console.log(this.totals)
            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }

        })
    }

    resend(data) {
        this.dataToResend = data;
    }
    resendAction(e) {

        if (e.action) {
            this.bookingService.resendGiftCardEmail(this.dataToResend.id).subscribe(data => {
                this.dataToResend = undefined;
                this.getCards();
            })
        } else {
            this.dataToResend = undefined;
        }
    }

}
