import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { HotelService } from '../../services/hotel.service';
import { OverlayService } from '../../services/overlay.service';
import { Store } from '../../store';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';

@Component({
    selector: 'app-hotels-edit',
    templateUrl: './hotels-edit.component.html',
    styleUrls: ['./hotels-edit.component.css']
})
export class HotelsEditComponent implements OnInit {

    hotel = this.store.selectForLocal('hotel');

    constructor(
        private overlayService: OverlayService,
        private hotelService: HotelService,
        private store: Store,
        private router: Router,
        private cacheService: CacheService,
    ) {

    }

    ngOnInit() {
        if (!this.hotel) {
            this.back();
        }
        console.log(this.hotel);


    }

    save() {
        this.hotelService.save(this.hotel);
        this.cacheService.clearCache().subscribe();
        this.back();
    }

    back() {
        this.router.navigate(['/hotels']);
    }
}
