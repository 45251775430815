<app-booking-navbar [step]="4"></app-booking-navbar>

<div class="row">
    <div class="col-12 col-lg-4">

        <mat-card class="mt-3">
            <mat-card-content *ngIf="(availabilitySearch$ | async)">
                <h2 class="mat-title mb-2">Room Overview</h2>
                <div class="row">
                    <div class="col-6">
                        <p class="mb-0">{{(availabilitySearch$ | async).hotel.name}}</p>
                        <p class="mb-0">{{(availabilitySearch$ | async).room.name}}</p>
                        <p class="mb-0">Check-in: {{(availabilitySearch$ | async).checkIn | date: 'dd/MM/yyyy'}}</p>

                    </div>
                    <div class="col-6">
                        <p class="mb-0">Nights: {{(availabilitySearch$ | async).nights}}</p>
                        <p class="mb-0">Adults: {{(availabilitySearch$ | async).adults}}</p>
                        <p class="mb-0">Children: {{(availabilitySearch$ | async).children}}</p>

                    </div>
                </div>


            </mat-card-content>
        </mat-card>

        <mat-card class="mt-3">
            <mat-card-content *ngIf="hotel">
                <h2 class="mat-title mb-2">Cost Breakdown</h2>
                <!-- <div class="strong">Total cost</div>
                <div>{{(basket$ | async).checkoutTotals.costs.total - (basket$ | async).checkoutTotals.discount.total - (basket$ | async).checkoutTotals.paid.giftCard - (basket$ | async).checkoutTotals.paid.voucher  | currency: 'GBP'}}</div> -->

                <div class="addOn">
                    <div class="mr-3">Room cost</div>
                    <div>{{(basket$ | async).checkoutTotals.costs.room | currency: 'GBP'}}</div>
                </div>

                <div class="addOn" *ngIf="(basket$ | async).checkoutTotals.costs.addOns > 0">
                    <div class="mr-3">Extras cost</div>
                    <div>{{(basket$ | async).checkoutTotals.costs.addOns | currency: 'GBP'}}</div>
                </div>
                <div class="addOn" *ngIf="(basket$ | async).checkoutTotals.costs.events > 0">
                    <div class="mr-3">Events</div>
                    <div>{{(basket$ | async).checkoutTotals.costs.events | currency: 'GBP'}}</div>
                </div>
                <div class="addOn" *ngIf="(basket$ | async).checkoutTotals.costs.treatments > 0">
                    <div  class="mr-3">Treatments</div>
                    <div>{{(basket$ | async).checkoutTotals.costs.treatments | currency: 'GBP'}}</div>
                </div>

                <div class="addOn" *ngIf="(basket$ | async).checkoutTotals.costs.supplements.total > 0">
                    <div class="mr-3">Supplements</div>
                    <div>{{(basket$ | async).checkoutTotals.costs.supplements.total | currency: 'GBP'}}</div>
                </div>

                <div class="addOn" *ngIf="(basket$ | async).checkoutTotals.discount.total > 0">
                    <div class="mr-3">Passport discount</div>
                    <div> - {{(basket$ | async).checkoutTotals.discount.passport | currency: 'GBP'}}</div>
                </div>
                <!-- <div class="addOn" *ngIf="(basket$ | async).checkoutTotals.discount.cs > 0"> -->
                <div class="addOn">

                    <div class="mr-3">Additional discount</div>
                    <div> - {{(basket$ | async).checkoutTotals.discount.cs | currency: 'GBP'}}</div>
                </div>



                <div class="addOn">
                    <div  class="mr-3">
                        <p class="fs-4 fs-regular mb-0">Total cost for stay</p>
                    </div>
                    <div>
                        <p class="fs-4 fs-regular mb-0">{{(basket$ | async).checkoutTotals.costs.total - (basket$ | async).checkoutTotals.discount.total | currency: 'GBP'}}</p>
                    </div>
                </div>

                <div class="addOn">
                    <div  class="mr-3">
                        <p class="fs-4 fs-regular mb-0">Payable today</p>
                    </div>
                    <div>
                        <p class="fs-4 fs-regular mb-0">{{(basket$ | async).checkoutTotals.paid.card | currency: 'GBP'}}</p>
                    </div>
                </div>


                <div class="addOn">
                    <div  class="mr-3">
                        <p class="fs-4 fs-regular mb-0">Payable upon check-out</p>
                    </div>
                    <div>
                        <p class="fs-4 fs-regular mb-0">{{(basket$ | async).checkoutTotals.unpaid.total | currency: 'GBP'}}</p>
                    </div>
                </div>


                <!-- <div>{{(+(basket$ | async).totals.total - +(basket$ | async).totals.discount - +(basket$ | async).totals.giftCardDiscount) | currency: 'GBP'}}</div>
                <h1>{{+(basket$ | async).totals.toPay}} / {{+(basket$ | async).totals.depositToPay}}</h1> -->




                <!-- <ng-container *ngIf="(basket$ | async).allowPaymentChoice">
                    <a (click)="toggleDeposit()" class="btn btn-sm btn-primary" *ngIf="(basket$ | async).fullPaymentSelected">Pay deposit only</a>
                    <a (click)="toggleDeposit()" class="btn btn-sm btn-primary" *ngIf="!(basket$ | async).fullPaymentSelected">Pay full balance</a>
                </ng-container> -->


            </mat-card-content>
        </mat-card>

    </div>
    <div class="col-12 col-lg-8">

        <div class="payment-container" *ngIf="hotel">
            <div class="costs">

                <div class="addOns-cost-container" *ngIf="(basket$ | async).events.length">
                    <div><p class="fs-4 fs-regular mb-0">Events cost</p></div>
                    <div class="addOn" *ngFor="let event of (basket$ | async).events">
                        <div class="mr-3">{{event.name}}</div>
                        <div>{{event.totalCost | currency: 'GBP'}}</div>
                    </div>
                    <div class="addOn" *ngIf="(basket$ | async).checkoutTotals.discount.events">
                        <div class="mr-3">Norfolk Passport Discount</div>
                        <div>-{{(basket$ | async).checkoutTotals.discount.events | currency: 'GBP'}}</div>
                    </div>
                    <div class="addOn">
                        <div><p class="fs-4 fs-regular mb-0">Total cost of events</p></div>
                        <div class="addOnTotal">
                            {{(basket$ | async).checkoutTotals.costs.events - (basket$ | async).checkoutTotals.discount.events | currency: 'GBP'}}
                        </div>
                    </div>

                </div>

                <div class="addOns-cost-container" *ngIf="(vouchersAvailable$ | async).length">
                    <div><p class="fs-4 fs-regular mb-0">Your Vouchers</p></div>
                    <div class="addOn voucher" *ngFor="let voucher of (vouchersAvailable$ | async)">
                        <div class="mr-3" style="width: 40%;">{{voucher.guestName}}</div>
                        <div *ngIf="voucher.discountType === 'POUND'">{{voucher.discountValue | currency: 'GBP'}} off</div>
                        <div *ngIf="voucher.discountType === 'PERC'">{{voucher.discountValue | number: '1.0-0' }}% off</div>

                        <button
                            *ngIf="!voucher.selected"
                            class="btn btn-outline-primary"
                            (click)="selectVoucher(voucher)">
                            Select
                        </button>
                        <button
                            *ngIf="voucher.selected"
                            class="btn btn-outline-secondary"
                            (click)="removeVoucher(voucher)">
                            Remove
                        </button>

                    </div>
                    <div class="addOn" *ngIf="(basket$ | async).totals.discountVoucher">
                        <div><p class="fs-4 fs-regular mb-0">Total saving</p></div>
                        <div class="addOnTotal">{{(basket$ | async).totals.discountVoucher | currency: 'GBP'}}</div>
                    </div>
                </div>


                <!-- <div class="room-cost-container" *ngIf="(basket$ | async).checkoutTotals.discount.passport > 0">
                    <div>Norfolk Passport 10% membership discount</div>
                    <div class="room-cost-amount">- {{(basket$ | async).checkoutTotals.discount.passport | currency: 'GBP'}}</div>
                </div> -->


                <mat-card class="mb-3">
                    <h2 class="mat-title mb-2">Additional Discount</h2>
                    <p class="mt-2">If required you can add a further discount to the booking</p>

                    <div class="row d-flex align-items-center">

                        <div class="col-3">
                            <label>Discount type</label>
                            <select class="form-control form-control-sm" [(ngModel)]="basket.discountCs.type">
                                <option [value]="'perc'">Percentage</option>
                                <option [value]="'pound'">Pound value</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <label>Rate or full booking?</label>
                            <select class="form-control form-control-sm" [(ngModel)]="basket.discountCs.rateOnly">
                                <option [value]="true">Rate only</option>
                                <option [value]="false">Booking value</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <label *ngIf="basket.discountCs.type === 'perc'">Percentage %</label>
                            <label *ngIf="basket.discountCs.type === 'pound'">Value £</label>
                            <input *ngIf="basket.discountCs.type === 'perc'" type="text" class="form-control form-control-sm" [(ngModel)]="basket.discountCs.typeValue" />
                            <input *ngIf="basket.discountCs.type === 'pound'" type="text" class="form-control form-control-sm" [(ngModel)]="basket.discountCs.typeValue" />
                        </div>

                        <div class="col-3">
                            <label> </label>
                            <button class="btn btn-sm btn-primary" type="button" (click)="applyCsDiscount()" style="margin-top: 29px;">
                                Apply discount
                            </button>
                        </div>
                    </div>

                </mat-card>

                <ng-container *ngIf="(basket$ | async).items.length < 2">
                    <div class="giftcard-container" *ngIf="!(basket$ | async).giftCards.length && !showGiftCardEntry">
                        <div class="row d-flex align-items-center">
                        <div class="col-7 col-lg-6">
                            <p class="fs-4 fs-regular mb-0">Redeem a gift card?</p>
                        </div>
                        <div class="col-5 col-lg-6 text-right">
                            <button *ngIf="!(basket$ | async).giftCards || !(basket$ | async).giftCards.length" class="btn btn-sm btn-warning" type="button" (click)="toggleGiftCard()">
                                Add gift card
                            </button>

                        </div>
                    </div>

                    </div>


                    <div class="giftcard-container" *ngIf="(basket$ | async).giftCards.length">
                        <p class="fs-4 fs-regular mb-0"> card(s) applied</p>
                        <div class="row mt-2">
                            <div class="col-12 col-lg-6">
                                <div *ngFor="let card of (basket$ | async).giftCards; let idx = index">
                                    <span>{{card.number}} {{card.valueToRedeem | currency: 'GBP'}}</span>
                                    <span class="ml-3">
                                        <a (click)="removeGiftCard(idx)" class="hover">Remove</a>
                                    </span>
                                </div>

                            </div>
                            <div class="col-12 col-lg-6 text-right">
                                <p>
                                    - {{(basket$ | async).checkoutTotals.paid.giftCard | currency: 'GBP'}}
                                </p>
                                <div *ngIf="!showGiftCardEntry">
                                    <button *ngIf="(basket$ | async).giftCards && (basket$ | async).giftCards.length" class="btn btn-warning" type="button" (click)="toggleGiftCard()">
                                        Add another gift card
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="giftcard-container" *ngIf="showGiftCardEntry">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class=" d-flex align-items-top mb-2">
                                    <p class="fs-4 fs-regular mb-0">Enter gift card number to confirm</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- <app-giftcard-entry [basket]="(basket$ | async)" (onClose)="toggleGiftCard()"></app-giftcard-entry> -->
                            </div>
                        </div>
                    </div>

                </ng-container>


            </div>

            <mat-card *ngIf="(basket$ | async).allowPaymentChoice">
                <mat-card-content>
                    <h2 class="mat-title mb-2">Deposit Options</h2>
                    <div class="row">
                        <div class="col-6">
                            <p class="mb-0">{{(availabilitySearch$ | async).hotel.name}} allows a choice of payment options</p>

                            <p *ngIf="(basket$ | async).checkoutTotals.costs.treatments">Please note, PIGSPA treatments have to be paid in full on booking</p>
                        </div>
                        <div class="col-6 text-right">
                            <div class="mb-3 text-right">
                                <a (click)="toggleDeposit('standard')" class="btn btn-sm btn-primary" [ngClass]="{'btn-primary' : basket.depositPaymentType === 'standard', 'btn-light' : basket.depositPaymentType !== 'standard'}">Deposit only</a>
                                <a (click)="toggleDeposit('full')" class="btn btn-sm btn-primary ml-4" [ngClass]="{'btn-primary' : basket.depositPaymentType === 'full', 'btn-light' : basket.depositPaymentType !== 'full'}">Full balance</a>
                                <a (click)="toggleDeposit('fixed')" class="btn btn-sm btn-primary ml-4" [ngClass]="{'btn-primary' : basket.depositPaymentType === 'fixed', 'btn-light' : basket.depositPaymentType !== 'fixed'}">Set amount</a>
                            </div>
                            <div class="form-row align-items-center mt-2 mb-2" *ngIf="basket.depositPaymentType === 'fixed'">

                                <div class="col-6">
                                    <label class="mt-2">Enter the amount to pay today</label>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="depositAmount" />
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-sm btn-primary" type="button" (click)="applyFixedDeposit()">Apply</button>
                                </div>
                            </div>

                        </div>
                    </div>


                </mat-card-content>
            </mat-card>

            <mat-card class="mt-3 mb-4">
                <mat-card-content>
                    <h2 class="mat-title mb-2">Payment</h2>

                    <div class="row mt-3">
                        <div class="col-5">
                            <p class="mb-1">
                                Take card payment directly over the phone or send a payment link for online payment.
                            </p>
                        </div>
                        <div class="col-7 text-right">
                            <button *ngIf="cards.length" type="button" class="btn btn-sm btn-primary" (click)="setPaymentMethod('stored')">Pay by stored card</button>
                            <button type="button" class="btn btn-sm btn-primary ml-4" (click)="setPaymentMethod('link')">Pay by payment Link</button>
                            <button type="button" class="btn btn-sm btn-primary ml-4" (click)="setPaymentMethod('card')">Pay by credit/debit card</button>
                        </div>
                    </div>


                    <div class="addOns-cost-container" *ngIf="giftCardChargeError || cardErrors.length">

                        <div class="alert alert-danger my-4" *ngIf="giftCardChargeError">
                            <div style="color: white;" class="mb-1">
                                Gift card error - {{giftCardChargeError}}. Your payment card has not been charged.
                            </div>
                        </div>
                        <div class="alert alert-danger my-4" *ngIf="cardErrors.length">
                            <div *ngFor="let error of cardErrors" class="mb-1">{{error}}</div>
                        </div>

                    </div>

                </mat-card-content>
            </mat-card>


    </div>
</div>
<app-payment-modal *ngIf="showPaymentModal" [paymentData]="paymentData" (complete)="paymentComplete($event)"></app-payment-modal>
