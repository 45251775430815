import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-brand-banner',
    templateUrl: './brand-banner.component.html',
    styleUrls: ['./brand-banner.component.css']
})
export class BrandBannerComponent implements OnInit {
    @Input() hotel;

    constructor() { }

    ngOnInit() {
    }

    setImage(e) {
        console.log(e);
        this.hotel.brandBannerImage = e.URL;
    }

}
