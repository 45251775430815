import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { ImagesService } from 'src/app/services/images.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
    selector: 'app-event-type',
    templateUrl: './event-type.component.html',
    styleUrls: ['./event-type.component.css']
})
export class EventTypeComponent implements OnInit {

    isNew = false;
    dataId = '';
    dataToEdit;

    data = {
        id: '',
        name: '',
        description: '',
        active: 1,
        leadImage: '',
    };

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],  // custom dropdown
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    constructor(
        private toolsService: ToolsService,
        private router: Router,
        private route: ActivatedRoute,
        private eventsService: EventsService,
        private imagesService: ImagesService,
    ) { }

    ngOnInit() {
        this.dataId = this.route.snapshot.params.id;
        if (this.dataId === 'create') {
            this.isNew = true;
            this.data.id = this.toolsService.secureId();
        } else {
            this.get()
        }
    }

    get() {
        this.eventsService.getType(this.dataId).subscribe(data => {
            console.log(data);
            this.data = data;
        })

    }

    save() {
        if (this.isNew) {
            this.eventsService.createType(this.data).subscribe(data => {
                this.router.navigate(['/events/types'])
            })
        } else {
            this.eventsService.updateType(this.data).subscribe(data => {
                this.get();
            })
        }
    }

    setEventBannerImage(e) {
        console.log(e.URL);
        this.data.leadImage = e.URL;

    }

}
