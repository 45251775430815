<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/rr-sync']">RR Sync Log</a>
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

  <!-- <div class="mat-card mt-2 p-2">
      <div class="row">
        <div class="col-md-2">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="params.sort"
                    (selectionChange)="filterSearch()"
                    placeholder="Sort By">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts">
                        {{sort.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

          <div class="col-md-2">
              <mat-form-field class="padding-10">
                  <mat-select [(ngModel)]="params.limit" (ngModelChange)="filterSearch()" placeholder="Per page">
                      <mat-option [value]="l" *ngFor="let l of params.limits">{{l}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>

      </div>
  </div> -->


<div class="mat-card mt-4 p-2">
  <div class="card mb-3 no-records" *ngIf="!loading && !data.length">
      No results for the search criteria entered
  </div>


  <div *ngIf="data.length">

    <div class="text-center">
        <button mat-raised-button color="primary" (click)="filterSearch()">Refresh</button>

    </div>
    <app-pagination [params]="params" (update)="search()"></app-pagination>

      <table class="example-full-width data-listing">
          <thead>
          <tr>
              <th>
                  <a *ngIf="params.sort === 'rr_api.createdAt DESC'" (click)="setSort('rr_api.createdAt')">Submitted <i class="fa fa-angle-up"></i></a>
                  <a *ngIf="params.sort !== 'rr_api.createdAt DESC'" (click)="setSort('rr_api.createdAt DESC')">Submitted <i class="fa fa-angle-down"></i></a>
              </th>
              <th>
                <a *ngIf="params.sort === 'rr_api.startedProcessing DESC'" (click)="setSort('rr_api.startedProcessing')">Started Processing <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'rr_api.startedProcessing DESC'" (click)="setSort('rr_api.startedProcessing DESC')">Started Processing <i class="fa fa-angle-down"></i></a>
            </th>
            <th>
                <a *ngIf="params.sort === 'rr_api.endedProcessing DESC'" (click)="setSort('rr_api.endedProcessing')">Ended Processing <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'rr_api.endedProcessing DESC'" (click)="setSort('rr_api.endedProcessing DESC')">Ended Processing <i class="fa fa-angle-down"></i></a>
            </th>
            <th>
                <a *ngIf="params.sort === 'rr_api.type DESC'" (click)="setSort('rr_api.type')">Type <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'rr_api.type DESC'" (click)="setSort('rr_api.type DESC')">Type <i class="fa fa-angle-down"></i></a>
            </th>
           <th class="d-none d-lg-table-cell">
                <a *ngIf="params.sort === 'rr_api.echoToken DESC'" (click)="setSort('rr_api.echoToken')">Echo Token <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'rr_api.echoToken DESC'" (click)="setSort('rr_api.echoToken DESC')">Echo Token <i class="fa fa-angle-down"></i></a>
            </th>
            <th class="d-none d-lg-table-cell">
                <a *ngIf="params.sort === 'rr_api.processed DESC'" (click)="setSort('rr_api.processed')">Processed <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'rr_api.processed DESC'" (click)="setSort('rr_api.processed DESC')">Processed <i class="fa fa-angle-down"></i></a>
            </th>
            <th class="d-none d-lg-table-cell">
                <a *ngIf="params.sort === 'rr_api.processing DESC'" (click)="setSort('rr_api.processing')">Processing <i class="fa fa-angle-up"></i></a>
                <a *ngIf="params.sort !== 'rr_api.processing DESC'" (click)="setSort('rr_api.processing DESC')">Processing <i class="fa fa-angle-down"></i></a>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="hover" *ngFor="let data of data; let i = index" [ngClass]="{'tr-odd': isOdd(i)}">
            <td class="d-none d-xl-table-cell">{{data.createdAt | date:'dd MMM yyyy HH:mm:ss'}}</td>
            <td class="d-none d-xl-table-cell">{{data.startedProcessing | date:'dd MMM yyyy HH:mm:ss'}}</td>
            <td class="d-none d-xl-table-cell">{{data.endedProcessing | date:'dd MMM yyyy HH:mm:ss'}}</td>
            <td>{{data.type}}</td>
            <td style="width: 175px">{{data.echoToken}}</td>
              <td style="width: 175px">
                  <mat-icon *ngIf="data.processed" class="text-success">check</mat-icon>
                  <mat-icon *ngIf="!data.processed" class="text-danger">remove</mat-icon>
              </td>
              <td style="width: 175px">
                <mat-icon *ngIf="data.processing" class="text-success">check</mat-icon>
                <mat-icon *ngIf="!data.processing" class="text-danger">remove</mat-icon>
            </td>

          </tr>
          </tbody>
      </table>

      <app-pagination [params]="params" (update)="search()"></app-pagination>

  </div>

</div>


