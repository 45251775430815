import { HotelService } from './../services/hotel.service';
import { RoomService } from './../services/room.service';
import { Router, ActivatedRoute } from '@angular/router';
import { VoucherService } from './../services/voucher.service';
import { Store } from './../store';
import { OverlayService } from './../services/overlay.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-voucher-edit',
    templateUrl: './voucher-edit.component.html',
    styleUrls: ['./voucher-edit.component.css']
})
export class VoucherEditComponent implements OnInit {

    hotels$ = this.store.select<any[]>('hotels');
    rooms = [];
    hotels = [];

    promotions = [];

    voucher;

    promoParams = {
        hotel: '',
    }

    config = {
        toolbar: [
            [{ 'size': ['small', false, 'large'] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ]
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private store: Store,
        private voucherService: VoucherService,
        private roomService: RoomService,
        private hotelService: HotelService,
    ) {
    }

    ngOnInit() {
        if (this.route.snapshot.params.id != 0) {
            this.getVoucher();
        } else {
            this.voucher = {
                name: '',
                guestName: '',
                overview: '',
                terms: '',
                type: 'discount',
                stDate: undefined,
                endDate: undefined,
                stBookingDate: undefined,
                endBookingDate: undefined,
                active: 1,
                hotel: '',
                promotion: '',
                discountType: '',
                discountValue: 0,
                hotels: [],
                rooms: []
            }
        }
        this.getPromotions();
        this.getHotels();

        this.getRooms();
    }

    getVoucher() {

        this.voucherService.getVoucher(this.route.snapshot.params.id).subscribe(data => {
            console.log(data.data);
            this.voucher = data.data;
        })
    }

    getPromotions() {
        this.voucherService.getVoucherPromotions(this.promoParams).subscribe(data => {
            this.promotions = data.packages;
        })
    }

    getHotels() {
        this.hotelService.allLite().subscribe(data => {
            this.hotels = data;
            console.log(data);
        });
    }

    getRooms() {
        this.roomService.allRooms().subscribe(data => {
            console.log(data);
        });

    }
    assignHotel() {
        this.voucher.promotion = '';
        this.promoParams.hotel = this.voucher.hotel;
        this.getPromotions();
    }

    save() {
        if (this.voucher.id) {
            this.voucherService.update(this.voucher).subscribe(data => {
                this.router.navigate(['/', 'vouchers'])
            });
        } else {
            this.voucherService.create(this.voucher).subscribe(data => {
                this.router.navigate(['/', 'vouchers'])
            });
        }
    }
    close() {
    }

    isHotelSelected(hotelId) {
        if (!this.voucher.hotels) {
            this.voucher.hotels = [];
        }
        if (this.voucher.hotels.indexOf(hotelId) >= 0) {
            return true;
        }

        return false;
    }

    hotelToggle(hotelId) {
        if (!this.voucher.hotels) {
            this.voucher.hotels = [];
        }
        if (this.voucher.hotels.indexOf(hotelId) >= 0) {
            this.voucher.hotels.splice(this.voucher.hotels.indexOf(hotelId), 1)
        } else {
            this.voucher.hotels.push(hotelId)
        }

    }

    isRoomSelected(roomId) {
        if (!this.voucher.rooms) {
            this.voucher.rooms = [];
        }
        if (this.voucher.rooms.indexOf(roomId) >= 0) {
            return true;
        }

        return false;
    }

    removeAllRooms(hotelId) {
        for (let h = 0; h < this.hotels.length; h++) {
            if (this.hotels[h].id === hotelId) {
                console.log('init')
                for (let r = 0; r < this.hotels[h].rooms.length; r++) {
                    if (this.voucher.rooms.indexOf(this.hotels[h].rooms[r].id) >= 0) {
                        this.voucher.rooms.splice(this.voucher.rooms.indexOf(this.hotels[h].rooms[r].id), 1)
                    }
                }
            }
        }

        for (let r = 0; r < this.voucher.rooms.length; r++) {
            if (this.voucher.rooms[r].hotelId === hotelId) {
                this.voucher.rooms.splice(r, 1);
            }
        }
    }

    addAllRooms(hotelId) {
        if (!this.voucher.rooms) {
            this.voucher.rooms = []
        }

        for (let r = 0; r < this.voucher.rooms.length; r++) {
            if (this.voucher.rooms[r].hotelId === hotelId) {
                this.voucher.rooms.splice(r, 1);
            }
        }

        for (let h = 0; h < this.hotels.length; h++) {
            console.log(this.hotels[h].id, hotelId)
            if (this.hotels[h].id === hotelId) {
                for (let r = 0; r < this.hotels[h].rooms.length; r++) {
                    this.voucher.rooms.push(this.hotels[h].rooms[r].id);
                }
            }
        }
        console.log(this.voucher.rooms)
    }

    toggleRoom(roomId) {
        console.log(roomId)
        let wasFound = false;
        for (let r = 0; r < this.voucher.rooms.length; r++) {
            if (this.voucher.rooms[r] === roomId) {
                console.log('XXXXXX')
                wasFound = true;
                this.voucher.rooms.splice(r, 1);
            }
        }

        if (!wasFound) {
            this.voucher.rooms.push(roomId);
        }
    }
}
