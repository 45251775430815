
<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>

    <h2 class="mat-title">Edit question</h2>

    <form [formGroup]="form" autocomplete="off" novalidate [autocomplete]="'off'" (ngSubmit)="save()">
        <div class="form-group">
            <label>Question</label>
            <input
                type="text"
                autocomplete="new-password"
                class="form-control"
                [ngClass]="{'is-invalid' : form.controls.question.invalid && form.controls.question.dirty}"
                formControlName="question"
                placeholder="Enter question">
            <div class="invalid-feedback">Enter a valid question</div>
        </div>

        <div class="form-group">
            <label>Topic</label>
            <input
                type="text"
                autocomplete="new-password"
                class="form-control"
                [ngClass]="{'is-invalid' : form.controls.subject.invalid && form.controls.subject.dirty}"
                formControlName="subject"
                placeholder="Enter subject">
            <div class="invalid-feedback">Enter a valid subject</div>
        </div>


        <div class="form-group">
            <label>Answer</label>

               <textarea
                   rows="8"
                   type="text"
                   autocomplete="new-password"
                   class="form-control"
                   [ngClass]="{'is-invalid' : form.controls.answer.invalid && form.controls.answer.dirty}"
                   formControlName="answer"
                   placeholder="Enter answer"></textarea>
            <div class="invalid-feedback">Answer must be entered</div>
        </div>

        <div class="mt-2">
            <a (click)="toggleStatus()">
                <i
                    class="toggle fa"
                    [ngClass]="{
                    'fa-toggle-on text-success': form.value.status,
                    'fa-toggle-off' : !form.value.status}"></i>
                Make available?
            </a>
        </div>

        <div class="mt-4 text-right">
            <button
                [disabled]="form.invalid"
                class="btn btn-round btn-primary mt-4"
                type="submit"
                tabindex="3">
                Save
            </button>
        </div>

    </form>

</div>

