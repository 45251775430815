import { Component, OnInit } from '@angular/core';
import {ProductService} from '../../services/product.service';
import {OverlayService} from '../../services/overlay.service';
import {Store} from '../../store';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

    products$ = this.store.select<any[]>('products');

    constructor(
        private productService: ProductService,
        private overlayService: OverlayService,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.productsGet();
    }

    productsGet() {
        this.productService.groupGet(this.route.snapshot.params['id']);
    }
    productAdd() {
        this.store.set('product', undefined);
        this.overlayService.open('product');
        this.store.set('asideOpen', true);
    }

    productUpdate(product) {
        this.store.set('product', product);
        this.overlayService.open('product');
        this.store.set('asideOpen', true);
    }
    productDelete(product) {
        this.store.set('product', product);
        this.overlayService.open('productDelete');
        this.store.set('asideOpen', true);
    }
}
