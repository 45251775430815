<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/cms']">CMS</a>
            <mat-icon>arrow_forward_ios</mat-icon>
            Edit Snippet
        </div>
        <div class="col-md-2 text-right">
        </div>
    </div>
</mat-card>

<mat-card>

    <div class="row margin-top-20">
        <div class="col-6">
            <button mat-raised-button (click)="back()">Back</button>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="warn" (click)="save()">Update</button>
        </div>
    </div>
    <hr />

    <ng-container *ngIf="snippet">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="snippet.name" placeholder="Snippet Name">
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width">
                    <mat-select
                        [(ngModel)]="snippet.type"
                        placeholder="Content Type">
                        <mat-option
                            [value]="t.id"
                            *ngFor="let t of snippetTypes">
                            {{t.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <ng-container *ngIf="snippet.type === 'title'">
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="snippet.content" placeholder="Enter Content Text">
            </mat-form-field>

        </ng-container>

        <ng-container *ngIf="snippet.type === 'text'">
            <div class="row" *ngIf="snippet.type !== 'wysiwyg'">
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="snippet.url_link" placeholder="Link to URL">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <mat-select
                            [(ngModel)]="snippet.url_target"
                            placeholder="How to open link">
                            <mat-option value="1">Same Tab/Window</mat-option>
                            <mat-option value="2">New Tab/Window</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <app-image-upload *ngIf="snippet.type !== 'wysiwyg'" [ownerID]="snippet.id" [type]="'cms'" [text]="'Click here to upload'" (imageUploaded)="setImage($event)"></app-image-upload>

            <div class="row" *ngIf="snippet.type !== 'wysiwyg'">
                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="snippet.content" placeholder="Enter Content Text">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="snippet.type !== 'wysiwyg'">
                <div class="col-3">
                    <mat-form-field class="example-full-width">
                        <input matInput type="number" [(ngModel)]="snippet.font.size" placeholder="Font Size">
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field class="example-full-width">
                        <mat-select
                            [(ngModel)]="snippet.font.colour"
                            placeholder="Font Colour">
                            <mat-option [value]="o" *ngFor="let o of colours">
                                {{o}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field class="example-full-width">
                        <mat-select
                            [(ngModel)]="snippet.font.weight"
                            placeholder="Font Weight">
                            <mat-option [value]="o" *ngFor="let o of weights">
                                {{o}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field class="example-full-width">
                        <mat-select
                            [(ngModel)]="snippet.font.style"
                            placeholder="Font Style">
                            <mat-option [value]="o" *ngFor="let o of styles">
                                {{o}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>


            <div class="preview" *ngIf="snippet.type !== 'wysiwyg'">
                <div
                    style="padding: 25px; background-position: center center; background-repeat: no-repeat; background-size: contain; display: flex; justify-content: center; flex-direction: column; text-align: center"
                    [style.height]="snippet.max_height + 'px'"
                     [style.width]="snippet.max_width + 'px'"
                     [style.color]="snippet.font.colour"
                    [style.font-weight]="snippet.font.weight"
                    [style.font-size]="snippet.font.size + 'px'"
                    [style.font-style]="snippet.font.style"
                    [style.background-image]="'url(' + snippet.image.URL + ')'">
                        {{snippet.content}}
                </div>

            </div>
            </ng-container>


        <ng-container *ngIf="snippet.type === 'wysiwyg'">
            <quill-editor [modules]="config" [(ngModel)]="snippet.content" [style]="{'height':'450px'}"></quill-editor>
        </ng-container>


    </ng-container>

</mat-card>






