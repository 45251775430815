import { Component, OnInit } from '@angular/core';
import { BookingService } from '../../services/booking.service';
import { ToolsService } from '../../services/tools.service';
import { Store } from '../../store';
import { GiftCardService } from 'src/app/services/gift-card.service';

@Component({
    selector: 'app-giftcard-products',
    templateUrl: './giftcard-products.component.html',
    styleUrls: ['./giftcard-products.component.css']
})
export class GiftcardProductsComponent implements OnInit {

    hotels$ = this.store.select<any[]>('hotels');

    cards = [];
    totals = [];

    showGiftCardCreateWindow = false;

    dataToDelete;
    confirmationData = {
        title: 'Confirm delete',
        detail: 'Are you sure you want to delete this product?'
    }

    params = {
        searchText: '',
        shopifyOnly: -1,
        hotelId: undefined,
        startDate: undefined,
        endDate: undefined,
        status: 1,
        limit: 32,
        limits: [8, 16, 24, 32],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "giftcard_product.name",
        sorts: [
            { id: "giftcard_product.name", name: "Name A-Z" },
            { id: "giftcard_product.name DESC", name: "Name Z-A" },
            { id: "giftcard_product.brand", name: "Brand A-Z" },
            { id: "giftcard_product.brand DESC", name: "Brand Z-A" },
            { id: "giftcard_product.price", name: "Value 0-999" },
            { id: "giftcard_product.price DESC", name: "Value 999-0" },
        ],
    };

    constructor(
        private bookingService: BookingService,
        private giftcardService: GiftCardService,
        private toolsService: ToolsService,
        private store: Store,
    ) { }

    ngOnInit() {
        this.getCards();
    }

    paginate(e) {
        this.params = e;
        this.getCards();
    }


    getCards() {

        this.giftcardService.searchProducts(this.params).subscribe(data => {

            console.log(data)
            this.cards = data.data;
            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }

        })
    }

    delete(data) {
        this.dataToDelete = data;
    }
    deleteAction(e) {

        if (e.action) {
            this.giftcardService.deleteProduct(this.dataToDelete.id).subscribe(data => {
                this.dataToDelete = undefined;
                this.getCards();
            })
        } else {
            this.dataToDelete = undefined;
        }
    }

    create() {
        this.showGiftCardCreateWindow = true;
    }
    giftCardProductCreated(e) {
        if (e.action) {
            this.getCards();
        }
        this.showGiftCardCreateWindow = false;
    }

}
