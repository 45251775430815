
<div
    class="av-cell available fs-2 h-100 w-100 hover"
    *ngIf="roomCost > 0"
    (click)="viewRoom()">
    {{roomCost | removePence}}
</div>

<div
    class="av-cell"
    *ngIf="roomCost === 0">

</div>

