import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
    ) {
    }

    exportAttendees(eventId) {
        const user = this.store.selectForLocal('user');

        const dataToSend = {
            eventId,
            email: user.email
        }
        return this.http.post('https://bpt-api.787512.co.uk/events/export/attendees/' + eventId, dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    exportPurchasers(eventId) {
        const user = this.store.selectForLocal('user');

        const dataToSend = {
            eventId,
            email: user.email
        }
        return this.http.post('https://bpt-api.787512.co.uk/events/export/purchasers/' + eventId, dataToSend)
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    get(id) {
        return this.http.get(this.env.apiV2Path + 'events/' + id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    getTypes() {
        return this.http.get(this.env.apiV2Path + 'events/types')
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    getType(slug) {
        return this.http.get(this.env.apiV2Path + 'events/types/' + slug)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    createType(eventType) {
        const dataToSend = {
            eventType
        }
        return this.http.post(this.env.apiV2Path + 'events/types/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    updateType(eventType) {
        const dataToSend = {
            eventType
        }
        return this.http.put(this.env.apiV2Path + 'events/types/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    deleteType(eventType) {
        return this.http.delete(this.env.apiV2Path + 'events/types/' + eventType.slug)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }





    search(params) {
        const dataToSend = {
            params,
        }
        return this.http.post(this.env.apiV2Path + 'events/search', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    searchAttendees(params) {
        const dataToSend = {
            params,
        }
        return this.http.post(this.env.apiV2Path + 'events/search/attendees', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    create(event) {
        const dataToSend = {
            event
        }
        return this.http.post(this.env.apiV2Path + 'events', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    update(event) {
        const dataToSend = {
            event
        }
        return this.http.put(this.env.apiV2Path + 'events', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    delete(event) {
        return this.http.delete(this.env.apiV2Path + 'events/' + event.id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    searchEventUsers(params) {
        const dataToSend = {
            params
        }

        return this.http.post(this.env.apiV2Path + 'events/users/', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));

    }

    deleteUser(userId, eventId) {
        return this.http.delete(this.env.apiV2Path + 'events/user/' + userId + '/' + eventId)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));

    }


    searchBookings(params) {
        const dataToSend = {
            params,
        }
        return this.http.post(this.env.apiV2Path + 'events/search/bookings', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


    getBooking(id) {
        return this.http.get(this.env.apiV2Path + 'events/bookings/' + id)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    sortSkus(skus) {
        const dataToSend = {
            skus
        }
        return this.http.post(this.env.apiV2Path + 'events/skus/sort', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

    deleteTicket(ticketId) {
        return this.http.delete(this.env.apiV2Path + 'events/ticket/' + ticketId)
            .pipe(
                map((data: any) => {
                    return data.data;
                },
                    catchError(this.apiService.handleError)
                ));
    }


}
