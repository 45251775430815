import { Component, OnInit } from '@angular/core';
import { OverlayService } from '../../services/overlay.service';
import { GrowlerService } from '../../services/growler.service';
import { Store } from '../../store';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

    users$ = this.store.select<any[]>('users');
    user$ = this.store.select<any>('user');

    user: any;

    params: any;

    dataToDelete;
    deleteConfirmation = {
        title: 'Confirm delete',
        detail: 'Are you sure you want to delete this user?'
    }

    constructor(
        private store: Store,
        private usersService: UserService,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
        this.params = this.usersService.params;
        this.loadUsers();
    }

    loadUsers() {
        this.usersService.loadUsers(this.params);
    }
    paginate(e) {
        this.params = e;
        this.loadUsers();
    }

    setStatus(user) {
        this.usersService.update(user).subscribe();
    }
    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }

    userCreate() {
        this.overlayService.open('userCreate');
        this.store.set('asideOpen', true);
    }
    userUpdate(user) {
        this.store.set('userToEdit', user);
        this.overlayService.open('userUpdate');
        this.store.set('asideOpen', true);
    }
    passwordReset(user) {
        this.store.set('userToEdit', user);
        this.overlayService.open('passwordReset');
        this.store.set('asideOpen', true);
    }
    delete(data) {
        this.dataToDelete = data;
    }
    deleteAction(e) {

        if (e.action) {
            this.usersService.delete(this.dataToDelete).subscribe(data => {
                this.dataToDelete = undefined;
                this.loadUsers();
            })
        } else {
            this.dataToDelete = undefined;
        }
    }

}
