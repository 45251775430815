import { Store } from './../../store';
import { OverlayService } from './../../services/overlay.service';
import { FaqService } from './../faq.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq-delete',
    templateUrl: './faq-delete.component.html',
    styleUrls: ['./faq-delete.component.css']
})
export class FaqDeleteComponent implements OnInit {

    faqQuestion;

    constructor(
        private faqService: FaqService,
        private store: Store,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
        this.faqQuestion = this.store.selectForLocal('faqQuestion');
    }
    close() {
        this.overlayService.closeAll();
    }
    delete() {
        this.faqQuestion.deleted = 1;
        this.faqService.updateQuestion(this.faqQuestion).subscribe(data => {
            this.faqService.getQuestions().subscribe();
            this.close();
        });
    }

}
