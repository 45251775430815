<div class="aside">
    <a class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </a>
    
    <h2 class="mat-title">Sort Feature Groups</h2>
    <ng-container *ngIf="featureGroups.length">
        <ul style="padding-left: 0" dragula="featureGroups" [(dragulaModel)]="featureGroups" (dragulaModelChange)="drop($event)">
            <li class="drag-list" *ngFor="let fg of featureGroups">
                <i class="fas fa-grip-lines"></i> {{fg.name}}
            </li>
        </ul>
    </ng-container>

</div>
