<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <h2 class="mat-title mb-0" *ngIf="isNew">New Passport</h2>
            <h2 class="mat-title mb-0" *ngIf="!isNew">Edit Passport</h2>
        </div>
        <div class="col-md-2 text-right">
            <button id="pullright" mat-raised-button color="primary" [routerLink]="['/passports']">
                Back
            </button>
        </div>
    </div>
</mat-card>

<ng-container *ngIf="passport">
    <mat-card class="margin-top-20" *ngIf="!isNew">
        <div class="row">
            <div class="col-lg-4">
                <p class="mb-1"><span class="strong">Card printed:</span> <span *ngIf="passport.cardPrintedAt">{{passport.cardPrintedAt | date: 'dd/MM/yyyy'}}</span></p>
                <p class="mb-1"><span class="strong">Epos Now ID:</span> {{passport.eposNowId}}</p>
                <p class="mb-1"><span class="strong">Passport number:</span> {{passport.cardNumber}}</p>
                <p class="mb-1"><span class="strong">Passport created:</span> {{passport.createdAt | date: 'dd/MM/yyyy @ HH:mm'}}</p>
            </div>
            <div class="col-lg-4">
                <p class="mb-1"><span class="strong">Email:</span> {{passport.username}}</p>
                <p class="mb-1"><span class="strong">Activated?:</span>
                    <span *ngIf="passport.confirmed"> YES</span>
                    <span *ngIf="!passport.confirmed"> NO</span>
                </p>
                <p class="mb-1"><span class="strong">Initiated Password Reset?:</span>
                    <span *ngIf="passport.passwordReset"> YES</span>
                    <span *ngIf="!passport.passwordReset"> NO</span>
                </p>
                <p class="mb-1"><span class="strong">Referral Key:</span> </p>
            </div>
            <div class="col-lg-4">
                <div class="mb-3 text-center">
                    <p>Send user 'Add to Wallet' link</p>

                    <button type="button" class="btn btn-warning btn-sm" (click)="sendWalletLink(passport.id)">
                        <i class="fa fa-envelope"></i>
                        <span *ngIf="!passport.linkSent"> Send Link</span>
                        <span *ngIf="passport.linkSent"> Link sent</span>
                    </button>

                </div>
                <div class="mb-3 text-center">
                    <p>Send user a purchase or replace physical card link</p>
                    <button type="button" class="btn btn-warning btn-sm" (click)="sendPurchaseLink(passport.confirmationId)">
                        <i class="fa fa-envelope"></i>
                        <span *ngIf="!passport.purchaseLinkSent"> Send Link</span>
                        <span *ngIf="passport.purchaseLinkSent"> Link sent</span>
                    </button>
                </div>


            </div>
        </div>
    </mat-card>


    <form [formGroup]="form" autocomplete="off" novalidate [autocomplete]="'off'" (ngSubmit)="onSubmit()">

        <div class="row">
            <div class="col-xl-6">
                <mat-card class="margin-top-20">

                    <h2 class="mat-title">Personal Details</h2>

                    <mat-form-field class="example-full-width">
                        <mat-select
                            formControlName="title"
                            placeholder="Title">
                            <mat-option
                                [value]="c.id"
                                *ngFor="let c of titles">
                                {{c.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="firstname" placeholder="First name">
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="surname" placeholder="Surname">
                    </mat-form-field>

                    <div class="row">
                        <div class="col-xl-6">
                            <mat-form-field class="example-full-width">
                                <mat-select
                                    formControlName="gender"
                                    placeholder="Gender">
                                    <mat-option
                                        [value]="c.id"
                                        *ngFor="let c of genders">
                                        {{c.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-xl-6">
                            <mat-form-field>
                                <mat-label>Date of birth</mat-label>
                                <input matInput
                                       autocomplete="off"
                                       formControlName="dob"
                                       (click)="dob.open()"
                                       [matDatepicker]="dob">
                                <!--<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
                                <mat-datepicker touchUi #dob [startView]="'multi-year'" [startAt]="dob"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="col-xl-6">
                <mat-card class="margin-top-20">

                    <h2 class="mat-title">Contact Details</h2>

                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="username" (keyup)="dupeEmail = false;" (blur)="dupeCheckEmail()" placeholder="Email address">
                    </mat-form-field>
                    <p class="text-danger" *ngIf="dupeEmail">Email address in use</p>
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="telephone" placeholder="Telephone">
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="mobile" placeholder="Mobile">
                    </mat-form-field>
                </mat-card>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <mat-card class="margin-top-50">

                    <h2 class="mat-title">Address</h2>

                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="add1" placeholder="Address Line 1">
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="add2" placeholder="Address Line 2">
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="town" placeholder="Town/City">
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="county" placeholder="County">
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="postcode" placeholder="Postcode">
                    </mat-form-field>

                </mat-card>
            </div>
            <div class="col-xl-6">
                <mat-card class="margin-top-50">

<!--                    <mat-form-field class="example-full-width">-->
<!--                        <input matInput formControlName="eposNowId" placeholder="Passport number">-->
<!--                    </mat-form-field>-->

                    <mat-form-field class="example-full-width">
                        <mat-select
                            formControlName="staffGroup"
                            placeholder="Staff group">
                            <mat-option
                                [value]="c.id"
                                *ngFor="let c of passportCompanies">
                                {{c.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                        <mat-select
                            formControlName="discountLevel"
                            placeholder="Discount level">
                            <mat-option
                                [value]="c.id"
                                *ngFor="let c of discountLevels">
                                {{c.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ngx-barcode [bc-value]="passport.cardNumber" [bc-display-value]="true"></ngx-barcode>
                </mat-card>


                <mat-card class="margin-top-50">
                    <h2 class="mat-title">Notes</h2>

                    <quill-editor [modules]="config" formControlName="notes" [style]="{'height':'250px'}"></quill-editor>

                    <!-- <mat-form-field class="example-full-width">
                        <textarea matInput formControlName="notes" placeholder="Enter notes...." rows="8"></textarea>
                    </mat-form-field> -->

                </mat-card>
            </div>
        </div>


        <mat-card class="margin-top-50">
            <div class="row">
                <label formArrayName="preferences" class="col-3" *ngFor="let preference of preferencesFormArray.controls; let i = index">
                    <input type="checkbox" [formControlName]="i">
                    {{passportCompanies[i].name}}
                </label>
            </div>
        </mat-card>

        <mat-card class="margin-top-50">
            <div class="row">
                <div class="col-6">
                    <label>
                        <input type="checkbox" formControlName="isSupplier">
                        Is this a supplier account?
                    </label>
                    <mat-form-field class="example-full-width" *ngIf="this.form.value.isSupplier">
                        <input matInput formControlName="company" placeholder="Company Name">
                    </mat-form-field>
                    <mat-form-field class="example-full-width" *ngIf="this.form.value.isSupplier">
                        <input matInput formControlName="referredBy" placeholder="Referred By">
                    </mat-form-field>

                </div>
                <div class="col-3 text-right">
                    <button *ngIf="!isNew" mat-raised-button color="danger" type="button" (click)="reprint()">
                        Add to reprint list
                    </button>
                </div>
                <div class="col-3 text-right">
                    <button mat-raised-button color="warn" type="submit">Save</button>
                </div>
            </div>
        </mat-card>


    </form>







    <ng-container *ngIf="this.passportId !== 'new'">

        <mat-card class="margin-top-50 mb-4 text-center" *ngIf="!giftcards.length">
            <h2 class="mat-title">No gift cards purchased</h2>
        </mat-card>

        <div class="row mb-4" *ngIf="giftcards.length">
            <div class="col-xl-12">
                <mat-card class="margin-top-50">
                    <h2 class="mat-title">Gift Cards</h2>

                    <table class="example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>Card number</th>
                            <th>Brand</th>
                            <th>Card type</th>
                            <th>Purchased</th>
                            <th>Value</th>
                            <th>Balance</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let t of giftcards">
                            <tr>
                                <td style="width: 130px;" class="hover">{{t.cardNumber}}</td>
                                <td class="hover" style="text-transform: capitalize;">{{t.brand}}</td>
                                <!-- <td class="hover" style="text-transform: capitalize;">{{t.productName}}</td> -->
                                <td class="hover" style="text-transform: capitalize;">{{t.shortName}}</td>
                                <td style="width: 200px;" class="hover">{{t.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                                <td style="width: 130px;" class="hover">{{t.purchaseValue | currency: 'GBP'}}</td>
                                <td style="width: 130px;" class="hover">{{t.balance | currency: 'GBP'}}</td>
                                <td style="width: 130px;" class="hover">
                                    <span *ngIf="t.cardStatus !== 'Normal'" class="text-danger">{{t.cardStatus}}</span>
                                    <span *ngIf="t.cardStatus === 'Normal'" class="text-success">{{t.cardStatus}}</span>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>

                </mat-card>

            </div>
        </div>



        <hr />


        <mat-card class="margin-top-50 mb-4 text-center" *ngIf="!passport.payments.length">
            <h2 class="mat-title">No passport payments to report</h2>
        </mat-card>

        <div class="row mb-4" *ngIf="passport.payments.length">
            <div class="col-xl-12">
                <mat-card class="margin-top-50">
                    <h2 class="mat-title">Passport payments</h2>

                    <table class="example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>TransactionId</th>
                            <th>Date/time</th>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let t of passport.payments">
                            <tr>
                                <td style="width: 130px;" class="hover">{{t.id}}</td>
                                <td style="width: 200px;" class="hover">{{t.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                                <td class="hover" style="text-transform: capitalize;">{{t.description}}</td>
                                <td style="width: 130px;" class="hover">{{t.amount | currency: 'GBP'}}</td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>

                </mat-card>

            </div>
        </div>



        <hr />
        <mat-card class="margin-top-50 text-center" *ngIf="!bookings.length">
            <h2 class="mat-title">No bookings to report</h2>
        </mat-card>


        <div class="row" *ngIf="bookings.length">
            <div class="col-xl-12">
                <mat-card class="margin-top-50">
                    <h2 class="mat-title">Bookings</h2>
                    <table class="example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>Check In</th>
                            <th>Status</th>
                            <th>Channel</th>
                            <th>Hotel</th>
                            <th>Room</th>
                            <th>Nights</th>
                            <th>Adults / Children</th>
                            <th>Gross (&pound;)</th>
                            <th>Depost Paid (&pound;)</th>
                            <th>Created</th>
                            <!-- <th>Extras?</th>
                            <th>Promo</th> -->
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let booking of bookings">
                            <td class="hover" (click)="viewBooking(booking)">{{booking.checkIn | date: 'dd/MM/yyyy'}}</td>
                            <td class="hover" (click)="viewBooking(booking)">{{booking.resStatus}}</td>
                            <td class="hover" (click)="viewBooking(booking)">{{booking.channel}}</td>
                            <td class="hover" (click)="viewBooking(booking)">{{booking.hotel.name}}</td>
                            <td class="hover" (click)="viewBooking(booking)">{{booking.room.name}}</td>
                            <td class="hover" (click)="viewBooking(booking)">{{booking.nights}}</td>
                            <td class="hover" (click)="viewBooking(booking)">{{booking.adults}} / {{booking.children}}</td>
                            <td class="hover" (click)="viewBooking(booking)">&pound;{{booking.payment.gross}}</td>
                            <td class="hover" (click)="viewBooking(booking)">&pound;{{booking.payment.deposit}}</td>
                            <td class="hover" (click)="viewBooking(booking)">{{booking.created | date: 'dd/MM/yyyy'}}</td>
                            <!-- <td class="hover" (click)="viewBooking(booking)">
                                <i class="fa fa-check text-success" *ngIf="booking.hasExtras"></i>
                            </td> -->

                            <!-- <td class="hover" (click)="viewBooking(booking)">
                                <i class="fa fa-check text-success" *ngIf="booking.hasUpgrade"></i>
                            </td> -->
                            <!-- <td class="hover" (click)="viewBooking(booking)">
                                <i class="fa fa-check text-success" *ngIf="booking.promoCode"></i>
                            </td> -->
                            <td class="text-right">

                                <button type="button" class="btn btn-warning btn-sm" (click)="viewBooking(booking)">
                                    <i class="fa fa-search"></i> View
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                </mat-card>
            </div>
        </div>


        <mat-card class="margin-top-50 mb-4 text-center" *ngIf="!transactions.length">
            <h2 class="mat-title">No transactions to report</h2>
        </mat-card>

        <div class="row mb-4" *ngIf="transactions.length">
            <div class="col-xl-12">
                <mat-card class="margin-top-50">
                    <h2 class="mat-title">Transactions</h2>

                    <table class="example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>TransactionId</th>
                            <th>Date/time</th>
                            <th>Location</th>
                            <th>Total items</th>
                            <th>After discount</th>
                            <th>Discount</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let t of transactions">
                            <tr (click)="toggleViewingTransactions(t)">
                                <td style="width: 130px;" class="hover">{{t.transactionId}}</td>
                                <td style="width: 200px;" class="hover">{{t.createdAt | date: 'dd/MM/yyyy HH:mm'}}</td>
                                <td class="hover" style="text-transform: capitalize;">{{t.brand}}</td>
                                <td style="width: 130px;" class="hover">{{t.totalItems}}</td>
                                <td style="width: 130px;" class="hover">{{t.totalAmount | currency: 'GBP'}}</td>
                                <td style="width: 130px;" class="hover">{{t.discountValue | currency: 'GBP'}}</td>
                                <td style="width: 30px;">
                                    <a (click)="toggleViewingTransactions(t)">
                                        <i *ngIf="!t.viewingTransactions" class="fa fa-chevron-down"></i>
                                        <i *ngIf="t.viewingTransactions" class="fa fa-chevron-up"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="t.viewingTransactions">
                                <td colspan="6">
                                    <table class="example-full-width" *ngIf="transactionsToView.length">
                                        <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Category</th>
                                            <th>Quantity</th>
                                            <th>After discount</th>
                                            <!-- <th>Discount</th> -->
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let t of transactionsToView">
                                            <td class="hover">{{t.name}}</td>
                                            <td class="hover">{{t.categoryName}}</td>
                                            <td class="hover" style="width: 130px;">{{t.quantity}}</td>
                                            <td class="hover" style="width: 130px;">{{((t.unitPrice * t.quantity) - t.discountAmount) | currency: 'GBP'}}</td>
                                            <!-- <td class="hover" style="width: 130px;">{{t.discountAmount | currency: 'GBP'}}</td> -->
                                        </tr>
                                        </tbody>
                                    </table>

                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>




                </mat-card>

                <app-pagination [params]="transactionParams" (update)="getTransactions()"></app-pagination>


            </div>
        </div>









        <mat-card class="margin-top-50 mb-4 text-center" *ngIf="!feedback.length">
            <h2 class="mat-title">No feedback to report</h2>
        </mat-card>

        <div class="row mb-4" *ngIf="feedback.length">
            <div class="col-xl-12">
                <mat-card class="margin-top-50">
                    <h2 class="mat-title">Feedback</h2>

                    <table class="example-full-width data-listing">
                        <thead>
                        <tr>
                            <th>Sent</th>
                            <th>Completed</th>
                            <th>Template</th>
                            <th>Score</th>
                            <th>Staff Comment</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let t of feedback">
                            <tr>
                                <td (click)="toggleViewingFeedback(t)" style="width: 200px;" class="hover">{{t.sent | date: 'dd/MM/yyyy HH:mm'}}</td>
                                <td (click)="toggleViewingFeedback(t)" style="width: 200px;" class="hover">{{t.completed | date: 'dd/MM/yyyy HH:mm'}}</td>
                                <td (click)="toggleViewingFeedback(t)" class="hover">{{t.templateName}}</td>
                                <td (click)="toggleViewingFeedback(t)" class="hover">
                                    <span class="mr-2">{{t.score}}%</span>
                                    <ng-container *ngIf="t.score > 0">
                                        <img style="height:25px;" *ngIf="t.score >= 98" [src]="'https://feedback.norfolkpassport.com/assets/images/large_thumbsup_gold.png'" />
                                        <img style="height:25px;" *ngIf="t.score < 98 && t.score >= 95" [src]="'https://feedback.norfolkpassport.com/assets/images/large_thumbsup_green.png'" />
                                        <img style="height:25px;" *ngIf="t.score < 95" [src]="'https://feedback.norfolkpassport.com/assets/images/large_thumbsup_red.png'" />
                                    </ng-container>

                                </td>
                                <td>
                                    <button *ngIf="t.completed && !t.staffResponse" type="button" class="btn btn-warning btn-sm" (click)="addFeedbackComment(t)">
                                        <i class="fa fa-comment"></i> Add comment
                                    </button>
                                    <span *ngIf="t.staffResponse">
                                        {{t.username}} commented {{t.respondedAt | date: 'dd/MM/yyyy'}}

                                        <a class="hover" (click)="addFeedbackComment(t)">
                                            <i class="fa fa-edit ml-2"></i>
                                        </a>

                                    </span>
                                </td>

                                <td (click)="toggleViewingFeedback(t)" style="width: 30px;">
                                    <a class="hover">
                                        <i *ngIf="!t.viewingFeedback" class="fa fa-chevron-down"></i>
                                        <i *ngIf="t.viewingFeedback" class="fa fa-chevron-up"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="t.viewingFeedback">
                                <td colspan="6">
                                    <table class="example-full-width" *ngIf="t.answers.length">
                                        <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Comments</th>
                                            <th>Response</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let a of t.answers">
                                            <td class="hover" style="width: 45%;">{{a.text}}</td>
                                            <td class="hover" style="width: 45%;">{{a.comments}}</td>
                                            <td class="hover">
                                            <ng-container *ngIf="a.question_type_id < 4">
                                                <img *ngIf="a.response === 'tu'" [src]="'https://feedback.787512.co.uk/assets/images/small_thumbsup_green.png'" />
                                                <img *ngIf="a.response === 'td'" [src]="'https://feedback.787512.co.uk/assets/images/small_thumbsdown_red.png'" />
                                            </ng-container>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>




                </mat-card>

                <app-pagination [params]="feedbackParams" (update)="getFeedback()"></app-pagination>


            </div>
        </div>





    </ng-container>



</ng-container>

<app-feedback-comment *ngIf="feedbackCommentData" [feedbackData]="feedbackCommentData" (feedbackCommentDone)="feedbackCommentDone($event)"></app-feedback-comment>
