<div>
    <h2 class="mat-title">Contact tile</h2>

    <div class="row">
        <div class="col-6">
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="contact.generic" placeholder="Generic contact tile title">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <textarea matInput [(ngModel)]="contact.textBlock" rows="6" placeholder="Non hotel specific contact tile text"></textarea>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="contact.textBlock" placeholder="Generic contact tile text">
            </mat-form-field> -->
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="contact.brand" placeholder="Brand contact tile title">
            </mat-form-field>

        </div>
        <div class="col-6">
            <!-- <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="contact.q1" placeholder="Generic contact question one">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="contact.q2" placeholder="Generic contact question two">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="contact.q3" placeholder="Generic contact question three">
            </mat-form-field> -->
        </div>
    </div>
</div>

<div class="row margin-top-20">
    <div class="col-6 text-right">
        <button mat-raised-button color="warn" (click)="save()">Save</button>
        <!-- <button mat-raised-button (click)="back()">Back</button> -->
    </div>
    <div class="col-6 text-right">
    </div>
</div>
