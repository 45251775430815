import { VoucherService } from './../services/voucher.service';
import { ReportingService } from './../services/reporting.service';
import { Router } from '@angular/router';
import { GrowlerService } from './../services/growler.service';
import { OverlayService } from './../services/overlay.service';
import { Store } from './../store';
import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash';

@Component({
    selector: 'app-vouchers',
    templateUrl: './vouchers.component.html',
    styleUrls: ['./vouchers.component.css']
})
export class VouchersComponent implements OnInit {

    hotels$ = this.store.select<any[]>('hotels');

    data = [];
    voucherToEdit;
    voucherToDelete;

    loading = false;
    showVoucherWindow = false;
    recordsSelected = 0;

    yesNo = [{ id: 0, name: 'Please Select' }, { id: 1, name: 'Yes' }, { id: 2, name: 'No' }]
    params = {
        searchText: '',
        hotel: undefined,
        active: 1,
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "voucher.name",
        sorts: [
            { ID: "voucher.name ASC", name: "Name A-Z" },
            { ID: "voucher.name DESC", name: "Name Z-A" },
            { ID: "voucher.createdAt DESC", name: "Recent First" },
            { ID: "voucher.createdAt ASC", name: "Recent Last" },
        ],
    };

    asideOpen$ = this.store.select<boolean>('asideOpen');
    overlays$ = this.store.select<any>('overlays');

    exportConfirmationData = {
        open: false,
        title: '',
        detail: '',
        data: undefined,
    };

    constructor(
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
        private router: Router,
        private voucherService: VoucherService,
    ) {
        this.filterSearch = debounce(this.filterSearch, 450);
    }

    ngOnInit() {
        this.filterSearch();
    }

    setSort(v) {
        this.params.sort = v;
        this.search();
    }

    setParam(name, value) {
        this.params[name] = '';
        this.search();
    }

    filterSearch() {
        this.params.page = 0;
        this.search();
    }

    search() {
        this.voucherToEdit = undefined;
        this.loading = true;

        this.voucherService.search(this.params).subscribe(data => {
            console.log(data.data)
            this.data = data.data;

            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
            window.scroll(0, 0);
            this.loading = false;
        });
    }

    setStatus(data) {
    }

    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }

    exportConfirm() {
        this.exportConfirmationData.title = 'Export Records';
        this.exportConfirmationData.data = '';
        this.exportConfirmationData.detail = 'Clicking confirm will create a CSV file of your selection and deliver by email';
        this.exportConfirmationData.open = true;
    }
    //   exportConfirmationComplete(e) {
    //     this.exportConfirmationData.open = false;
    //     if (e.action) {
    //       this.reportingService.export(this.params).subscribe(data => {
    //         // this.search();
    //         if (data.success) {
    //           this.growlerService.notification(['Your export has completed and will be emailed to you']);
    //         } else {
    //           this.growlerService.error(['Sorry - there was a problem exporting your data']);
    //         }
    //         console.log(data);
    //       });
    //     } else {
    //       console.log('cancelled');
    //     }

    //   }


    create() {
        const voucher = {
            name: '',
            type: '',
            stDate: undefined,
            endDate: undefined,
            stBookingDate: undefined,
            endBookingDate: undefined,
            active: 1,
            hotel: '',
            promotion: '',
            discountType: '',
            discountValue: 0,
            hotels: [],
            rooms: []
        }
        this.voucherToEdit = voucher;
        this.router.navigate(['/', 'vouchers', 'edit', 0])
    }

    update(voucher) {
        this.router.navigate(['/', 'vouchers', 'edit', voucher.id])
        // this.voucherToEdit = JSON.parse(JSON.stringify(voucher));
    }

    delete(voucher) {
        this.voucherToDelete = voucher;
    }

    viewUsers(voucher) {
        this.router.navigate(['/vouchers', voucher.id]);
    }
}
