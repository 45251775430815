import { Component, OnInit } from '@angular/core';
import { TestimonialService } from '../services/testimonial.service';

@Component({
    selector: 'app-testimonial',
    templateUrl: './testimonial.component.html',
    styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {
    params;
    testimonials = [];

    constructor(private testimonialService: TestimonialService) {}

    ngOnInit() {
        this.params = this.testimonialService.params;
        this.search();
    }

    search() {
        this.testimonialService.search(this.params).subscribe(data => {
            this.testimonials = data.data;

            this.params.pages = Math.ceil(
                data.params.totalRecords / this.params.limit
            );
            this.params.totalRecords = data.params.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
        });
    }
}
