import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import { GrowlerService } from './growler.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RoomService {
    env = environment;

    defaultParams = {
        searchTerm: '',
        name: '',
        hotel: '',
        limit: 10,
        limits: [10, 20, 30, 40],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'room.name ASC',
        sorts: [
            { ID: 'room.name ASC', name: 'Hotel Name A-Z' },
            { ID: 'room.name DESC', name: 'Hotel Name Z-A' },
            { ID: 'hotel.name DESC', name: 'Hotel A-Z' },
            { ID: 'hotel.name ASC', name: 'Hotel Z-A' },
        ],
    };



    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) { }

    updateParams(params) {
        this.store.set('roomParams', params);
        this.search();
    }
    resetParams() {
        this.store.set('roomParams', JSON.parse(JSON.stringify(this.defaultParams)));
        console.log(JSON.parse(JSON.stringify(this.defaultParams)))
        this.search();
    }

    addSupplement(supplement) {
        const dataToSend = {
            supplement: supplement
        };
        return this.http.post(this.apiService.path() + 'supplement/create', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    deleteSupplement(supplement) {
        const dataToSend = {
            supplement: supplement
        };
        return this.http.post(this.apiService.path() + 'supplement/delete', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    setRoom(room) {
        const data = JSON.parse(JSON.stringify(this.store.selectForLocal('rooms')));
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === room.id) {
                data[i] = room;
            }
        }
        this.store.set('room', room);
        this.store.set('rooms', data);
    }
    save(room) {
        const data = this.store.selectForLocal('rooms');
        let foundRecord = false;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === room.id) {
                data[i] = room;
                foundRecord = true;
            }
        }
        this.store.set('rooms', data);

        this.saveRemote(room);
    }

    create(room) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            room: room
        };
        return this.http.post(this.apiService.path() + 'room/create', dataToSend).subscribe((data: any) => {
            if (data.errors.length) {
                this.growlerService.error(data.errors);
            } else {
                this.setRoom(data.data);
                this.growlerService.notification(['Room Details Added']);
            }
        });
    }

    saveRemote(room) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            room: room
        };
        return this.http.post(this.apiService.path() + 'room', dataToSend).subscribe((data: any) => {
            if (data.errors.length) {
                this.growlerService.error(data.errors);
            } else {
                this.setRoom(data.data);
                this.growlerService.notification(['Room Details Updated']);
            }
        });
    }


    search() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('roomParams')
        };
        return this.http.post(this.apiService.path() + 'room/search', dataToSend).subscribe((data: any) => {
            this.store.set('rooms', data.data);
            const p = this.store.selectForLocal('roomParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (let i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            this.store.set('roomParams', p);
        });
    }

    byHotelID(hotelID) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            hotelID: hotelID
        };
        return this.http.post(this.apiService.path() + 'room/byhotel', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }
    roomFeaturesByHotel(hotelID) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            hotelID: hotelID
        };
        return this.http.post(this.apiService.path() + 'hotel/room/features', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    setRoomFeatures(roomID, featureID) {
        const dataToSend = {
            roomID: roomID,
            featureID: featureID
        };
        return this.http.post(this.apiService.path() + 'hotel/room/features/set', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    addRoomFeature(feature) {
        const dataToSend = {
            name: feature.name,
        };
        return this.http.post(this.apiService.path() + 'room/feature/add', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    roomTypes() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
        };
        return this.http.post(this.apiService.path() + 'room-types/all', dataToSend).subscribe((data: any) => {
            this.store.set('roomTypes', data.data);
        });
    }

    allRooms() {
        return this.http.get(this.env.apiV2Path + 'room').pipe(map((data: any) => {
            return data.data;
        }));
    }

    addRoomType(roomType) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            roomType: roomType
        };
        return this.http.post(this.apiService.path() + 'room-type', dataToSend).pipe(map((data: any) => {
            this.roomTypes();
            return data;
        }));
    }
    deleteRoomType(roomType) {
        const dataToSend = {
            id: roomType.id
        };
        return this.http.post(this.apiService.path() + 'room-type/delete', dataToSend).pipe(map((data: any) => {
            this.roomTypes();
            return data;
        }));
    }



}
