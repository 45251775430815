import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule, MatPseudoCheckboxModule, } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    exports: [
        MatTabsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatPseudoCheckboxModule,
        MatIconModule,
        MatNativeDateModule,
        MatChipsModule,
        MatSidenavModule,
        MatListModule,
        MatInputModule,
        MatCardModule,
        MatOptionModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatSelectModule,
        MatMenuModule,
        MatCheckboxModule,
        MatSlideToggleModule,
    ]
})
export class MaterialModule {
}
