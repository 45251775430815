import {AfterContentInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {HotelService} from '../../services/hotel.service';
import {OverlayService} from '../../services/overlay.service';
import {Store} from '../../store';
import {Router} from '@angular/router';

@Component({
  selector: 'app-hotel-create',
  templateUrl: './hotel-create.component.html',
  styleUrls: ['./hotel-create.component.css']
})
export class HotelCreateComponent implements OnInit {

    hotel = {
        welcomeID: '',
        name: '',
        address: {
            add1: '',
            add2: '',
            add3: '',
            town: '',
            county: {
                id: 4
            },
            country: 'GB',
            postcode: ''
        },
        contact: {
            email: '',
            webUrl: '',
            telephone: '',
            facebook: '',
            twitter: '',
            instagram: ''
        },
        xmas_text: ''
    };

    constructor(
        private overlayService: OverlayService,
        private hotelService: HotelService,
        private store: Store,
        private router: Router,
    ) {

    }

    ngOnInit() {
        console.log(this.hotel);
    }

    save() {
        this.hotelService.save(this.hotel);
        this.back();
    }

    back() {
        this.router.navigate(['/hotels']);
    }
}
