import { Component, OnInit } from '@angular/core';
import {RateGroupService} from '../../services/rate-group.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rate-groups-list',
  templateUrl: './rate-groups-list.component.html',
  styleUrls: ['./rate-groups-list.component.css']
})
export class RateGroupsListComponent implements OnInit {

  data = [];

  constructor(
      private rateGroupService: RateGroupService,
      private router: Router,
  ) { }

  ngOnInit() {
      this.getAll();
  }

  getAll() {
      this.rateGroupService.pricingGroups().subscribe(serviceData => {
          this.data = serviceData.data;
      });
  }

  edit(Id) {
      this.router.navigate(['rategroup', 'edit', Id]);
  }
  delete(Id) {

  }

  saveStatus(g) {
      if (g.status === 1) {
          g.status = 0;
      } else {
          g.status = 1;
      }
      this.rateGroupService.PricingGroupSaveStatus(g).subscribe();
  }

}
