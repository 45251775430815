import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { NgForm } from '@angular/forms';
import { GrowlerService } from '../../services/growler.service';
import { Store } from '../../store';
import { HotelService } from '../../services/hotel.service';
import { RoomService } from '../../services/room.service';
import { BookingService } from '../../services/booking.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private growlerService: GrowlerService,
        private store: Store,
        private hotelService: HotelService,
        private roomService: RoomService,
        private bookingService: BookingService,
    ) {
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['/bookings']);
        }
    }

    onSubmit(form: NgForm) {
        const dataToSend = {
            email: form.controls.email.value,
            password: form.controls.password.value
        };
        this.authService.login(dataToSend).subscribe(data => {
            if (!data.data.authenticated) {
                this.growlerService.error(data.errors);
            } else {
                this.store.set('showMenu', true);
                this.bookingService.search();
                this.hotelService.search();
                this.roomService.search();
                this.roomService.roomTypes();


                this.router.navigate(['/bookings']);
            }
        });
    }
}
