import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImagesService } from '../services/images.service';
import { Store } from '../store';
import { OverlayService } from '../services/overlay.service';

@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {
    @Input() allowSelection: false;
    @Input() type;
    @Input() ownerID;
    @Input() smallThumb = false;
    @Output() imageSelected: EventEmitter<any> = new EventEmitter<any>();

    overlays$ = this.store.select<any>('overlays');

    images = [];
    pagination: any;
    user: any;

    modals = {
        update: false,
        create: false,
        delete: false
    };

    modalData: any = {
    };

    constructor(
        private imagesService: ImagesService,
        private overlayService: OverlayService,
        private store: Store,
    ) {
    }

    ngOnInit() {
        this.pagination = this.imagesService.getPagination();
        this.getImages();
    }

    drop(e) {
        console.log(e)
        // moveItemInArray(this.images, event.previousIndex, event.currentIndex);
        this.updateSortOrder();
    }

    updateSortOrder() {
        this.imagesService.updateSortOrder(this.images).subscribe();
    }


    getImages() {
        this.imagesService.get(this.type, this.ownerID).subscribe(data => {
            console.log(data);
            this.images = data.data;
        });
    }
    // delete(image) {
    //     image.edit = false;
    //     this.imagesService.delete(image).subscribe(data => {
    //         console.log(data);
    //         const idx = this.images.indexOf(image);
    //         this.images.splice(idx, 1);
    //     });
    // }

    selectImage(image) {
        this.imageSelected.emit(image);
    }


    appendImage(e) {
        console.log(e);
        this.images.push(e);
        if (this.allowSelection) {
            this.selectImage(e);
        }
    }

    delete(item) {
        this.overlayService.open('imageDelete');
        this.modals.delete = true;
        this.modalData = item;
    }
    deleteResponse(event) {
        this.modals.delete = event.active;
        if (event.success) {
            for (let i = 0; i < this.images.length; i++) {
                if (this.images[i].id === this.modalData.id) {
                    this.images.splice(i, 1);
                }
            }
        }
    }
    cropImage(image) {
        this.store.set('cropImage', image);
        this.overlayService.open('imageCropper');

    }

}
