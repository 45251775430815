<div class="row">
    <div class="col-md-6">

        <div>
            <h2 class="mat-title">Hotel Details</h2>

            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.name" placeholder="Hotel Name">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.rrId" placeholder="Room Raccoon ID">
                    </mat-form-field>
                </div>
            </div>


            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.roomCount" placeholder="Total rooms">
                    </mat-form-field>
                </div>
            </div>


            <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.promo_text" placeholder="Promotional Text">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.promo_url" placeholder="Promotional URL">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-6">

                    <mat-form-field class="example-full-width">
                        <mat-select
                            [(ngModel)]="hotel.fullPaymentRequired"
                            placeholder="Full payment required at checkout">
                            <mat-option [value]="0">No</mat-option>
                            <mat-option [value]="1">Yes</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6">

                    <mat-form-field class="example-full-width">
                        <mat-select
                            [(ngModel)]="hotel.allowPaymentChoice"
                            placeholder="Allow deposit or full payment at checkout?">
                            <mat-option [value]="0">No</mat-option>
                            <mat-option [value]="1">Yes</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>



            <!-- <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.brandBannerTitle" placeholder="Brand banner title">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.brandBannerDescription" placeholder="Brand banner subtitle">
                    </mat-form-field>
                </div>
            </div> -->


            <!--			<div class="row">-->
<!--				<div class="col-12">-->
<!--					<mat-form-field class="example-full-width">-->
<!--						<textarea matInput [(ngModel)]="hotel.xmas_text" rows="4" placeholder="Christmas email content for Campaign Monitor"></textarea>-->
<!--					</mat-form-field>-->
<!--				</div>-->
<!--			</div>-->



<!--                <div class="row">-->
<!--                <div class="col-8">-->
<!--                    <mat-form-field class="example-full-width">-->
<!--                        <input matInput [(ngModel)]="hotel.review_score.bookingcom.URL" placeholder="booking.com reviews URL">-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--                <div class="col-4">-->
<!--                    <mat-form-field class="example-full-width">-->
<!--                        <input matInput [(ngModel)]="hotel.review_score.bookingcom.score" placeholder="booking.com review score">-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--            </div>-->
<!--    -->
<!--            <div class="row">-->
<!--                <div class="col-8">-->
<!--                    <mat-form-field class="example-full-width">-->
<!--                        <input matInput [(ngModel)]="hotel.review_score.tripadvisor.URL" placeholder="Trip Advisor reviews URL">-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--                <div class="col-4">-->
<!--                    <mat-form-field class="example-full-width">-->
<!--                        <input matInput [(ngModel)]="hotel.review_score.tripadvisor.score" placeholder="Trip Advisor review score">-->
<!--                    </mat-form-field>-->
<!--                </div>-->
<!--            </div>-->


            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.background_colour" placeholder="Hotel Theme Colour">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <div class="hotel_theme_colour" [style.background-color]="'#' + hotel.background_colour"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">

                    <mat-form-field class="example-full-width">
                        <mat-select
                            [(ngModel)]="hotel.allow_dogs"
                            placeholder="Allow dogs?">
                            <mat-option [value]="0">No</mat-option>
                            <mat-option [value]="1">Yes</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="hotel.dogSupplement" placeholder="Dog supplement (&pound;)">
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="margin-top-30">
            <h2 class="mat-title">Contact Details</h2>
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="hotel.contact.telephone" placeholder="Telephone">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="hotel.contact.email" placeholder="Email Address">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="hotel.contact.webUrl" placeholder="Web URL">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input matInput [(ngModel)]="hotel.contact.twitter" placeholder="Twitter URL">
            </mat-form-field>
        </div>

        <div class="margin-top-30">
            <h2 class="mat-title">Contact us messages</h2>
            <p>These are the messages to appear on hotel specific contact tiles</p>

            <div class="row" *ngFor="let message of hotel.contactMessages; let i = index">
                <div class="col-11">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="message.message" placeholder="Enter message">
                    </mat-form-field>

                </div>
                <!-- <div class="col-1 text-right">
                    <a (click)="deleteMessage(i)" class="hover" matTooltip="Delete" style="margin-top: 10px">
                        <i class="fa fa-trash"></i>
                    </a>

                </div> -->
            </div>


            <!-- <button mat-raised-button color="warn" (click)="addMessage()" type="button">
                Add message
            </button> -->
        </div>

    </div>
    <div class="col-md-6">

        <h2 class="mat-title">Hotel Logo</h2>
        <div class="row">
            <div class="col-md-4">

                <img *ngIf="!hotel.logo.URL" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
                <img *ngIf="hotel.logo.URL" [src]="hotel.logo.URL" class="img-fluid" style="max-height: 150px" />
            </div>
            <div class="col-md-8">
                <app-image-upload [ownerID]="hotel.id" [type]="'logo'" [text]="'Click here to upload logo'" (imageUploaded)="setHotelLogo($event)"></app-image-upload>
            </div>
        </div>

        <hr />
            <h2 class="mat-title margin-top-20">Homepage overview text and main banner top section</h2>
            <quill-editor [modules]="config" [(ngModel)]="hotel.strapline" [style]="{'height':'100px'}"></quill-editor>

            <div class="margin-top-20">
                <h2 class="mat-title">Homepage preview title</h2>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="hotel.previewTileOneIcon" placeholder="Title one icon">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="hotel.previewTileOne" placeholder="Title one">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row margin-top-20">
                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="hotel.previewTileTwoIcon" placeholder="Title two icon">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="hotel.previewTileTwo" placeholder="Title two">
                        </mat-form-field>
                    </div>
                </div>

            </div>

            <h2 class="mat-title margin-top-20">Homepage overview text and main banner bottom section</h2>
            <quill-editor [modules]="config" [(ngModel)]="hotel.previewText" [style]="{'height':'200px'}"></quill-editor>


            <div class="margin-top-20">
                <h2 class="mat-title">Hotel overview</h2>
                <quill-editor [modules]="config" [(ngModel)]="hotel.overview" [style]="{'height':'200px'}"></quill-editor>
            </div>

        <div class="margin-top-20">
            <h2 class="mat-title margin-top-20">House Rules</h2>
            <quill-editor [modules]="config" [(ngModel)]="hotel.rules" [style]="{'height':'150px'}"></quill-editor>
        </div>


		<div class="margin-top-20">
            <!-- <p><strong>Here's a bit of info about your Christmas booking</strong></p><p><br></p><p>As much as we want to be there for you it's Christmas and we all need a bit of downtime so once you've had your lovely Christmas Dinner we're all heading off home to open a few pressies and eat.</p> -->
			<h2 class="mat-title margin-top-20">Christmas bookings email content</h2>
			<quill-editor [modules]="config" [(ngModel)]="hotel.xmas_text" [style]="{'height':'100px'}"></quill-editor>
		</div>

    </div>
</div>

