import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from './../../services/product.service';
import { HotelService } from './../../services/hotel.service';
import { Store } from './../../store';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-add-ons',
    templateUrl: './add-ons.component.html',
    styleUrls: ['./add-ons.component.css']
})
export class AddOnsComponent implements OnInit {
    @Input() addOns = [];

    dataToView = undefined;

    availabilitySearch$ = this.store.select<any>('availabilitySearch');
    passportValidated$ = this.store.select<boolean>('passportValidated');
    basket$ = this.store.select<any>('basket');
    hotel$ = this.store.select<any>('hotel');
    error = '';

    c = this.store.selectForLocal('availabilitySearch');

    hotel;
    comments = '';
    showComments = false;

    basketCounts = {};
    productGroups = [];

    constructor(
        private store: Store,
        private hotelService: HotelService,
        private productService: ProductService,
        public route: ActivatedRoute,
    ) { }

    ngOnInit() {
        const availabilitySearch = this.store.selectForLocal('availabilitySearch')

        this.hotel = availabilitySearch.hotel;

        this.getHotel();
        this.setInitComment();

    }

    setInitComment() {
        const basket = this.store.selectForLocal('basket');
        this.comments = basket.comments;
    }
    getHotel() {

        this.hotelService.getHotelBySlug(this.hotel.id).subscribe(data => {
            this.hotel = data;
            this.getAddons();
        })
    }
    getAddons() {
        const basket = this.store.selectForLocal('basket');
        const addOnParams = {
            checkInDay: this.c.checkInDay,
            nights: this.c.nights,
            roomId: this.c.room.id
        }
        this.productService.findByRoom(addOnParams).subscribe(data => {
            this.productGroups = data;
        })
    }

    basketAdd(product, maxPerGuest) {

        if (+product.maxQty === 1) {
            product.qtySelected = 1;
        } else {
            if (+product.qtySelected < +product.maxQty) {
                product.qtySelected = +product.qtySelected + 1;
            }
        }
        const currentPosition = this.basketPosition(product);

        if (currentPosition < 0) {
            this.addOns.push(product);
        } else {
            this.addOns[currentPosition] = product;
        }

    }

    basketRemove(product, maxPerGuest) {
        if (product.qtySelected > 0) {
            product.qtySelected = +product.qtySelected - 1;
        }

        const currentPosition = this.basketPosition(product);

        if (+product.qtySelected === 0) {
            this.addOns.splice(currentPosition, 1)
        } else {
            this.addOns[currentPosition] = product;
        }
    }

    basketPosition(product) {
        if (!this.addOns.length) {
            return -1;
        }
        for (let i = 0; i < this.addOns.length; i++) {
            if (this.addOns[i].id === product.id) {
                return i;
            }
        }
        return -1;
    }

    checkForAnswers() {
        this.error = '';
        const basket = this.store.selectForLocal('basket');
        for (let p = 0; p < this.addOns.length; p++) {

            if (+this.addOns[p].inputRequired === 1 && (!this.addOns[p].answer || this.addOns[p].answer === '')) {
                this.error = `The following requires an answer: ${this.addOns[p].question}`
            }
        }
    }

    info(product, productGroup) {
        this.dataToView = { product, productGroup };
    }
    saveAddOns() {
        const basketBooking = this.store.selectForLocal('basketBooking');
        basketBooking.addOns = this.addOns;
        this.store.set('basketBooking', basketBooking);
    }
}

