<ng-container *ngIf="business">
    <mat-card class="breadcrumb">
        <div class="row">
          <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/feedback/business']">Brands - {{business.name}}</a>
          </div>
          <div class="col-md-2 text-right">
            <!--<button id="pullright" mat-raised-button color="primary" routerLink="/hotels/create">Add Hotel</button>-->
          </div>
        </div>
      </mat-card>


      <div class="row">
          <div class="col-4">
            <mat-card>
                <h5 class="mb-2">{{business.name}} staff included ({{businessStaff.length - businessStaffExcluded.length}})</h5>
                <button
                    class="mb-3"
                    mat-raised-button
                    color="primary"
                    type="button"
                    [disabled]="!(businessStaff.length - businessStaffExcluded.length)"
                    (click)="excludeAll()">Remove all</button>
                <table class="mat-elevation-z2 example-full-width data-listing">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th class="text-right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let s of businessStaff">
                            <tr *ngIf="isExcluded(s.id) < 0">
                                <td>{{s.firstname}} {{s.surname}}</td>
                                <td>
                                    <span *ngIf="!s.email_work">{{s.email}}</span>
                                    <span *ngIf="s.email_work">{{s.email_work}}</span>
                                </td>
                                <td>
                                    <a class="hover" (click)="exclude(s)">
                                        <i class="fa fa-arrow-right"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                </tbody>
                </table>
            </mat-card>
          </div>

          <div class="col-4">
            <mat-card>
                <h5 class="mb-2">{{business.name}} staff excluded ({{businessStaffExcluded.length}})</h5>

                <button
                    class="mb-3"
                    mat-raised-button
                    color="primary"
                    [disabled]="!businessStaffExcluded.length"
                    type="button"
                    (click)="includeAll()">Add all</button>

                <table class="mat-elevation-z2 example-full-width data-listing">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let s of businessStaff">
                            <tr *ngIf="isExcluded(s.id) >= 0">
                                <td>
                                    <a class="hover" (click)="include(s)">
                                        <i class="fa fa-arrow-left"></i>
                                    </a>
                                </td>

                                <td>{{s.firstname}} {{s.surname}}</td>
                                <td>
                                    <span *ngIf="!s.email_work">{{s.email}}</span>
                                    <span *ngIf="s.email_work">{{s.email_work}}</span>
                                </td>
                            </tr>

                        </ng-container>
                </tbody>
                </table>

            </mat-card>
          </div>

          <div class="col-4">
            <mat-card>
                <h5 class="mb-4">Non {{business.name}} staff included</h5>

                <table class="mat-elevation-z2 example-full-width data-listing">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let s of nonBusinessStaff">
                            <tr>
                                <td>{{s.name}}</td>
                                <td>
                                    <span *ngIf="!s.email_work">{{s.email}}</span>
                                    <span *ngIf="s.email_work">{{s.email_work}}</span>
                                </td>
                                <td>
                                    <a class="hover" (click)="removeNonStaff(s)">
                                        <i class="fa fa-times text-danger"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>

                        <tr>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="staffToAdd.name" />
                            </td>
                            <td>
                                <input type="text" class="form-control" [(ngModel)]="staffToAdd.email" />
                            </td>
                            <td>
                                <a class="hover" (click)="addNonStaff()">
                                    <i class="fa fa-plus-circle"></i>
                                </a>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </mat-card>


        </div>
      </div>
</ng-container>
