
<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Assign Gift Card</h5>
                <a class="close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">
                <p class="text-left">Enter the fields to create your digital gift card</p>
                <p>Upon creation this will be available for selection</p>

                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="product.name" placeholder="Gift card name">
                </mat-form-field>


                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Select brand</mat-label>
                    <mat-select [(ngModel)]="product.hotel" style="width: 100%;">
                        <mat-option [value]="v" *ngFor="let v of hotels">{{v.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="product.emailSubject" placeholder="Enter email subject">
                </mat-form-field>

                <p class="mb-1" style="font-size: 12px; color: grey">Email intro text</p>
                <quill-editor [modules]="config" [(ngModel)]="product.emailText" [style]="{'height':'100px'}"></quill-editor>

                <mat-form-field class="example-full-width mt-3">
                    <textarea rows="6" matInput [(ngModel)]="product.pdfText" placeholder="Enter PDF text"></textarea>
                </mat-form-field>

                <div class="mt-3 row">
                    <div class="col-6">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="product.cardValue" placeholder="Voucher Value (£)">
                        </mat-form-field>
                    </div>
                    <div class="col-6">

                    </div>
                </div>


                <div class="text-center">
                    <button class="btn btn-success btn-width-50 mt-3 mb-3" (click)="confirm()">
                        Create Gift Card
                    </button>
                </div>

            </div>
        </div>
    </div>
  </div>

  <div class="modal-backdrop fade show modal-slide-show"></div>

