<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a *ngIf="isNew" [routerLink]="['/events']">Create Event</a>
            <a *ngIf="!isNew" [routerLink]="['/events']">Edit Event</a>
        </div>
        <div class="col-md-5 text-right">
        </div>
    </div>
</mat-card>


<mat-card class="mt-3">
    <div class="row" *ngIf="data">
        <div class="col-6">
            <ng-container *ngIf="isNew">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="data.name" placeholder="Event Name">
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!isNew">
                <div class="row">
                    <div class="col-9">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="data.name" placeholder="Event Name">
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <a class="hover" matTooltip="Copy URL" (click)="copyToClipboard(data)">
                            <mat-icon>content_copy</mat-icon>
                        </a>
                        <a class="hover" matTooltip="View page in new tab" (click)="visitEvent(data)">
                            <mat-icon>link</mat-icon>
                        </a>
                    </div>
                </div>

            </ng-container>


            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width mb-4">
                        <input matInput [(ngModel)]="data.location" placeholder="Event Location">
                        <mat-hint>Where is this event taking place?</mat-hint>
                    </mat-form-field>

                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width mb-4">
                        <input matInput [(ngModel)]="data.mapUrl" placeholder="Event map url">
                        <mat-hint>Google Maps URL</mat-hint>
                    </mat-form-field>

                </div>
            </div>


            <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <input type="search" matInput [(ngModel)]="data.stDate" (ngModelChange)="setEndData()" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Start date">
                        <mat-datepicker #startpicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="data.stTime" placeholder="Start time">
                    </mat-form-field>

                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <input type="search" matInput [(ngModel)]="data.endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Ends">
                        <mat-datepicker #endpicker></mat-datepicker>
                    </mat-form-field>
                    </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="data.endTime" placeholder="End time">
                    </mat-form-field>

                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                matTooltip="The hotel that should receive payment"
                                [(ngModel)]="data.hotel"
                                placeholder="Payment Hotel">
                            <mat-option [value]="hotel.id" *ngFor="let hotel of hotels">{{hotel.name}}</mat-option>
                        </mat-select>
                        <mat-hint>The hotel that should receive payment</mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="data.capacity" placeholder="Max event capacity">
                        <mat-hint>How many tickets to be sold in total</mat-hint>
                    </mat-form-field>
                </div>

            </div>


            <div class="row" style="margin-top: 20px;">
                <div class="col-6">
                    <mat-form-field style="display: block;">
                        <mat-select
                                [(ngModel)]="data.type"
                                placeholder="Event type">
                            <mat-option value="">Event type</mat-option>
                            <mat-option [value]="t.id" *ngFor="let t of eventTypes">{{t.name}}</mat-option>
                        </mat-select>
                        </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="example-full-width">
                        <input matInput [(ngModel)]="data.contactEmail" placeholder="Contact us email address">
                        <mat-hint>Enter contact email for this event</mat-hint>
                    </mat-form-field>
                </div>

            </div>





            <h3 class="mat-title mb-1">Room booking hotel selection</h3>
            <p style="color: rgba(0,0,0,.54); font-size: 11px;">Select the hotels that should show this event at room booking</p>
            <div class="mb-4 mt-1">

                <button
                    class="mr-2 mb-2"
                    *ngFor="let hotel of hotels"
                    mat-raised-button
                    [color]="isHotelSelected(hotel.id) > -1 ? 'primary' : 'grey'"
                    color="primary"
                    (click)="toggleHotel(hotel.id)">
                    {{hotel.name}}
                </button>

            </div>


            <div class="row mb-3">
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.active"
                                placeholder="Active?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                        <mat-hint>Show this event on the site?</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.hideFromCheckout"
                                placeholder="Show on room checkout?">
                                <mat-option [value]="0">Yes</mat-option>
                                <mat-option [value]="1">No</mat-option>
                        </mat-select>
                        <mat-hint>Show this event during room booking process?</mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">

                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.namingRequired"
                                placeholder="Require attendee name?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                        <mat-hint>Do you require attendee names for each ticket?</mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field style="display: block">
                        <mat-select
                                [(ngModel)]="data.allowPassportDiscount"
                                placeholder="Allow passport discount?">
                            <mat-option [value]="1">Yes</mat-option>
                            <mat-option [value]="0">No</mat-option>
                        </mat-select>
                        <mat-hint>Allow passport discount for this event?</mat-hint>
                    </mat-form-field>
                </div>
            </div>






        </div>
        <div class="col-6">

            <h3 class="mat-title">Lead Banner Image</h3>
            <div class="row">
                <div class="col-md-4">
                    <img *ngIf="!data.bannerImage" src="/assets/images/nia.png" class="img-fluid" style="max-height: 150px" />
                    <img *ngIf="data.bannerImage" [src]="data.bannerImage" class="img-fluid" style="max-height: 150px" />
                </div>
                <div class="col-md-8">
                    <app-image-upload *ngIf="data.id" [ownerID]="data.id" [type]="'eventBannerImage'" [text]="'Click here to upload image'" (imageUploaded)="setEventBannerImage($event)"></app-image-upload>
                </div>
            </div>
            <!-- <div class="row mt-4">
                <div class="col-2" *ngFor="let img of data.images">
                    <img [src]="img.URL" style="height: 60px;" />
                </div>
            </div> -->

            <!-- <h3 class="mat-title">Event Gallery</h3>
            <div class="row">
                <div class="col-md-12">
                    <app-image-upload *ngIf="data.id" [ownerID]="data.id" [type]="'eventImage'" [text]="'Click here to upload image'" (imageUploaded)="setEventImage($event)"></app-image-upload>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-2 text-center" *ngFor="let img of data.images; let idx = index">
                    <img [src]="img.URL" style="height: 60px;" />
                    <a class="hover text-center mt-2" (click)="deleteImage(idx)">
                        <i class="fa fa-trash text-danger"></i>
                    </a>
                </div>
            </div> -->

            <div class="mb-3 mt-4">
                <h3 class="mat-title">Event Description</h3>

                <quill-editor [modules]="config" [(ngModel)]="data.description" [style]="{'height':'200px'}"></quill-editor>

            </div>


            <div class="mb-4">

                <h3 class="mat-title">Terms and Conditions</h3>

                <quill-editor [modules]="config" [(ngModel)]="data.terms" [style]="{'height':'200px'}"></quill-editor>
            </div>

        </div>
    </div>



    <div class="row">
        <div class="col-6">
            <h2 class="mat-title">Event Ticket Types</h2>
        </div>
        <div class="col-6 text-right">
            <button mat-raised-button color="primary" (click)="addSku()">Add Ticket Type</button>
        </div>
    </div>

    <div class=" text-small">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-3">Ticket name</div>
            <div class="col-3">Notes</div>
            <div class="col-1">Max tickets for sale</div>
            <div class="col-1">Max tickets for purchase</div>
            <div class="col-1">Ticket price</div>
            <div class="col-2"></div>
        </div>
        <div
        class="row"
        dragula="priCategory"
        [(dragulaModel)]="data.skus"
        (dragulaModelChange)="drop($event)">
        <ng-container *ngFor="let sku of data.skus; let i = index">
            <div
            class="col-12 mb-2"
            [ngClass]="{'bg-danger' : sku.deleted}">
            <div class="row data-tile align-items-center">
                <div class="col-1">
                    <span class="label label-info hover">
                        <i class="fa fa-bars"></i>
                    </span>

                </div>
                <div class="col-3">
                    {{sku.name}}
                </div>
                <div class="col-3">
                    {{sku.notes}}
                </div>
                <div class="col-1 ">{{sku.capacity}}</div>
                <div class="col-1">{{sku.maxTickets}}</div>
                <div class="col-1">{{sku.price | currency: 'GBP'}}</div>
                <div class="col-2">
                    <div class="row">
                        <div class="col-4 text-center">
                            <a class="hover"  (click)="toggleStatusSku(i)" class="hover">
                                <mat-icon class="text-success" *ngIf="sku.active">check</mat-icon>
                                <mat-icon class="text-danger" *ngIf="!sku.active">remove</mat-icon>
                            </a>

                        </div>
                        <div class="col-4 text-center">
                            <a class="hover" (click)="editSku(sku)">
                                <i class="fa fa-edit"></i>
                            </a>
                        </div>
                        <div class="col-4 text-center">
                            <a class="hover" (click)="toggleDeleteSku(i)">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="data-tile" [ngClass]="{'bg-danger' : sku.deleted}">
                <div class="mt-2">
                    <p class="mb-1"></p>
                    <table>
                        <tr>
                            <th>Notes:</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Max tickets for sale</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Max tickets per purchase</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Ticket price</th>
                            <td>{{sku.price | currency: 'GBP'}}</td>
                        </tr>
                    </table>

                </div>
            </div> -->

        </div>

        </ng-container>
    </div>

    </div>



    <!-- <table class="example-full-width data-listing mb-4">
        <thead>
        <tr>
            <th>Name</th>
            <th>Notes</th>
            <th>Max tickets for sale</th>
            <th>Max tickets per purchase</th>
            <th>Price</th>
            <th>Active</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let sku of data.skus; let i = index" [ngClass]="{'tr-odd': isOdd(i), 'bg-danger' : sku.deleted}">
            <td>{{sku.name}}</td>

            <td>{{sku.notes}}</td>
            <td>{{sku.capacity}}</td>
            <td>{{sku.maxTickets}}</td>
            <td>{{sku.price | currency: 'GBP'}}</td>
            <td style="width: 175px">
                <a  (click)="toggleStatusSku(i)" class="hover">
                    <mat-icon class="text-success" *ngIf="sku.active">check</mat-icon>
                    <mat-icon class="text-danger" *ngIf="!sku.active">remove</mat-icon>
                </a>
            </td>

            <td class="text-right">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editSku(sku)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="toggleDeleteSku(i)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </tr>
        </tbody>
    </table> -->



    <div class="text-right mb-4" *ngIf="data.skus.length">
        <button mat-raised-button color="primary" (click)="save()">Save</button>
        <div class="text-danger mt-2" *ngIf="errorString">{{errorString}}</div>
    </div>

    <app-event-sku-edit *ngIf="dataToEdit" [data]="dataToEdit" (complete)="dataEditComplete($event)"></app-event-sku-edit>
</mat-card>
