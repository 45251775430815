<ng-container *ngIf="featureGroups.length">
    <table class="mat-elevation-z2 example-full-width data-listing" style="margin-bottom: 30px" *ngFor="let fg of featureGroups">
        <thead>
        <tr>
            <th style="width: 33%">Feature Group</th>
            <th style="width: 33%">Feature Name</th>
            <th style="width: 33%">Selected</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let f of fg.features">
            <td>{{fg.name}}</td>
            <td>{{f.name}}</td>
            <td>
                <a *ngIf="f.selected" (click)="toggleFeature(f)">
                    <mat-icon style="color: #28a745">check_circle</mat-icon>
                </a>
                <a *ngIf="!f.selected" (click)="toggleFeature(f)">
                    <mat-icon style="color: #dddddd">check_circle_outline</mat-icon>
                </a>
            </td>
        </tr>
        
        </tbody>
    </table>


</ng-container>
