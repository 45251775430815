import { CmsService } from './../services/cms.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cms-contact',
    templateUrl: './cms-contact.component.html',
    styleUrls: ['./cms-contact.component.css']
})
export class CmsContactComponent implements OnInit {

    contact = {
        generic: '',
        textBlock: '',
        brand: '',
        q1: '',
        q2: '',
        q3: '',
    }
    constructor(
        private cmsService: CmsService,
    ) { }

    ngOnInit() {
        this.get();
    }

    get() {
        this.cmsService.getContactTile().subscribe(data => {
            this.contact = data.data;
        })
    }
    save() {
        this.cmsService.updateContactTile(this.contact).subscribe();
    }
}
