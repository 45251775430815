import {Component, Input, OnInit} from '@angular/core';
import {ChartService} from '../../services/chart.service';
import {Store} from '../../store';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-chart-assessment-scores',
  templateUrl: './chart-assessment-scores.component.html',
  styleUrls: ['./chart-assessment-scores.component.css']
})
export class ChartAssessmentScoresComponent implements OnInit {

    chart: any;

    constructor(
        private chartService: ChartService,
        private store: Store,
    ) { }

    ngOnInit() {
        this.chart = new Chart('assessmentScores', {
          type: 'bar',
          data: {
            labels: ['Jun 18', 'Jul 18', 'Aug 18', 'Sep 18', 'Oct 18', 'Nov 18'],
            datasets: [
              {
                backgroundColor: this.chartService.chartColours,
                label: 'Cancellations',
                data: [4, 6, 9, 3, 2, 4 ],
                yAxisID: 'y-axis-1'
              },
              {
                // backgroundColor: this.chartService.chartColours,
                label: 'Bookings',
                data: [120, 159, 152, 108, 110, 123],
                fill: false,
                type: 'line',
                yAxisID: 'y-axis-2'
              },

            ]
          },
          options: {
            height: 500,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: {
                  display: false
                },
                labels: {
                  show: true,

                },
                ticks: {
                  beginAtZero: true,
                }
              }, {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                  display: false
                },
                labels: {
                  show: true,

                },
                ticks: {
                  beginAtZero: true,
                }
              }]


            }
          }
        });
        this.chart.update();
    }

}
