<div *ngIf="view === 'calendar'">
	
	<div class="calendar-holder">
		<div style="height: 30px">
			<div class="top-bar-direction-box" (click)="monthBack()">
				<i class="fa fa-chevron-left"></i>
			</div>
			<div class="top-bar-date-box" (click)="setView('month')">
				{{monthName}}
				<i class="fa fa-caret-down"></i>
			</div>
			<div class="top-bar-date-box" (click)="setView('year')">
				{{theYear}}
				<i class="fa fa-caret-down"></i>
			</div>
			<div class="top-bar-direction-box" (click)="monthForward()">
				<i class="fa fa-chevron-right"></i>
			</div>
		</div>
		<div class="day-bar">
			<div class="calendar-holder-day-of-week">M</div>
			<div class="calendar-holder-day-of-week">T</div>
			<div class="calendar-holder-day-of-week">W</div>
			<div class="calendar-holder-day-of-week">T</div>
			<div class="calendar-holder-day-of-week">F</div>
			<div class="calendar-holder-day-of-week">S</div>
			<div class="calendar-holder-day-of-week">S</div>
		</div>
		<div style="clear-after: both; height: 185px">
			<ng-container *ngFor="let day of days">
				<div
						class="calendar-holder-day-blank"
						*ngIf="day === 0">
				</div>
				<div
						class="calendar-holder-day"
						[ngClass]="{'today': dateToOutput.y === theYear && dateToOutput.m === theMonth && dateToOutput.d === day, 'date-selected': isDateSelected(day, theMonth, theYear)}"
						(click)="setDay(day)"
						*ngIf="day > 0">
					<span>{{day}}</span>
				</div>
			</ng-container>
		</div>
	
	</div>

</div>
<div class="calendar-holder" *ngIf="view === 'month'">
	<a class="btn-close" (click)="setView('calendar')">X</a>
	<div class="row no-gutters margin-top-40">
		<div class="col-3"
			 *ngFor="let m of monthsArray" (click)="setMonth(m)">
			<div
					class="month-selector"
					[ngClass]="{'cal-selected' : m === theMonth}">
				{{monthNames[m].name}}
			</div>
		</div>
	</div>
</div>
<div class="calendar-holder" *ngIf="view === 'year'">
	<a class="btn-close" (click)="setView('calendar')">X</a>
	<div class="row no-gutters margin-top-40">
		<div class="col-3"
			 *ngFor="let y of yearsArray" (click)="setYear(y)">
			<div
					class="year-selector"
					[ngClass]="{'cal-selected' : y === theYear}">
				{{y}}
			</div>
		</div>
	</div>
</div>
