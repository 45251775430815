import { ReportingService } from './../../services/reporting.service';
import { Store } from '../../store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VoucherService } from 'src/app/services/voucher.service';

@Component({
    selector: 'app-confirm-reporting-export',
    templateUrl: './confirm-reporting-export.component.html',
    styleUrls: ['./confirm-reporting-export.component.css']
})
export class ConfirmReportingExportComponent implements OnInit {
    @Input() params;
    @Input() segmentSelected;
    @Input() exportConfirmationData;
    @Output() complete = new EventEmitter();

    user$ = this.store.select<any>('user');
    filename = '';
    url = '';
    exportCompleted = false;

    vouchers = [];
    voucherSelected;

    constructor(
        private store: Store,
        private reportingService: ReportingService,
        private voucherService: VoucherService,
    ) { }

    ngOnInit() {
        if (this.segmentSelected) {
            this.filename = this.segmentSelected.name
        }
        this.getVouchers();
    }

    getVouchers() {
        this.voucherService.getVouchers().subscribe(data => {
            this.vouchers = data.data;
        })
    }

    confirm() {

        this.reportingService.export(this.params, this.segmentSelected, this.filename, this.exportConfirmationData.type, this.voucherSelected).subscribe(data => {
            // this.search();
            console.log(data.data);
            if (data.data.success) {
                this.url = data.data.url;
                this.exportCompleted = true;
            } else {
            }
            // this.complete.emit({ action: true, filename: this.filename });
            console.log(data);
        });


    }
    cancel() {
        this.complete.emit({ action: false });
    }
}
